import perlegoBooks from 'assets/icons/perlego-books@2x.png';
import safetyWings from 'assets/icons/safety-wings.svg';
import SIXTlogo from 'assets/images/SIXT@2x.png';
import airalo from 'assets/images/airalo@2x.png';
import beehiiv from 'assets/images/beehiiv@2x.png';
import betterHelp from 'assets/images/better_help.png';
import corel from 'assets/images/corel@2x.png';
import freshBooks from 'assets/images/fresh-books@2x.png';
import genki from 'assets/images/genki@2x.png';
import headout from 'assets/images/headout@2x.png';
import littleEmperors from 'assets/images/little_emperors@2x.png';
import mentorCruise from 'assets/images/mentor-cruise@2x.png';
import moreMines from 'assets/images/mode-mines@2x.png';
import moralisAcademy from 'assets/images/moralis_academy@2x.png';
import parallels from 'assets/images/parallels@2x.png';
import spotahome from 'assets/images/spotahome@2x.png';
import synthesia from 'assets/images/synthesia@2x.png';
import wise from 'assets/images/wise@2x.png';
import womenInTech from 'assets/images/women-in-tech@2x.png';
import worknmates from 'assets/images/worknmates@2x.png';
import React from 'react';

import { Box } from '@mui/material';

import { OptionType } from '@libs/ui/components/form/select';
import Typography from '@libs/ui/components/typography';

import { PromoActions, UrlActions } from './perk-card';
import { PerkCardV2Props } from './perk-card-2';

const WINGS: PerkCardV2Props = {
  title: 'Remote Health with up to 35% off individual rates.',
  subtitle: 'Insurance',
  couponText: '-35%',
  text: (
    <Box>
      <Typography variant="body2" color="textSecondary">
        Live, work and travel in 175+ countries worldwide with network-free
        access to the best healthcare wherever you are located.
      </Typography>
    </Box>
  ),
  logo: safetyWings,
  serviceName: 'SafetyWing',
  actions: (
    <UrlActions url="https://safetywing.com/remote-health/member/signup/OpenTalent" />
  ),
};
const BETTER_HELP: PerkCardV2Props = {
  title: '50% off the first month of professional online therapy',
  subtitle: 'Health & Wellness',
  couponText: '-50%',
  text: (
    <Box>
      <Typography variant="body2" color="textSecondary">
        BetterHelp is the world's largest professional online therapy platform.
        Over 3 Million people worldwide have received help from a credentialed
        therapist on BetterHelp. Fill out a questionnaire and get matched to a
        therapist.
      </Typography>
    </Box>
  ),
  logo: betterHelp,
  logo2x: betterHelp,
  serviceName: 'BetterHelp',
  actions: <UrlActions url="http://www.betterhelp.com/opentalent" />,
};
const PERLEGO_BOOKS: PerkCardV2Props = {
  title: '15% discount on monthly and annual plan.',
  subtitle: 'Learning & EdTech',
  couponText: '-15%',
  text: (
    <Box>
      <Typography variant="body2" color="textSecondary">
        Read and learn without limits. Access over 1 million academic titles and
        top nonfiction bestsellers, all for less than the price of a single
        book.
      </Typography>
    </Box>
  ),
  logo: perlegoBooks,
  logo2x: perlegoBooks,
  serviceName: 'Perlego Books',
  actions: (
    <UrlActions url="https://www.perlego.com/?irclickid=RJ723IXW2xyNW4R0t9xkiVtHUkFzA5T0-0ksz40&utm_source=OpenTalent&utm_medium=&utm_campaign=Open%20Talent%3A%2015%25%20off%2012%20months&utm_content=COUPON&discount=OPENTALENT15&irgwc=1" />
  ),
};

const WORKNMATES: PerkCardV2Props = {
  title: '15% discount in more than 2500 co-workings across 25 countries.',
  subtitle: 'Co-working',
  couponText: '-15%',
  text: (
    <Box>
      <Typography variant="body2" color="textSecondary">
        Embrace the remote work revolution with Worknmates! Register now for
        access to 2500+ co-working spaces and receive your exclusive promotional
        code via email.
      </Typography>
    </Box>
  ),
  logo: worknmates,
  logo2x: worknmates,
  serviceName: 'Worknmates',
  actions: <UrlActions url="https://worknmates.com/perk/129" />,
};

const MOREMINS: PerkCardV2Props = {
  title: '50% off for the first year.',
  subtitle: 'Lifestyle & Mobility',
  couponText: '-50%',
  text: (
    <Box>
      <Typography variant="body2" color="textSecondary">
        MoreMins is a UK-based digital mobile operator offering: Virtual phone
        numbers of different countries; Low-cost international calls and SMS;
        Cheap eSIM data for international traveling.
      </Typography>
    </Box>
  ),
  logo: moreMines,
  logo2x: moreMines,
  serviceName: 'MoreMins',
  actions: (
    <UrlActions url="https://www.moremins.com/en/app/login?ref=otc5otq" />
  ),
};

const GENKI: PerkCardV2Props = {
  title: 'Worldwide Insurance starting at €39.30/person/month.',
  subtitle: 'Insurance',
  couponText: 'DEAL',
  text: (
    <Box>
      <Typography variant="body2" color="textSecondary">
        Genki emerges as a reliable provider of worldwide health insurance
        products, catering to the needs of frequent and long-term travelers.
        With a commitment to offering flexible and extensive coverage, Genki
        ensures travelers can explore the world peacefully.
      </Typography>
    </Box>
  ),
  logo: genki,
  logo2x: genki,
  serviceName: 'Genki',
  actions: <UrlActions url="https://www.genki.world/with/opentalent" />,
};

const SPOTAHOME: PerkCardV2Props = {
  title: '20% discount on the registration fee',
  subtitle: 'Lifestyle & Mobility',
  couponText: '-20%',
  text: (
    <Box>
      <Typography variant="body2" color="textSecondary">
        Spotahome is an international online marketplace that offers verified
        rental accommodation across 100 European cities in 27 countries and with
        a global ambition. You need to add that they need to add the promo code
        at the time of the booking
      </Typography>
    </Box>
  ),
  logo: spotahome,
  logo2x: spotahome,
  serviceName: 'Spotahome',
  actions: (
    <PromoActions
      code="OPENTALENT20"
      url="https://www.spotahome.com"
      moreInfoUrl="https://main.ottest.co/files/one-pager_opentalent20_spotahome_promo_code.pdf"
    />
  ),
};

const MORALIS_ACADEMY: PerkCardV2Props = {
  title: 'Pay 50% less forever',
  subtitle: 'Learning & EdTech',
  couponText: '-50%',
  text: (
    <Box>
      <Typography variant="body2" color="textSecondary">
        Moralis Academy is a world-leading Blockchain, Crypto, and Web3
        e-Learning provider. Our instructors live and breathe everything
        Blockchain.
      </Typography>
    </Box>
  ),
  logo: moralisAcademy,
  logo2x: moralisAcademy,
  serviceName: 'Moralis Academy',
  actions: (
    <UrlActions url="https://academy.moralis.io/direct-sales?ref=3399" />
  ),
};

const LITTLE_EMPERORS: PerkCardV2Props = {
  title: '1-year complimentary Travel membership',
  subtitle: 'Lifestyle & Mobility',
  couponText: '-£299',
  text: (
    <Box>
      <Typography variant="body2" color="textSecondary">
        Little Emperors is a members-only luxury hotel travel club, for business
        and leisure travel. With the lowest rates guaranteed, ground-breaking
        technology, and packed with relevant content, Little Emperor is a
        game-changer in the world of luxury travel.
      </Typography>
    </Box>
  ),
  logo: littleEmperors,
  logo2x: littleEmperors,
  serviceName: 'Little Emperors',
  actions: (
    <PromoActions
      url="https://littleemperors.com/opentalent"
      code="opentalent"
      moreInfoUrl="https://main.ottest.co/files/introducing_little_emperors.pdf"
    />
  ),
};

const HELLO_AI: PerkCardV2Props = {
  title: '50% off on  AI-Language Training',
  subtitle: 'Learning & EdTech',
  couponText: '-50%',
  text: (
    <Box>
      <Typography variant="body2" color="textSecondary">
        HalloAI helps global teams improve their language and communication
        skills. Give everyone on your team 50+ languages to learn with an AI
        tutor. Employees learn quickly with our conversation-focused lessons.
      </Typography>
    </Box>
  ),
  logo: 'https://www.app.hallo.tv/static/media/hallo_primary_logo.4d2d37a4.png',
  logo2x:
    'https://www.app.hallo.tv/static/media/hallo_primary_logo.4d2d37a4.png',
  serviceName: 'HelloAI',
  actions: (
    <PromoActions
      url="http://www.app.hallo.tv/hallo-plus"
      code="HalloOpenTalent23"
    />
  ),
};

const AIRALO: PerkCardV2Props = {
  title: '10% discount on all services.',
  subtitle: 'Productivity',
  couponText: '-10%',
  text: (
    <Box>
      <Typography variant="body2" color="textSecondary">
        With Airalo's eSIMs, you can ditch expensive roaming and physical SIM
        cards, enjoying reliable connectivity in over 190 countries worldwide.
      </Typography>
    </Box>
  ),
  logo: airalo,
  logo2x: airalo,
  serviceName: 'airalo',
  actions: (
    <PromoActions
      url="https://www.airalo.com/auth/signup"
      code="OPENTALENT10"
    />
  ),
};

const BEEHIIV: PerkCardV2Props = {
  title: '20% off for the first 3 months.',
  subtitle: 'Productivity',
  couponText: '-20%',
  text: (
    <Box>
      <Typography variant="body2" color="textSecondary">
        Beehiiv is an all-in-one platform for content creators, streamlining the
        creation, distribution, and monetization of newsletters with its
        user-friendly tools and features.
      </Typography>
    </Box>
  ),
  logo: beehiiv,
  logo2x: beehiiv,
  serviceName: 'beehiiv',
  actions: (
    <PromoActions url="https://www.beehiiv.com/?via=opentalent" code="OT20" />
  ),
};

const WISE: PerkCardV2Props = {
  title: 'Fastest currency transfer at exciting rates.',
  subtitle: 'Finance',
  couponText: 'DEAL',
  text: (
    <Box>
      <Typography variant="body2" color="textSecondary">
        Wise, is a financial technology company that offers international money
        transfers at real exchange rates. Known for its speed and cost-effective
        services, Wise provides individuals and businesses with a more
        affordable way to send money across borders.
      </Typography>
    </Box>
  ),
  logo: wise,
  logo2x: wise,
  serviceName: 'Wise',
  actions: <UrlActions url="https://wise.prf.hn/l/OV9zkLZ" />,
};

const SYNTHESIA: PerkCardV2Props = {
  title: '30% off for the first month.',
  subtitle: 'Productivity',
  couponText: '-30%',
  text: (
    <Box>
      <Typography variant="body2" color="textSecondary">
        Synthesia is the #1 rated AI video creation platform. Thousands of
        companies use it to create videos in 120 languages, saving up to 80% of
        their time and budget. Trusted by Accenture, BBC, Google, Amazon and
        more.
      </Typography>
    </Box>
  ),
  logo: synthesia,
  logo2x: synthesia,
  serviceName: 'Synthesia',
  actions: <UrlActions url="https://www.synthesia.io/?via=opentalent" />,
};

const MENTOR_CRUISE: PerkCardV2Props = {
  title: '$20 in credit to be used for any session',
  subtitle: 'Learning & EdTech',
  couponText: '-20%',
  text: (
    <Box>
      <Typography variant="body2" color="textSecondary">
        MentorCruise connects individuals seeking guidance in tech and business
        with experienced mentors in the field. Through personalized mentorship
        sessions, learners receive tailored advice, resources, and support to
        achieve their career goals.
      </Typography>
    </Box>
  ),
  logo: mentorCruise,
  logo2x: mentorCruise,
  serviceName: 'MentorCruise',
  actions: <UrlActions url="https://mentorcruise.com/partner/opentalent/" />,
};

const FRESH_BOOKS: PerkCardV2Props = {
  title: '50% off upto 5 month',
  subtitle: 'Accounting',
  couponText: '-50%',
  text: (
    <Box>
      <Typography variant="body2" color="textSecondary">
        FreshBooks is a cloud-based accounting software designed specifically
        for small businesses, freelancers, and entrepreneurs. It offers features
        for invoicing, expense tracking, time tracking, and financial reporting
        to simplify financial management and bookkeeping.
      </Typography>
    </Box>
  ),
  logo: freshBooks,
  logo2x: freshBooks,
  serviceName: 'FreshBooks',
  actions: <UrlActions url="https://shrsl.com/48b7z" />,
};

const COREL: PerkCardV2Props = {
  title: 'Exclusive discounts on select products',
  subtitle: 'Productivity',
  couponText: 'DEAL',
  text: (
    <Box>
      <Typography variant="body2" color="textSecondary">
        Corel, a powerhouse in creative software, ignites the canvas of digital
        artists with tools like CorelDRAW and Painter. Unleashing vibrant
        graphics and immersive designs, it's where creativity meets innovation.
      </Typography>
    </Box>
  ),
  logo: corel,
  logo2x: corel,
  serviceName: 'Corel',
  actions: <UrlActions url="https://prf.hn/l/RmV5gbn" />,
};

const PARALLELS: PerkCardV2Props = {
  title: '10% off on Parallels products/subscriptions',
  subtitle: 'Productivity',
  couponText: '-10%',
  text: (
    <Box>
      <Typography variant="body2" color="textSecondary">
        With Parallels Desktop, the walls between macOS and Windows dissolve,
        letting you run both side-by-side seamlessly. Dive into the best of both
        worlds, without compromise or rebooting.
      </Typography>
    </Box>
  ),
  logo: parallels,
  logo2x: parallels,
  serviceName: 'Parallels',
  actions: <PromoActions code="58L-KFB-931" url="https://prf.hn/l/RmV5gbn" />,
};

const HEADOUT: PerkCardV2Props = {
  title: 'Exciting discounts on curated experiences',
  subtitle: 'Lifestyle & Mobility',
  couponText: 'DEAL',
  text: (
    <Box>
      <Typography variant="body2" color="textSecondary">
        Headout is your magic carpet to the world's most exhilarating
        experiences and local adventures. From Broadway shows to desert safaris,
        it's a passport to unforgettable moments at the touch of a button.
      </Typography>
    </Box>
  ),
  logo: headout,
  logo2x: headout,
  serviceName: 'Headout',
  actions: (
    <UrlActions url="https://www.headout.com/r/opentalentrecommends-BWLRw/" />
  ),
};

const SIXT: PerkCardV2Props = {
  title: 'Save up to 10% on SIXT rentals worldwide!',
  subtitle: 'Lifestyle & Mobility',
  couponText: '-10%',
  text: (
    <Box>
      <Typography variant="body2" color="textSecondary">
        SIXT stands for exciting mobility and tailor-made solutions. With more
        than 2,200 branches in more than 100 countries, SIXT is your perfect
        mobility partner – wherever you go, for business or leisure, SIXT has
        the perfect rental car for your needs.
      </Typography>
    </Box>
  ),
  logo: SIXTlogo,
  logo2x: SIXTlogo,
  serviceName: 'SIXT',
  actions: <UrlActions url="http://www.sixt.co.uk/opentalent" />,
};

const WOMEN_IN_TECH: PerkCardV2Props = {
  title: '20% discount on ticket booking',
  subtitle: 'Learning & EdTech',
  couponText: '-20%',
  text: (
    <Box>
      <Typography variant="body2" color="textSecondary">
        Get ready to immerse yourself in Digital X Human at European Women in
        Technology, Europe’s leading tech event for women.
      </Typography>
    </Box>
  ),
  logo: womenInTech,
  logo2x: womenInTech,
  serviceName: 'European Women in Tech',
  actions: (
    <PromoActions
      code="OPENTALENT20"
      moreInfoUrl="https://europeanwomenintech.com/"
      url="https://europeanwomenintech.com/buy-tickets/"
    />
  ),
};

export const CARDS_DATA = [
  BETTER_HELP,
  WINGS,
  PERLEGO_BOOKS,
  WORKNMATES,
  MOREMINS,
  GENKI,
  SPOTAHOME,
  MORALIS_ACADEMY,
  LITTLE_EMPERORS,
  HELLO_AI,
  AIRALO,
  BEEHIIV,
  WISE,
  SYNTHESIA,
  MENTOR_CRUISE,
  FRESH_BOOKS,
  COREL,
  PARALLELS,
  HEADOUT,
  SIXT,
  WOMEN_IN_TECH,
];

export const CATEGORIES_OPTIONS: OptionType[] = Array.from(
  new Set(CARDS_DATA.map((i) => i.subtitle!).filter(Boolean)),
).map((i) => ({ text: i, value: i }));
