import { useCurrentUser } from 'hooks/auth';
import { useIsPaidTalentAccount } from 'hooks/talents/useTalentAccountType';
import React from 'react';

import { CreateTalentCommnunityMiddleContent } from './CreateTalentCommnunityMiddleContent';
import { PendingVerificationTalentContent } from './PendingVerificationTalentContent';

interface MiddleContentProps { }

export const MiddleContent = (props: MiddleContentProps) => {
  const { data } = useCurrentUser();
  const isPaidTalent = useIsPaidTalentAccount();
  const talent = data?.currentTalent;

  if (!!talent && !isPaidTalent) {
    return <PendingVerificationTalentContent />;
  }

  if (!!talent) {
    return <CreateTalentCommnunityMiddleContent />;
  }
  return <div></div>;
};
