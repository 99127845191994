import predicates from 'auth0/predicates';
import React from 'react';
import PrivateRoute from 'routes/PrivateRoute';
import { pathManager } from 'routes/consts';
import LazyProfile from 'screens/profile';
import SourceJob from 'screens/talent-matcher/source-job/SourceJob';
import CommunityPage from 'screens/talent/community';
import { ViewCompany, CompaniesScreen } from 'screens/talent/companies';
import CreateTalentCommunity from 'screens/talent/create-talent-community';
import EditProfile from 'screens/talent/edit-profile';
import Groups from 'screens/talent/groups';
import InviteByTalent from 'screens/talent/invite-by-talent';
import JobApply from 'screens/talent/job-apply';
import TalentJobBoardSwitcher from 'screens/talent/job-board-v2/TalentJobBoardSwitcher';
import TalentJobInvitation from 'screens/talent/job-invitation';
import TalentLobby from 'screens/talent/lobby';
import MarkedJobs from 'screens/talent/marked-jobs';
import PendingVerification from 'screens/talent/pending-verification';
import TalentSettings from 'screens/talent/settings';
import Subscription from 'screens/talent/subscription';
import TalentPageForTalent from 'screens/talent/view-profile';

export const TALENT_ROUTES = [
  // PAGES WITH RECRUITERS VIEW
  <PrivateRoute
    predicate={predicates.isTalent}
    exact
    component={TalentJobBoardSwitcher}
    path={pathManager.talent.jobBoard.getRoute()}
    key={pathManager.talent.jobBoard.getRoute()}
  />,
  <PrivateRoute
    predicate={predicates.isTalent}
    exact
    component={SourceJob}
    path={pathManager.recruiter.sourceJob.getRoute()}
    key={pathManager.recruiter.sourceJob.getRoute()}
  />,
  <PrivateRoute
    predicate={predicates.isTalent}
    exact
    component={CompaniesScreen}
    path={pathManager.talent.companies.main.getRoute()}
    key={pathManager.talent.companies.main.getRoute()}
  />,
  <PrivateRoute
    predicate={predicates.isTalent}
    exact
    component={ViewCompany}
    path={pathManager.talent.companies.viewCompany.getRoute()}
    key={pathManager.talent.companies.viewCompany.getRoute()}
  />,

  <PrivateRoute
    predicate={predicates.isTalent}
    exact
    component={TalentLobby}
    path={[
      pathManager.talent.lobby.main.getRoute(),
      pathManager.talent.lobby.member.getRoute(),
      pathManager.talent.lobby.matcher.getRoute(),
      pathManager.talent.lobby.info.getRoute(),
    ]}
    key={pathManager.talent.lobby.main.getRoute()}
  />,
  <PrivateRoute
    predicate={predicates.isTalent}
    exact
    component={TalentPageForTalent}
    path={pathManager.talent.viewOtherTalent.getRoute()}
    key={pathManager.talent.viewOtherTalent.getRoute()}
  />,
  <PrivateRoute
    predicate={predicates.isTalent}
    exact
    component={CommunityPage}
    path={pathManager.talent.community.getRoute()}
    key={pathManager.talent.community.getRoute()}
  />,
  <PrivateRoute
    predicate={predicates.isTalent}
    exact
    component={Subscription}
    path={pathManager.talent.subscription.getRoute()}
    key={pathManager.talent.subscription.getRoute()}
  />,

  // NORMAL PAGES
  <PrivateRoute
    component={PendingVerification}
    predicate={predicates.isTalent}
    path={pathManager.talent.pendingVerificaion.getRoute()}
    key={pathManager.talent.pendingVerificaion.getRoute()}
  />,

  <PrivateRoute
    component={CreateTalentCommunity}
    predicate={predicates.isTalent}
    path={pathManager.talent.createCommunity.getRoute()}
    key={pathManager.talent.createCommunity.getRoute()}
  />,

  <PrivateRoute
    component={JobApply}
    predicate={predicates.isTalent}
    path={pathManager.talent.jobApply.getRoute()}
    key={pathManager.talent.jobApply.getRoute()}
  />,
  <PrivateRoute
    component={MarkedJobs}
    predicate={predicates.isTalent}
    path={[pathManager.talent.markedJobs.getRoute()]}
    key={pathManager.talent.markedJobs.getRoute()}
  />,
  <PrivateRoute
    component={TalentJobInvitation}
    predicate={predicates.isTalent}
    path={pathManager.talent.jobInvitation.getRoute()}
    key={pathManager.talent.jobInvitation.getRoute()}
  />,
  <PrivateRoute
    exact
    predicate={predicates.isTalent}
    path={pathManager.talent.editProfile.getRoute()}
    key={pathManager.talent.editProfile.getRoute()}
    component={EditProfile}
  />,
  <PrivateRoute
    component={LazyProfile}
    predicate={predicates.isTalent}
    exact
    path={pathManager.talent.profile.getRoute()}
    key={pathManager.talent.profile.getRoute()}
  />,
  <PrivateRoute
    component={InviteByTalent}
    predicate={predicates.isTalent}
    exact
    path={pathManager.talent.invite.getRoute()}
    key={pathManager.talent.invite.getRoute()}
  />,
  <PrivateRoute
    component={Groups}
    predicate={predicates.isTalent}
    path={pathManager.talent.groups.getRoute()}
    key={pathManager.talent.groups.getRoute()}
  />,
  <PrivateRoute
    component={TalentSettings}
    predicate={predicates.isTalent}
    path={pathManager.talent.settings.main.getRoute()}
    key={pathManager.talent.settings.main.getRoute()}
  />,
];
