import { useApolloClient } from '@apollo/client';
import { UserInterface } from 'auth0/types';
import { AuthUtils } from 'auth0/utils';
import {
  CurrentUserContextProviderValue,
  useCurrentUserContext,
} from 'components/contexts/CurrentUserContext';
import { GET_CURRENT_TALENT } from 'graphql/talents';
import { GET_CURRENT_COMPANY_USER } from 'graphql/user';
import { useMemo, useCallback } from 'react';

import { Query, Talent, User } from '@libs/graphql-types';

import { useAuth0 } from './useAuth0';

interface CurrentUserResult {
  data: Query | null;
  user?: Talent | User;
  isAuthorized: boolean;
  isTalent: boolean;
  isCompany: boolean;
}

const getCurrentUserFromData = (
  data: Query | null,
  {
    currentUserContext,
    auth0User,
  }: {
    currentUserContext?: CurrentUserContextProviderValue;
    auth0User: UserInterface;
  },
) => {
  if (!currentUserContext?.type) {
    return {
      currentTalent: data?.currentTalent,
      currentCompanyUser: data?.currentCompanyUser,
    };
  }

  return {
    currentTalent: data?.currentUserAccounts?.talent_accounts?.find(
      (i) => i?.id === currentUserContext?.userId,
    ),
    currentCompanyUser: data?.currentUserAccounts?.company_user_accounts?.find(
      (i) => i?.id === currentUserContext?.userId,
    ),
  };
};

export const useCurrentUser = (): CurrentUserResult & {
  getData: () => CurrentUserResult;
} => {
  const { value: currentUserContext } = useCurrentUserContext();
  const apolloClient = useApolloClient();
  const { user: auth0User, isAuthenticated } = useAuth0();
  const isTalent = AuthUtils.isTalent(auth0User);
  const isCompany = AuthUtils.isCompany(auth0User);
  const userData = apolloClient.readQuery<Query>({
    query: isTalent ? GET_CURRENT_TALENT : GET_CURRENT_COMPANY_USER,
  });
  const { currentTalent, currentCompanyUser } = getCurrentUserFromData(
    userData,
    {
      currentUserContext,
      auth0User,
    },
  );
  const result = useMemo(() => {
    return {
      data: userData,
      user: userData && isTalent ? currentTalent : currentCompanyUser,
      isAuthorized: isAuthenticated,
      isTalent: currentUserContext?.type === 'talent' || isTalent,
      isCompany: currentUserContext?.type === 'company_user' || isCompany,
    };
  }, [
    currentCompanyUser,
    currentTalent,
    currentUserContext?.type,
    isAuthenticated,
    isCompany,
    isTalent,
    userData,
  ]);

  const getData = useCallback(() => {
    const userData = apolloClient.readQuery<Query>({
      query: isTalent ? GET_CURRENT_TALENT : GET_CURRENT_COMPANY_USER,
    });

    return {
      data: userData,
      user: userData && isTalent ? currentTalent : currentCompanyUser,
      isAuthorized: isAuthenticated,
      isTalent: currentUserContext?.type === 'talent' || isTalent,
      isCompany: currentUserContext?.type === 'company_user' || isCompany,
    };
  }, [
    apolloClient,
    currentCompanyUser,
    currentTalent,
    isAuthenticated,
    isCompany,
    isTalent,
    currentUserContext?.type,
  ]);

  return { ...result, getData };
};
