import { useCurrentUser } from 'hooks/auth';

const MAXIMUM_ASSIGNMENTS_COUNT = 10;

export function useMatcherAssignmentsExceeded() {
  const { getData } = useCurrentUser();
  const count =
    getData()?.data?.currentTalent?.number_of_active_matcher_assignments || 0;

  return count >= MAXIMUM_ASSIGNMENTS_COUNT;
}
