import { useApolloClient } from '@apollo/client';
import { useOpenModal } from 'utils/modals';

import { Query } from '@libs/graphql-types';

import { InviteByCompanyModalData } from '../../modals/invite-by-company';
import { GET_JOBS_TOTAL } from '../../queries';
import { TalentModals } from '../../types';

export const useGetCompanyJobs = () => {
  const client = useApolloClient();
  const data = client.readQuery<Query>({
    query: GET_JOBS_TOTAL,
  });
  return data?.jobs?.paginatorInfo?.total || 0;
};

export const useOnInviteAction = ({
  talentId,
  talentName,
}: {
  talentId: string;
  talentName: string;
}) => {
  const total = useGetCompanyJobs();
  const openCreateCompanyModal = useOpenModal(TalentModals.CreateCompany);
  const openInviteModal = useOpenModal<InviteByCompanyModalData>(
    TalentModals.InviteByCompany,
  );

  if (total) {
    return () => openInviteModal({ talentId, talentName });
  }
  return () => openCreateCompanyModal();
};
