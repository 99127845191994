import abn from 'assets/logos/abn.white@2x.png';
import aizon from 'assets/logos/aizon.white@2x.png';
import asml from 'assets/logos/asml.white@2x.png';
import fedex from 'assets/logos/fedex.white@2x.png';
import pon from 'assets/logos/pon.white@2x.png';
import React from 'react';

import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Typography from '@libs/ui/components/typography';

interface LogosSidebarProps { }

const LOGOS = [abn, fedex, pon, asml, aizon];

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(10),

    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(6),
    },
  },
  logoBox: {
    marginBottom: theme.spacing(10),

    '&:not(:last-child)': {
      [theme.breakpoints.down('md')]: {
        marginRight: theme.spacing(4),
      },
    },

    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(4),
    },

    '& img': {
      display: 'block',
      margin: '0 auto',
    },
  },
  logosBlock: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      maxWidth: '100vw',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      zoom: '.7',
    },
  },
}));

export const LogosSidebar = (props: LogosSidebarProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.title}>
        <Typography
          whiteSpace="break-spaces"
          textAlign="center"
          style={{ color: '#069973' }}
        >{`Companies already
on OpenTalent`}</Typography>
      </Box>
      <Box className={classes.logosBlock}>
        {LOGOS.map((m) => (
          <Box textAlign="center" className={classes.logoBox} key={m}>
            <img srcSet={`${m} 2x`} alt="company" />
          </Box>
        ))}
      </Box>
    </Box>
  );
};
