import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react';

import { Box, Grow } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useRestoreAccountMutation } from '@libs/graphql-types';
import Button from '@libs/ui/components/button';
import Typography from '@libs/ui/components/typography';

interface LeavingContentProps {
  name: string;
  deleteKey?: string;
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    maxWidth: 600,
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: theme.spacing(0, 6),
  },
}));

const LeavingContent = ({ name, deleteKey }: LeavingContentProps) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [restore, { loading }] = useRestoreAccountMutation({
    onCompleted(data) {
      const link =
        data?.restoreDeletedAccountByDeleteKey?.link_to_set_password_page;

      if (link) {
        window.location.href = link;
      } else {
        enqueueSnackbar('Something went wrong', { variant: 'error' });
        console.error(`Restore link: ${link}`);
      }
    },
  });
  const onSubmit = useCallback(() => {
    if (!deleteKey) {
      return;
    }
    restore({ variables: { delete_key: deleteKey } });
  }, [deleteKey, restore]);

  return (
    <Box className={classes.wrapper}>
      <Grow in timeout={500}>
        <div>
          <Typography
            fontWeight={500}
            variant="h4"
            paragraph
            fontStyle="italic"
          >
            Sorry to hear you’re leaving{' '}
            <span style={{ fontStyle: 'normal' }} role="img">
              😔
            </span>
          </Typography>
          <Box>
            <Typography variant="body2" paragraph>
              {name} invited you to our platform but you decided not to accept
              the&nbsp;invite.
            </Typography>
            <Typography variant="body2" paragraph>
              If you still want to create your account on OpenTalent, click the
              button below
            </Typography>
            <Typography variant="body2" paragraph>
              - Team OpenTalent
            </Typography>
          </Box>
          {deleteKey && (
            <Box pt={2}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                onClick={onSubmit}
                disabled={loading}
              >
                Let me activate my account
              </Button>
            </Box>
          )}
        </div>
      </Grow>
    </Box>
  );
};

export default LeavingContent;
