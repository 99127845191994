import React from 'react';

import { Box, Collapse } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Job } from '@libs/graphql-types';
import { stopEvent } from '@libs/helpers/common';
import Typography from '@libs/ui/components/typography';

interface JobDescriptionProps {
  job: Job;
  isOpen: boolean;
}

const useStyles = makeStyles((theme) => ({
  description: {
    color: 'black !important',
    paddingTop: 8,
    whiteSpace: 'break-spaces',
    maxWidth: `calc(100vw - 700px)`,

    [theme.breakpoints.down('lg')]: {
      maxWidth: `calc(100vw - 400px)`,
    },

    [theme.breakpoints.down('md')]: {
      maxWidth: `calc(100vw - 100px)`,
    },

    '& *': {
      color: 'black !important',
    },
    '& > p': {
      margin: 0,
    },
  },
}));

const JobDescription = ({ job, isOpen }: JobDescriptionProps) => {
  const classes = useStyles();

  return (
    <Collapse in={isOpen}>
      <div>
        <Typography variant="subtitle2">Description</Typography>

        <Box display="flex">
          <div
            className={classes.description}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: job?.description || '' }}
            onClick={stopEvent}
          />
        </Box>
      </div>
    </Collapse>
  );
};

export default JobDescription;
