import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { pathManager } from 'routes';

interface OnboardingRedirectProps
  extends RouteComponentProps<{ userKey: string }> {}

const OnboardingRedirect = ({ match }: OnboardingRedirectProps) => {
  const userKey = match.params.userKey;

  return (
    <Redirect
      to={`${pathManager.company.onboarding.greeting.generatePath()}?rl=${userKey}`}
    />
  );
};

export default OnboardingRedirect;
