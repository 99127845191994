import { gql } from '@apollo/client';

export const CHANGE_NOTIFICATION_READ_STATUS = gql`
  mutation ChangeNotificationReadStatus(
    $notification_id: ID!
    $new_status: Boolean!
  ) {
    changeNotificationReadStatus(
      notification_id: $notification_id
      new_status: $new_status
    )
  }
`;

export const DELETE_NOTIFICATION = gql`
  mutation DeleteNotification($notification_id: ID!) {
    deleteNotification(notification_id: $notification_id)
  }
`;

export const UPDATE_NOTIFICATION = gql`
  mutation UpdateNotification($id: ID!, $is_read: Boolean!) {
    updateNotification(id: $id, is_read: $is_read) {
      id
      is_read
      __typename
    }
  }
`;
