import { TalentModals } from 'components/custom/talent/types';
import { useCurrentUser } from 'hooks/auth';
import React from 'react';
import { pathManager } from 'routes';
import { DefaultModalProps, withLocationStateModal } from 'utils/modals';

import { Box } from '@mui/material';
import { green } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';

import { RouterButton } from '@libs/ui/components/button';
import { DefaultModal } from '@libs/ui/components/modals';
import Typography from '@libs/ui/components/typography';

import avatar from './avatar@2x.png';
import { ReactComponent as CheckIcon } from './check.svg';

interface LobbyPaidPopupProps extends DefaultModalProps<true> { }

const useStyles = makeStyles((theme) => ({
  poster: {
    height: 280,
    width: 550,
    background: green[500],
    margin: '24px -40px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  posterInner: {
    position: 'relative',
  },
  avatar: {
    width: 140,
    height: 140,
    borderRadius: '50%',

    [theme.breakpoints.down('sm')]: {
      width: 80,
      height: 80,
    },
  },

  check: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
}));

const LobbyPaidPopupComponent = ({ isOpen, close }: LobbyPaidPopupProps) => {
  const classes = useStyles();
  const { user } = useCurrentUser();
  const image = user?.avatar?.avatar || avatar;

  return (
    <DefaultModal
      handleClose={close}
      open={isOpen}
      title={
        <>
          <Typography variant="h5" paragraph>
            Welcome to OpenTalent 🎉
          </Typography>
        </>
      }
      actions={
        <Box marginTop={-9} width="100%">
          <RouterButton
            size="large"
            rounded
            fullWidth
            variant="contained"
            color="info"
            to={pathManager.talent.lobby.main.generatePath()}
          >
            CONTINUE
          </RouterButton>
        </Box>
      }
    >
      <Box className={classes.poster}>
        <div className={classes.posterInner}>
          <img
            className={classes.avatar}
            srcSet={`${image} 2x`}
            alt="User avatar"
          />

          <CheckIcon className={classes.check} />
        </div>
      </Box>
      <Box maxWidth={476} mt={4}>
        <Typography paragraph variant="h6">
          Your account has been verified.
        </Typography>
      </Box>
    </DefaultModal>
  );
};

export const LobbyPendingPopup = withLocationStateModal({
  id: TalentModals.VerifiedLobby,
})(LobbyPaidPopupComponent);
