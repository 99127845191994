import { useMenuAction } from 'hooks/common/useMenuAction';
import { useTalentPoolConnections } from 'hooks/talents';
import React from 'react';

import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';

import {
  Company,
  TalentCompanyPoolConnection,
  TalentCompanyPoolingInitiatorEnum,
  TalentCompanyPoolingStatusEnum,
} from '@libs/graphql-types';
import { noop } from '@libs/helpers/common';
import Button, { ButtonProps } from '@libs/ui/components/button';

import TalentPoolActionsMenu from './CompaniesPoolActionsMenu';
import { ConnectTalentToCompanyPoolAction } from './ConnectTalentToCompanyPoolAction';
import { useCompanyPoolActions } from './hooks';

interface TalentPoolActionsProps {
  company: Company;
  refetch?: VoidFunction;
  loading?: boolean;
}

type ActionItemDataType = {
  text: string;
  Icon: React.ComponentType;
  color: ButtonProps['color'];
};

const getActionData = (
  connection: TalentCompanyPoolConnection,
): Record<TalentCompanyPoolingStatusEnum, ActionItemDataType> => ({
  [TalentCompanyPoolingStatusEnum.Pending]: {
    text:
      connection.initiator === TalentCompanyPoolingInitiatorEnum.Talent
        ? 'Applied'
        : 'Pending',
    Icon: AccessTimeOutlinedIcon,
    color: 'warning' as const,
  },
  [TalentCompanyPoolingStatusEnum.Approved]: {
    text: 'Connected',
    Icon: CheckCircleOutlineOutlinedIcon,
    color: 'success' as const,
  },
  [TalentCompanyPoolingStatusEnum.Rejected]: {
    text: 'Rejected',
    Icon: BlockOutlinedIcon,
    color: 'error' as const,
  },
  [TalentCompanyPoolingStatusEnum.Cancelled]: {
    text: 'Cancelled',
    Icon: PersonOffOutlinedIcon,
    color: 'error' as const,
  },
});

export const CompaniesPoolActions = ({
  refetch = noop,
  loading = false,
  company,
}: TalentPoolActionsProps) => {
  const connections = useTalentPoolConnections();
  const { open, handleClose, handleClick, anchorEl } = useMenuAction();
  const connection = connections.find((i) => i?.company?.id === company.id);
  const { actions } = useCompanyPoolActions({
    company,
    connection,
    onSuccess: refetch,
  });

  if (!connection) {
    return (
      <ConnectTalentToCompanyPoolAction
        company={company}
        refetch={refetch}
        disabled={loading}
      />
    );
  }

  const actionData = connection?.status
    ? getActionData(connection)[connection.status]
    : ({
      text: 'Connect',
      Icon: PersonAddAlt1OutlinedIcon,
      color: 'info',
    } as ActionItemDataType);

  return (
    <>
      <Button
        onClick={handleClick as VoidFunction}
        variant="outlined"
        size="medium"
        color={actionData.color}
        startIcon={<actionData.Icon />}
        data-test-id="talentActions"
      >
        {actionData.text}
      </Button>
      <TalentPoolActionsMenu
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
        actions={actions}
      />
    </>
  );
};
