import { CompaniesPoolActions } from 'components/custom/talent/companies-pool-actions';
import { DEFAULT_AVATAR } from 'consts/common';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { pathManager } from 'routes';

import {
  Avatar,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Company } from '@libs/graphql-types';
import { stopEvent } from '@libs/helpers/common';

export interface CompanyCardProps {
  company: Company;
  refetch?: VoidFunction;
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 4),
    border: `1px solid ${theme.palette.info.light}`,
    borderRadius: theme.spacing(4),
    cursor: 'pointer',
    transition: 'background .3s',

    '&:hover': {
      background: '#eeeeee',
    },
  },
  listItem: {
    paddingLeft: 0,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

export const CompanyCard = ({ company, refetch }: CompanyCardProps) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div
      className={classes.root}
      onClick={() =>
        history.push(
          pathManager.talent.companies.viewCompany.generatePath({
            id: company.id,
          }),
        )
      }
    >
      <Grid container>
        <Grid item flexGrow={1}>
          <ListItem
            alignItems="flex-start"
            classes={{ root: classes.listItem }}
          >
            <ListItemAvatar>
              <Avatar alt="Avatar" src={company?.logo?.url || DEFAULT_AVATAR} />
            </ListItemAvatar>
            <ListItemText
              primary={company.name}
              secondary={[company.type_of_activity, company.country]
                .filter(Boolean)
                .join(' • ')}
            />
          </ListItem>
        </Grid>
        <Grid item className={classes.actions} onClick={stopEvent}>
          <CompaniesPoolActions company={company} refetch={refetch} />
        </Grid>
      </Grid>
    </div>
  );
};
