import { useCurrentUser } from 'hooks/auth';
import React from 'react';
import { Link } from 'react-router-dom';
import { pathManager } from 'routes';

import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import {
  Avatar,
  Box,
  ClickAwayListener,
  Grid,
  Grow,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';

import Typography from '@libs/ui/components/typography';

import { AccountsSwitch } from './AccountsSwitch';

interface AccountMenuProps {
  isOpen: boolean;
  anchorRef: any;
  handleClose: VoidFunction;
  menuId: string;
  user?: {
    avatar?: string;
    name: string;
  };
}

const AccountMenu = (props: AccountMenuProps) => {
  const { isOpen, anchorRef, handleClose, menuId, user } = props;
  const { isTalent } = useCurrentUser();
  const settingsLink = isTalent
    ? pathManager.talent.settings.main.generatePath()
    : pathManager.company.settings.main.generatePath();

  return (
    <Popper
      placement="bottom-end"
      open={isOpen}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === 'bottom' ? 'right top' : 'right top',
            minWidth: 180,
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <Box>
                {user && (
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    wrap="nowrap"
                    component={Box}
                    px={4}
                    pt={6}
                    pb={2}
                    spacing={2}
                    style={{ alignItems: 'center' }}
                  >
                    <Grid item>
                      <Avatar
                        style={{ width: 48, height: 48 }}
                        src={user.avatar}
                        alt={user.name}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="body1" color="textSecondary">
                        {user.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        textAlign="center"
                      >
                        {isTalent ? 'Candidate' : 'Recruiter'}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                <MenuList autoFocusItem={isOpen} id={menuId}>
                  <AccountsSwitch />
                  <Link to={settingsLink}>
                    <MenuItem>
                      <ListItemIcon>
                        <SettingsOutlinedIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        disableTypography
                        color="#fff"
                        primary="Settings"
                      />
                    </MenuItem>
                  </Link>
                  <Link to={pathManager.logout.generatePath()}>
                    <MenuItem>
                      <ListItemIcon>
                        <ExitToAppIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        disableTypography
                        color="#fff"
                        primary="Logout"
                      />
                    </MenuItem>
                  </Link>
                </MenuList>
              </Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

export default AccountMenu;
