import { useCurrentUserContext } from 'components/contexts';
import { useCurrentUser } from 'hooks/auth';
import { useCurrentUserAccounts } from 'hooks/auth/useCurrentUserAccounts';
import React from 'react';

import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';
import { MenuItem, ListItemText, ListItemIcon } from '@mui/material';

interface AccountsSwitchProps { }

export const AccountsSwitch = (props: AccountsSwitchProps) => {
  const { accounts } = useCurrentUserAccounts();
  const { setValue: setCurrentUser } = useCurrentUserContext();
  const { getData } = useCurrentUser();
  const talentAccounts = accounts?.talent_accounts || [];
  const companyAccounts = accounts?.company_user_accounts || [];
  const isTalent = getData()?.isTalent;
  const showSwitchToCompany = isTalent && !!companyAccounts[0];
  const showSwitchToTalent = !isTalent && !!talentAccounts[0];

  return (
    <div>
      {showSwitchToCompany && (
        <MenuItem
          onClick={() =>
            setCurrentUser({
              type: 'company_user',
              userId: companyAccounts[0]!.id,
            })
          }
        >
          <ListItemIcon>
            <ToggleOffOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText
            disableTypography
            color="#fff"
            primary="Switch to Recruiter"
          />
        </MenuItem>
      )}

      {showSwitchToTalent && (
        <MenuItem
          onClick={() =>
            setCurrentUser({
              type: 'talent',
              userId: talentAccounts[0]!.id,
            })
          }
        >
          <ListItemIcon>
            <ToggleOffOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText
            disableTypography
            color="#fff"
            primary="Switch to Candidate"
          />
        </MenuItem>
      )}
    </div>
  );
};
