import { Form, Formik } from 'formik';
import React from 'react';

import { Box } from '@mui/material';

import {
  Job,
  JobApplication,
  JobMatcherApplicationStatusEnum,
} from '@libs/graphql-types';
import { modelPath } from '@libs/helpers/form';
import Button from '@libs/ui/components/button';
import { ConnectedTextField } from '@libs/ui/components/form/text-field';
import Typography from '@libs/ui/components/typography';

import { INITIAL_VALUES } from './consts';
import { useApplyForAction, useModalValidationSchema } from './hooks';
import { FormState } from './types';

interface ApplyFormProps {
  job: Job;
  application?: JobApplication;
  applicationStatus: JobMatcherApplicationStatusEnum;
}

const ApplyForm = ({ job, application, applicationStatus }: ApplyFormProps) => {
  const { onSubmit, isLoading } = useApplyForAction(job.id);
  const { schema } = useModalValidationSchema();
  const title =
    applicationStatus === 'REJECTED'
      ? 'Sorry your application has been rejected'
      : 'Sorry this job has no more seats left';

  return (
    <Box maxWidth={500} margin="0 auto">
      <Box pb={6}>
        <Typography textAlign="center" variant="h5">
          {title}
        </Typography>
      </Box>
      <Box pb={6}>
        <Typography fontWeight={500} textAlign="center" variant="h6">
          Still want to present candidates?
        </Typography>
      </Box>
      <Box pb={4}>
        <Typography paragraph variant="body1" color="textSecondary">
          Do you know suitable candidates for this role? You can invite them to
          apply and in case they get hired, there will be a Finder’s Fee for
          you. Learn more here.
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Invite a new candidate to apply for this role:
        </Typography>
      </Box>
      <Formik<FormState>
        validationSchema={schema}
        onSubmit={onSubmit}
        initialValues={INITIAL_VALUES}
      >
        {() => (
          <Form>
            <Box mb={4}>
              <ConnectedTextField
                size="medium"
                name={modelPath<FormState>((m) => m.firstName)}
                fullWidth
                variant="filled"
                label="First name"
              />
            </Box>
            <Box mb={4}>
              <ConnectedTextField
                size="medium"
                name={modelPath<FormState>((m) => m.lastName)}
                fullWidth
                variant="filled"
                label="Last name"
              />
            </Box>
            <Box mb={4}>
              <ConnectedTextField
                size="medium"
                name={modelPath<FormState>((m) => m.email)}
                fullWidth
                variant="filled"
                label="Email"
              />
            </Box>
            <Box pb={8}>
              <Button
                type="submit"
                size="large"
                fullWidth
                variant="contained"
                color="primary"
                disabled={!!application || isLoading}
              >
                SEND INVITE
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default ApplyForm;
