import React from 'react';

import { useCheckFormErrors } from './hooks';

interface CheckFormErrorsProps { }

export const CheckFormErrors = (props: CheckFormErrorsProps) => {
  useCheckFormErrors();

  return <></>;
};
