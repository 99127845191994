import { useMenuAction } from 'hooks/common/useMenuAction';
import { useGetCompanyPoolConnectionToTalent } from 'hooks/company';
import React from 'react';

import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';

import {
  Talent,
  TalentCompanyPoolConnection,
  TalentCompanyPoolingInitiatorEnum,
  TalentCompanyPoolingStatusEnum,
} from '@libs/graphql-types';
import { noop } from '@libs/helpers/common';
import Button, { ButtonProps } from '@libs/ui/components/button';

import { ActionItem } from '../types';
import { ConnectTalentToCompanyPoolAction } from './ConnectTalentToCompanyPoolAction';
import TalentPoolActionsMenu from './TalentPoolActionsMenu';
import { useCompanyPoolActions } from './hooks';
import { AllCompanyToTalentModals } from './modals/AllCompanyToTalentModals';

interface TalentPoolActionsProps {
  talent: Talent;
  refetch?: VoidFunction;
  loading?: boolean;
  filterActions?: (actions: ActionItem[]) => ActionItem[];
}

type ActionItemDataType = {
  text: string;
  Icon: React.ComponentType;
  color: ButtonProps['color'];
};

const getActionData = (
  connection: TalentCompanyPoolConnection,
): Record<TalentCompanyPoolingStatusEnum, ActionItemDataType> => ({
  [TalentCompanyPoolingStatusEnum.Pending]: {
    text:
      connection.initiator === TalentCompanyPoolingInitiatorEnum.Talent
        ? 'Applied'
        : 'Pending',
    Icon: AccessTimeOutlinedIcon,
    color: 'warning' as const,
  },
  [TalentCompanyPoolingStatusEnum.Approved]: {
    text: 'Connected',
    Icon: CheckCircleOutlineOutlinedIcon,
    color: 'success' as const,
  },
  [TalentCompanyPoolingStatusEnum.Rejected]: {
    text: 'Rejected',
    Icon: BlockOutlinedIcon,
    color: 'error' as const,
  },
  [TalentCompanyPoolingStatusEnum.Cancelled]: {
    text: 'Cancelled',
    Icon: PersonOffOutlinedIcon,
    color: 'error' as const,
  },
});

export const TalentPoolActions = ({
  talent,
  refetch = noop,
  loading = false,
  filterActions,
}: TalentPoolActionsProps) => {
  const { open, handleClose, handleClick, anchorEl } = useMenuAction();
  const connection = useGetCompanyPoolConnectionToTalent({ talent });
  const { actions } = useCompanyPoolActions({
    talent,
    connection,
    onSuccess: refetch,
  });
  const finalActions = filterActions ? filterActions(actions) : actions;

  const actionData = connection?.status
    ? getActionData(connection)[connection.status]
    : ({
      text: 'Connect',
      Icon: PersonAddAlt1OutlinedIcon,
      color: 'info',
    } as ActionItemDataType);

  return (
    <>
      {!connection ? (
        <ConnectTalentToCompanyPoolAction
          talent={talent}
          refetch={refetch}
          disabled={loading}
        />
      ) : (
        <>
          <Button
            onClick={handleClick as VoidFunction}
            variant="outlined"
            fullWidth
            size="medium"
            color={actionData.color}
            startIcon={<actionData.Icon />}
            data-test-id="talentActions"
          >
            {actionData.text}
          </Button>
          <TalentPoolActionsMenu
            open={open}
            anchorEl={anchorEl}
            handleClose={handleClose}
            actions={finalActions}
          />
        </>
      )}
      <AllCompanyToTalentModals onSuccess={refetch} modalKey={talent.id} />
    </>
  );
};
