import React from 'react';

import { makeStyles } from '@mui/styles';

interface EmptySidebarComponentProps {}

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(20),
  },
}));

export const EmptySidebarComponent = (props: EmptySidebarComponentProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <svg
        width="100"
        height="87"
        viewBox="0 0 100 87"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.0544 9.13516C12.7498 9.13516 11.4986 9.65341 10.5761 10.5759C9.65365 11.4984 9.1354 12.7495 9.1354 14.0541V57.3312L19.1792 47.2874C19.311 47.1556 19.4897 47.0816 19.6761 47.0816H36.5412C36.9293 47.0816 37.2439 47.3962 37.2439 47.7843C37.2439 48.1724 36.9293 48.487 36.5412 48.487H19.9672L8.92958 59.5246C8.72861 59.7256 8.42636 59.7857 8.16378 59.6769C7.90119 59.5681 7.72998 59.3119 7.72998 59.0277V14.0541C7.72998 12.3768 8.3963 10.7682 9.58236 9.58211C10.7684 8.39606 12.377 7.72974 14.0544 7.72974H53.4062C55.0836 7.72974 56.6922 8.39606 57.8783 9.58211C59.0643 10.7682 59.7306 12.3768 59.7306 14.0541V22.9364C59.7306 23.3245 59.416 23.6391 59.0279 23.6391C58.6398 23.6391 58.3252 23.3245 58.3252 22.9364V14.0541C58.3252 12.7495 57.807 11.4984 56.8845 10.5759C55.962 9.65341 54.7108 9.13516 53.4062 9.13516H14.0544Z"
          fill="#EEEEEE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M84.4378 26.675C85.7424 26.675 86.9936 27.1932 87.916 28.1157C88.8385 29.0382 89.3568 30.2893 89.3568 31.5939V74.871L79.313 64.8272C79.1812 64.6954 79.0025 64.6214 78.8161 64.6214H45.0859C43.7814 64.6214 42.5302 64.1031 41.6077 63.1806C40.6852 62.2582 40.167 61.007 40.167 59.7024V31.5939C40.167 30.2893 40.6852 29.0382 41.6077 28.1157C42.5302 27.1932 43.7814 26.675 45.0859 26.675H84.4378ZM88.9098 27.1219C87.7238 25.9359 86.1151 25.2695 84.4378 25.2695H45.0859C43.4086 25.2695 41.8 25.9359 40.6139 27.1219C39.4279 28.308 38.7615 29.9166 38.7615 31.5939V59.7024C38.7615 61.3797 39.4279 62.9884 40.6139 64.1744C41.8 65.3605 43.4086 66.0268 45.0859 66.0268H78.525L89.5626 77.0644C89.7636 77.2654 90.0658 77.3255 90.3284 77.2167C90.591 77.1079 90.7622 76.8517 90.7622 76.5675V31.5939C90.7622 29.9166 90.0959 28.308 88.9098 27.1219Z"
          fill="#EEEEEE"
        />
      </svg>
    </div>
  );
};
