import React from 'react';

import { FileTypeEnum } from '@libs/graphql-types';
import { modelPath } from '@libs/helpers/form';

import ConnectedImageControl, {
  ConnectedImageControlProps,
} from '../connected-image-control';
import { useChangeImageHandler, useCurrentImageSrc } from './hooks';
import { ImageFormikState } from './types';

interface UploadImageControlProps
  extends Omit<ConnectedImageControlProps, 'imgSrc'> {
  fileType: FileTypeEnum;
  initialImageUrl?: string;
}

const UploadImageControl = ({
  fileType,
  name,
  initialImageUrl,
  ...rest
}: UploadImageControlProps) => {
  const imgSrc = useCurrentImageSrc(name);
  const { onFileChange, loading } = useChangeImageHandler({
    name,
    fileType,
  });

  return (
    <ConnectedImageControl
      {...rest}
      name={`${name}.${modelPath<ImageFormikState>((m) => m.file)}`}
      loading={loading}
      onChange={onFileChange}
      imgSrc={imgSrc || initialImageUrl}
    />
  );
};

export default UploadImageControl;
