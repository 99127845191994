import React from 'react';
import { MessageList } from 'stream-chat-react';

import { CustomMessage } from './CustomMessage';
import { DEFAULT_MASSAGE_ACTIONS_LIST } from './consts';

interface CustomMessageListProps { }

export const CustomMessageList = (props: CustomMessageListProps) => {
  return (
    <MessageList
      onMentionsClick={console.log}
      Message={CustomMessage}
      messageActions={DEFAULT_MASSAGE_ACTIONS_LIST}
    />
  );
};
