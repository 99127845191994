import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ReactComponent as LogoIcon } from 'assets/icons/logo.svg';
import React from 'react';

interface AppLoadingScreenProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.secondary.main,
    flexDirection: 'column',
  },
  icon: {
    height: '70px !important',
    width: 'auto !important',
  },
}));

export const AppLoadingScreen = (props: AppLoadingScreenProps) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <LogoIcon className={classes.icon} />
      <CircularProgress />
    </div>
  );
};
