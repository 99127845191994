import { DirectSubscriptionModal } from 'components/custom/company/modals/direct';
import { TalentPremiumModal } from 'components/custom/talent/modals/limited-profile-access';
import {
  LobbyPendingPopup,
  useCheckLobbyPaidPopup,
  useCheckLobbyPendingPopup,
} from 'components/custom/talent/modals/lobby-popup';
import React from 'react';

import { LobbyPaidPopup } from '../talent/modals/lobby-popup/LobbyPaidPopup';

interface GlobalModalsProps { }

export const GlobalModals = (props: GlobalModalsProps) => {
  useCheckLobbyPendingPopup();
  useCheckLobbyPaidPopup();

  return (
    <div>
      <DirectSubscriptionModal />
      <TalentPremiumModal />
      <LobbyPendingPopup />
      <LobbyPaidPopup />
    </div>
  );
};
