import { ConnectedPageLayout } from 'components/layout/page-layout';
import React from 'react';

import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useMediaQueries } from '@libs/helpers/hooks/media-queries';

import { CreateCommunityForm } from './create-community-form';
import { TopBlock } from './top-block';

interface PageProps { }

const useStyles = makeStyles((theme) => ({
  content: {
    padding: 0,
    maxWidth: '100%',
  },
  wrapper: {
    height: '100%',
  },
}));

const CreateTalentCommunity = (props: PageProps) => {
  const classes = useStyles();
  const { isSM } = useMediaQueries();

  return (
    <ConnectedPageLayout
      documentTitle="Job board"
      drawerProps={{}}
      headerProps={{ accountProps: {} }}
      classes={{ contentWrapper: classes.content }}
    >
      <Grid
        container
        flexDirection={isSM ? 'column-reverse' : 'column'}
        className={classes.wrapper}
      >
        <Grid item>
          <TopBlock />
        </Grid>
        <Grid item flexGrow={1}>
          <CreateCommunityForm />
        </Grid>
      </Grid>
    </ConnectedPageLayout>
  );
};

export default CreateTalentCommunity;
