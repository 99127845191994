import { mergeDeep } from '@apollo/client/utilities';
import React from 'react';

import PageLayout, { PageLayoutProps } from '../PageLayout';
import { useLayoutProps } from './hooks';

interface ConnectedPageLayoutProps extends PageLayoutProps { }

const ConnectedPageLayout = ({
  drawerProps,
  headerProps,
  ...props
}: ConnectedPageLayoutProps) => {
  const { connectedHeaderProps, connectedDrawerProps } = useLayoutProps();
  return (
    <PageLayout
      {...props}
      headerProps={{
        ...mergeDeep(connectedHeaderProps, headerProps),
        accountProps:
          headerProps?.accountProps === null
            ? undefined
            : connectedHeaderProps.accountProps,
      }}
      drawerProps={
        drawerProps ? { ...drawerProps, ...connectedDrawerProps } : undefined
      }
    />
  );
};

export default ConnectedPageLayout;
