import { SetStateAction } from 'react';
import { ChannelSearchFunctionParams } from 'stream-chat-react/dist/components/ChannelSearch/hooks/useChannelSearch';

import { useStreamChatContext } from '../../common/provider';

export const useSearchFilter = () => {
  const { client } = useStreamChatContext();
  return async (
    props: ChannelSearchFunctionParams,
    event: { target: { value: SetStateAction<string> } },
  ) => {
    const { setResults, setSearching, setQuery } = props;
    const query = event.target.value || '';

    const filters = {
      name: { $q: query || '' },
      'member.user.name': { $autocomplete: query },
      members: { $in: [client.userID] },
    } as any;

    if (query) {
      setQuery(event.target.value);
      setSearching(true);
      const channels = await client.queryChannels(filters);
      setResults(channels);
      setSearching(false);
    } else {
      setQuery(event.target.value);
      setResults([]);
      setSearching(false);
    }
  };
};

export const useSearchMassagesAndUsersHandler = () => {
  const { client } = useStreamChatContext();

  return async (
    props: ChannelSearchFunctionParams,
    event: { target: { value: SetStateAction<string> } },
  ) => {
    const { setResults, setSearching, setQuery } = props;
    const query = (event.target.value as string) || '';

    const channelFilters = {
      members: { $in: [client.userID!] },
      name: { $autocomplete: query },
      last_message_at: { $lte: new Date().toISOString() },
    };

    const messageFilters = {
      members: { $in: [client.userID!] },
    };

    if (query) {
      setQuery(event.target.value);
      setSearching(true);
      const channels = await client.queryChannels(channelFilters);
      const messages = await client
        .search(messageFilters, query)
        .then(({ results }) => results.map((i) => i.message));

      setResults(channels.concat(messages as any));
      setSearching(false);
    } else {
      setQuery(event.target.value);
      setResults([]);
      setSearching(false);
    }
  };
};
