import FixedFooter from 'components/custom/onboarding/fixed-footer';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { pathManager } from 'routes';

import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { modelPath } from '@libs/helpers/form';
import { ConnectedTextField } from '@libs/ui/components/form/text-field';

import { TOTAL_STEPS } from '../../consts';
import NextButtonWithValidation from '../../shared/NextButtonWithValidation';
import StepTemplate from '../../shared/StepTemplate';
import { LogosSidebar } from '../../sidebars/LogosSidebar';
import { OnboardingV3State } from '../../types';

interface GeneralProps { }

export const useStyles = makeStyles((theme) => ({
  fieldsWrapper: {
    width: 500,
    margin: theme.spacing(0, 2),

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  footer: {
    position: 'absolute',
  },
}));

export const General = (props: GeneralProps) => {
  const classes = useStyles();
  const history = useHistory();
  const onSubmit = () => {
    history.push(pathManager.company.onboardingV3.company.generatePath());
  };

  return (
    <StepTemplate
      title="Welcome 🎉"
      subtitle="Let’s get you setup!"
      totalSteps={TOTAL_STEPS}
      currentStep={1}
      SidebarContentContent={LogosSidebar}
    >
      <Box className={classes.fieldsWrapper}>
        <Box mb={4}>
          <ConnectedTextField
            name={modelPath<OnboardingV3State>((m) => m.general.firstName)}
            fullWidth
            variant="filled"
            label="First name"
          />
        </Box>
        <Box mb={4}>
          <ConnectedTextField
            name={modelPath<OnboardingV3State>((m) => m.general.lastName)}
            fullWidth
            variant="filled"
            label="Last name"
          />
        </Box>
        <Box mb={4}>
          <ConnectedTextField
            name={modelPath<OnboardingV3State>((m) => m.general.email)}
            fullWidth
            type="email"
            variant="filled"
            label="Email"
          />
        </Box>
        <FixedFooter className={classes.footer}>
          <Grid spacing={4} justifyContent="center" container>
            <Grid xs={12} item>
              <NextButtonWithValidation
                path={modelPath<OnboardingV3State>((m) => m.general)}
                onClick={onSubmit}
              >
                next
              </NextButtonWithValidation>
            </Grid>
          </Grid>
        </FixedFooter>
      </Box>
    </StepTemplate>
  );
};
