import { LOCAL_STORAGE_KEYS } from 'consts/ls-keys';
import React, { useEffect } from 'react';
import { pathManager } from 'routes';

import { Card, CardContent, Zoom } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Typography, { RouterLink } from '@libs/ui/components/typography';

interface AppLoadingScreenProps { }

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.secondary.main,
    flexDirection: 'column',
  },
  card: {
    textAlign: 'center',
    maxWidth: '420px',
    color: 'white',
    background: '#252525',
    padding: theme.spacing(4),
  },
}));

export const AppWrongEmailScreen = (props: AppLoadingScreenProps) => {
  const classes = useStyles();
  const authErrorMessage =
    localStorage.getItem(LOCAL_STORAGE_KEYS.authError) ||
    'Please contact OpenTalent support to get access to the application';

  useEffect(() => () => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.authError);
  });

  return (
    <div className={classes.root}>
      <Zoom in timeout={300}>
        <Card classes={{ root: classes.card }}>
          <CardContent>
            <Typography variant="h5" paragraph>
              Permission denied
            </Typography>
            <Typography variant="body2">
              {authErrorMessage}
              <br />
              <RouterLink
                variant="body2"
                to={pathManager.logout.generatePath()}
              >
                Sign in with another account
              </RouterLink>
            </Typography>
          </CardContent>
        </Card>
      </Zoom>
    </div>
  );
};
