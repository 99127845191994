import React from 'react';
import { SearchResultItemProps } from 'stream-chat-react';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';

import { isItMessage, isItChannel } from '../utils';
import { SearchChannelResultItem } from './SearchChannelResultItem';
import { SearchMessageResultItem } from './SearchMessageResultItem';

interface DefaultSearchResultItemProps
  extends SearchResultItemProps<DefaultStreamChatGenerics> {}

export const DefaultSearchResultItem = ({
  result,
  selectResult,
  index,
}: DefaultSearchResultItemProps) => {
  if (isItChannel(result)) {
    return (
      <SearchChannelResultItem
        index={index}
        selectResult={selectResult}
        result={result}
      />
    );
  }

  if (isItMessage(result)) {
    return (
      <SearchMessageResultItem
        index={index}
        selectResult={selectResult}
        result={result}
      />
    );
  }
  console.warn('Unknown search result type');
  return null;
};
