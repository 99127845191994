import React from 'react';

import { JobSkill, Skill, SkillTypeEnum } from '@libs/graphql-types';

import Chip, { ChipProps } from './Chip';

interface SkillChipProps extends Omit<ChipProps, 'color'> {
  skill: Skill | JobSkill;
}

const SKILL_COLORS = {
  [SkillTypeEnum.Solutions]: 'success',
  [SkillTypeEnum.HardSkills]: 'success',
  [SkillTypeEnum.SoftSkills]: 'success',
} as const;

export const SkillChip = ({ skill, ...props }: SkillChipProps) => {
  return (
    <Chip
      {...props}
      color={SKILL_COLORS[skill.skill_type]}
      label={skill.name}
      variant="outlined"
      style={{
        borderColor: '#2E7D32',
        color: '#2E7D32',
      }}
    />
  );
};

export default SkillChip;
