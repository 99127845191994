import React from 'react';

import { Box } from '@mui/material';

import Typography, { OuterLink } from '@libs/ui/components/typography';

interface HowItWorksProps { }

const HowItWorks = (props: HowItWorksProps) => {
  return (
    <Box maxWidth={630} margin="0 auto">
      <Box mb={2}>
        <Typography variant="h5" textAlign="center">
          Need help?
        </Typography>
      </Box>
      <Typography textAlign="center">Recruiter program</Typography>

      <Box mt={8}>
        <Typography color="text.secondary">
          If you have any inquiries, please refer to our{' '}
          <OuterLink href="https://www.notion.so/opentalent/Q-A-about-the-Talent-Matcher-TM-program-c2a3175264a64fdab40f4869f03be05c?pvs=4">
            Q&A section
          </OuterLink>
          . To obtain further information regarding the payout conditions of the
          program, kindly click{' '}
          <OuterLink href="https://www.notion.so/opentalent/Get-Paid-fd07a861715e49c98df46448b91b770d?pvs=4">
            here
          </OuterLink>
          . We also have a{' '}
          <OuterLink href="https://t.sidekickopen14.com/s3t/c/5/f18dQhb0V1-gmb8cpm_YW16gy-_59hl3kW7_k2841CXdp3VP1rFR1DWrgSW2dykgg4kXZ9J101?te=W3R5hFj4cm2zwW3W0hjl1JG9fLW3F8JJD43T4P8W1LC3cr41Yx_gW3K78gL43Tbd5W41S_rG1LDKpZW49Kv7y3T1lt3W4fGvw84fdjdVW3FbsfQ4pzwgqW1Vw49224VQQdW1ZkXrx2nPLDJn2CYk_f2c3&si=8000000005608251&pi=1c248976-ce64-4fd7-b37e-311a028e286d">
            Slack channel
          </OuterLink>{' '}
          (currently not very active) that you can join to connect with other
          recruiters and professionals on OpenTalent.
        </Typography>
      </Box>
    </Box>
  );
};

export default HowItWorks;
