import { JoinTypeEnum, TypeCardProps } from './types';

export const CARDS_DATA: Pick<TypeCardProps, 'title' | 'text' | 'value'>[] = [
  {
    title: 'Company',
    text: 'I am part of a company’s in-house recruiting team',
    value: JoinTypeEnum.Company,
  },
  {
    title: 'Recruiter (freelance)',
    text: 'I work / want to work as a freelance recruiter',
    value: JoinTypeEnum.Independent,
  },
  {
    title: 'Agency',
    text: 'I work for a recruiting agency / consultancy',
    value: JoinTypeEnum.Agency,
  },
];
