import { gql } from '@apollo/client';

export default gql`
  fragment JobBoardJobFragment on Job {
    id
    name
    country
    city
    is_saved
    is_applied
    link_to_details
    salary_max
    salary_min
    finders_fee
    skills {
      id
      name
      skill_type
      job_skill_pivot {
        is_required
      }
    }
    category {
      id
      name
    }
    description
    pitch
    start_date
    end_date
    rate_min
    rate_max
    is_rate_negotiable
    campaign_owner {
      id
      first_name
      last_name
      company {
        logo
        name
        id
      }
      stream_chat_id
    }
    invitations {
      id
      talent_id
    }

    matches {
      id
      suggesting_stage
      job_suggestion {
        suggested_by_talent {
          first_name
          last_name
          first_name_abac {
            value
          }
          last_name_abac {
            value
          }
        }
        screening_questionnaire_attachment {
          title
          url
        }
        motivation_letter_attachment {
          title
          url
        }
        cv_attachment {
          title
          url
        }
        first_name
        last_name
        talent {
          id
          first_name_abac {
            value
          }
          last_name_abac {
            value
          }
          avatar {
            avatar
          }
        }
      }
      talent_invitation {
        id
        first_name
        last_name
        talent {
          id
          first_name_abac {
            value
          }
          last_name_abac {
            value
          }
          avatar {
            avatar
          }
        }
      }
    }
    job_source_type
    campaign_start_date
    campaign_end_date
    campaign_talent_pool
    is_archived
    matches_count
    instant_matches_count
    matches_with_application_count
    location
    hours_per_week
    location_type
    type
    period
    client
    capacity
    posted_at
    matchers {
      id
    }
    job_matcher_applications {
      id
      matcher {
        id
      }
      status
    }
  }
`;
