import { CheckCircle } from '@mui/icons-material';
import { MenuItemProps } from '@mui/material';

export enum CompanyModals {
  DirectSubscription = 'direct',
  JobPushDirectModal = 'JobPushDirectModal',
}

export interface ActionItem {
  text: string;
  Icon: typeof CheckCircle;
  onClick: VoidFunction;
  disabled?: boolean;
  color?: MenuItemProps['color'];
}

export interface ActionsMenuProps {
  open: boolean;
  anchorEl: any;
  handleClose: VoidFunction;
  actions: ActionItem[];
  hideDisabled?: boolean;
}
