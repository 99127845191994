import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import React from 'react';

import { Box, Grow } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Typography from '@libs/ui/components/typography';

interface FinalProps {
  link: string;
}

const useStyles = makeStyles(() => ({
  wrapper: {
    maxWidth: 700,
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
  tickIcon: {
    marginBottom: -3,
  },
}));

const Final = ({ link }: FinalProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      <Grow in timeout={600}>
        <div>
          <Box mb={12}>
            <Typography variant="h3" fontWeight={600}>
              <Typography
                component="span"
                variant="h4"
                fontStyle="italic"
                fontWeight={600}
              >
                Verification required
              </Typography>{' '}
              <CheckIcon height={30} width={30} className={classes.tickIcon} />
            </Typography>
          </Box>
          <Typography
            variant="h6"
            whiteSpace="break-spaces"
            fontStyle="italic"
            textAlign="center"
          >{`We sent you an email. Visit your inbox to verify your
account and access OpenTalent.`}</Typography>
        </div>
      </Grow>
    </Box>
  );
};

export default Final;
