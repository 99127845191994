import cn from 'classnames';
import React from 'react';

import { makeStyles } from '@mui/styles';

interface InfoBoxProps extends React.HtmlHTMLAttributes<HTMLDivElement> {}

const useStyles = makeStyles((theme) => ({
  choiceDetails: {
    borderRadius: 16,
    background: 'rgba(94, 143, 246, 0.3)',
    padding: theme.spacing(4),
    textAlign: 'center',
    position: 'relative',
  },
}));

export const InfoBox = ({ children, className }: InfoBoxProps) => {
  const classes = useStyles();

  return <div className={cn(classes.choiceDetails, className)}>{children}</div>;
};
