import React from 'react';
import { Route } from 'react-router-dom';
import { pathManager } from 'routes/consts';
import Auth0 from 'screens/auth/Auth0';
import Chat from 'screens/chat';
import Notifications from 'screens/notifications';
import Perks from 'screens/perks';

export const COMMON_ROUTES = [
  <Route
    path={pathManager.notifications.getRoute()}
    key={pathManager.notifications.getRoute()}
    component={Notifications}
  />,
  <Route
    path={pathManager.chat.getRoute()}
    key={pathManager.chat.getRoute()}
    component={Chat}
  />,
  <Route
    path={pathManager.perks.getRoute()}
    key={pathManager.perks.getRoute()}
    component={Perks}
  />,
  <Route exact key={4} component={Auth0} path={pathManager.auth0.getRoute()} />,
];
