import { ReactComponent as WorkforceIcon } from 'assets/icons/workforce.svg';
import React, { useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import DrawerList from '../drawer-list';
import useStyles from './styles';

export interface DrawerListItemProps {
  text: string;
  link?: string;
  Icon?: React.ElementType;
  isActive?: boolean;
  badge?: React.ReactNode;
  items?: DrawerListItemProps[];
  isExternal?: boolean;
  testName?: string;
  disabled?: boolean;
  htmlTitle?: string;
}

const DrawerListItem = (props: DrawerListItemProps) => {
  const {
    text,
    link,
    Icon = WorkforceIcon,
    badge,
    isActive,
    htmlTitle = '',
    items,
    isExternal,
    testName,
    disabled,
  } = props;
  const matchRoute = useRouteMatch({
    path: link,
    exact: true,
  });
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const classes = useStyles({ ...props, isActive: isActive || !!matchRoute });
  const Caret = collapsed ? ExpandMore : ExpandLess;
  // eslint-disable-next-line no-nested-ternary
  const Wrapper = (
    disabled ? 'div' : isExternal ? 'a' : link ? Link : 'span'
  ) as any;

  return (
    <>
      <Wrapper
        to={link as string}
        {...(isExternal && { href: link, target: '_blank' })}
      >
        <Tooltip title={htmlTitle} placement="bottom-end" followCursor>
          <ListItem
            onClick={() => setCollapsed((s) => !s)}
            classes={{ root: classes.listItem }}
            button
          >
            <ListItemIcon classes={{ root: classes.icon }}>
              <Icon color="inherit" data-test-id={testName} />
            </ListItemIcon>
            <ListItemText
              disableTypography
              classes={{ root: classes.text }}
              primary={text}
            />
            {!!badge && badge}
            {items?.length && <Caret classes={{ root: classes.caret }} />}
          </ListItem>
        </Tooltip>
      </Wrapper>
      {items?.length && (
        <Collapse in={!collapsed} timeout="auto">
          <Box paddingLeft="20px">
            <DrawerList disablePadding items={items} />
          </Box>
        </Collapse>
      )}
    </>
  );
};

export default DrawerListItem;
