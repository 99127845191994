import cn from 'classnames';
import { DEFAULT_AVATAR } from 'consts/common';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { stopEvent } from 'utils/common';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { DefaultHeaderProps } from './types';

interface CustomChannelHeaderProps extends DefaultHeaderProps {
  title: string;
  subtitle?: string;
  image?: string;
  link?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'white',
  },
  clickable: {
    cursor: 'pointer',
  },
  btn: {
    marginRight: theme.spacing(2),
    display: 'none',
    marginLeft: -10,

    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
}));

export const ChannelHeaderDump = ({
  toggleMobileView,
  title = 'Chat',
  subtitle = '',
  image = DEFAULT_AVATAR,
  link = '',
}: CustomChannelHeaderProps) => {
  const classes = useStyles();
  const history = useHistory();

  const onUserClick = () => {
    if (link) {
      history.push(link);
    }
  };

  return (
    <ListItem
      onClick={onUserClick}
      classes={{ root: cn(classes.root, { [classes.clickable]: !!link }) }}
    >
      {toggleMobileView && (
        <IconButton
          className={classes.btn}
          onClick={(e) => {
            stopEvent(e);
            toggleMobileView();
          }}
          size="small"
        >
          <ChevronLeftIcon />
        </IconButton>
      )}
      <ListItemAvatar>
        <Avatar alt={title} src={image} />
      </ListItemAvatar>
      <ListItemText primary={title} secondary={subtitle} />
    </ListItem>
  );
};
