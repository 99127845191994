import { ChatSidebarItemDumb } from 'components/chat/common/chat-sidebar';
import React from 'react';

import { UserToTalentChatMeta } from '../../types';
import { ChatSidebarItemProps } from './types';

interface ChatCompanyWithTalentProps extends ChatSidebarItemProps {}

const ChatCompanyWithTalent = ({
  channel,
  latestMessage,
  active = false,
  unread,
  onChannelSelect,
}: ChatCompanyWithTalentProps) => {
  const details = channel.data?.channelDetails as UserToTalentChatMeta;
  const avatarImage = details.talent.image;
  const talentName = details.talent.name;
  const lastMessageTime = channel?.state?.last_message_at as Date | undefined;

  return (
    <ChatSidebarItemDumb
      date={lastMessageTime}
      avatar={avatarImage}
      onChannelSelect={onChannelSelect}
      primaryContent={talentName}
      secondaryContent={latestMessage}
      unread={unread}
      active={active}
    />
  );
};

export default ChatCompanyWithTalent;
