import { LOCAL_STORAGE_KEYS } from 'consts/ls-keys';
import { useCurrentUser } from 'hooks/auth';
import {
  useIsCurrentTalentVerified,
  useIsLobbyTalent,
  useIsPaidTalentAccount,
} from 'hooks/talents/useTalentAccountType';
import { useEffect } from 'react';
import { useOpenModal } from 'utils/modals';

import { TalentModals } from '../../types';

export const useOpenLobbyPendingModal = () =>
  useOpenModal(TalentModals.PendingLobby);

export const useCheckLobbyPendingPopup = () => {
  const { user, isTalent } = useCurrentUser();
  const key = LOCAL_STORAGE_KEYS.lobbyPopup(user?.id || '');
  const isShown = !!localStorage.getItem(key);
  const openModal = useOpenLobbyPendingModal();
  const isLobbyTalent = useIsLobbyTalent();
  const isTalentVerified = !!useIsCurrentTalentVerified();

  useEffect(() => {
    if (!isShown && isTalent && isTalentVerified) {
      openModal();
      localStorage.setItem(key, 'true');
    }
  }, [isLobbyTalent, isTalentVerified, isShown, isTalent, key, openModal]);
};

export const useOpenLobbyVerifiedModal = () =>
  useOpenModal(TalentModals.PaidTalentWelcome);

export const useCheckLobbyPaidPopup = () => {
  const { user, isTalent } = useCurrentUser();
  const key = LOCAL_STORAGE_KEYS.paidTalentWelcome(user?.id || '');
  const isShown = !!localStorage.getItem(key);
  const openModal = useOpenLobbyVerifiedModal();
  const isPaidTalentAccount = useIsPaidTalentAccount();

  useEffect(() => {
    if (!isShown && isTalent && isPaidTalentAccount) {
      openModal();
      localStorage.setItem(key, 'true');
    }
  }, [isPaidTalentAccount, isShown, isTalent, key, openModal]);
};
