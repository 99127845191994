import { DEFAULT_AVATAR } from 'consts/common';
import React from 'react';

import { Avatar, Badge, Box, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Talent } from '@libs/graphql-types';

interface LogoBlockProps {
  talent: Talent;
  name: string;
}

const useStyles = makeStyles((theme) => ({
  avatarBadge: {
    background: ({ talent }: LogoBlockProps) =>
      talent?.available_now
        ? theme.palette.primary.light
        : theme.palette.grey[500],
    border: `2px solid ${theme.palette.grey[200]}`,
    width: 22,
    height: 22,
  },
  avatar: {
    background: `url(${DEFAULT_AVATAR}) no-repeat center`,
    border: `2px solid ${theme.palette.grey[200]}`,
    borderRadius: '100%',
    [theme.breakpoints.down('md')]: {
      width: theme.spacing(18),
      height: theme.spacing(18),
    },
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(14),
      height: theme.spacing(14),
    },
    width: theme.spacing(24),
    height: theme.spacing(24),
  },
}));

const LogoBlock = (props: LogoBlockProps) => {
  const { talent, name } = props;
  const classes = useStyles(props);

  return (
    <Badge
      overlap="circular"
      classes={{ badge: classes.avatarBadge }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      variant="standard"
      badgeContent={
        <Box>
          <Tooltip
            placement="right-end"
            open={talent?.available_now ? undefined : false}
            title="Available now - let’s work together!"
          >
            <Box width={22} height={22} />
          </Tooltip>
        </Box>
      }
    >
      <Avatar
        src={talent?.avatar?.avatar || DEFAULT_AVATAR}
        className={classes.avatar}
        imgProps={{ loading: 'lazy' }}
        alt={name}
      />
    </Badge>
  );
};

export default LogoBlock;
