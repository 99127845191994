import { useStreamChatContext } from 'components/chat/common/provider';
import { useCallback, useEffect, useState } from 'react';
import { Channel } from 'stream-chat';

type HTTPError = {
  status: number;
  response: any;
};

export const useChannelById = (channelId?: string) => {
  const { client } = useStreamChatContext();
  const [channel, setChannel] = useState<Channel>();
  const [error, setError] = useState<HTTPError | undefined>();
  const getChannel = useCallback(async () => {
    if (channelId) {
      try {
        const channeslsSearch = await client.queryChannels({ id: channelId });
        const resChannel = channeslsSearch[0];

        setChannel(resChannel);
      } catch (error) {
        const err = error as any as HTTPError;
        console.dir(err);
        setError(err);
      }
    }
  }, [channelId, client]);

  useEffect(() => {
    getChannel();
  }, [getChannel]);

  return { channel, error };
};

export const useMentionClickHandler = () => { };
