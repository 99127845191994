import { JoinReasonEnum } from '@libs/graphql-types';

export const PREMIUM_FEATURES = [
  'View full member profiles',
  'Message any member',
  'Download CVs',
  'Advanced search',
  'Chat with OpenTalent',
];

export const NEW_TALENTS_FLAG_DAYS_COUNT = 7;

export const JOIN_REASON_LABELS: Record<JoinReasonEnum, string> = {
  [JoinReasonEnum.Freelance]: 'Freelance',
  [JoinReasonEnum.Candidates]: 'Find candidates',
  [JoinReasonEnum.Networking]: 'Networking',
  [JoinReasonEnum.PermanentWork]: 'Permanent Work',
  [JoinReasonEnum.VentureBuilding]: 'Venture Building',
  [JoinReasonEnum.PerksAndBenefits]: 'Perks & Benefits',
};
