import React from 'react';

import { List } from '@mui/material';

import { BottomDrawerListItem } from './BottomDrawerListItem';
import { useBottomDrawerItems } from './hooks';

export const BottomDrawerList = () => {
  const items = useBottomDrawerItems();

  return (
    <List disablePadding={false}>
      {items?.map((item) => (
        <BottomDrawerListItem key={item.text} {...item} />
      ))}
    </List>
  );
};
