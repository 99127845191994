export const LOCAL_STORAGE_KEYS = {
  talentOnboardingEmail: 'talentOnboardingEmail',
  talentOnboardingDeviceId: 'talentOnboardingDeviceId',
  talentOnboardingCompanyId: 'talentOnboardingCompanyId',
};

export const EXTERNAL_LINKS = {
  communityGuidelines:
    'https://www.notion.so/opentalent/Community-Guidelines-8297d78bd5664e8384876d516488879d',
  slackCommunity:
    'https://join.slack.com/t/opentalentcommunity/shared_invite/zt-wgcocyxg-63qpP96wR5biBAF5GGmlOw',
  privacyPolicy:
    'https://www.notion.so/Privacy-Policy-OpenTalent-229e57a0fb584fd4834f07ee9f594132',
  termsAndConditions:
    'https://www.notion.so/opentalent/Terms-Conditions-OpenTalent-6929e5fe35ff4840855db8fd6c46df41',
  userGuide: `https://otazureapi.wwweberry.com/graphql2/files/opentalent_user_guide.pdf`,
  matching:
    'https://www.notion.so/opentalent/Understanding-Matching-Coefficient-41d7a8b105be422a887a3865bc62656e',
  bookMeeting:
    'https://calendly.com/sanjib-meeting/feedback-session-opentalent-direct',

  opentalentProMeeting: 'https://calendly.com/pieter-meeting/30min',
  pieterLink: 'https://www.meeting.opentalent.co/meetings/pieter40',
  pricing: 'https://www.knowledge.opentalent.co/pricing-request',
  pricingInfo:
    'https://opentalent.notion.site/Pricing-0b24c6d75e9f4e938d07c48b7714217a',
  ABNCase: ' https://www.knowledge.opentalent.co/abn-case',
  deleteAccount: 'https://form.typeform.com/to/TyT09GXP',

  teamHiring: 'https://form.typeform.com/to/tATRcx1G',
  whatsAppForCompanies: 'https://wa.me/message/VHHZBB7TCN52C1',
  whatsNext: `https://form.typeform.com/to/cPnNdBVH`,
};
