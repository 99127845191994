import FixedFooter from 'components/custom/onboarding/fixed-footer';
import SkillSelect from 'components/custom/skill-select';
import React from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { SkillTypeEnum } from '@libs/graphql-types';
import { modelPath } from '@libs/helpers/form';
import { RouterButton } from '@libs/ui/components/button';

import { useDefaultOnSubmitHandler } from '../form-context/hooks';
import { BuilderFormState } from '../form-context/types';
import NextButtonWithValidation from '../shared/NextButtonWithValidation';
import StepTemplate from '../shared/StepTemplate';
import { DefaultStepProps } from '../shared/types';

interface JobSkillsProps extends DefaultStepProps {}

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.MuiAutocomplete-noOptions': {
      display: 'none',
    },
  },
  form: {
    maxWidth: 400,
    margin: '0 auto',

    '& .MuiFormHelperText-root': {
      color: 'rgba(255, 255, 255, 0.7);',

      '&.Mui-error': {
        color: theme.palette.error.main,
      },
    },
  },
}));

const JobSkills = ({ index, nextStepRoute, prevStepRoute }: JobSkillsProps) => {
  const classes = useStyles();

  const { onSubmit, loading } = useDefaultOnSubmitHandler({
    redirectRoute: nextStepRoute,
  });

  return (
    <StepTemplate
      title="Select Skills"
      subtitle="What are you looking for?"
      currentStep={index}
      currentStepLabel="Skills"
    >
      <Box className={classes.form}>
        <Box pb={6}>
          <SkillSelect
            skillTypes={[SkillTypeEnum.HardSkills, SkillTypeEnum.Solutions]}
            name={modelPath<BuilderFormState>((m) => m.jobSkills.mandatory)}
            label="Mandatory skills"
            helperText="Select 2 or more technologies and/or hard skills"
            noOptionsText={null}
            // open
          />
        </Box>

        <SkillSelect
          name={modelPath<BuilderFormState>((m) => m.jobSkills.common)}
          label="Nice-to-have skills"
          noOptionsText={null}
        />
      </Box>

      <FixedFooter>
        <Grid spacing={4} justifyContent="center" container>
          <Grid xs={6} item>
            <RouterButton
              startIcon={<ArrowBackIcon />}
              to={prevStepRoute}
              color="primary"
              variant="outlined"
              fullWidth
            >
              Back
            </RouterButton>
          </Grid>
          <Grid xs={6} item>
            <NextButtonWithValidation
              path={modelPath<BuilderFormState>((m) => m.jobSkills)}
              disabled={loading}
              onClick={onSubmit}
            >
              next
            </NextButtonWithValidation>
          </Grid>
        </Grid>
      </FixedFooter>
    </StepTemplate>
  );
};

export default JobSkills;
