import { gql } from '@apollo/client';

export default gql`
  mutation UploadTalentAvatar($talent_id: ID!, $file: Upload!) {
    uploadTalentAvatar(talent_id: $talent_id, file: $file) {
      hash
      avatar
    }
  }
`;
