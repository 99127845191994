import { useField } from 'formik';
import React from 'react';

import DatePicker, { DatePickerProps } from './DatePicker';

interface ConnectedDatePickerProps
  extends Omit<DatePickerProps, 'value' | 'onChange'> {
  name: string;
  helperText?: string;
}

const ConnectedDatePicker = (props: ConnectedDatePickerProps) => {
  const { name, helperText, TextFieldProps, ...rest } = props;
  const [field, meta, helpers] = useField({ name });

  const error = meta.touched && !!meta.error;
  const finalHelperText = meta.touched && meta.error ? meta.error : helperText;
  const fieldValue = field.value;

  return (
    <DatePicker
      value={fieldValue}
      onChange={(newValue) => {
        helpers.setTouched(true);
        helpers.setValue(newValue, true);
      }}
      TextFieldProps={{
        ...TextFieldProps,
        error,
        helperText: finalHelperText,
        id: field.name,
        name: field.name,
      }}
      {...rest}
    />
  );
};

export default ConnectedDatePicker;
