export const CUSTOM_COLORS = [
  'tertiary' as const,
  'success' as const,
  'lightBlue' as const,
  'green' as const,
  'grey' as const,
  'white' as const,
  'red' as const,
  'successDark' as const,
];
