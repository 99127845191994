import { useMenuAction } from 'hooks/common/useMenuAction';
import React from 'react';
import { noop } from 'utils/common';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { makeStyles } from '@mui/styles';

import { JobMatch } from '@libs/graphql-types';
import Button from '@libs/ui/components/button';

import { RejectSubmittedTalentModal } from './RejectSubmittedTalentModal';
import { TalentActionsMenu } from './TalentActionsMenu';
import { useSubmittedTalentActions } from './hooks';

interface SubmittedTalentActionsProps {
  match: JobMatch;
  refetch?: VoidFunction;
  loading?: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    top: 16,
    right: 16,
  },
}));

export const SubmittedTalentActions = ({
  match,
  refetch = noop,
}: SubmittedTalentActionsProps) => {
  const { open, handleClose, handleClick, anchorEl } = useMenuAction();
  const { actions, loading } = useSubmittedTalentActions({
    match,
    onSuccess: refetch,
  });
  const classes = useStyles();

  if (!actions.length) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Button
        disabled={loading}
        onClick={handleClick as VoidFunction}
        variant="outlined"
        size="medium"
        data-test-id="talentActions"
        endIcon={<MoreVertIcon />}
      >
        Actions
      </Button>
      <TalentActionsMenu
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
        match={match}
        onSuccess={refetch}
        actions={actions}
      />

      <RejectSubmittedTalentModal onSuccess={refetch} modalKey={match.id} />
    </div>
  );
};
