import { mergeDeep } from '@apollo/client/utilities';
import { mapRequiredSkillsToServer } from 'screens/company-user/job-form/utils';

import {
  CreateNewJobMutationVariables,
  JobLocationTypeEnum,
  JobTypeEnum,
  SkillTypeEnum,
  User,
} from '@libs/graphql-types';

import { CreationInfo } from '../account-creation/types';
import { BuilderFormState } from './types';
import { getDefaultValues } from './utils';

export const mapUserToForm = (user?: User): CreationInfo =>
  mergeDeep(getDefaultValues().companyAccount, {
    firstName: user?.first_name || '',
    lastName: user?.last_name || '',
    email: user?.email || '',
    companyName: user?.company?.name || '',
    id: user?.id,
  });

export const mapTmpDataToForm = ({
  tmpData,
}: {
  tmpData?: string;
}): BuilderFormState => {
  if (!tmpData) {
    return getDefaultValues();
  }

  const data = JSON.parse(tmpData);

  return {
    companyAccount: data.companyAccount,
    jobType: data.jobType,
    jobCategory: data.jobCategory,
    jobSkills: data.jobSkills,
    jobDuration: data.jobDuration,
    workspace: data.workspace,
    findersFee: data.findersFee,
    rate: data.rate,
    _internal: { deviceId: '' },
  };
};

export const mapFormDataToJobCreation = (
  form: BuilderFormState,
): CreateNewJobMutationVariables => {
  const requiredSkills = mapRequiredSkillsToServer(form.jobSkills.mandatory);
  const jobType = form.jobType.type;
  const isFreelance = jobType === JobTypeEnum.Freelance;
  const isPermanent = jobType === JobTypeEnum.Permanent;

  const freelanceFields = {
    hours_per_week: form.jobDuration.capacity as number,
    end_date: form.jobDuration.endDate,
    rate_min: form.rate.maxRate,
    rate_max: form.rate.maxRate,
  };

  const permanentFields = {
    permanent_capacity_type: form.jobDuration.permanentCapacityType,
    salary_min: form.rate.minSalary,
    salary_max: form.rate.maxSalary,
  };

  return {
    ...(isFreelance && freelanceFields),
    ...(isPermanent && permanentFields),
    name: form.jobCategory.title,
    category_id: form.jobCategory.category,
    subcategory_id: form.jobCategory.subcategory.value as string,

    campaign_start_date: form.jobDuration.startDate,
    campaign_end_date: form.jobDuration.endDate,

    campaign_owner_id: form.companyAccount.id!,

    skills: form.jobSkills.common.map((i) => i.value) as string[],

    solutions_required: requiredSkills[SkillTypeEnum.Solutions],
    hard_skills_required: requiredSkills[SkillTypeEnum.HardSkills],

    location_type: form.workspace.location as JobLocationTypeEnum,
    type: jobType,
    client: form.companyAccount.companyName!,
    description: '_',

    is_draft: true,
  };
};
