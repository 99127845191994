import { ConnectedPageLayout } from 'components/layout/page-layout';
import React from 'react';

import { Box, LinearProgress } from '@mui/material';

import { useGetCompaniesScreenDataQuery } from '@libs/graphql-types';
import Typography from '@libs/ui/components/typography';

import { useCommunityFilter } from './hooks';
import { CompaniesList } from './list';
import { CompaniesFilter } from './list/CompaniesFilter';

export const CompaniesScreen = () => {
  const filter = useCommunityFilter();
  const { loading, data, refetch } = useGetCompaniesScreenDataQuery({
    variables: {
      pooling_type: filter.type,
      country: filter.country ? filter.country : undefined,
      first: 50,
    },
  });

  return (
    <ConnectedPageLayout
      documentTitle="Companies"
      headerProps={{ accountProps: {} }}
      drawerProps={{}}
    >
      <Box>
        <Typography variant="h5" paragraph>
          Active Communities
        </Typography>
        <Typography variant="body1">
          Companies and recruiters use OpenTalent to build private talent
          communities.
          <br /> Request to join your favourite communities for exclusive access
          to jobs and opportunities.
        </Typography>

        <Box mt={6}>
          <CompaniesFilter filter={filter} />
        </Box>

        <Box mt={6}>
          {loading && (
            <Box mb={6}>
              <LinearProgress color="info" />
            </Box>
          )}
          <CompaniesList refetch={refetch} companies={data?.poolingCompanies} />
        </Box>

        {/* <Box> */}
        {/*   <Invite /> */}
        {/* </Box> */}
      </Box>
    </ConnectedPageLayout>
  );
};
