import { ReactComponent as LogoIcon } from 'assets/icons/logo.svg';
import { ReactComponent as MobileLogoIcon } from 'assets/icons/mobile-logo.svg';
import Account, { AccountProps } from 'components/layout/account';
import { useCurrentUser } from 'hooks/auth';
import useMediaQueries from 'hooks/common/useMediaQueries';
import { useIsCurrentTalentVerified } from 'hooks/talents/useTalentAccountType';
import React from 'react';
import { Link } from 'react-router-dom';
import { pathManager } from 'routes';

import MenuIcon from '@mui/icons-material/Menu';
import { Box } from '@mui/material';
import AppBar, { AppBarProps } from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';

import { MiddleContent } from './middle-content';
import Notifications from './notifications';
import useStyles from './styles';

export interface HeaderProps extends AppBarProps {
  accountProps?: AccountProps | null;
  toggleDrawer?: VoidFunction;
  isLoading?: boolean;
  inventionCount?: number;
  Logo?: React.ComponentType;
  customControls?: React.ReactNode;
}

const Header = (props: HeaderProps) => {
  const {
    accountProps,
    toggleDrawer,
    isLoading = false,
    inventionCount,
    Logo,
    customControls,
    ...rest
  } = props;
  const classes = useStyles(props);
  const { isSM } = useMediaQueries();
  const LogoComponent = Logo ? Logo : isSM ? MobileLogoIcon : LogoIcon;
  const isTalentVerified = useIsCurrentTalentVerified();
  const { isTalent } = useCurrentUser();
  const showLinkToPendingVerification = isTalent && !isTalentVerified;

  return (
    <AppBar className={classes.header} {...rest} color="secondary">
      <Toolbar>
        <Grid alignItems="center" justifyContent="space-between" container>
          <Grid
            className={classes.logoBlock}
            spacing={2}
            xs={3}
            alignItems="center"
            container
            item
          >
            {toggleDrawer && (
              <Hidden mdUp>
                <Grid item>
                  <IconButton
                    onClick={() => toggleDrawer()}
                    size="small"
                    color="inherit"
                  >
                    <MenuIcon />
                  </IconButton>
                </Grid>
              </Hidden>
            )}
            <Grid item>
              {showLinkToPendingVerification ? (
                <Link
                  to={pathManager.talent.pendingVerificaion.generatePath()}
                  style={{ textDecoration: 'none' }}
                >
                  <LogoComponent className={classes.logo} />
                </Link>
              ) : (
                <LogoComponent className={classes.logo} />
              )}
            </Grid>
          </Grid>
          {isTalentVerified && (
            <Grid item className={classes.middleBlock}>
              <MiddleContent />
            </Grid>
          )}
          <Grid item>
            <Grid alignItems="center" container>
              {(accountProps || (accountProps && isLoading)) && (
                <>
                  {!!customControls && (
                    <Grid component={Box} pr={2} item>
                      {customControls}
                    </Grid>
                  )}
                  {isTalentVerified && (
                    <Grid component={Box} pr={2} item>
                      <Notifications isLoading={isLoading} />
                    </Grid>
                  )}
                  <Grid item>
                    <Account isLoading={isLoading} {...(accountProps || {})} />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
