import validationErrors from 'consts/validationErrors';
import { isValid } from 'date-fns';
import * as yup from 'yup';

import { JobLocationTypeEnum, JobTypeEnum } from '@libs/graphql-types';

import { RATES } from '../consts';
import { JobTypeState } from '../job-type-choosing/types';

const getPeriodDateValidator = ({
  compareWith,
}: {
  compareWith: 'startDate' | 'endDate';
}) =>
  yup
    .date()
    .nullable()
    .min(new Date(), validationErrors.presentDate)
    .when(compareWith, function(startDate: number, schema: any) {
      const compareWithStart = compareWith === 'startDate';
      const type = compareWithStart ? 'min' : 'max';
      const error = compareWithStart
        ? 'Choose later date'
        : 'Choose earlier date';

      return isValid(startDate) ? schema[type](startDate, error) : schema;
    } as any);

export const getValidator = () =>
  yup.object().shape({
    companyAccount: yup.object().shape({
      firstName: yup.string().trim().required('First name is required'),
      lastName: yup.string().trim().required('Last name is required'),
      email: yup
        .string()
        .onlyBusinessEmail()
        .email()
        .required('Email is required'),
      companyName: yup.string().trim().required('Company name is required'),
      agreement: yup.boolean().oneOf([true], 'You must agree to the terms'),
    }),
    jobCategory: yup.object().shape({
      title: yup.string().trim().required('Title is required'),
      subcategory: yup.object().nullable().required('Subcategory is required'),
      category: yup.string().trim().required('Category is required'),
    }),

    jobSkills: yup.object().shape({
      mandatory: yup
        .array()
        .min(2, validationErrors.mandatoryJobSkillsNumber())
        .max(6, validationErrors.mandatoryJobSkillsNumber()),
      common: yup.array().max(6, validationErrors.mandatoryJobSkillsNumber()),
    }),

    jobType: yup.object().shape({
      type: yup.string().nullable().required('Job type is required'),
    }),

    jobDuration: yup.object().when('jobType', {
      is: (jobType: JobTypeState) => jobType.type === JobTypeEnum.Freelance,
      then: (schema) =>
        schema.shape(
          {
            startDate: getPeriodDateValidator({
              compareWith: 'endDate',
            }).required(),
            endDate: getPeriodDateValidator({
              compareWith: 'startDate',
            }).required(),
            capacity: yup.number().nullable().required('Capacity is required'),
          },
          ['endDate', 'startDate'] as any,
        ),
      otherwise: (schema) =>
        schema.shape(
          {
            startDate: getPeriodDateValidator({
              compareWith: 'endDate',
            }).required(),
            endDate: getPeriodDateValidator({
              compareWith: 'startDate',
            }).required(),
            permanentCapacityType: yup
              .string()
              .nullable()
              .required('Capacity is required'),
          },
          ['endDate', 'startDate'] as any,
        ),
    }),

    rate: yup.object().when('jobType', {
      is: (jobType: JobTypeState) => jobType.type === JobTypeEnum.Freelance,
      then: (schema) =>
        schema.shape(
          {
            minRate: yup
              .number()
              .nullable()
              .min(RATES.FREELANCE.min)
              .max(RATES.FREELANCE.max)
              .required()
              .test({
                name: 'salaryRangeTest',
                test: function() {
                  const minSalary: string = this.parent.minRate;
                  const maxSalary: string = this.parent.maxRate;

                  return +minSalary <= +maxSalary;
                },
                message: `Min rate is bigger than max rate`,
              }),
            maxRate: yup
              .number()
              .nullable()
              .min(RATES.FREELANCE.min)
              .max(RATES.FREELANCE.max)
              .required()
              .test({
                name: 'salaryRangeTest',
                test: function() {
                  const minSalary: string = this.parent.minRate;
                  const maxSalary: string = this.parent.maxRate;

                  return +minSalary <= +maxSalary;
                },
                message: `Min rate is bigger than max rate`,
              }),
          },
          ['maxRate', 'minRate'] as any,
        ),
      otherwise: (schema) =>
        schema.shape(
          {
            minSalary: yup
              .number()
              .nullable()
              .min(RATES.PERMANENT.min)
              .max(RATES.PERMANENT.max)
              .required()
              .test({
                name: 'salaryRangeTest',
                test: function() {
                  const minSalary: string = this.parent.minSalary;
                  const maxSalary: string = this.parent.maxSalary;

                  return +minSalary <= +maxSalary;
                },
                message: `Min salary is bigger than max salary`,
              }),
            maxSalary: yup
              .number()
              .nullable()
              .min(RATES.PERMANENT.min)
              .max(RATES.PERMANENT.max)
              .required()
              .test({
                name: 'salaryRangeTest',
                test: function() {
                  const minSalary: string = this.parent.minSalary;
                  const maxSalary: string = this.parent.maxSalary;

                  return +minSalary <= +maxSalary;
                },
                message: `Min salary is bigger than max salary`,
              }),
          },
          ['maxRate', 'minRate'] as any,
        ),
    }),

    workspace: yup.object().shape({
      location: yup.string().nullable().required('Location is required'),
      city: yup
        .string()
        .nullable()
        .trim()
        .when('location', {
          is: JobLocationTypeEnum.Remote,
          then: (schema) => schema,
          otherwise: (schema) => schema.required('City is required'),
        }),
      country: yup
        .string()
        .nullable()
        .trim()
        .when('location', {
          is: JobLocationTypeEnum.Remote,
          then: (schema) => schema,
          otherwise: (schema) => schema.required('Country is required'),
        }),
    }),
  });
