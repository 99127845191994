import { gql } from '@apollo/client';

export const GET_COMPANY_ONBOARDING_DATA = gql`
  query GetCompanyOnboardingData($device_id: String!) {
    getTempDataItem(device_id: $device_id) {
      success
      result {
        device_id
        data
      }
    }
  }
`;
