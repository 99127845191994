import React from 'react';

import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { GetCompaniesScreenDataQuery } from '@libs/graphql-types';

import { CompanyCard } from './CompanyCard';

interface CompaniesListProps {
  companies?: GetCompaniesScreenDataQuery['poolingCompanies'];
  refetch?: VoidFunction;
}

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

export const CompaniesList = ({ companies, refetch }: CompaniesListProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {companies?.data?.map((company, index) => {
        if (!company) {
          return null;
        }
        return (
          <Box
            mb={index === companies?.data?.length - 1 ? 0 : 4}
            key={company.id}
          >
            <CompanyCard key={company.id} company={company} refetch={refetch} />
          </Box>
        );
      })}
    </div>
  );
};
