import stripeImage from 'assets/images/stripe@2x.png';
import { CheckThickListItem } from 'components/check-thick-list';
import { CompanyModals } from 'components/custom/company/types';
import { INFINITY_SIGN } from 'consts/common';
import { DIRECT_FEATURES, DIRECT_PRICE } from 'consts/plans';
import React from 'react';
import {
  DefaultModalProps,
  useOpenModal,
  withLocationStateModal,
} from 'utils/modals';

import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useGetCompaniesLandingDataQuery } from '@libs/graphql-types';
import { formatCurrency, formatNumberSafe } from '@libs/helpers/format';
import { DefaultModal } from '@libs/ui/components/modals';
import Typography from '@libs/ui/components/typography';

import StripePaymentButton from '../../company-stripe-payment-button';

interface DirectSubscriptionModalProps extends DefaultModalProps<true> { }

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#191919',
    color: 'white',
    borderRadius: 8,
    width: 600,

    [theme.breakpoints.down('md')]: {
      width: 'auto',
    },
  },
}));

const DirectSubscriptionModalComponent = ({
  isOpen,
  close,
}: DirectSubscriptionModalProps) => {
  const classes = useStyles();
  const res = useGetCompaniesLandingDataQuery();
  const talentsCount =
    res?.data?.commonAppInfo?.total_ot_approved_freelancers_count;

  return (
    <DefaultModal
      className={classes.root}
      handleClose={close}
      open={isOpen}
      title={
        <Box mb={2}>
          <Typography variant="h4" component="span" fontWeight={300}>
            OpenTalent
          </Typography>{' '}
          <Typography variant="h4" component="span" fontWeight={700}>
            Direct
          </Typography>
        </Box>
      }
      actions={
        <Box margin="0 auto" width={260}>
          <StripePaymentButton color="primary">
            UPGRADE to DIRECT
          </StripePaymentButton>

          <Box mt={6}>
            <img srcSet={`${stripeImage} 2x`} alt="Stripe" />
          </Box>
        </Box>
      }
    >
      <Box mt={4} mb={6}>
        <Typography
          whiteSpace="break-spaces"
          variant="body1"
        >{`Get instant access to ${formatNumberSafe(talentsCount, {
          fallback: INFINITY_SIGN,
        })} carefully vetted
professionals, available free of any fees.`}</Typography>
      </Box>

      <Box mt={6} mb={6}>
        <Typography style={{ textDecoration: 'underline' }}>
          What will you get:
        </Typography>
      </Box>

      <Box width={340} margin="0 auto">
        {DIRECT_FEATURES.map((feature) => (
          <CheckThickListItem key={feature} isChecked>
            {feature}
          </CheckThickListItem>
        ))}
      </Box>

      <Box mt={6}>
        <Box display="flex" justifyContent="center">
          <Typography variant="h4">{formatCurrency(DIRECT_PRICE)}</Typography>
          <Typography variant="body1">&nbsp;per month</Typography>
        </Box>
      </Box>
    </DefaultModal>
  );
};

export const DirectSubscriptionModal = withLocationStateModal({
  id: CompanyModals.DirectSubscription,
})(DirectSubscriptionModalComponent);

export const useOpenDirectSubscriptionModal = () =>
  useOpenModal(CompanyModals.DirectSubscription);
