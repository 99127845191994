import React from 'react';

import LaunchIcon from '@mui/icons-material/Launch';
import { Grid } from '@mui/material';

import Button from '@libs/ui/components/button';
import Typography from '@libs/ui/components/typography';

interface UrlActionsProps {
  url: string;
  actionText?: string;
}

export const UrlActions = ({
  url,
  actionText = 'Follow the link and register.',
}: UrlActionsProps) => {
  return (
    <Grid container>
      <Grid flexGrow={1} display="flex" alignItems="center" item>
        <Button
          href={url}
          {...{ target: '_blank' }}
          variant="contained"
          color="info"
          endIcon={<LaunchIcon />}
        >
          Get perk
        </Button>
      </Grid>
      <Grid display="flex" alignItems="center" item>
        <Typography variant="subtitle2" color="textSecondary">
          {actionText}
        </Typography>
      </Grid>
    </Grid>
  );
};
