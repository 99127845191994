import { ChatTypes } from 'components/chat';
import React from 'react';
import { pathManager } from 'routes';

import { Notification } from '@libs/graphql-types';
import { RouterLink } from '@libs/ui/components/typography';

interface NotificationReplyLinkProps {
  notification: Notification;
}

const NotificationReplyLink = ({
  notification,
}: NotificationReplyLinkProps) => {
  const data = JSON.parse(JSON.stringify(notification.reply_to!));

  return (
    <RouterLink
      style={{ marginTop: 12, display: 'block' }}
      to={{
        pathname: pathManager.chat.generatePath(),
        state: {
          strategy: {
            type: ChatTypes.UserToTalent,
            data,
          },
        },
      }}
      variant="body2"
    >
      [Click here to reply]
    </RouterLink>
  );
};

export default NotificationReplyLink;
