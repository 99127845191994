import { DEFAULT_AVATAR } from 'consts/common';
import { formatName } from 'utils/talent';

import { Talent, User } from '@libs/graphql-types';

import {
  CompanyUserChatData,
  CustomChatUserData,
} from '../common/provider/types';

export const mapTalentToChatUserData = (
  talent: Talent,
): CustomChatUserData => ({
  id: talent.id,
  image: talent.avatar?.avatar || DEFAULT_AVATAR,
  title: talent.recent_position_title || '',
  name: formatName({
    firstName: talent.first_name || talent.first_name_abac?.value,
    lastName: talent.last_name || talent.last_name_abac?.value,
  }),

  token: talent.stream_chat_token as string,
  chatId: talent.stream_chat_id as string,
});

export const mapChatUserData = (
  chatUser: Talent | User,
): CustomChatUserData | CompanyUserChatData => ({
  id: chatUser.id,
  image: chatUser.avatar?.avatar || DEFAULT_AVATAR,
  title:
    (chatUser as Talent)?.recent_position_title ||
    (chatUser as User)?.position ||
    '',
  name: formatName({
    firstName:
      chatUser.first_name || (chatUser as Talent).first_name_abac?.value,
    lastName: chatUser.last_name || (chatUser as Talent).last_name_abac?.value,
  }),

  companyName: (chatUser as User)?.company?.name || '',

  token: chatUser.stream_chat_token as string,
  chatId: chatUser.stream_chat_id as string,
});

export const generateChatName = (name1: string, name2: string) =>
  `${name1} - ${name2}`;

export const isCompanyChatUser = (
  user: CustomChatUserData | CompanyUserChatData,
): user is CompanyUserChatData => !!(user as CompanyUserChatData).companyName;
