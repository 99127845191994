import React from 'react';
import { noop } from 'utils/common';

import { Talent } from '@libs/graphql-types';

import { ActionItem, ActionsMenuProps } from '../types';
import ActionsMenu from './ActionsMenu';
import { useTalentActions } from './hooks/hooks';

interface TalentActionsMenuProps extends Omit<ActionsMenuProps, 'actions'> {
  talent: Talent;
  onSuccess?: VoidFunction;
  filterActions?: (actions: ActionItem[]) => ActionItem[];
}

export const TalentActionsMenu = ({
  talent,
  onSuccess = noop,
  filterActions,
  ...rest
}: TalentActionsMenuProps) => {
  const actions = useTalentActions({ talent, onSuccess });
  const finalActions = filterActions ? filterActions(actions) : actions;

  return <ActionsMenu {...rest} actions={finalActions} />;
};
