import React from 'react';

import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ActionsMenuProps } from '../types';

const useStyles = makeStyles((theme) => ({
  menu: {
    minWidth: `150px !important`,
  },
  error: {
    color: theme.palette.error.main,

    '& svg': {
      color: theme.palette.error.main,
    },
  },
  warinig: {
    color: theme.palette.warning.main,

    '& svg': {
      color: theme.palette.warning.main,
    },
  },
  success: {
    color: theme.palette.success.main,

    '& svg': {
      color: theme.palette.success.main,
    },
  },
}));

const TelentPoolActionsMenu = (props: ActionsMenuProps) => {
  const { open, anchorEl, actions, handleClose, hideDisabled } = props;
  const classes = useStyles(props);

  return (
    <Menu
      id="long-menu"
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      classes={{ list: classes.menu }}
    >
      {actions.map(({ text, onClick, Icon, disabled = false, color }) => {
        if (disabled && hideDisabled) {
          return null;
        }

        return (
          <MenuItem
            key={text}
            onClick={() => {
              onClick();
              handleClose();
            }}
            style={{ paddingTop: '2px', paddingBottom: '2px' }}
            disabled={disabled}
            classes={{
              // @ts-ignore
              root: disabled ? '' : classes[color as any],
            }}
          >
            {Icon && (
              <ListItemIcon color={color}>
                <Icon fontSize="inherit" color="inherit" />
              </ListItemIcon>
            )}
            <ListItemText primary={text} />
          </MenuItem>
        );
      })}
    </Menu>
  );
};

export default TelentPoolActionsMenu;
