import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { pathManager } from 'routes';

import { Box, Card, CardContent, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Button from '@libs/ui/components/button';
import Typography, { RouterLink } from '@libs/ui/components/typography';

import { TypeCard } from './TypeCard';
import { CARDS_DATA } from './consts';
import { JoinTypeEnum } from './types';

interface AddCompanyFormProps {}

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  card: {
    marginLeft: 'auto',
    background: '#1E1E1A',
    textAlign: 'center',
    borderRadius: theme.spacing(4),

    '& a': {
      color: `${theme.palette.info.main} !important`,
    },
  },
  createText: {
    cursor: 'pointer',
    color: theme.palette.info.main,
  },
  cardContent: {
    padding: `${theme.spacing(14)} !important`,

    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacing(6)} !important`,
    },
  },
  yellowText: {
    color: 'rgba(255, 255, 255, 0.54) !important',
  },
  btn: {
    borderRadius: 34,

    'a&': {
      color: `${theme.palette.text.primary} !important`,
    },

    '&.Mui-disabled': {
      background: '#BDBDBD',
    },
  },
}));

export const AddCompanyForm = (props: AddCompanyFormProps) => {
  const [currentType, setCurrentType] = useState<JoinTypeEnum>();
  const classes = useStyles();
  const history = useHistory();
  const onSubmit = () => {
    if (!currentType) {
      return;
    }
    if (currentType === JoinTypeEnum.Company) {
      history.push(pathManager.company.onboardingV4.create.generatePath());
      return;
    }

    if (currentType === JoinTypeEnum.Agency) {
      history.push(pathManager.company.onboardingV4.bookAgency.generatePath());
      return;
    }

    window.location.href = 'https://www.opentalent.co/program';
  };

  return (
    <div className={classes.root}>
      <Card elevation={0} className={classes.card}>
        <CardContent classes={{ root: classes.cardContent }}>
          <Box mb={3}>
            <Typography style={{ fontSize: 24 }} variant="h5" fontWeight={600}>
              Create your free account
            </Typography>
          </Box>

          <Box mb={6}>
            <Typography variant="body1">Pick your type of account</Typography>
          </Box>

          <Grid container flexDirection="column" spacing={4} mb={8}>
            {CARDS_DATA.map((card) => (
              <Grid item key={card.value}>
                <TypeCard
                  {...card}
                  onChange={() => setCurrentType(card.value)}
                  selected={currentType === card.value}
                />
              </Grid>
            ))}
          </Grid>

          <Box mb={6}>
            <Button
              fullWidth
              size="large"
              color="primary"
              variant="contained"
              className={classes.btn}
              disabled={!currentType}
              onClick={onSubmit}
            >
              CONTINUE
            </Button>
          </Box>

          <Box>
            <Typography variant="subtitle2" style={{ fontSize: 14 }}>
              Already have an account?{' '}
              <RouterLink
                to={pathManager.quickAuth.generatePath()}
                color="info.main"
                style={{ fontSize: 14 }}
              >
                Sign in
              </RouterLink>
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};
