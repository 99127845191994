import {
  IS_VALID_PHONE,
  setupCustomValidators,
  IS_VALID_URL,
} from './customMethods';
import { setYupLocale } from './locale';

export * from './validators';

export const configureYup = () => {
  setupCustomValidators();
  setYupLocale();
};

declare module 'yup' {
  interface StringSchema {
    onlyBusinessEmail(): StringSchema;
    [IS_VALID_PHONE](): StringSchema;
    [IS_VALID_URL](): StringSchema;
  }
}
