import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '94%',
    paddingTop: theme.spacing(4),
  },
  valueLabel: {
    '& > span > span': {
      background: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
  },
  mark: {
    background: 'transparent',
  },
}));

export default useStyles;
