import { useSearchParams } from 'hooks/routing';
import { useMemo } from 'react';

export const useReferralLink = () => {
  const { rl } = useSearchParams();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const link = useMemo(() => (rl as string) || '', []);

  return String(link);
};
