import { LOCAL_STORAGE_KEYS } from 'consts/ls-keys';
import React, { useEffect } from 'react';

import { Box } from '@mui/material';

import { EXTERNAL_LINKS } from '@libs/helpers/consts';
import Button from '@libs/ui/components/button';
import Typography, { OuterLink } from '@libs/ui/components/typography';

import final from './final.png';

interface FinalStepProps {
  onNext: () => void;
}

export const FinalStep = (props: FinalStepProps) => {
  useEffect(() => {
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.createCommunityByTalentHeaderBadge,
      'true',
    );
  }, []);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      textAlign="center"
      height={300}
    >
      <img srcSet={`${final} 2x`} src={final} alt="Some pic" />
      <Box my={8}>
        <Typography variant="h5">Thank you!</Typography>
      </Box>
      <Box mb={6}>
        <Typography variant="body2">
          We will verify your request and configure your account. Please book a{' '}
          <OuterLink
            color="info.main"
            href={EXTERNAL_LINKS.pieterLink}
            hasUnderline
            target="_blank"
            rel="noopener noreferrer"
          >
            15-min meeting
          </OuterLink>{' '}
          to continue. During the meeting our team will give you access and
          explain you the most important features.
        </Typography>
      </Box>

      <Box>
        <Button
          href={EXTERNAL_LINKS.pieterLink}
          {...{
            target: '_blank',
            rel: 'noopener noreferrer',
          }}
          variant="contained"
          color="info"
        >
          book YOUR GO-LIVE meeting
        </Button>
      </Box>
    </Box>
  );
};
