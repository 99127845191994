import cn from 'classnames';
import { CHAT_STYLE_VARS, streamI18nInstance } from 'components/chat';
import { NoChannelMessagesCentered } from 'components/chat/common';
import { useStreamChatContext } from 'components/chat/common/provider';
import { useChatStyles } from 'components/chat/styles';
import React from 'react';
import {
  Channel,
  Thread,
  Chat,
  Window,
  MessageInput,
  MessageList,
} from 'stream-chat-react';
import { SearchQueryParams } from 'stream-chat-react/dist/components/ChannelSearch/hooks/useChannelSearch';

import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import { Alert } from '@mui/lab';
import { makeStyles } from '@mui/styles';

import { Job } from '@libs/graphql-types';

import { CustomInput } from './CustomInput';
import { useChannelById } from './hooks';

interface CommentsProps {
  job: Job;
}

const MASSAGE_ACTIONS = ['edit', 'delete', 'react'];

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    padding: theme.spacing(4),

    '& div.str-chat__list': {
      padding: '0 !important',
    },

    '& .str-chat__input-flat': {
      padding: '0 !important',
    },

    '& .str-chat__send-button': {
      margin: '0 -8px 0 12px',
    },
  },
}));

const getQueryParams = (): SearchQueryParams['userFilters'] => ({
  filters: {
    // $or: [
    //   {
    //     id: { $autocomplete: 'Talent' },
    //     name: { $autocomplete: str || '' },
    //   },
    //   { joined: { $eq: true } },
    // ],
    id: { $autocomplete: 'Talent' },
  },
});

export const Comments = ({ job }: CommentsProps) => {
  const { client } = useStreamChatContext();
  const chatClasses = useChatStyles();
  const classes = useStyles();
  const { channel, error } = useChannelById(job.stream_chat_subject_id);
  const errorMessage =
    error?.status === 403
      ? 'You have no permissions to see the job chat'
      : error?.response?.data?.message || 'Something went wrong';

  return (
    <div className={cn(chatClasses.chat, classes.root)}>
      {error && (
        <Alert icon={<DoNotDisturbAltIcon />} color="error">
          {errorMessage}
        </Alert>
      )}
      {channel && (
        <Chat
          client={client}
          defaultLanguage="en"
          i18nInstance={streamI18nInstance}
          customStyles={CHAT_STYLE_VARS}
        >
          <Channel
            channel={channel}
            Input={CustomInput}
            EmptyStateIndicator={NoChannelMessagesCentered}
          >
            <Window>
              <MessageList messageActions={MASSAGE_ACTIONS} />
              <MessageInput
                mentionAllAppUsers
                mentionQueryParams={getQueryParams()}
              />
            </Window>
            <Thread />
          </Channel>
        </Chat>
      )}
    </div>
  );
};
