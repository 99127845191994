import { ChatTypes } from 'components/chat';
import React from 'react';
import { pathManager } from 'routes';

import { Job } from '@libs/graphql-types';
import { ButtonProps, RouterButton } from '@libs/ui/components/button';

interface ChatWithClientButtonProps extends ButtonProps {
  job: Job;
  disabled?: boolean;
}

const ChatWithClientButton = ({
  job,
  disabled = false,
  ...rest
}: ChatWithClientButtonProps) => {
  const text = `Hi ${job.campaign_owner?.first_name || ''},
I would like to learn more about the job ${job.name}`;

  return (
    <RouterButton
      disabled={disabled}
      to={{
        pathname: pathManager.chat.generatePath(),
        state: {
          inputText: text,
          strategy: {
            type: ChatTypes.UserToTalent,
            data: job.campaign_owner!,
          },
        },
      }}
      size="small"
      variant="outlined"
      {...rest}
    >
      CONTACT HIRING MANAGER
    </RouterButton>
  );
};

export default ChatWithClientButton;
