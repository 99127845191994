import { useState } from 'react';
import { useEffectOnce } from 'react-use';
import { pathManager } from 'routes';

export const useBackLink = () => {
  const [search, setSearch] = useState('');

  useEffectOnce(() => {
    setSearch(window.location.search);
  });

  return `${pathManager.company.workforce.generatePath()}${search}`;
};
