import React from 'react';

import { makeStyles } from '@mui/styles';

interface ContainerProps extends React.PropsWithChildren<unknown> {
  children: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
  },
}));

const Container = ({ children }: ContainerProps) => {
  const classes = useStyles();

  return <div className={classes.container}>{children}</div>;
};

export default Container;
