import { createTheme, ThemeOptions } from '@mui/material/styles';

import { INPUT_THEME_OPTIONS } from './input';
import { PALETTE } from './palette';
import { TYPOGRAPHY_THEME_OPTIONS } from './typography';

export const themeOptions: ThemeOptions = {
  palette: PALETTE,
  spacing: (factor: number) => `${4 * factor}px`,
  components: {
    ...INPUT_THEME_OPTIONS,
    MuiCssBaseline: {
      styleOverrides: {
        'body a': {
          textDecoration: 'none',
          color: 'inherit',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          '&.MuiChip-colorSuccess': {
            borderColor: '#4CAF50',
            color: '#2E7D32',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            background: `${PALETTE.tertiary.main} !important`,

            '&:hover': {
              background: 'rgba(0, 0, 0, 0.1)',
            },
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: TYPOGRAPHY_THEME_OPTIONS.subtitle2,
      },
    },

    MuiListSubheader: {
      styleOverrides: {
        root: {
          background: 'rgba(249, 241, 46, 0.12)',
          color: 'black',
          fontWeight: 600,
        },
      },
    },

    // MuiFilledInput: {
    //   styleOverrides: {
    //     root: {
    //       background: 'transparent',
    //     },
    //   },
    // },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderRadius: 0,
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        dot: {
          height: 12,
          width: 12,
          lineHeight: '12px',
          borderRadius: '100%',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: (PALETTE.info as any).main,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: (PALETTE.info as any).main,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          lineHeight: '18px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: (PALETTE.info as any).main,
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: (PALETTE.info as any).main,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        root: {
          height: 36,
          whiteSpace: 'nowrap',
          borderRadius: 8,

          '&:not(:active)': {
            boxShadow: 'none',
          },
        },
        sizeMedium: {
          height: 36,
          borderRadius: 8,
        },
        sizeLarge: {
          height: 42,
          borderRadius: 10,
        },
        sizeSmall: {
          height: 30,
          borderRadius: 6,
        },
      },
    },
  },
  typography: TYPOGRAPHY_THEME_OPTIONS,
  transitions: {
    duration: {
      short: 0.1,
      standard: 0.3,
    },
  },
};

const theme = createTheme(themeOptions);

export default theme;
export type Theme = typeof theme;
