import { ClientToTalentChatSidebarItem } from 'components/chat/client-to-talent/chat-sidebar';
import { JobChatSidebarItem } from 'components/chat/job-chat/chat-sidebar/chat-sidebar-item';
import { TalentToTalentChatSidebarItem } from 'components/chat/talent-to-talent/chat-sidebar';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useChatContext } from 'stream-chat-react';

import { ChatMetaInfo, ChatTypes } from '../../../types';
import { ChatSidebarItemProps } from './types';

export const ChatSidebarItem = (props: ChatSidebarItemProps) => {
  const { channel } = props;
  const history = useHistory();
  const chatContext = useChatContext();
  const details = channel.data?.channelDetails as ChatMetaInfo;
  const onChannelSelect = useCallback(() => {
    props.setActiveChannel?.(channel);
    history.replace({ state: null });
    chatContext.closeMobileNav();
  }, [channel, chatContext, props, history]);

  if (!details) {
    return null;
  }

  switch (details.type) {
    case ChatTypes.UserToTalent:
      return (
        <ClientToTalentChatSidebarItem
          onChannelSelect={onChannelSelect}
          {...props}
        />
      );
    case ChatTypes.JobChannel:
      return (
        <JobChatSidebarItem onChannelSelect={onChannelSelect} {...props} />
      );
    case ChatTypes.TalentToTalent:
      return (
        <TalentToTalentChatSidebarItem
          onChannelSelect={onChannelSelect}
          {...props}
        />
      );
    default:
      return <>Unknown</>;
  }
};
