import { Collapse } from '@mui/material';
import React from 'react';

import { Job } from '@libs/graphql-types';
import Typography from '@libs/ui/components/typography';

interface JobDescriptionProps {
  job: Job;
  isOpen: boolean;
}

const JobDescription = ({ job, isOpen }: JobDescriptionProps) => {
  return (
    <Collapse in={isOpen}>
      <div>
        <Typography variant="subtitle2">Description</Typography>
        <div
          style={{ paddingTop: 8 }}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: job?.description || '' }}
        />
      </div>
    </Collapse>
  );
};

export default JobDescription;
