import { Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';

import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { noop } from '@libs/helpers/common';
import { EXTERNAL_LINKS } from '@libs/helpers/consts';
import Typography, { OuterLink } from '@libs/ui/components/typography';

import { AboutCompanyFormStep } from './AboutCompanyFormStep';
import { CompanyFormStep } from './CompanyFormStep';
import { FinalStep } from './FinalStep';
import logos from './logos@2x.png';
import { FormState } from './types';

interface PendingStaticPopupProps { }

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    transform: 'translateY(-168px)',
    position: 'relative',
    height: '100%',
    minHeight: 220,

    [theme.breakpoints.down('md')]: {
      transform: 'none',
      padding: theme.spacing(8),
    },
  },
  card: {
    position: 'absolute',
    overflow: 'hidden',
    width: 488,
    borderRadius: 24,
    boxShadow:
      '0px 8px 10px -5px rgba(0, 0, 0, 0.12), 0px 16px 24px 2px rgba(0, 0, 0, 0.12), 0px 6px 30px 5px rgba(0, 0, 0, 0.08)',

    [theme.breakpoints.down('md')]: {
      width: '100%',
      borderRadius: 24,
      position: 'relative',
    },
  },
  content: {
    padding: theme.spacing(10),
    background: 'white',

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(6, 4),
    },
  },
  label: {
    fontSize: 14,
    lineHeight: '20px',
  },
  logos: {
    width: `calc(100% - ${theme.spacing(8)}px)`,
    maxWidth: 780,
    margin: theme.spacing(0, 4),
    flexGrow: 1,
    paddingBottom: theme.spacing(10),
  },
}));

const getValidator = () =>
  yup.object().shape({
    company: yup.object().shape({
      name: yup
        .object()
        .shape({
          text: yup.string(),
          value: yup.string(),
        })
        .required(),
      verified: yup.boolean(),
    }),
    about: yup.object().shape({
      website: yup.string().required('Required').customUrl().trim().min(1),
      country: yup.string().required('Required').trim().min(1),
    }),
  });

const STEPS = [
  { Component: CompanyFormStep, key: 'company' },
  { Component: AboutCompanyFormStep, key: 'about' },
  { Component: FinalStep, key: 'final' },
];

export const CreateCommunityForm = (props: PendingStaticPopupProps) => {
  const [step, setStep] = React.useState(0);
  const classes = useStyles();
  const { Component } = STEPS[step];

  return (
    <Box display="flex" flexDirection="column" style={{ height: '100%' }}>
      <Formik<FormState>
        initialValues={{
          company: {
            name: {} as any,
            verified: false,
          },
          about: {
            website: '',
            country: '',
            activity: '',
          },
        }}
        onSubmit={console.log}
        validationSchema={getValidator()}
      >
        <div className={classes.root}>
          <div className={classes.card}>
            <div className={classes.content}>
              <Component
                onNext={() => {
                  setStep(step + 1);
                }}
                onBack={
                  step > 0
                    ? () => {
                      setStep(step - 1);
                    }
                    : noop
                }
              />
            </div>
          </div>
        </div>
      </Formik>
      <Box
        mt={4}
        flexGrow={1}
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignContent="flex-end"
        mb={4}
      >
        <Box display="flex" justifyContent="center">
          <Box className={classes.logos}>
            <img srcSet={`${logos} 2x`} alt="logos" />
          </Box>
        </Box>

        <Typography textAlign="center">
          Have any questions?{' '}
          <OuterLink
            href={EXTERNAL_LINKS.whatsAppForCompanies}
            hasUnderline
            target="_blank"
            color="info.main"
          >
            Contact our team.
          </OuterLink>
        </Typography>
      </Box>
    </Box>
  );
};
