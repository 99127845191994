import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

import Typography, { TypographyProps } from './Typography';

interface RouterLinkProps extends TypographyProps {
  to: LinkProps['to'];
  target?: string;
  rel?: string;
}

export const RouterLink = (props: RouterLinkProps) => {
  return (
    <Typography
      pointer
      color="info.main"
      component={Link as any}
      style={{ textDecoration: 'underline', ...props.style }}
      {...props}
    />
  );
};
