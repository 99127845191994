import { gql } from '@apollo/client';

export const CREATE_INVITATION_LINK = gql`
  mutation CreateInvitationLink($inviting_talent_id: ID!, $name: String!) {
    createInvitationLink(inviting_talent_id: $inviting_talent_id, name: $name) {
      url
      name
    }
  }
`;
