import { formatDistance, isToday, parseISO } from 'date-fns';
import React from 'react';

import { Talent } from '@libs/graphql-types';
import Typography from '@libs/ui/components/typography';

interface RemindTextProps {
  talent: Talent;
}

const formatDur = (d1: Date, d2: Date) => {
  const formatted = formatDistance(d1, d2);

  return formatted;
};

const RemindTextComponent = ({
  primary,
  secondary,
}: {
  primary: string;
  secondary: string;
}) => (
  <Typography color="textSecondary" variant="subtitle2">
    {secondary}{' '}
    <Typography color="textPrimary" variant="subtitle2" component="span">
      {primary}
    </Typography>
  </Typography>
);

const RemindText = ({ talent }: RemindTextProps) => {
  if (talent.reminded_at) {
    const remindedAt = parseISO(talent.reminded_at);
    if (isToday(remindedAt)) {
      return <RemindTextComponent secondary="Reminder sent" primary="today" />;
    }
    const days = formatDur(new Date(), remindedAt);
    return (
      <RemindTextComponent secondary="Reminder sent" primary={`${days} ago`} />
    );
  }
  let invited = talent.created_at ? parseISO(talent.created_at) : null;

  if (!invited) {
    return null;
  }

  if (isToday(invited)) {
    return <RemindTextComponent secondary="Invited" primary="today" />;
  }

  const days = formatDur(new Date(), invited);

  return <RemindTextComponent secondary="Invited" primary={`${days} ago`} />;
};

export default RemindText;
