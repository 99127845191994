import { AuthUtils } from 'auth0/utils';
import { useAuth0 } from 'hooks/auth/useAuth0';

export const COMPANY_FORM_ID = 'PZQC7nXF';
export const TALENT_FORM_ID = 'gj9nG4nh';

export const useFormId = () => {
  const { user: auth0User } = useAuth0();
  const isTalent = AuthUtils.isTalent(auth0User);
  const formId = isTalent ? TALENT_FORM_ID : COMPANY_FORM_ID;

  return formId;
};
