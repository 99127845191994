import { usePageTitle } from 'hooks/common/usePageTitle';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { pathManager } from 'routes';

import { useCheckAuth0, useSubmitAction, useTmpVarsFromQuery } from './hooks';
import { OnboardingForm } from './onboarding-form/OnboardingForm';
import { AddCompany } from './steps/add-company';
import { ChooseType } from './steps/choose-type';
import { JoinTypeEnum } from './steps/choose-type/add-company-form/types';
import Final from './steps/final';
import { General } from './steps/general';
import { Meeting } from './steps/meeting';

interface OnboardingV4Props { }

export const OnboardingV4 = (props: OnboardingV4Props) => {
  const { onSubmit, loading } = useSubmitAction();

  useTmpVarsFromQuery();
  useCheckAuth0();
  usePageTitle('Onboarding');

  return (
    <OnboardingForm onSubmit={onSubmit}>
      <Switch>
        <Route
          path={pathManager.company.onboardingV4.final.getRoute()}
          component={Final}
        />

        <Route
          path={[pathManager.company.onboardingV4.general.getRoute()]}
          render={() => <General loading={loading} />}
        />
        <Route
          path={[pathManager.company.onboardingV4.create.getRoute()]}
          render={() => <AddCompany />}
        />
        <Route
          path={[pathManager.company.onboardingV4.bookAgency.getRoute()]}
          render={() => <Meeting type={JoinTypeEnum.Agency} />}
        />
        <Route
          path={[pathManager.company.onboardingV4.bookRecruiter.getRoute()]}
          render={() => <Meeting type={JoinTypeEnum.Independent} />}
        />

        <Route
          path={[pathManager.company.onboardingV4.bookMeeting.getRoute()]}
          render={() => <Meeting />}
        />

        <Route
          path={[
            pathManager.company.onboardingV4.company.getRoute(),
            pathManager.company.onboardingV4.main.getRoute(),
          ]}
          render={() => <ChooseType />}
        />
      </Switch>
    </OnboardingForm>
  );
};

export default OnboardingV4;
