import { ReactComponent as EuroIcon } from 'assets/icons/euro.svg';

import DateRangeIcon from '@mui/icons-material/DateRange';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import PersonIcon from '@mui/icons-material/Person';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import WorkIcon from '@mui/icons-material/Work';

export const ICONS_MAP = {
  book: MenuBookIcon,
  speak: RecordVoiceOverIcon,
  location: LocationOnIcon,
  clock: WatchLaterIcon,
  calendar: PermContactCalendarIcon,
  period: DateRangeIcon,
  client: WorkIcon,
  timelapse: TimelapseIcon,
  euro: EuroIcon,
  owner: PersonIcon,
};
