import React from 'react';
import { LinkProps, Link } from 'react-router-dom';

import { IconButton, IconButtonProps } from '@mui/material';

import Button, { ButtonProps } from './Button';

interface RouterButtonProps extends ButtonProps, Pick<LinkProps, 'to'> {}

export const RouterButton = (props: RouterButtonProps) => {
  return <Button {...props} component={Link} />;
};

interface RouterIconButtonProps
  extends IconButtonProps,
    Pick<LinkProps, 'to'> {}

export const RouterIconButton = (props: RouterIconButtonProps) => {
  return (
    <Link to={props.to}>
      <IconButton {...props} />
    </Link>
  );
};
