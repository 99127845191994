import { BooleanModalState } from 'components/custom/skills-boolean-search';
import { SOLUTIONS_WITH_HARD } from 'consts/skills';
import { parseISO } from 'date-fns';
import { formatName } from 'utils/talent';

import {
  Job,
  JobLocationTypeEnum,
  JobServiceTypeEnum,
  JobTypeEnum,
  Skill,
  SkillTypeEnum,
  SourceTypeEnum,
  TalentsBooleanSkillsFilterInput,
  User,
} from '@libs/graphql-types';
import { parseNumber } from '@libs/helpers/common';
import { OptionType } from '@libs/ui/components/form/select';
import { CampaignStatus } from '@libs/ui/components/job/utils';

import { DEFAULT_BOOLEAN_ITEMS_LIST, INITIAL_VALUES } from './consts';
import { CreateJobForm } from './types';

export const DEFAULT_SKILLS: Record<
  SkillTypeEnum | typeof SOLUTIONS_WITH_HARD,
  OptionType[]
> = {
  [SkillTypeEnum.HardSkills]: [],
  [SkillTypeEnum.SoftSkills]: [],
  [SkillTypeEnum.Solutions]: [],
  SOLUTIONS_WITH_HARD: [],
};

const mapSkillToOption = (skill: Skill): OptionType => ({
  ...skill,
  value: skill.id,
  text: skill.name,
});
const mapListSkills = (skills?: Skill[]): OptionType[] =>
  ((skills || []) as Skill[]).map(mapSkillToOption);

export const mapRequiredSkillsToServer = (skills: OptionType[]) =>
  skills.reduce<Record<SkillTypeEnum, string[]>>(
    (acc, item) => {
      if (item.value) {
        acc[item.skill_type as SkillTypeEnum].push(item.value as string);
      }
      return acc;
    },
    {
      [SkillTypeEnum.Solutions]: [],
      [SkillTypeEnum.HardSkills]: [],
      [SkillTypeEnum.SoftSkills]: [],
    },
  );

export const mapBooleanToServer = ({
  not,
  items,
}: BooleanModalState): TalentsBooleanSkillsFilterInput => ({
  not_skills_ids: not?.map((i) => Number(i.value!) as any),
  boolean_skills_filter_items: items.map((i, index) => ({
    all_of_skills_ids: i.and.map((i) => Number(i.value)),
    one_of_skills_ids: i.or.map((i) => Number(i.value)),
    boolean_operator: items?.[index - 1]?.relationWithNext || 'AND',
  })) as any,
});

export const getSkillsFields = ({
  is_boolean_search,
  skills,
  required_skills,
  skills_boolean_v2,
}: CreateJobForm) => {
  const requiredSkills = mapRequiredSkillsToServer(required_skills);

  const skillsFields = is_boolean_search
    ? {
      skills_boolean_filter: mapBooleanToServer(skills_boolean_v2),
    }
    : {
      skills: skills.map((i) => +i.value),
      solutions_required: requiredSkills[SkillTypeEnum.Solutions],
      hard_skills_required: requiredSkills[SkillTypeEnum.HardSkills],
    };

  return skillsFields;
};

export const mapJobToServer = (form: CreateJobForm) => {
  const {
    skills,
    required_skills,
    campaign_talent_pool,
    campaign_start_date,
    campaign_end_date,
    is_archived,
    rate_min,
    rate_max,
    country,
    salary_min,
    salary_max,
    city,
    is_remote_an_option,
    location_type,
    is_boolean_search,
    skills_boolean_v2,
    skills_boolean_filter,
    end_date,
    type,
    ...values
  } = form;
  const isHybrid = location_type === JobLocationTypeEnum.Hybrid;
  const needCountryAndCity =
    isHybrid || location_type === JobLocationTypeEnum.OnSite;
  // const isOpentalentPool = campaign_talent_pool.includes(
  //   SourceTypeEnum.Opentalent,
  // );
  // const isOwnPool = campaign_talent_pool.includes(SourceTypeEnum.Own);
  const skillsFields = getSkillsFields(form);
  const endDate =
    end_date && type !== JobTypeEnum.Permanent ? end_date : undefined;

  return {
    ...values,
    ...skillsFields,
    type,
    end_date: endDate,
    campaign_talent_pool,
    campaign_start_date: campaign_start_date || new Date(),
    campaign_end_date: campaign_end_date,
    rate_min: parseNumber(rate_min),
    rate_max: parseNumber(rate_max),
    salary_min: parseNumber(salary_min),
    salary_max: parseNumber(salary_max),

    location_type,
    office_hours_per_month: isHybrid
      ? parseNumber(values.office_hours_per_month)
      : 0,
    finders_fee: parseNumber(values.finders_fee) || undefined,
    ...(needCountryAndCity && {
      country,
      city,
    }),
  };
};

const mapBooleanSkillsToClient = (
  booleanSkills: Job['skills_boolean_filter'],
): BooleanModalState => ({
  not: mapListSkills(booleanSkills?.not_skills as Skill[]),
  items:
    booleanSkills?.boolean_skills_filter_items?.map((item) => ({
      or: mapListSkills(item?.one_of_skills as Skill[]),
      and: mapListSkills(item?.all_of_skills as Skill[]),
      relationWithNext: 'AND',
    })) || DEFAULT_BOOLEAN_ITEMS_LIST,
});

export const getInitialValues = ({
  job,
  talentPoolOptions = [],
  isDuplicate,
  isEnterprise,
}: {
  job?: Job;
  talentPoolOptions: OptionType[];
  isDuplicate: boolean;
  isEnterprise: boolean;
}): CreateJobForm => {
  return job
    ? {
      ...INITIAL_VALUES,
      name: job.name,
      is_remote_an_option: job.is_remote_an_option || false,
      category_id: job.category?.id || '',
      // subcategory_id: {
      //   text: job.subcategory?.name || '',
      //   value: job.subcategory?.id || '',
      // },
      office_hours_per_month: job.office_hours_per_month,
      description: job.description || '',
      pitch: job.pitch || '',
      propose_to_matchers: job.propose_to_matchers || false,
      start_date: job.start_date ? parseISO(job.start_date) : '',
      end_date: job.end_date ? parseISO(job.end_date) : '',
      rate_min: (job.rate_min ? job.rate_min : undefined) as any,
      rate_max: (job.rate_max ? job.rate_max : undefined) as any,
      salary_min: (job.salary_min ? job.salary_min : undefined) as any,
      salary_max: (job.salary_max ? job.salary_max : undefined) as any,
      campaign_owner_id: job.campaign_owner?.id || '',
      campaign_start_date: isDuplicate
        ? ''
        : parseISO(job.campaign_start_date),
      campaign_end_date: isDuplicate ? '' : parseISO(job.campaign_end_date),
      is_boolean_search: !!job?.skills_boolean_filter,
      skills:
        job?.skills
          ?.filter((i) => !i?.job_skill_pivot?.is_required)
          ?.map((skill) => ({
            ...skill,
            text: skill?.name || '',
            value: skill?.id || '',
          })) || [],
      client: job.client,
      location_type: job.location_type || JobLocationTypeEnum.Remote,
      location: job.location,
      city: job.city,
      country: job.country,
      campaign_talent_pool:
        (job?.campaign_talent_pool as SourceTypeEnum[]) || [],
      hours_per_week: job.hours_per_week,
      is_rate_negotiable: job.is_rate_negotiable || false,
      required_skills: (job.skills || [])
        .filter(
          (skill) =>
            skill?.job_skill_pivot?.is_required &&
            [SkillTypeEnum.HardSkills, SkillTypeEnum.Solutions].includes(
              skill?.skill_type as any,
            ),
        )
        .map((skill) => ({
          ...skill,
          value: skill?.id || '',
          text: skill?.name || '',
        })),
      finders_fee: (job.finders_fee ? job.finders_fee : NaN) as any,
      skills_boolean_v2: mapBooleanSkillsToClient(job.skills_boolean_filter),
    }
    : {
      ...INITIAL_VALUES,
      // campaign_talent_pool: isEnterprise ? [SourceTypeEnum.Own] : [],
    };
};
export const getOptionFromUser = (user: User) => ({
  text: formatName({
    firstName: user.first_name,
    lastName: user.last_name,
  }),
  value: user.id,
});

export const getFieldActivity = ({
  status,
  blockStatuses,
}: {
  status?: CampaignStatus;
  blockStatuses: CampaignStatus[];
}) => {
  return status ? blockStatuses.includes(status) : false;
};

export const getPageTexts = ({
  isDuplicate,
  isCreate,
  name,
  jobType = JobTypeEnum.Project,
  isItDraft,
  serviceType,
}: {
  isDuplicate: boolean;
  isCreate: boolean;
  name?: string;
  jobType?: JobTypeEnum;
  isItDraft: boolean;
  serviceType?: JobServiceTypeEnum;
}) => {
  if (isDuplicate) {
    return {
      title: `Duplicate job campaign "${name}"`,
      submit: 'Duplicate job',
      save: 'Save job',
    };
  }
  if (!isCreate) {
    return {
      title: `Edit campaign "${name}"`,
      submit: isItDraft ? 'Post job' : 'Update job',
      save: 'save as draft',
    };
  }

  const isDedicated = serviceType === JobServiceTypeEnum.DedicatedSearch;

  if (jobType === JobTypeEnum.Freelance) {
    return {
      title: `Post a new Job / Gig ${isDedicated ? '> Dedicated Search' : ''}`,
      submit: 'Post job',
      save: 'save as draft',
    };
  }
  if (jobType === JobTypeEnum.Permanent) {
    return {
      title: `Create New Permanent Role ${isDedicated ? '> Dedicated Search' : ''
        }`,
      submit: 'Post job',
      save: 'save as draft',
    };
  }
  if (jobType === JobTypeEnum.Project) {
    return {
      title: 'Create New Project',
      submit: 'post PROJECT',
      save: 'save PROJECT',
    };
  }
  return {
    title: 'Create New Campaign',
    submit: 'Submit',
    save: 'save as draft',
  };
};
