import React from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Job } from '@libs/graphql-types';
import Button from '@libs/ui/components/button';
import Typography from '@libs/ui/components/typography';

interface JobCardFooterProps {
  job: Job;
  isOpen?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  icon: {
    color: theme.palette.info.main,
    transform: 'translateY(5px)',
  },
}));

const JobCardFooter = ({ job }: JobCardFooterProps) => {
  const classes = useStyles();

  return (
    <>
      <Grid className={classes.root} spacing={2} component={Box} container>
        <Grid item>
          <Button
            variant="contained"
            href={job.link_to_details}
            color="info"
            size="small"
            data-test-id="applyJobButton"
            disabled={!job.link_to_details}
            {...{ target: '_blank' }}
          >
            Apply to job
          </Button>
        </Grid>
        <Grid item>
          <Box>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <InfoOutlinedIcon fontSize="small" className={classes.icon} />
              </Grid>
              <Grid item>
                <Typography variant="caption" component="span">
                  Links to external job
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default JobCardFooter;
