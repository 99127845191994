import { useMediaQuery, useTheme } from '@mui/material';

const useMediaQueries = () => {
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down('sm'));
  const isSM = useMediaQuery(theme.breakpoints.down('md'));
  const isMD = useMediaQuery(theme.breakpoints.down('lg'));
  const isLG = useMediaQuery(theme.breakpoints.down('xl'));
  const isXL = useMediaQuery(theme.breakpoints.down('xl'));
  const isXXL = useMediaQuery(theme.breakpoints.up('xl'));

  return { isXS, isSM, isMD, isLG, isXL, isXXL };
};

export default useMediaQueries;
