import { useLazyQuery } from '@apollo/client';
import PageLayout from 'components/layout/page-layout';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { makeStyles } from '@mui/styles';

import { Job, Query, QueryJobArgs } from '@libs/graphql-types';
import Typography from '@libs/ui/components/typography';

import FooterText from './footer-text';
import Intro from './intro';
import JobInfo from './job-info';
import { GET_JOB_INFO } from './queries';

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    maxWidth: '100%',
    width: '100%',
  },
}));

export const JobDetails = () => {
  const classes = useStyles();
  const params = useParams<{ id?: string }>();
  const jobId = params.id;
  const [request, { data, loading }] = useLazyQuery<Query, QueryJobArgs>(
    GET_JOB_INFO,
  );
  const job = data?.job as Job | undefined;

  useEffect(() => {
    if (jobId) {
      request({ variables: { id: jobId } });
    }
  }, [jobId, request]);

  if (!job) {
    return (
      <PageLayout
        classes={{ contentWrapper: classes.contentWrapper }}
        documentTitle="Job details"
        isLoading={loading}
      >
        <Typography variant="h2">404</Typography>
        <Typography variant="h5">Job not found</Typography>
      </PageLayout>
    );
  }

  return (
    <PageLayout
      documentTitle="Job details"
      isLoading={loading}
      centered
      classes={{ contentWrapper: classes.contentWrapper }}
    >
      <Intro job={job} />
      <JobInfo job={job} />
      <FooterText job={job} />
    </PageLayout>
  );
};
