import { ActionItem } from 'components/custom/talent/types';
import { useGetCompanyPoolConnectionToTalent } from 'hooks/company';
import { useTalentName } from 'hooks/talents';
import React from 'react';
import { Link } from 'react-router-dom';
import { pathManager } from 'routes';

import { Box, Grid, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Talent, TalentSearchResult } from '@libs/graphql-types';

import CategoriesList from './CategoriesList';
import Highlights from './Highlights';
import { JobApplicationChip } from './JobApplicationChip';
import LogoBlock from './LogoBlock';
import MainInfo from './MainInfo';
import TitleSection from './TitleSection';

interface TalentCardV2Props {
  talentSearch: TalentSearchResult;
  refetch: VoidFunction;
  showJobApplicationBadge?: boolean;
  filterActions?: (actions: ActionItem[]) => ActionItem[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    transition: 'background .3s',
    padding: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',

    '&:hover': {
      background: 'rgba(25, 25, 25, 0.08)',
    },
  },
}));

const TalentCardV2 = (props: TalentCardV2Props) => {
  const classes = useStyles();
  const { showJobApplicationBadge, talentSearch, refetch, filterActions } =
    props;
  const talent = talentSearch?.talent as Talent;
  const isInPool = !!useGetCompanyPoolConnectionToTalent({ talent });

  const highlights = talentSearch?.highlights || [];
  const name = useTalentName(talent);
  const talentLink = pathManager.company.talentProfile.generatePath({
    id: talent.id,
  });

  const jobApplicationBadge = showJobApplicationBadge ? (
    <JobApplicationChip talent={talent} />
  ) : undefined;

  return (
    <div>
      <Paper
        className={classes.root}
        component={Link}
        data-test-id="talent-card"
        elevation={0}
        to={talentLink}
      >
        <Grid wrap="nowrap" spacing={6} container>
          <Grid item>
            <LogoBlock name={name} talent={talent} />
          </Grid>
          <Grid flexGrow={1} item>
            <TitleSection
              applicationBage={jobApplicationBadge}
              refetch={refetch}
              talent={talent}
              isInPool={isInPool}
              name={name}
              filterActions={filterActions}
            />

            <MainInfo talent={talent} />

            <CategoriesList talent={talent} />
          </Grid>
        </Grid>

        {!!highlights.length && (
          <Box mt={3}>
            <Highlights items={highlights} />
          </Box>
        )}
      </Paper>
    </div>
  );
};

export default TalentCardV2;
