import { Box, FormControl, FormGroup, Grid, Slider } from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';

import useStyles from './styles';

interface ValueType {
  name?: string;
}
interface RateSelectorProps {
  minValue?: ValueType;
  maxValue?: ValueType;
}

const getLabelText = (value: number) => `${value}€`;
const MARKS = [0, 300];
const MARKS_ITEMS = MARKS.map((value) => ({
  label: getLabelText(value),
  value,
}));

const RateSelector = (props: RateSelectorProps) => {
  const { minValue, maxValue } = props;
  const classes = useStyles(props);
  const minName = minValue?.name || 'min';
  const maxName = maxValue?.name || 'max';

  const { values, setValues } = useFormikContext<any>();
  const onChange = (
    event: Event | React.SyntheticEvent<Element, Event>,
    rate: number | number[],
  ) => {
    if (Array.isArray(rate)) {
      setValues({ ...values, [minName]: rate?.[0], [maxName]: rate?.[1] });
    }
  };
  const defaultValue = [
    values?.[minName] || 0,
    values?.[maxName] || 300,
  ] as number[];

  return (
    <FormControl fullWidth component="fieldset">
      <FormGroup>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid className={classes.container} item>
            <Box>
              <Slider
                min={MARKS[0]}
                max={MARKS[1]}
                color="secondary"
                marks={MARKS_ITEMS}
                valueLabelDisplay="auto"
                getAriaValueText={getLabelText}
                defaultValue={defaultValue}
                classes={{
                  valueLabel: classes.valueLabel,
                  mark: classes.mark,
                }}
                onChangeCommitted={onChange}
              />
            </Box>
          </Grid>
        </Grid>
      </FormGroup>
    </FormControl>
  );
};

export default RateSelector;
