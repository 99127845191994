import { makeStyles } from '@mui/styles';

export const useChatStyles = makeStyles((theme) => ({
  chat: {
    '& .str-chat': {
      background: 'transparent',
      maxHeight: '100%',
      height: '100%',
    },

    '& .str-chat__message-text-inner': {
      textAlign: 'left',

      '& pre': {
        margin: 0,

        '& code': {
          textAlign: 'left',
          display: 'block',
        },
      },
    },

    '& .str-chat__send-button': {
      display: 'block !important',
      cursor: 'pointer',
    },

    '& .str-chat__main-panel': {
      padding: '0 !important',
    },

    '& .str-chat-channel-list.messaging': {
      position: 'static !important',
      overflowY: 'scroll',
      minHeight: 'auto !important',
    },

    '& .str-chat__input-flat .str-chat__textarea>textarea': {
      padding: '20px 40px 16px 46px',
      minHeight: '100% !important',

      [theme.breakpoints.down('md')]: {
        fontSize: 14,
      },
    },

    '& .str-chat__input-flat .rfu-file-upload-button': {
      top: 'calc(100% - 40px)',
      right: 14,
    },

    '& .str-chat__input-flat-emojiselect': {
      left: 10,
      top: 'calc(100% - 43px)',
      [theme.breakpoints.down('md')]: {},
    },
  },
}));
