import { Box, Grid } from '@mui/material';
import React from 'react';

import { DefaultModal } from '@libs/ui/components/modals';

interface SuccessInviteModalProps {
  open: boolean;
  handleClose: VoidFunction;
}

const SuccessInviteModal = ({ open, handleClose }: SuccessInviteModalProps) => (
  <DefaultModal
    open={open}
    handleClose={handleClose}
    title="Your invite has been sent"
  >
    <Box maxWidth="290px">
      <Grid spacing={4} direction="column" container>
        <Grid item>Thanks - that&apos;s it for now</Grid>
      </Grid>
    </Box>
  </DefaultModal>
);

export default SuccessInviteModal;
