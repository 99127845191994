import { DEFAULT_AVATAR } from 'consts/common';
import useMediaQueries from 'hooks/common/useMediaQueries';
import React from 'react';

import { Avatar, Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Job, MatchQualityEnum } from '@libs/graphql-types';
import { stopEvent } from '@libs/helpers/common';
import { JOB_TYPES_LABELS_MAP } from '@libs/ui/components/job/utils';
import Typography from '@libs/ui/components/typography';

import { MatchLabel } from '../external-job-card/MatchLabel';
import SaveToFavoritesButton from './SaveToFavoritesButton';

interface JobCardTitleProps {
  job: Job;
  currentTime: Date;
  isSaved: boolean;
  onJobSave?: VoidFunction;
  matchQuality?: MatchQualityEnum;
}

const useStyles = makeStyles(() => ({
  avatar: {
    '& > img': {
      objectFit: 'contain',
    },
  },
}));

const JobCardTitle = ({
  job,
  isSaved,
  onJobSave,
  matchQuality,
}: JobCardTitleProps) => {
  const { isXS } = useMediaQueries();
  const classes = useStyles();
  const companyLogo = job?.campaign_owner?.company?.logo || DEFAULT_AVATAR;

  return (
    <Grid
      justifyContent="space-between"
      container
      wrap="nowrap"
      direction={isXS ? 'column' : 'row'}
    >
      <Grid item>
        <Grid
          wrap="nowrap"
          spacing={4}
          style={{ alignItems: 'center' }}
          container
        >
          <Grid item>
            <Avatar
              alt="Company logo"
              src={companyLogo}
              className={classes.avatar}
            />
          </Grid>
          <Grid item>
            <Typography variant="h6">{job?.name}</Typography>
            <Typography color="textSecondary" variant="caption">
              {job?.type ? JOB_TYPES_LABELS_MAP[job.type] : ''}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item onClick={stopEvent}>
        <Grid
          wrap="nowrap"
          component={Box}
          pt={isXS ? 3 : 0}
          spacing={4}
          container
        >
          {!!matchQuality && (
            <Grid style={{ display: 'flex', alignItems: 'center' }} item>
              <MatchLabel matchQuality={matchQuality} />
            </Grid>
          )}
          <Grid item>
            <SaveToFavoritesButton
              onJobSave={onJobSave}
              isSaved={isSaved}
              jobId={job.id}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(JobCardTitle);
