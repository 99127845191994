import { Skeleton } from '@mui/lab';
import { Grid } from '@mui/material';
import React from 'react';

const DefaultContentSkeleton = () => (
  <>
    <Grid container spacing={2} direction="column">
      <Grid item>
        <Skeleton width="90%" height="30px" variant="rectangular" />
      </Grid>
      <Grid item>
        <Skeleton width="100%" height="30px" variant="rectangular" />
      </Grid>
      <Grid item>
        <Skeleton width="80%" height="30px" variant="rectangular" />
      </Grid>
      <Grid item>
        <Skeleton width="40%" height="30px" variant="rectangular" />
      </Grid>
    </Grid>
    <br />
    <Grid container spacing={2} direction="column">
      <Grid item>
        <Skeleton width="90%" height="30px" variant="rectangular" />
      </Grid>
      <Grid item>
        <Skeleton width="100%" height="30px" variant="rectangular" />
      </Grid>
      <Grid item>
        <Skeleton width="80%" height="30px" variant="rectangular" />
      </Grid>
      <Grid item>
        <Skeleton width="40%" height="30px" variant="rectangular" />
      </Grid>
    </Grid>
  </>
);

export default DefaultContentSkeleton;
