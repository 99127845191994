import { getIn, useFormikContext } from 'formik';
import useMediaQueries from 'hooks/common/useMediaQueries';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { pathManager } from 'routes';
import { JobMarkType } from 'types';

import TuneIcon from '@mui/icons-material/Tune';
import { TabContext, TabList } from '@mui/lab';
import { Box, Button, Grid, Hidden, Tab } from '@mui/material';

import { modelPath } from '@libs/helpers/form';

import { FilterForm, JobTabType, ViewTypes } from '../types';
import { getCountText } from './hooks';

interface ControlsProps {
  openDrawer: VoidFunction;
  currentView: ViewTypes;
  applicationsCount?: number;
  savedCount?: number;
  recommendedCount?: number;
  openCount?: number;
  externalCount?: number;
}

const Controls = ({
  openDrawer,
  currentView,
  applicationsCount,
  savedCount,
  recommendedCount,
  openCount,
  externalCount = 0,
}: ControlsProps) => {
  const location = useLocation();
  const { isSM } = useMediaQueries();
  const { setFieldValue, values } = useFormikContext<FilterForm>();
  const filterPath = modelPath<FilterForm>((m) => m.tab);
  const filterType = getIn(values, filterPath) || JobTabType.Open;
  const history = useHistory();

  const talentFinalOpenJobs =
    (values.jobSourceInternal ? openCount ?? 0 : 0) +
    (values.jobSourceExternal ? externalCount : 0);

  return (
    <Box pb={4}>
      <Box mb={2}>
        <Grid
          container
          alignItems="center"
          spacing={2}
          justifyContent="space-between"
        >
          <Hidden lgUp>
            <Grid
              style={{ flexGrow: 1, width: isSM ? '100%' : 'inherit' }}
              item
            >
              <Button onClick={openDrawer} endIcon={<TuneIcon />}>
                Search Filter
              </Button>
            </Grid>
          </Hidden>
        </Grid>
      </Box>

      <Box mb={4}>
        <Grid container>
          {currentView === ViewTypes.Matcher && (
            <Grid item>
              <TabContext value={filterType}>
                <TabList
                  indicatorColor="secondary"
                  textColor="secondary"
                  onChange={(_, value) => setFieldValue(filterPath, value)}
                >
                  <Tab
                    color="secondary"
                    label={`Total ${getCountText(openCount)}`}
                    value={JobTabType.Open}
                  />
                  <Tab
                    color="secondary"
                    label={`${currentView === ViewTypes.Matcher
                        ? 'My sourcing jobs'
                        : 'My applications'
                      } ${getCountText(applicationsCount)}`}
                    value={JobTabType.MyApplications}
                  />
                </TabList>
              </TabContext>
            </Grid>
          )}
          {currentView === ViewTypes.Talent && (
            <Grid item>
              <TabContext value={filterType}>
                <TabList
                  indicatorColor="secondary"
                  textColor="secondary"
                  onChange={(_, value) => setFieldValue(filterPath, value)}
                >
                  <Tab
                    onClick={() => {
                      setFieldValue(
                        modelPath<FilterForm>((m) => m.is_active),
                        true,
                      );
                    }}
                    color="info"
                    label={`Total ${getCountText(talentFinalOpenJobs)}`}
                    value={JobTabType.Open}
                  />
                  <Tab
                    onClick={() => {
                      history.push({
                        state: { jobBoardSearch: location.search },
                        pathname: pathManager.talent.markedJobs.generatePath({
                          type: JobMarkType.Recommended,
                        }),
                      });
                    }}
                    color="secondary"
                    label={`Recommended ${getCountText(recommendedCount)}`}
                    value={JobTabType.Recommended}
                  />
                  <Tab
                    value={JobTabType.MyApplications}
                    onClick={() => {
                      history.push({
                        state: { jobBoardSearch: location.search },
                        pathname: pathManager.talent.markedJobs.generatePath({
                          type: JobMarkType.Applied,
                        }),
                      });
                    }}
                    color="info"
                    label={`My applications ${getCountText(applicationsCount)}`}
                  />
                  <Tab
                    color="secondary"
                    label={`Saved jobs ${getCountText(savedCount)}`}
                    onClick={() => {
                      history.push({
                        state: { jobBoardSearch: location.search },
                        pathname: pathManager.talent.markedJobs.generatePath({
                          type: JobMarkType.Saved,
                        }),
                      });
                    }}
                    value={JobTabType.Saved}
                  />
                </TabList>
              </TabContext>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default Controls;
