import React from 'react';

import { AutocompleteGetTagProps } from '@mui/lab';
import MuiAutocomplete, {
  AutocompleteProps as MuiAutocompleteProps,
} from '@mui/lab/Autocomplete';

import Chip, { ChipProps } from '@libs/ui/components/chip/Chip';
import wrapControlWithGraph from '@libs/ui/components/form/hocs/wrapControlWithGraph';
import { OptionType } from '@libs/ui/components/form/select';
import TextField, {
  TextFieldProps,
} from '@libs/ui/components/form/text-field/TextField';

import withStyles from './styles';

export interface MultipleSelectProps
  extends Omit<
    MuiAutocompleteProps<OptionType, true, false, false>,
    'renderInput'
  > {
  chipProps?: ChipProps | ((option: OptionType) => ChipProps);
  inputProps?: TextFieldProps;
  helperText?: string;
  autoCompleteProps?: any;
  error?: boolean;
  name?: string;
  noOptionsText?: string | React.ReactNode;
}

const MultipleSelect = (props: MultipleSelectProps) => {
  const classes = withStyles(props);
  const {
    options,
    chipProps,
    inputProps,
    autoCompleteProps = {},
    helperText,
    error,
    noOptionsText,
    renderTags,
  } = props;

  const commonRenderTags = (
    value: OptionType[],
    getTagProps: AutocompleteGetTagProps,
  ) =>
    value.map((option: OptionType, index: number) => (
      <Chip
        {...(typeof chipProps === 'function' ? chipProps(option) : chipProps)}
        key={`id-${option.value}-${index + 1}`}
        label={option.text}
        {...getTagProps({ index })}
      />
    ));

  return (
    <MuiAutocomplete
      multiple
      {...autoCompleteProps}
      fullWidth
      ListboxProps={{
        ...autoCompleteProps?.ListboxProps,
        'data-test-id': 'select-listbox',
      }}
      options={options}
      noOptionsText={noOptionsText}
      getOptionSelected={(option: OptionType) => {
        const value = autoCompleteProps?.value;
        if (Array.isArray(value)) {
          return (value || []).some(
            (formikOption: OptionType) => formikOption.value === option.value,
          );
        }
        return value === option.value;
      }}
      getOptionLabel={(option: OptionType) => option?.text ?? ''}
      renderTags={renderTags || commonRenderTags || ''}
      renderInput={(params) => {
        return (
          <TextField
            helperText={helperText}
            error={error}
            {...params}
            {...inputProps}
            inputProps={{
              ...params.inputProps,
              ...inputProps?.inputProps,
            }}
            InputLabelProps={{
              ...params?.InputLabelProps,
              ...inputProps?.InputLabelProps,
              classes: {
                shrink: classes.labelShrink,
              },
            }}
          />
        );
      }}
    />
  );
};

export default MultipleSelect;
export const GraphMultipleSelect =
  wrapControlWithGraph<Omit<MultipleSelectProps, 'options'>>(MultipleSelect);
