import React from 'react';
import { MessageInputFlat } from 'stream-chat-react';

import { useApplyTextFromLocationState } from './hooks';

interface CustomMessageInputProps {}

export const CustomMessageInput = (props: CustomMessageInputProps) => {
  useApplyTextFromLocationState();

  return <MessageInputFlat />;
};
