import { useCurrentUser } from 'hooks/auth';
import React from 'react';

import { makeStyles } from '@mui/styles';

import Typography from '@libs/ui/components/typography';

interface NoChannelsContentProps { }

export const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(4),
  },
}));

export const NoChannelsContent = (props: NoChannelsContentProps) => {
  const classes = useStyles();
  const { isCompany } = useCurrentUser();

  return (
    <div className={classes.root}>
      <Typography>
        You haven’t received any messages. <br />
        {isCompany && (
          <>
            To send messages, visit a candidate's profile page and press
            "Message".
          </>
        )}
      </Typography>
    </div>
  );
};
