import useMediaQueries from 'hooks/common/useMediaQueries';
import React from 'react';

import { Box, Grid } from '@mui/material';

import { Job } from '@libs/graphql-types';
import { getJobFieldsForTalent } from '@libs/ui/components/job/utils';
import Typography from '@libs/ui/components/typography';

interface JobDetailsProps {
  job: Job;
  isOpen: boolean;
}

const JobDetails = ({ job, isOpen }: JobDetailsProps) => {
  const items = getJobFieldsForTalent(job);
  const finalItems = isOpen ? items : items.slice(0, 6);
  const { isSM } = useMediaQueries();

  return (
    <Grid
      component={Box}
      pt={4}
      container
      spacing={2}
      direction={isSM ? 'column' : 'row'}
    >
      {finalItems.map((i) => (
        <Grid xs={12} sm={6} md={4} key={i.label} item>
          <Typography component="span" variant="body2">
            {i.label}
          </Typography>
          {` `}
          <Typography color="textSecondary" component="span" variant="body2">
            {i.value}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};

export default JobDetails;
