import { useCurrentUser } from 'hooks/auth';
import { useSnackbar } from 'notistack';

import { useGetPaymentLinkForCompanyLazyQuery } from '@libs/graphql-types';

export const useSelectPremiumAction = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useCurrentUser();
  const [makeRequest, { loading }] = useGetPaymentLinkForCompanyLazyQuery({
    variables: { primary_user_id: user?.id },
    onCompleted: (res) => {
      if (!res.getPaymentLinkForCompany) {
        enqueueSnackbar('Failed to get payment link', { variant: 'error' });
        return;
      }
      window.location.href = res.getPaymentLinkForCompany;
    },
  });

  return { makeRequest, loading };
};
