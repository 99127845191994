import { mergeDeep } from '@apollo/client/utilities';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useChannelActionContext } from 'stream-chat-react';

interface JumpToMessageActionProps {}

export const MESSAGE_TO_JUMP_HISTORY_KEY = 'messageToJumpId';

export const JumpToMessageAction = (props: JumpToMessageActionProps) => {
  const channelContext = useChannelActionContext();
  const history = useHistory<{ [MESSAGE_TO_JUMP_HISTORY_KEY]?: string }>();
  const messageToJumpId = history.location.state?.[MESSAGE_TO_JUMP_HISTORY_KEY];

  useEffect(() => {
    if (messageToJumpId) {
      channelContext.jumpToMessage(messageToJumpId);

      const newLocation = mergeDeep(history.location, {
        state: { [MESSAGE_TO_JUMP_HISTORY_KEY]: undefined },
      });

      history.replace(newLocation);
    }
  }, [channelContext, history, messageToJumpId]);

  return null;
};
