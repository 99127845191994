import cn from 'classnames';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Step, StepLabel, Stepper } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface StepperProps {
  current: number;
  allStepsCount: number;
  getStepLink: (step: number) => string;
  currentStepLabel?: string;
}

export const useStyles = makeStyles((theme) => ({
  stepper: {
    background: 'none',
    padding: 0,
    marginBottom: theme.spacing(18),

    '& .MuiStepConnector-root': {
      [theme.breakpoints.down('sm')]: {
        visibility: 'hidden',
      },
    },
  },
  root: {
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },

    '& span > svg': {
      '& > circle': {
        color: theme.palette.grey[700],
      },
      '& > text': {
        color: theme.palette.primary.contrastText,
      },
    },
  },
  current: {
    '& span > svg': {
      fontSize: 48,
      '& > circle': {
        color: theme.palette.primary.main,
      },
    },
  },
  completed: {},
  clickable: {
    cursor: 'pointer',
  },
  label: {
    'body $root &.Mui-active': {
      color: 'white !important',

      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },
}));

const TopStepper = ({
  current,
  allStepsCount,
  getStepLink,
  currentStepLabel,
}: StepperProps) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Stepper activeStep={current - 1} className={classes.stepper}>
      {Array.from({ length: allStepsCount }).map((_, index) => {
        const step = index + 1;
        const isClickable = current > step;
        const isCurrent = step === current;

        return (
          <Step
            key={step}
            onClick={() => {
              if (!isClickable) {
                return;
              }

              history.push(getStepLink(step));
            }}
            classes={{
              root: cn(classes.root, {
                [classes.current]: isCurrent,
                [classes.clickable]: isClickable,
                [classes.completed]: isClickable,
              }),
            }}
          >
            <StepLabel classes={{ active: classes.label }}>
              {isCurrent ? currentStepLabel : ''}
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

export default TopStepper;
