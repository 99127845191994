import QueryString from 'qs';
import { useLocation } from 'react-router-dom';

import { UserInfo } from '../final/types';

export const useMeetingLink = (url: string) => {
  const { state } = useLocation<UserInfo>();
  const queries = QueryString.stringify(state, { skipNulls: true });
  const link = `${url}?${queries}`;

  return link;
};
