import cn from 'classnames';
import React from 'react';

import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import amazonLogo from './icons/amazon.svg';
import appleLogo from './icons/apple.svg';
import googleLogo from './icons/google.svg';
import n26Logo from './icons/n26.svg';
import teslaLogo from './icons/tesla.svg';
import tierLogo from './icons/tier.svg';

interface IconsProps {
  shadowColor?: 'deepblack' | 'black' | 'green';
  className?: string;
  icons?: string[];
}

export const ICONS = [
  googleLogo,
  n26Logo,
  amazonLogo,
  tierLogo,
  appleLogo,
  teslaLogo,
];

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    overflow: 'hidden',
    maxWidth: '100%',
    margin: '0 auto',
  },
  '@keyframes slideRight': {
    from: { transform: 'translateX(0px)' },
    to: { transform: 'translateX(-9000px)' },
  },
  grid: {
    flexWrap: 'nowrap',
    animationName: '$slideRight',
    animationDuration: '240s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',

    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',
  },
  icon: {
    // width: 70,
    // height: 50,
    objectFit: 'contain',
  },
}));

const IconsRow = (props: IconsProps) => {
  const { className, icons = ICONS } = props;
  const classes = useStyles(props);

  return (
    <div className={cn(classes.root, className)}>
      <Grid className={classes.grid} alignItems="center" spacing={8} container>
        {Array.from({ length: 20 }, () => icons)
          .flat()
          .map((icon, index) => (
            <Grid key={index} item>
              <img className={classes.icon} src={icon} alt="icon" />
            </Grid>
          ))}
      </Grid>
    </div>
  );
};

export default IconsRow;
