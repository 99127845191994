import { gql } from '@apollo/client';
import FULL_USER_FRAGMENT from 'graphql/fragments/companyUser/fullCompanyUserFrag';
import GET_NOTIFICATIONS_FRAG from 'graphql/fragments/notifications/currentNotificationsFrag';
import FULL_TALENT_FRAGMENT from 'graphql/fragments/talent/fullTalentFragment';

export const GetCompanyUserLayoutData = gql`
  ${FULL_USER_FRAGMENT}
  ${FULL_TALENT_FRAGMENT}
  ${GET_NOTIFICATIONS_FRAG}
  query CompanyUserLayout {
    currentCompanyUser {
      ...FullUser
    }
    currentUserAccounts {
      company_user_accounts {
        ...FullUser
      }
      talent_accounts {
        ...FullTalent
      }
    }
    currentUserUnreadNotificationsCount
    currentUserNotifications(first: 20, page: 1) {
      ...CurrentNotifications
    }
    jobs {
      paginatorInfo {
        total
      }
    }
  }
`;

export const GetTalentLayoutData = gql`
  ${FULL_TALENT_FRAGMENT}
  ${FULL_USER_FRAGMENT}
  ${GET_NOTIFICATIONS_FRAG}
  query TalentUserLayout {
    currentUserNotifications(first: 20, page: 1) {
      ...CurrentNotifications
    }
    currentUserAccounts {
      company_user_accounts {
        ...FullUser
      }
      talent_accounts {
        ...FullTalent
      }
    }
    currentUserUnreadNotificationsCount
    currentTalent {
      ...FullTalent
    }
    currentTalentSubscription {
      start_date
      current_period_end
      cancel_at_period_end
    }
  }
`;
