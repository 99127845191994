import { makeStyles } from '@mui/styles';

import { Theme } from '@libs/ui/themes/default';

import { DRAWER_WIDTH } from '../consts';
import { HeaderProps } from './Header';

const useStyles = makeStyles((theme: Theme) => ({
  logoBlock: {
    maxWidth: DRAWER_WIDTH,
  },
  header: {
    zIndex: 2,

    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: -1,
      left: ({ toggleDrawer }: HeaderProps) =>
        toggleDrawer ? DRAWER_WIDTH : 0,
      height: 1,
      width: ({ toggleDrawer }: HeaderProps) =>
        toggleDrawer ? `calc(100% - ${DRAWER_WIDTH}px)` : '100%',
      background: theme.palette.grey[300],

      [theme.breakpoints.down('md')]: {
        'body &': {
          left: '0',
          width: '100%',
        },
      },
    },
  },
  middleBlock: {
    flexGrow: 1,
  },
  logo: {
    marginTop: theme.spacing(3),
  },
  badge: {
    top: 3,
    right: 3,
  },
  skeleton: {
    // backgroundColor: 'white',
    opacity: 0.1,
    margin: `0 ${theme.spacing(3)}`,
  },
}));

export default useStyles;
