import { useSnackbar } from 'notistack';
import React from 'react';
import useCopyToClipboard from 'react-use/lib/useCopyToClipboard';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LaunchIcon from '@mui/icons-material/Launch';
import { Grid } from '@mui/material';

import Button from '@libs/ui/components/button';
import Typography from '@libs/ui/components/typography';

interface PromoActionsProps {
  url: string;
  code: string;
  moreInfoUrl?: string;
}

export const PromoActions = ({ code, url, moreInfoUrl }: PromoActionsProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [, copyToClipboard] = useCopyToClipboard();
  const onCopy = () => {
    copyToClipboard(code);
    enqueueSnackbar('Promo code copied', { variant: 'success' });
  };
  return (
    <Grid container>
      <Grid flexGrow={1} display="flex" alignItems="center" item>
        <Grid spacing={4} container>
          <Grid item>
            <Button
              href={url}
              {...{ target: '_blank' }}
              variant="contained"
              color="info"
              endIcon={<LaunchIcon />}
            >
              Get perk
            </Button>
          </Grid>
          {!!moreInfoUrl && (
            <Grid item>
              <Button
                href={moreInfoUrl}
                {...{ target: '_blank' }}
                variant="outlined"
                color="info"
              >
                learn more
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid item>
        <Typography variant="body2" color="textSecondary">
          Promo code
        </Typography>
        <Button
          color="secondary"
          variant="text"
          style={{
            textTransform: 'none',
          }}
          startIcon={<ContentCopyIcon style={{ color: '#9E9E9E' }} />}
          onClick={onCopy}
        >
          {code}
        </Button>
      </Grid>
    </Grid>
  );
};
