/* eslint-disable no-param-reassign */
export function get(
  object: Record<string, any>,
  keys: string | string[],
  defaultVal?: any,
): any {
  keys = Array.isArray(keys) ? keys : keys.split('.');
  object = object?.[keys[0]];
  if (object && keys.length > 1) {
    return get(object, keys.slice(1), defaultVal);
  }
  return object === undefined ? defaultVal : object;
}

export const stopEvent = (e: React.UIEvent) => {
  e.stopPropagation();
  e.preventDefault();
};

export const uniq = (arr: any[]) => [...new Set(arr)];

export const isNumber = (num: any): num is number =>
  !Number.isNaN(+num) && Number.isFinite(+num);

export const noop = (...params: any) => undefined;

const nilTypes = [undefined, null];
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isNil = (arg: any): arg is typeof nilTypes[number] =>
  nilTypes.includes(arg);
