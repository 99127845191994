import React from 'react';

import { Chip, ChipProps } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface DrawerItemBadgeProps {
  text: string;
  variant?: 'filled' | 'outlined';
  color?: ChipProps['color'];
  style?: React.CSSProperties;
}
const useStyles = makeStyles((theme) => ({
  badge: {
    right: 26,
    cursor: 'pointer',
  },
}));

export const DrawerItemBadge = (props: DrawerItemBadgeProps) => {
  const { text, variant = 'outlined', color = 'warning', style } = props;
  const classes = useStyles({ variant: 'outlined', ...props });

  return (
    <Chip
      classes={{ root: classes.badge }}
      label={text}
      color={color}
      size="small"
      variant={variant}
      style={style}
    />
  );
};
