import { useField } from 'formik';
import React, { useCallback } from 'react';

import TextField, { TextFieldProps } from './TextField';

export type ConnectedTextFieldProps = {
  name: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
} & TextFieldProps;

export const ConnectedTextField = (props: ConnectedTextFieldProps) => {
  const { name, helperText, onChange, ...rest } = props;
  const [field, meta] = useField({ name });
  const handleChange = useCallback(
    (e: any) => {
      field.onChange(e);
      if (onChange) onChange(e);
    },
    [onChange, field],
  );

  const error = meta.touched && !!meta.error;
  const finalHelperText = meta.touched && meta.error ? meta.error : helperText;

  return (
    <TextField
      {...field}
      helperText={finalHelperText}
      error={error}
      onChange={handleChange}
      {...rest}
    />
  );
};
