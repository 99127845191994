import CloseIcon from '@mui/icons-material/Close';
import { Box, Grid } from '@mui/material';
import useStyles from 'components/form/dropzone/styles';
import { getIn, useFormikContext } from 'formik';
import React from 'react';

import Typography from '@libs/ui/components/typography';

import { ProfileFormState } from '../types';

interface ServerFilesProps {
  name?: string;
}

const ServerFiles = ({ name = 'serverDocuments' }: ServerFilesProps) => {
  const { values, setFieldValue } = useFormikContext<ProfileFormState>();
  const classes = useStyles({} as any);
  const value = getIn(values, name) as { hash: string; title: string }[];
  const onDocumentRemove = (index: number) => {
    const documents = value.filter((_, i: number) => i !== index) || [];
    setFieldValue(name, documents);
  };

  return (
    <Box component="ul">
      {value?.map((file, index) => (
        <Grid
          component="li"
          className={classes.fileItem}
          container
          key={file.hash}
        >
          <Grid item>
            <Typography variant="body2">{file.title}</Typography>
          </Grid>
          <Grid item>
            <CloseIcon
              className={classes.removeIcon}
              onClick={() => onDocumentRemove(index)}
            />
          </Grid>
        </Grid>
      ))}
    </Box>
  );
};

export default ServerFiles;
