import React from 'react';
import { EXTERNAL_RESOURCES } from 'routes';

import { Box, Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Job } from '@libs/graphql-types';
import Button from '@libs/ui/components/button';
import Typography, { OuterLink } from '@libs/ui/components/typography';

interface TextBlockProps extends React.PropsWithChildren<unknown> {
  job: Job;
  text?: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(10, 6),
    backgroundColor: '#FAFAFA',
  },
  form: {
    maxWidth: 256,
  },
}));

const TextBlock = ({ job, children, text }: TextBlockProps) => {
  const classes = useStyles();

  return (
    <Container maxWidth={false} className={classes.root}>
      <Box pb={6}>
        <Typography textAlign="center" variant="h5">
          Something for you?
        </Typography>
      </Box>

      <Grid container justifyContent="center">
        <Grid className={classes.form} item>
          <Button
            type="submit"
            size="large"
            color="primary"
            variant="contained"
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            fullWidth
          >
            Apply Today
          </Button>
        </Grid>
      </Grid>

      <Box pt={6} margin="0 auto" maxWidth={840}>
        <Typography paragraph fontWeight={600} variant="h6" textAlign="center">
          About Us
        </Typography>
        <Typography paragraph textAlign="center">
          Meet OpenTalent: the online platform that leverages the networks of
          vetted professionals and&nbsp;recruiters across the European Union to
          quickly find the best of the best people in the industry.
        </Typography>
        <Typography textAlign="center">
          Are you looking to hire top talent?{' '}
          <OuterLink href={EXTERNAL_RESOURCES.companiesLanding}>
            Learn how we can help.
          </OuterLink>
        </Typography>
      </Box>
    </Container>
  );
};

export default TextBlock;
