import { ChatTypes } from 'components/chat';
import {
  generateChatName,
  mapTalentToChatUserData,
  UserToTalentChatMeta,
} from 'components/chat/client-to-talent';
import {
  CompanyUserChatData,
  CustomChatUserData,
  useStreamChatContext,
} from 'components/chat/common/provider';
import { TalentToTalentChatMeta } from 'components/chat/talent-to-talent';
import { getChannelIdDefault } from 'components/chat/utils';
import { DEFAULT_AVATAR } from 'consts/common';
import { useCallback, useEffect, useState } from 'react';
import { Channel, DefaultGenerics, StreamChat } from 'stream-chat';

import { Talent } from '@libs/graphql-types';

type ChatTypeArgs = { chatType?: ChatTypes };

export const useCreateChat = (
  { chatType = ChatTypes.UserToTalent }: ChatTypeArgs = {
    chatType: ChatTypes.UserToTalent,
  },
) => {
  const { client, userData } = useStreamChatContext();

  const createChannel = useCallback(
    async (talent: Talent) => {
      if (!client || !userData) {
        return;
      }
      const talentData = mapTalentToChatUserData(talent);

      const chatId = getChannelIdDefault([talentData.chatId, userData.chatId]);
      let details;

      if (chatType === ChatTypes.UserToTalent) {
        const chatDetails: UserToTalentChatMeta = {
          type: chatType,
          companyUser: userData as CompanyUserChatData,
          talent: talentData,
        };
        details = chatDetails;
      }
      if (chatType === ChatTypes.TalentToTalent) {
        const chatDetails: TalentToTalentChatMeta = {
          type: chatType,
          talent1: userData,
          talent2: talentData,
        };
        details = chatDetails;
      }

      const channel = await client.channel('messaging', chatId, {
        members: [talentData.chatId, userData.chatId],
        name: generateChatName(userData.name, talentData.name),
        image: DEFAULT_AVATAR,
        channelDetails: details,
      });

      return channel;
    },
    [chatType, client, userData],
  );

  return { createChannel };
};

export const createUserToTalent = async (
  client: StreamChat<DefaultGenerics>,
  {
    talentData,
    userData,
  }: {
    talentData: CustomChatUserData;
    userData: CompanyUserChatData;
  },
) => {
  if (!client || !userData || !talentData) {
    return;
  }

  const chatId = getChannelIdDefault([talentData.chatId, userData.chatId]);
  const details: UserToTalentChatMeta = {
    type: ChatTypes.UserToTalent,
    companyUser: userData,
    talent: talentData,
  };

  const channel = await client.channel('messaging', chatId, {
    members: [talentData.chatId, userData.chatId],
    name: generateChatName(userData.name, talentData.name),
    image: DEFAULT_AVATAR,
    channelDetails: details,
  });

  return channel;
};

interface CreateChatProps {
  talent: Talent;
}

export const useCreateChatWithTalent = ({
  talent,
  chatType,
}: CreateChatProps & ChatTypeArgs) => {
  const [currentChannel, setCurrentChannel] =
    useState<Channel<DefaultGenerics>>();
  const { createChannel } = useCreateChat({ chatType });

  const createChannelWithTalent = useCallback(async () => {
    const channel = await createChannel(talent);
    setCurrentChannel(channel);
  }, [createChannel, talent]);

  useEffect(() => {
    createChannelWithTalent();
  }, [createChannelWithTalent]);

  return {
    channel: currentChannel,
  };
};
