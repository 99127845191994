import React from 'react';

import { Skill, SkillTypeEnum } from '@libs/graphql-types';
import { SkillChip } from '@libs/ui/components/chip';
import { ConnectedMultipleSelect } from '@libs/ui/components/form/multiple-select';
import { OptionType } from '@libs/ui/components/form/select';

import { useSkillsSuggest } from './hooks';

interface SkillSelectProps {
  name: string;
  disabled?: boolean;
  skillTypes?: SkillTypeEnum[];
  label?: string;
  helperText?: string;
  noOptionsText?: React.ReactNode;
  open?: boolean;
}

const DEFAULT_SKILL_TYPES = [
  SkillTypeEnum.HardSkills,
  SkillTypeEnum.SoftSkills,
  SkillTypeEnum.Solutions,
];

export const renderTags = (value: OptionType[], getTagProps: any) =>
  value.map((option: OptionType, index: number) => (
    <SkillChip
      {...getTagProps({ index })}
      size="small"
      skill={{ ...value[index], name: option.text } as unknown as Skill}
      key={`id-${option.value}-${index + 1}`}
      label={option.text}
    />
  ));

const SkillSelect = ({
  name,
  disabled,
  skillTypes = DEFAULT_SKILL_TYPES,
  label = 'Enter Technologies, Hard Skills & Soft Skills',
  helperText,
  noOptionsText,
  open,
}: SkillSelectProps) => {
  const { loading, createOnChangeHandler, skillsOptions } = useSkillsSuggest();

  return (
    <ConnectedMultipleSelect
      options={skillsOptions}
      name={name}
      loading={loading}
      noOptionsText={noOptionsText}
      chipProps={{
        size: 'small',
      }}
      renderTags={renderTags}
      autoCompleteProps={{
        loading,
        filterSelectedOptions: true,
        popupIcon: null,
        disabled,
        open,
      }}
      inputProps={{
        onChange: createOnChangeHandler(skillTypes),
        variant: 'filled',
        label,
        name: 'skills',
        helperText,
      }}
    />
  );
};

export default SkillSelect;
