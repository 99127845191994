import React, { useState } from 'react';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Paper } from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';

import { Job, MatchQualityEnum } from '@libs/graphql-types';
import { stopEvent } from '@libs/helpers/common';
import Button from '@libs/ui/components/button';

import JobCardFooter from './JobCardFooter';
import JobCardTitle from './JobCardTitle';
import JobDescription from './JobDescription';
import JobDetails from './JobDetails';

interface JobCardProps {
  onJobSave?: VoidFunction;
  job: Job;
  currentTime: Date;
  matchQuality?: MatchQualityEnum;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    marginBottom: theme.spacing(4),
    cursor: 'pointer',
    transition: 'background-color 0.5s ease',

    '&:hover': {
      backgroundColor: grey[100],
    },
  },
  toggler: {
    color: theme.palette.tertiary.main,
  },
  togglerIcon: {
    transform: ({ isOpen }: { isOpen: boolean }) =>
      `rotate(${isOpen ? 0 : 180}deg)`,
  },
}));

const ExternalJobCardV2 = ({
  job,
  currentTime,
  onJobSave,
  matchQuality,
}: JobCardProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles({ isOpen });

  return (
    <Paper
      component={job.link_to_details ? 'a' : 'div'}
      {...(!!job.link_to_details
        ? {
          href: job.link_to_details,
          target: '_blank',
        }
        : {})}
      data-test-id="jobCard"
      className={classes.root}
      elevation={0}
    >
      <Box p={6}>
        <JobCardTitle
          isSaved={!!job?.is_saved}
          job={job}
          currentTime={currentTime}
          onJobSave={onJobSave}
          matchQuality={matchQuality}
        />
        {/* <JobKeySkills job={job} isOpen={isOpen} /> */}
        <JobDetails job={job} isOpen={isOpen} />
        <JobDescription job={job} isOpen={isOpen} />
        <Box display="flex" onClick={stopEvent} justifyContent="flex-end">
          <Button
            onClick={() => setIsOpen(!isOpen)}
            color="info"
            variant="text"
            endIcon={
              <KeyboardArrowUpIcon
                className={classes.togglerIcon}
                color="inherit"
              />
            }
          >
            Show more
          </Button>
        </Box>
        <JobCardFooter job={job} isOpen={isOpen} />
      </Box>
    </Paper>
  );
};

export default ExternalJobCardV2;
