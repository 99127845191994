/* eslint-disable no-useless-escape */
import { isValidPhoneNumber } from 'libphonenumber-js';
import * as yup from 'yup';

import { COMMON_MAIL_DOMAINS } from './mainDomains';

const URL_REGEXP =
  /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
export const IS_VALID_PHONE = 'phone';
export const IS_VALID_URL = 'customUrl';
export const checkIsEmailCommon = (email: string) =>
  COMMON_MAIL_DOMAINS.some((domain) => email.includes(domain));

export const setupCustomValidators = () => {
  yup.addMethod(yup.string, 'onlyBusinessEmail', function() {
    return (this as any).test({
      name: 'onlyBusinessEmail',
      message: 'Please enter a valid business e-mail address.',
      test: (value: string | undefined = '') => {
        const [, currentDomain = ''] = value.split('@') || [];
        const res = !COMMON_MAIL_DOMAINS.some(
          (domain) => currentDomain === domain,
        );

        return res;
      },
    });
  });

  yup.addMethod(yup.string, IS_VALID_PHONE, function() {
    return (this as any).test({
      name: IS_VALID_PHONE,
      message: 'Invalid phone',
      test: (phone = '') => {
        const sanitizedPhone = phone.replace('+', '');

        return !sanitizedPhone || isValidPhoneNumber(phone);
      },
    });
  });

  yup.addMethod(yup.string, IS_VALID_URL, function() {
    return (this as any).test({
      name: IS_VALID_URL,
      message: 'Invalid url',
      test: (url = '') => {
        return !url || URL_REGEXP.test(url);
      },
    });
  });
};
