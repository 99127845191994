import { ReactComponent as LogoIcon } from 'assets/icons/opentalent_dark.svg';
import PageLayout from 'components/layout/page-layout';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { pathManager } from 'routes';

import { makeStyles } from '@mui/styles';

import BookCall from './book-call';
import Builder from './builder';
import Final from './final';
import { useCheckUserRedirect } from './hooks';

// import Welcome from './welcome';

interface NewOnboardingProps {}

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    overflow: 'hidden',
    margin: '0',
    width: '100%',
    maxWidth: '100%',
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
    paddingBottom: theme.spacing(25),

    '& .MuiFilledInput-root': {
      background: 'white',
    },
  },
  '@global': {
    header: {
      backgroundColor: `${theme.palette.other.black} !important`,

      '&::before': {
        background: `${theme.palette.other.black} !important`,
      },
    },
  },
}));

export const NewOnboarding = (props: NewOnboardingProps) => {
  const classes = useStyles();

  useCheckUserRedirect();

  return (
    <PageLayout
      classes={{ contentWrapper: classes.contentWrapper }}
      documentTitle="Onboarding"
      headerProps={{
        accountProps: null,
        Logo: LogoIcon,
      }}
    >
      <Switch>
        <Route
          path={pathManager.company.newOnboarding.bookCall.getRoute()}
          component={BookCall}
        />
        <Route
          path={pathManager.company.newOnboarding.final.getRoute()}
          component={Final}
        />

        <Route
          path={[
            pathManager.company.newOnboarding.builder.main.getRoute(),
            pathManager.company.newOnboarding.main.getRoute(),
          ]}
          component={Builder}
        />

        {/* <Route
          path={pathManager.company.newOnboarding.main.getRoute()}
          component={Welcome}
        /> */}

        {/* <Route
          path={[
            pathManager.company.newOnboarding.greeting.getRoute(),
            pathManager.company.newOnboarding.main.getRoute(),
          ]}
          component={Greeting}
        /> */}
      </Switch>
    </PageLayout>
  );
};

export default NewOnboarding;
