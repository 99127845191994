import React from 'react';

import { PredicateRejectionReasons } from 'routes/types';
import QuickAuth from 'screens/auth/QuickAuth';
import { WrongUserType } from 'screens/not-found';

interface RouteRestrictionProps {
  rejectReason: PredicateRejectionReasons;
}

const SCREEN_BY_RESTRICTION: Record<
  PredicateRejectionReasons,
  React.ComponentType
> = {
  [PredicateRejectionReasons.WrongRoleType]: WrongUserType,
  [PredicateRejectionReasons.NotAuthenticated]: QuickAuth,
};

const RouteRestriction = ({ rejectReason }: RouteRestrictionProps) => {
  const Screen = SCREEN_BY_RESTRICTION[rejectReason];

  return <Screen />;
};

export default RouteRestriction;
