import cn from 'classnames';
import React from 'react';

import { makeStyles } from '@mui/styles';

import Typography, { TypographyProps } from './Typography';

interface OuterLinkProps extends TypographyProps {
  href: string;
  target?: string;
  rel?: string;
  hasUnderline?: boolean;
}

const useStyles = makeStyles((theme) => ({
  link: {
    cursor: 'pointer',
    textDecoration: ({ hasUnderline }: OuterLinkProps) =>
      hasUnderline ? 'underline' : 'none',
  },
}));

export const OuterLink = (props: OuterLinkProps) => {
  const { href, target, rel, className, children, ...rest } = props;
  const classes = useStyles(props);

  return (
    <Typography
      component="a"
      {...{
        target,
        rel,
        href,
      }}
      color="tertiary"
      className={cn(className, classes.link)}
      {...rest}
    >
      {children}
    </Typography>
  );
};
