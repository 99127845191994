import { SubmittedTalentCard } from 'components/custom/talent/submitted-talent-card';
import React from 'react';
import { mapMatches } from 'screens/talent-matcher/job-board/content/matcher-job-card/job-candidates-pipeline';
import ArrowMatchesTabs from 'screens/talent-matcher/job-board/content/matcher-job-card/job-candidates-pipeline/ArrowMatchesTabs';

import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Job, SuggestingStageEnum } from '@libs/graphql-types';

interface JobTalentSugestionsProps {
  job: Job;
}

const useStyles = makeStyles(() => ({
  tabs: {
    '& > button': {
      height: 32,
    },
  },
  talents: {
    background: 'rgba(238, 238, 238, 1)',
  },
}));

export const JobTalentSugestions = ({ job }: JobTalentSugestionsProps) => {
  const classes = useStyles();
  const [currentStage, setCurrentStage] = React.useState<
    SuggestingStageEnum | undefined
  >(SuggestingStageEnum.Submitted);
  const { count, mapped } = mapMatches(job);

  const matches = currentStage ? mapped[currentStage] || [] : [];

  return (
    <Box mb={6}>
      <ArrowMatchesTabs
        value={currentStage}
        count={count || {}}
        className={classes.tabs}
        onChange={(stage) =>
          setCurrentStage(stage === currentStage ? undefined : stage)
        }
      />

      <Box className={classes.talents} mt={4}>
        {matches.map((match) => (
          <Box mb={4}>
            <SubmittedTalentCard match={match} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};
