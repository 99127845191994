import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import React from 'react';
import {
  DefaultModalProps,
  useOpenModal,
  withLocationStateModal,
} from 'utils/modals';
import { formatName } from 'utils/talent';

import { Box, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  Talent,
  useRejectTalentCompanyPoolApplicationMutation,
} from '@libs/graphql-types';
import { modelPath } from '@libs/helpers/form';
import Button from '@libs/ui/components/button';
import { ConnectedTextField } from '@libs/ui/components/form/text-field';
import { DefaultModal } from '@libs/ui/components/modals';

interface LimitedProfileAccessModalProps
  extends DefaultModalProps<{ talent: Talent }> {
  onSuccess?: () => void;
}

const useStyles = makeStyles((theme) => ({
  modal: {
    width: '585px',

    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
}));

type FormModel = {
  message: string;
};

const RejectTalentModalComponent = ({
  modalData,
  isOpen,
  close,
  onSuccess,
}: LimitedProfileAccessModalProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const talentId = modalData?.talent?.id;
  const talentName = formatName({
    firstName:
      modalData?.talent?.first_name ||
      modalData?.talent?.first_name_abac?.value,
    lastName:
      modalData?.talent?.last_name || modalData?.talent?.last_name_abac?.value,
  });
  const [reject, { loading: isRejecting }] =
    useRejectTalentCompanyPoolApplicationMutation({
      variables: { talent_id: talentId! },
      onCompleted: () => {
        enqueueSnackbar('Talent application has been rejected.', {
          variant: 'success',
        });
        onSuccess?.();
        close();
      },
    });

  const defaultText = `\
Dear ${talentName || 'talent'},
We regret to inform you that, after careful consideration, we have decided to remove you from our Community.
We appreciate the time you spent with us and wish you the best in your future endeavors.
Sincerely,
`;

  return (
    <Formik<FormModel>
      initialValues={{ message: defaultText }}
      onSubmit={(values) => {
        reject({
          variables: { talent_id: talentId!, message: values.message },
        });
      }}
    >
      {({ submitForm }) => {
        return (
          <DefaultModal
            handleClose={close}
            open={isOpen}
            className={classes.modal}
            title="Rejecting talent"
            actions={
              <Grid spacing={4} container>
                <Grid xs={6} item>
                  <Button
                    fullWidth
                    size="large"
                    color="error"
                    variant="contained"
                    disabled={isRejecting}
                    onClick={submitForm}
                  >
                    Reject talent
                  </Button>
                </Grid>
                <Grid xs={6} item>
                  <Button
                    fullWidth
                    size="large"
                    variant="outlined"
                    color="info"
                    onClick={close}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            }
          >
            <Box mt={4} mb={8}>
              <Typography variant="body1">
                Are you sure you want to reject this talent?
              </Typography>
            </Box>

            <Box>
              <ConnectedTextField
                fullWidth
                size="small"
                variant="filled"
                multiline
                label="Message text"
                name={modelPath<FormModel>((m) => m.message)}
                rows={6}
              />
            </Box>
          </DefaultModal>
        );
      }}
    </Formik>
  );
};
RejectTalentModalComponent.id = 'RejectTalentModal';

export const RejectTalentModal = withLocationStateModal<{ talent: Talent }>({
  id: RejectTalentModalComponent.id,
  getModalKey: (data) => data?.talent?.id,
})(RejectTalentModalComponent);

export const useOpenRejectTalentModal = () =>
  useOpenModal(RejectTalentModalComponent.id);
