import { gql } from '@apollo/client';

export default gql`
  query CheckSkillExists($skill_type: SkillTypeEnum!, $name: String!) {
    skillExists(skill_type: $skill_type, name: $name) {
      id
      skill_type
      name
      slug
      created_at
      updated_at
    }
  }
`;
