import cn from 'classnames';
import React from 'react';

import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Checkbox from '@libs/ui/components/form/checkbox';
import Typography from '@libs/ui/components/typography';

interface ChoiceBoxProps {
  name: string;
  title: string;
  subtitle: string;
  isActive: boolean;
  onClick: VoidFunction;
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 450,
  },
  choiceRoot: {
    cursor: 'pointer',
    position: 'relative',
    textAlign: 'center',
    borderRadius: 16,
    padding: theme.spacing(8),
    transition: 'border .3s',
    background: theme.palette.grey[800],
    border: ({ isActive }: ChoiceBoxProps) =>
      isActive
        ? `2px solid ${theme.palette.primary.main}`
        : `2px solid transparent`,

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4),
    },
  },
  checkboxChecked: {
    color: `${theme.palette.primary.main} !important`,
  },
  checkboxLabel: {
    margin: 0,
  },
  choiceDetails: {
    marginTop: theme.spacing(4),
    borderRadius: 16,
    background: 'rgba(94, 143, 246, 0.3)',
    padding: theme.spacing(4),
    textAlign: 'center',
  },
}));

const ChoiceBox = (props: ChoiceBoxProps) => {
  const classes = useStyles(props);
  const { name, title, subtitle, isActive, onClick, className } = props;

  return (
    <Box
      className={cn(classes.choiceRoot, className)}
      onClick={onClick}
      position="relative"
    >
      <Checkbox
        checked={isActive}
        formControlLabelProps={{ classes: { root: classes.checkboxLabel } }}
        classes={{ checked: classes.checkboxChecked }}
        name={name}
      />
      <Typography
        paragraph
        color={isActive ? 'primary' : undefined}
        variant="h5"
      >
        {title}
      </Typography>
      <Typography paragraph variant="subtitle1">
        {subtitle}
      </Typography>
    </Box>
  );
};

export default ChoiceBox;
