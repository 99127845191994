import { getIn, useFormikContext } from 'formik';
import React, { useMemo } from 'react';

import ErrorIcon from '@mui/icons-material/Error';
import { Alert } from '@mui/material';

import { Subcategory, TalentCategory } from '@libs/graphql-types';
import {
  ConnectedMultipleSelect,
  ConnectedMultipleSelectProps,
} from '@libs/ui/components/form/multiple-select';
import { OptionType } from '@libs/ui/components/form/select';
import Typography from '@libs/ui/components/typography';

interface SubCategorySelectorProps
  extends Omit<ConnectedMultipleSelectProps, 'options'> {
  categoryPath: string;
  categories: TalentCategory[];
}

const mapCategoryToOption = (cat: TalentCategory | Subcategory) => ({
  text: cat.name,
  value: cat.id,
});

export const SubCategorySelector = ({
  categoryPath,
  categories,
  name,
  multiple,
  ...rest
}: SubCategorySelectorProps) => {
  const { values } = useFormikContext();
  const currentCategory = getIn(values, categoryPath);
  const options = useMemo(() => {
    const subCategories =
      categories.find((i) => i.id === currentCategory)?.subcategories || [];
    return subCategories.map(mapCategoryToOption);
  }, [categories, currentCategory]);

  if (!options.length && !!currentCategory) {
    return (
      <Alert icon={<ErrorIcon />} variant="outlined" color="error">
        <Typography fontWeight={500} color="error" component="span">
          There are no subcategories
        </Typography>
      </Alert>
    );
  }

  return (
    <ConnectedMultipleSelect
      {...rest}
      multiple={multiple}
      name={name}
      options={options}
      autoCompleteProps={{
        filterSelectedOptions: true,
        isOptionEqualToValue: (option: OptionType, value: OptionType) => {
          return value.value === option.value;
        },
        ...rest.autoCompleteProps,
      }}
    />
  );
};
