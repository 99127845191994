import React from 'react';

import { makeStyles } from '@mui/styles';

import Typography from '@libs/ui/components/typography';

import { ReactComponent as CouponImage } from './coupon.svg';

interface CouponProps {
  text: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  text: {
    position: 'absolute',
    display: 'flex',
    fontWeight: 'bold',
    left: 34,
    top: 12,
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.common.white,
    fontSize: 20,
  },
}));

export const Coupon = ({ text }: CouponProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* <img src={image} alt="coupon" /> */}
      <CouponImage />
      <Typography className={classes.text}>{text}</Typography>
    </div>
  );
};
