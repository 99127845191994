import { getUniqHash } from './getUniqHash';

export const mayBeAddDeviceId = (key: string) => {
  let deviceId = localStorage.getItem(key);

  if (!deviceId) {
    deviceId = getUniqHash();

    localStorage.setItem(key, deviceId);
    console.info('New device id:', deviceId);
  }

  return deviceId;
};
