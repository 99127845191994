import { LOCAL_STORAGE_KEYS } from 'consts/ls-keys';
import React, { useContext, useState } from 'react';

interface CurrentUserContextProviderProps
  extends React.PropsWithChildren<unknown> { }

export interface CurrentUserContextProviderValue {
  type: 'talent' | 'company_user';
  userId: string;
}

interface CurrentUserContextProviderState {
  value?: CurrentUserContextProviderValue;
  setValue: (a?: CurrentUserContextProviderValue) => void;
}

export const CurrentUserContext =
  React.createContext<CurrentUserContextProviderState | null>(null);

export const getCurrentUserFromLS = ():
  | CurrentUserContextProviderValue
  | undefined => {
  const currLSAcc = localStorage.getItem(LOCAL_STORAGE_KEYS.currentAccount);
  return !!currLSAcc ? JSON.parse(currLSAcc) : undefined;
};

export const CurrentUserContextProvider = ({
  children,
}: CurrentUserContextProviderProps) => {
  const [value, setValue] = useState<
    CurrentUserContextProviderValue | undefined
  >(getCurrentUserFromLS());

  const setCurrentValue = (
    newValue: CurrentUserContextProviderValue | undefined,
  ) => {
    setValue(newValue);
    if (newValue) {
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.currentAccount,
        JSON.stringify(newValue),
      );
      window.location.href = '/';
    }
  };

  return (
    <CurrentUserContext.Provider value={{ value, setValue: setCurrentValue }}>
      {children}
    </CurrentUserContext.Provider>
  );
};

export const useCurrentUserContext = () => {
  const value = useContext(CurrentUserContext);

  if (!value) {
    throw new Error(`TalentMatcherContextContext not initialized`);
  }

  return value;
};
