import React from 'react';
import {
  useIsAppliedToSource,
  useMatcherApplication,
} from 'screens/talent-matcher/source-job/hooks';

import { Box, Chip, ChipProps, Grid } from '@mui/material';

import {
  Job,
  JobMatcherApplicationStatusEnum,
  Talent,
} from '@libs/graphql-types';
import { getPluralPostfix } from '@libs/helpers/common';
import { calcMatchersSeats } from '@libs/ui/components/job/utils';
import Typography, { TypographyProps } from '@libs/ui/components/typography';

interface JobStatusRowProps {
  job: Job;
}

const getTextsByJobStatus = (
  status: JobMatcherApplicationStatusEnum,
  seatsLeft: number,
) => {
  const BTN_TEXTS_MAP: Record<
    JobMatcherApplicationStatusEnum,
    {
      status: string;
      text: string;
      chipColor: ChipProps['color'];
      textColor: TypographyProps['color'];
    }
  > = {
    APPROVED: {
      status: 'Approved',
      text: 'Present your candidates!',
      chipColor: 'success',
      textColor: 'info.main',
    },
    REJECTED: {
      status: 'Declined',
      text: 'You can still suggest a candidate!',
      chipColor: 'error',
      textColor: 'warning.dark',
    },
    CANCELED: {
      status: 'Canceled',
      text: 'You can still suggest a candidate!',
      chipColor: 'error',
      textColor: 'warning.dark',
    },
    REQUESTED: {
      status: 'Applied',
      text: 'Waiting for approval',
      chipColor: 'info',
      textColor: 'textColor.main',
    },
    UNKNOWN: {
      status: `${seatsLeft} seat${getPluralPostfix(seatsLeft)} left`,
      text: seatsLeft > 0 ? '' : '',
      chipColor: 'success',
      textColor: 'textColor.main',
    },
  };

  return BTN_TEXTS_MAP[status];
};

const JobStatusRow = ({ job }: JobStatusRowProps) => {
  const seatsLeft = calcMatchersSeats(job?.matchers?.length || 0);
  const isAppliedToSource = useIsAppliedToSource(
    (job?.matchers as Talent[]) || [],
  );
  const matcherApplication = useMatcherApplication(
    job?.job_matcher_applications || [],
  );
  const applicationStatus = isAppliedToSource
    ? 'APPROVED'
    : matcherApplication?.status || 'UNKNOWN';
  const texts = getTextsByJobStatus(applicationStatus, seatsLeft);

  if (applicationStatus === 'UNKNOWN') {
    return null;
  }

  return (
    <Box mb={2}>
      <Grid spacing={2} container>
        <Grid item>
          <Chip
            color={texts.chipColor}
            size="small"
            label={texts.status}
            variant="outlined"
          />
        </Grid>
        <Grid item>
          <Typography
            fontWeight={500}
            color={texts.textColor}
            variant="caption"
          >
            {texts.text}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default JobStatusRow;
