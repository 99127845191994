import useMediaQueries from 'hooks/common/useMediaQueries';
import React from 'react';

import { Skeleton } from '@mui/lab';
import { Box } from '@mui/material';
import MuiDrawer, { DrawerProps as MuiDrawerProps } from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';

import { BottomDrawerList } from './bottom-drawer-list';
import DrawerList from './drawer-list';
import { DrawerListItemProps } from './drawer-list-item';
import useStyles from './styles';

export interface DrawerProps extends MuiDrawerProps {
  items?: DrawerListItemProps[];
  isLoading?: boolean;
  isHidden?: boolean;
}

const Drawer = (props: DrawerProps) => {
  const { items, isLoading, isHidden, ...rest } = props;
  const classes = useStyles(props);
  const { isSM } = useMediaQueries();

  if (isHidden) {
    return null;
  }

  return (
    <MuiDrawer
      {...rest}
      className={classes.drawer}
      variant={isSM ? 'temporary' : 'permanent'}
      classes={{
        paper: classes.paper,
      }}
    >
      <Toolbar />
      {!isLoading && <DrawerList items={items} />}
      {isLoading &&
        Array(4)
          .fill('')
          .map((_, key) => (
            <Skeleton
              key={key as any}
              height="60px"
              className={classes.skeleton}
            />
          ))}

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        flexGrow={1}
      >
        <BottomDrawerList />
      </Box>
    </MuiDrawer>
  );
};

export default Drawer;
