import { makeStyles } from '@mui/styles';

import { Theme } from '@libs/ui/themes/default';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: 'white',
    padding: theme.spacing(4),
    margin: '0 auto',
    cursor: 'pointer',
    borderRadius: theme.spacing(2),
    position: 'relative',

    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      margin: `0 ${theme.spacing(4)}`,
    },
  },
  avatar: {
    width: 80,
    height: 80,
  },
  status: {
    paddingTop: theme.spacing(2),
  },
  check: {
    color: theme.palette.info.main,
  },
  mainInfo: {
    overflow: 'hidden',
  },
  fullWidth: {
    width: '100%',
  },
  name: {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: theme.spacing(2),
  },
  email: {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export default useStyles;
