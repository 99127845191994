import { ActionItem } from 'components/custom/talent/types';
import { ConnectedPageLayout } from 'components/layout/page-layout';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Box, Grid, Pagination } from '@mui/material';

import { useGetPendingTalentsQuery } from '@libs/graphql-types';
import { formatNumberSafe } from '@libs/helpers/format';
import Typography from '@libs/ui/components/typography';

import TalentCard from '../shared/talent-card-v2';
import BackLinkButton from './BackLinkButton';
import { useBackLink } from './hooks';

interface PendingTalentsProps { }

const PendingTalents = (props: PendingTalentsProps) => {
  const backLink = useBackLink();
  const history = useHistory();
  const { data, loading, refetch } = useGetPendingTalentsQuery({
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      const total = data?.talentsSearch?.custom_paginator_info?.total || 0;

      if (!total) {
        history.push(backLink);
      }
    },
  });
  const talents = data?.talentsSearch?.data || [];
  const total = data?.talentsSearch?.custom_paginator_info?.total || 0;
  const current = data?.talentsSearch?.custom_paginator_info?.current_page || 0;
  const lastPage = data?.talentsSearch?.custom_paginator_info?.last_page || 0;
  const filterActions = (actions: ActionItem[]): ActionItem[] => {
    return actions.filter(
      (action) => !['Message', 'Invite to apply'].includes(action.text),
    );
  };

  return (
    <ConnectedPageLayout
      headerProps={{ accountProps: {} }}
      drawerProps={{}}
      isLoading={loading}
    >
      <Box mb={10}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography fontWeight={500} variant="h5">
              Verify these new profiles for your Talent Pool
            </Typography>
          </Grid>
          <Grid item>
            <BackLinkButton backLink={backLink} />
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Typography paragraph>
          <Typography variant="h6" component="span" color="info.main">
            {formatNumberSafe(total)}
          </Typography>{' '}
          Candidates are waiting for profile verification
        </Typography>
      </Box>

      <div>
        {talents?.map((item: any) => (
          <Box key={item.talent.id} mb={4}>
            <TalentCard
              showJobApplicationBadge
              talentSearch={item}
              refetch={refetch}
              filterActions={filterActions}
            />
          </Box>
        ))}
      </div>

      {!!total && !loading && (
        <Box display="flex" justifyContent="center" mt={4}>
          <Pagination
            page={current}
            showFirstButton
            showLastButton
            count={lastPage}
            variant="outlined"
            shape="rounded"
            onChange={(e, page) => {
              window.scroll({ top: 0, behavior: 'smooth' });
              refetch({ page });
            }}
          />
        </Box>
      )}
    </ConnectedPageLayout>
  );
};

export default PendingTalents;
