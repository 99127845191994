import { ReactComponent as CompaniesIcon } from 'assets/icons/companies.svg';
import { CompaniesPoolActions } from 'components/custom/talent/companies-pool-actions';
import { DEFAULT_AVATAR } from 'consts/common';
import React from 'react';

import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import { Avatar, Box, Card, CardContent, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Company, GetCompanyViewScreenDataQuery } from '@libs/graphql-types';
import Typography, { OuterLink } from '@libs/ui/components/typography';

interface TitleCardProps {
  company?: GetCompanyViewScreenDataQuery['company'];
  refetch?: VoidFunction;
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(4)} !important`,
  },
  shieldIcon: {
    color: theme.palette.success.main,
    marginTop: theme.spacing(1),
  },
}));

export const TitleCard = ({ company, refetch }: TitleCardProps) => {
  const classes = useStyles();

  return (
    <Card elevation={0}>
      <CardContent className={classes.root}>
        <Grid container spacing={4}>
          <Grid item flexGrow={1}>
            <Grid container spacing={8}>
              <Grid item>
                <Box>
                  <Avatar
                    alt={company?.name}
                    src={company?.logo?.url || DEFAULT_AVATAR}
                    sx={{ width: 96, height: 96 }}
                  />
                </Box>
              </Grid>
              <Grid item>
                <Box mb={1}>
                  <Grid spacing={2} container>
                    <Grid item>
                      <Typography variant="h5">{company?.name}</Typography>
                    </Grid>
                    <Grid item>
                      <GppGoodOutlinedIcon className={classes.shieldIcon} />
                    </Grid>
                  </Grid>
                </Box>
                <Box mb={1}>
                  <Grid container spacing={4}>
                    <Grid item>
                      <Grid container spacing={1}>
                        <Grid item>
                          <FmdGoodOutlinedIcon />
                        </Grid>
                        <Grid item>{company?.country || 'Europe'}</Grid>
                      </Grid>
                    </Grid>
                    {!!company?.website && (
                      <Grid item>
                        <Grid container spacing={1}>
                          <Grid item>
                            <LanguageOutlinedIcon />
                          </Grid>
                          <Grid item>
                            <OuterLink
                              href={company.website}
                              color="info.main"
                              target="_blank"
                            >
                              {company.website}
                            </OuterLink>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    {!!company?.type_of_activity && (
                      <Grid item>
                        <Grid container spacing={1}>
                          <Grid item>
                            <CompaniesIcon />
                          </Grid>
                          <Grid item>
                            <Typography variant="body2">
                              {company?.type_of_activity}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Box>
                {/* <Grid container spacing={2}> */}
                {/*   <Grid item> */}
                {/*     <Typography>Company size:</Typography> */}
                {/*   </Grid> */}
                {/*   <Grid item> */}
                {/*     <Typography color="text.secondary" style={{ marginTop: 1 }}> */}
                {/*       {company?.size} employees */}
                {/*     </Typography> */}
                {/*   </Grid> */}
                {/* </Grid> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <CompaniesPoolActions
              company={company as Company}
              refetch={refetch}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
