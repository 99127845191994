import { useApolloClient } from '@apollo/client';
import { mergeDeep } from '@apollo/client/utilities';
import { getIn, useFormikContext } from 'formik';
import useCurrentUserRequest from 'hooks/auth/useCurrentUserRequest';
import { useSearchParams } from 'hooks/routing';
import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { pathManager } from 'routes';
import * as yup from 'yup';

import { CheckCompanyUserExistsByEmailDocument } from '@libs/graphql-types';

import { OnboardingV3State } from '../types';

const INITIAL_VALUES: OnboardingV3State = {
  general: {
    firstName: '',
    lastName: '',
    email: '',
  },
  company: {
    name: undefined,
  },
};

const useSearchParamsValues = () => {
  const search = useSearchParams();
  const firstName = (search.first_name as string) || '';
  const email = (search.email as string) || '';
  const lastName = (search.last_name as string) || '';
  const companyName = (search.company_name as string) || '';
  const values = useMemo(
    () => ({
      firstName,
      lastName,
      email,
      companyName,
    }),
    [companyName, email, firstName, lastName],
  );

  return values;
};

export const useInitialState = () => {
  const { data, isLoading } = useCurrentUserRequest({
    respectAnonymusRoutes: false,
  });
  const valuesFromSearch = useSearchParamsValues();
  const companyUser = data?.currentCompanyUser;
  const initialValues: OnboardingV3State = mergeDeep(INITIAL_VALUES, {
    general: {
      firstName: companyUser?.first_name || valuesFromSearch.firstName || '',
      lastName: companyUser?.last_name || valuesFromSearch.lastName || '',
      email: companyUser?.email || valuesFromSearch.email || '',
    },
    company: {
      name: companyUser?.company?.name || valuesFromSearch.companyName || '',
    },
  });

  return { isLoading, initialValues };
};

const STEPS_ORDERS = [
  { key: 'general' as const, path: 'general.email' },
  { key: 'company' as const, path: 'company.name' },
];

export const useCheckFormErrors = () => {
  const { values } = useFormikContext<OnboardingV3State>();
  const history = useHistory();

  useEffect(() => {
    for (let data of STEPS_ORDERS) {
      const val = getIn(values, data.path);
      if (!val) {
        const route = pathManager.company.onboardingV3[data.key].generatePath();
        history.push(route);
        break;
      }
    }
    // eslint-disable-next-line
  }, [history]);
};

export const useValidator = () => {
  // const [check] = useCheckCompanyUserExistsByEmailLazyQuery();
  const client = useApolloClient();
  const validator = useMemo(() => {
    return yup.object().shape({
      general: yup.object().shape({
        firstName: yup.string().trim().required(),
        lastName: yup.string().trim().required(),
        email: yup
          .string()
          .trim()
          .email()
          .onlyBusinessEmail()
          .required()
          .test(
            'email-exist-check',
            'A user account with this email already exists',
            async function test(email) {
              const isValid = await yup
                .string()
                .email()
                .required()
                .validate(email);
              if (isValid) {
                const res = await client.query({
                  query: CheckCompanyUserExistsByEmailDocument,
                  variables: { email: email },
                });
                return !res.data.checkCompanyUserExistsByEmail;
              }
              return this.schema.validate(email);
            },
          ),
      }),
      company: yup.object().shape({
        name: yup.string().trim().required('Fill your company name'),
      }),
    });
    // eslint-disable-next-line
  }, []);

  return validator;
};
