import { gql } from '@apollo/client';

export default gql`
  query CurrentCompanyUserColleagues {
    currentCompanyUserColleagues {
      id
      email
      first_name
      last_name
      position
      company {
        id
        name
        logo
      }
      avatar {
        avatar
        hash
      }
      created_at
      updated_at
    }
  }
`;
