import { AuthUtils } from 'auth0/utils';
import { Form, Formik } from 'formik';
import { useAuth0 } from 'hooks/auth';
import qs from 'querystring';
import React from 'react';
import * as yup from 'yup';

import { Box, Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Job } from '@libs/graphql-types';
import Button from '@libs/ui/components/button';
import { ConnectedTextField } from '@libs/ui/components/form/text-field';
import Typography, { OuterLink } from '@libs/ui/components/typography';

import { useSubmitAction } from './hooks';
import { FormData } from './types';

interface IntroProps {
  job: Job;
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(10, 6),
    backgroundColor: '#FAFAFA',
  },
  wrap: {
    maxWidth: 700,
    margin: '0 auto',
  },
  form: {
    margin: '0 auto 24px',
  },
  submit: {
    height: 56,
    minWidth: 200,
  },
}));

const validator = yup.object().shape({
  email: yup.string().email().required(),
});

const Intro = ({ job }: IntroProps) => {
  const classes = useStyles();
  const auto0 = useAuth0();
  const { onSubmit, isLoading } = useSubmitAction({ jobId: job.id });
  const isCompanyUser = AuthUtils.isCompany(auto0.user);
  const showForm = !isCompanyUser;
  const emailLinkSearch = qs.stringify({
    subject: `Candidate suggestions for ${job.name}`,
    body: `Hey OpenTalent team,

I have a candidate who fits your job position ${job.name}`,
  });

  return (
    <Container maxWidth={false} className={classes.root}>
      <Box className={classes.wrap}>
        <Box>
          <Typography paragraph textAlign="center" variant="h5">
            Hey 👋 - we’re looking for a{' '}
            <Typography variant="h5" component="span" color="info.main">
              {job.name}.
            </Typography>{' '}
          </Typography>
        </Box>

        {showForm && (
          <div>
            <Box pb={8}>
              <Typography textAlign="center">
                Something for you? Apply today!
              </Typography>
            </Box>
            <Formik<FormData>
              validationSchema={validator}
              initialValues={{ email: '' }}
              onSubmit={onSubmit}
            >
              <Box component={Form} className={classes.form}>
                <Grid
                  flexWrap="wrap"
                  container
                  spacing={4}
                  justifyContent="center"
                >
                  <Grid md={4} item>
                    <ConnectedTextField
                      placeholder="Enter your email"
                      name="email"
                      fullWidth
                      variant="outlined"
                      size="medium"
                      disabled={isLoading}
                    />
                  </Grid>
                  <Grid md={4} item>
                    <Button
                      className={classes.submit}
                      type="submit"
                      size="large"
                      color="primary"
                      variant="contained"
                      fullWidth
                      disabled={isLoading}
                    >
                      Apply NOW
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Formik>
          </div>
        )}

        <Box>
          <Typography textAlign="center">
            Know someone? There’s a finder’s fee.{' '}
            <OuterLink
              style={{ textDecoration: 'underline' }}
              color="info.main"
              href={`mailto:info@opentalent.co?${emailLinkSearch}`}
            >
              Connect with us
            </OuterLink>{' '}
            for more info.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default Intro;
