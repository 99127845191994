import { gql } from '@apollo/client';

export default gql`
  query GetSkills($skill_type: SkillTypeEnum) {
    skills(skill_type: $skill_type) {
      data {
        id
        skill_type
        name
        slug
        created_at
        updated_at
      }
    }
  }
`;
