import React, { useContext, useState } from 'react';

interface TalentMatcherContextProviderProps
  extends React.PropsWithChildren<unknown> { }

export enum MatcherLayoutViewTypes {
  Matcher = 'Matcher',
  Talent = 'Talent',
}

export interface TalentMatcherContextProviderValue {
  currentView: MatcherLayoutViewTypes;
}

interface TalentMatcherContextProviderState {
  value: TalentMatcherContextProviderValue;
  setValue: React.Dispatch<
    React.SetStateAction<TalentMatcherContextProviderValue>
  >;
}

export const TalentMatcherContext =
  React.createContext<TalentMatcherContextProviderState | null>(null);

export const TalentMatcherContextProvider = ({
  children,
}: TalentMatcherContextProviderProps) => {
  const initialValue: TalentMatcherContextProviderValue = {
    currentView: MatcherLayoutViewTypes.Matcher,
  };
  const [value, setValue] = useState(initialValue);

  return (
    <TalentMatcherContext.Provider value={{ value, setValue }}>
      {children}
    </TalentMatcherContext.Provider>
  );
};

export const useTalentMatcherContext = () => {
  const value = useContext(TalentMatcherContext);

  if (!value) {
    throw new Error(`TalentMatcherContextContext not initialized`);
  }

  return value;
};
