import { mergeDeep } from '@apollo/client/utilities';
import { Formik } from 'formik';
import React, { useMemo } from 'react';

import { Box, Grid } from '@mui/material';

import { modelPath } from '@libs/helpers/form';
import Button from '@libs/ui/components/button';
import { ConnectedTextField } from '@libs/ui/components/form/text-field';
import Typography from '@libs/ui/components/typography';

import { useOldCompanyOnboardingContext } from '../context/OnboardingContext';
import { useCreateCompanyUser, useInitialValues } from './hooks';
import { CreateUserState } from './types';
import { getValidator } from './validator';

interface UserProps {}

const User = (props: UserProps) => {
  const { initialValues } = useInitialValues();
  const {
    value: { user },
    setValue,
  } = useOldCompanyOnboardingContext();
  const onSuccess = (values: any) => {
    setValue((val) =>
      mergeDeep(val, {
        user: {
          ...val.user,
          ...values,
        },
        company: {
          ...values?.company,
        },
      }),
    );
  };

  const { updateRequest } = useCreateCompanyUser({
    onSuccess,
  });

  const finalInit = useMemo(
    () => mergeDeep(initialValues, user),
    [initialValues, user],
  );

  return (
    <div>
      <Box mb={10}>
        <Typography>Fill in your personal details</Typography>
      </Box>
      <Formik<CreateUserState>
        onSubmit={updateRequest}
        validationSchema={getValidator()}
        initialValues={finalInit}
        validateOnBlur={false}
        validateOnChange
      >
        {({ handleSubmit, values }) => (
          <Grid spacing={4} direction="column" container wrap="nowrap">
            {/*<Grid item>
                <UploadImageControl
                  fileType={FileTypeEnum.CompanyUserAvatar}
                  name={modelPath<CreateUserState>((m) => m._avatar)}
                  label="Upload avatar"
                  dark
                />
              </Grid>*/}
            {/*<br />*/}
            <Grid item>
              <Grid spacing={4} container>
                <Grid xs={12} sm={6} item>
                  <ConnectedTextField
                    size="small"
                    name={modelPath<CreateUserState>((m) => m.first_name)}
                    fullWidth
                    variant="filled"
                    label="First name*"
                  />
                </Grid>
                <Grid xs={12} sm={6} item>
                  <ConnectedTextField
                    name={modelPath<CreateUserState>((m) => m.last_name)}
                    size="small"
                    fullWidth
                    variant="filled"
                    label="Last name"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <ConnectedTextField
                name={modelPath<CreateUserState>((m) => m.email)}
                fullWidth
                size="small"
                variant="filled"
                label="Email*"
              />
            </Grid>
            <Grid item>
              <Box pt={4}>
                <Grid spacing={5} container>
                  <Grid xs={6} item>
                    <Button
                      onClick={() => handleSubmit()}
                      variant="contained"
                      color="primary"
                      disabled={!!values._avatar?.isLoading}
                    >
                      next
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        )}
      </Formik>
    </div>
  );
};

export default User;
