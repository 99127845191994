import React from 'react';

import { Box, Container } from '@mui/material';

import { Job } from '@libs/graphql-types';
import BooleanSkills from '@libs/ui/components/job/boolean-skills';
import Description from '@libs/ui/components/job/description';
import GeneralInfo from '@libs/ui/components/job/general-info';
import RequiredSkills from '@libs/ui/components/job/required-skills';
import { getCampaignStatus } from '@libs/ui/components/job/utils';
import Typography from '@libs/ui/components/typography';

interface JobInfoProps {
  job: Job;
}

const JobInfo = ({ job }: JobInfoProps) => {
  const campaignStatus = getCampaignStatus(job);

  return (
    <Container maxWidth="md">
      <Box my={12}>
        <Typography fontWeight={500} textAlign="center" variant="h5" paragraph>
          {job.name} @ {job?.client || job?.campaign_owner?.company?.name}
        </Typography>

        <GeneralInfo campaignStatus={campaignStatus} job={job} />
        <Box mt={1}>
          {job.skills_boolean_filter ? (
            <BooleanSkills job={job} />
          ) : (
            <RequiredSkills job={job} />
          )}
        </Box>
        <Box mt={1}>
          <Description job={job} />
        </Box>
      </Box>
    </Container>
  );
};

export default JobInfo;
