import { getIn, useFormikContext } from 'formik';
import { SUGGEST_COMPANY } from 'graphql/common';
import React from 'react';

import { Box, Typography, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { modelPath } from '@libs/helpers/form';
import { ConnectedCheckbox } from '@libs/ui/components/form/checkbox';
import { GraphConnectedMultipleSelect } from '@libs/ui/components/form/multiple-select';

import { FormState } from './types';

interface CompanyFormStepProps {
  onNext: () => void;
  onBack: () => void;
}

const useStyles = makeStyles(() => ({
  label: {
    fontSize: 14,
    lineHeight: '20px',
  },
}));

export const CompanyFormStep = (props: CompanyFormStepProps) => {
  const classes = useStyles();
  const { validateForm, setFieldValue } = useFormikContext();

  const onSubmit = async () => {
    const errors = await validateForm();
    const currentErrors = getIn(
      errors,
      modelPath<FormState>((m) => m.company),
    );

    if (Object.keys(currentErrors || {}).length === 0) {
      props.onNext();
    }
  };

  return (
    <div>
      <Box mb={2}>
        <Typography variant="h5" textAlign="center">
          Select your Company
        </Typography>
      </Box>

      <Box mb={10}>
        <Typography variant="body2" textAlign="center">
          The use of OpenTalent is free.
        </Typography>
      </Box>
      <Box mt={4} mb={6}>
        <GraphConnectedMultipleSelect
          name={modelPath<FormState>((m) => m.company.name)}
          query={SUGGEST_COMPANY}
          dataPath="companies.data"
          mapInputTextToQuery={(value: string) => ({ search: value })}
          dataMap={{ text: 'name', value: 'id' }}
          autoCompleteProps={{
            multiple: false,
          }}
          noOptionsText="Brand new company on OpenTalent!"
          inputProps={{
            variant: 'filled',
            label: 'Select Company',
            placeholder: '',
            margin: 'dense',
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue(
                modelPath<FormState>((m) => m.company.name),
                { value: e.target.value, text: e.target.value },
              );
            },
          }}
        />
      </Box>
      <Box mb={8}>
        <ConnectedCheckbox
          formControlLabelProps={{
            classes: { label: classes.label },
          }}
          label="I verify that I currently work at this company."
          name={modelPath<FormState>((m) => m.company.verified)}
        />
      </Box>

      <Box display="flex" justifyContent="center">
        <Button fullWidth color="info" variant="contained" onClick={onSubmit}>
          CONTINUE &gt;
        </Button>
      </Box>
    </div>
  );
};
