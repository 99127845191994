import React from 'react';

import { Box, Card, CardContent, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Button from '@libs/ui/components/button';
import Typography from '@libs/ui/components/typography';

import { OnboardingWrapper } from '../../shared';
import { ReactComponent as OpentalentLogo } from '../asstes/opentalent-logo.svg';
import { JoinTypeEnum } from '../choose-type/add-company-form/types';

interface GeneralProps {
  type?: JoinTypeEnum;
}

export const useStyles = makeStyles((theme) => ({
  card: {
    background: '#1E1E1A',
    textAlign: 'center',
    borderRadius: theme.spacing(4),
    maxWidth: 610,

    '& a': {
      textDecoration: 'underline',
    },
  },
  cardContent: {
    padding: `${theme.spacing(14)} !important`,

    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacing(6)} !important`,
    },
  },

  fieldsWrapper: {
    width: 500,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  footer: {
    position: 'absolute',
  },
  btn: {
    borderRadius: 34,

    'a&': {
      color: `${theme.palette.text.primary} !important`,
    },

    '&.Mui-disabled': {
      background: '#BDBDBD',
    },
  },
}));

const LINKS: Record<JoinTypeEnum, string> = {
  [JoinTypeEnum.Agency]:
    'https://calendly.com/tyler-opentalent/verification-recruiter-clone ',
  [JoinTypeEnum.Independent]:
    'https://calendly.com/tyler-opentalent/verification',
  [JoinTypeEnum.Company]: 'https://calendly.com/tyler-opentalent/verification',
};

export const Meeting = ({ type = JoinTypeEnum.Independent }: GeneralProps) => {
  const classes = useStyles();
  const link = LINKS[type];

  return (
    <OnboardingWrapper>
      <Grid container flexDirection="column" alignItems="center">
        <Grid item>
          <Box mb={6}>
            <OpentalentLogo />
          </Box>
        </Grid>
        <Grid item>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Box mb={4}>
                <Typography
                  variant="h5"
                  style={{ fontSize: 28 }}
                  fontWeight={700}
                >
                  Book a meeting to get verified
                </Typography>
              </Box>

              <Box mb={6}>
                <Typography variant="body1" paragraph>
                  We’re gradually adding recruiters and agencies to our platform
                  and seek to verify each new account.
                </Typography>
                <Typography variant="body1">
                  Please book a call with our team to get verified.
                </Typography>
              </Box>

              <Box mt={12}>
                <Button
                  fullWidth
                  href={link}
                  size="large"
                  color="primary"
                  variant="contained"
                  className={classes.btn}
                  {...{ target: '_blank' }}
                >
                  Book 15-min. call
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </OnboardingWrapper>
  );
};
