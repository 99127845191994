import React from 'react';
import { AscDesc, ChannelFilters, StreamChat } from 'stream-chat';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';

import { ChatProviderValue } from './types';

export const StreamChatContext = React.createContext<ChatProviderValue | null>(
  null,
);

export const streamChatClient = StreamChat.getInstance('88wkpjh7j4tw');

export const getOwnChatFilter = (
  userId: string,
): ChannelFilters<DefaultStreamChatGenerics> => ({
  members: { $in: [userId] },
  last_message_at: { $lte: new Date().toISOString() },
});

export const DEFAULT_CHANNELS_SORT = { has_unread: -1 as AscDesc };
