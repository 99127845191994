import { gql } from '@apollo/client';

export default gql`
  query SkillsBySlug($search: String!) {
    skillSearch(search: $search) {
      id
      skill_type
      name
      slug
      created_at
      updated_at
    }
  }
`;
