import { ReactComponent as LogoIcon } from 'assets/icons/opentalent_dark.svg';
import PageLayout from 'components/layout/page-layout';
import React from 'react';
import { Redirect, RouteComponentProps, Switch, Route } from 'react-router-dom';
import { pathManager } from 'routes';

import { makeStyles } from '@mui/styles';

import Final from './final';
import Form from './form';
import { OldCompanyOnboardingProvider } from './form/context/OnboardingContext';
import Greeting from './greeting';

interface OnboardingProps extends RouteComponentProps { }

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    overflow: 'hidden',
    margin: '0',
    width: '100%',
    maxWidth: '100%',
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
    paddingBottom: theme.spacing(25),

    '& .MuiFilledInput-root': {
      background: 'white',
    },
  },
  '@global': {
    header: {
      backgroundColor: `${theme.palette.other.black} !important`,

      '&::before': {
        background: `${theme.palette.other.black} !important`,
      },
    },
  },
}));

const Onboarding = ({ match }: OnboardingProps) => {
  const classes = useStyles();

  return (
    <OldCompanyOnboardingProvider>
      <PageLayout
        classes={{ contentWrapper: classes.contentWrapper }}
        documentTitle="Onboarding"
        headerProps={{
          accountProps: null,
          Logo: LogoIcon,
        }}
      >
        <Switch>
          <Route
            path={pathManager.company.onboarding.greeting.getRoute()}
            render={(params) => (
              <Greeting
                {...params}
                link={pathManager.company.onboarding.form.getRoute()}
              />
            )}
          />
          <Route
            path={pathManager.company.onboarding.form.getRoute()}
            component={Form}
          />
          <Route
            path={pathManager.company.onboarding.final.getRoute()}
            component={Final}
          />
          <Redirect
            from={match.path}
            to={`${pathManager.company.onboarding.form.getRoute()}${window.location.search
              }`}
          />
        </Switch>
      </PageLayout>
    </OldCompanyOnboardingProvider>
  );
};

export default React.memo(Onboarding, () => true);
