import { GET_CURRENT_TALENT } from 'graphql/talents';
import { useSnackbar } from 'notistack';

import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';

import {
  TalentCompanyPoolConnection,
  TalentCompanyPoolingInitiatorEnum,
  TalentCompanyPoolingStatusEnum,
  Company,
  useApplyCurrentTalentToCompanyPoolMutation,
  useLeaveCurrentTalentFromCompanyPoolMutation,
  useAcceptCompanyPoolInvitationMutation,
  useRejectCompanyPoolInvitationMutation,
} from '@libs/graphql-types';

import { ActionItem } from '../types';

export const useCompanyPoolActions = ({
  company,
  connection,
  onSuccess,
}: {
  company: Company;
  connection?: TalentCompanyPoolConnection;
  onSuccess?: VoidFunction;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [apply, { loading: isAppling }] =
    useApplyCurrentTalentToCompanyPoolMutation({
      variables: { company_id: company!.id },
      refetchQueries: [
        {
          query: GET_CURRENT_TALENT,
        },
      ],
      onCompleted: () => {
        onSuccess?.();
        enqueueSnackbar('Your request has been sent to the company.', {
          variant: 'success',
        });
      },
    });

  const [leave, { loading: isLeaving }] =
    useLeaveCurrentTalentFromCompanyPoolMutation({
      variables: { company_id: company!.id },
      refetchQueries: [
        {
          query: GET_CURRENT_TALENT,
        },
      ],
      onCompleted: () => {
        onSuccess?.();
      },
    });

  const [accept, { loading: isAccepting }] =
    useAcceptCompanyPoolInvitationMutation({
      variables: { company_id: company!.id },
      refetchQueries: [
        {
          query: GET_CURRENT_TALENT,
        },
      ],
      onCompleted: () => {
        onSuccess?.();
        enqueueSnackbar('You have accepted company invitation', {
          variant: 'warning',
          persist: true,
        });
      },
    });

  const [reject, { loading: isRejecting }] =
    useRejectCompanyPoolInvitationMutation({
      variables: { company_id: company!.id },
      refetchQueries: [
        {
          query: GET_CURRENT_TALENT,
        },
      ],
      onCompleted: () => {
        onSuccess?.();
        enqueueSnackbar('You have rejected company invitation', {
          variant: 'warning',
          persist: true,
        });
      },
    });

  const actions: ActionItem[] = [];

  const isLoadingFinal = isRejecting || isAccepting || isLeaving || isAppling;

  if (connection?.status === TalentCompanyPoolingStatusEnum.Pending) {
    if (connection.initiator === TalentCompanyPoolingInitiatorEnum.Company) {
      actions.push({
        text: 'Accept',
        Icon: CheckCircleOutlineOutlinedIcon,
        onClick: accept,
        color: 'success',
      });

      actions.push({
        text: 'Reject',
        Icon: HighlightOffOutlinedIcon,
        onClick: reject,
        color: 'error',
      });
    }

    if (connection.initiator === TalentCompanyPoolingInitiatorEnum.Talent) {
      actions.push({
        text: 'Cancel request',
        Icon: CheckCircleOutlineOutlinedIcon,
        onClick: () =>
          leave({
            onCompleted: () => {
              onSuccess?.();
              enqueueSnackbar('Your request has been withdrawn.', {
                variant: 'warning',
                persist: true,
              });
            },
          }),
        color: 'error',
      });
    }
  }

  if (connection?.status === TalentCompanyPoolingStatusEnum.Approved) {
    actions.push({
      text: 'Leave',
      Icon: DeleteOutlinedIcon,
      onClick: () =>
        leave({
          onCompleted: () => {
            onSuccess?.();
            enqueueSnackbar('You have left this community.', {
              variant: 'warning',
              persist: true,
            });
          },
        }),
      color: 'error',
    });
  }

  if (connection?.status === TalentCompanyPoolingStatusEnum.Cancelled) {
    actions.push({
      text: 'Connect',
      Icon: PersonAddAltOutlinedIcon,
      onClick: apply,
      color: 'success',
    });
  }

  return { actions, isLoadingFinal };
};
