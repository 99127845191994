import { CheckThickListItem } from 'components/check-thick-list';
import { PREMIUM_FEATURES } from 'consts/talents';
import React from 'react';
import { pathManager } from 'routes';

import { Box, Typography } from '@mui/material';

import { RouterButton } from '@libs/ui/components/button';

interface SuccessProps {}

const Success = (props: SuccessProps) => {
  return (
    <>
      <Box mb={6}>
        <Typography variant="h5">Congratulations! 🎉</Typography>
      </Box>
      <Box mb={4}>
        <Typography whiteSpace="break-spaces" paragraph variant="subtitle1">
          {`You upgraded to OpenTalent Premium and
now have access to Europe’s #1 talent community.`}
        </Typography>
      </Box>

      <Box mb={6}>
        {PREMIUM_FEATURES.map((feature) => (
          <CheckThickListItem key={feature} isChecked>
            {feature}
          </CheckThickListItem>
        ))}
      </Box>

      <Box mb={8} maxWidth={380}>
        <RouterButton
          size="large"
          rounded
          fullWidth
          variant="contained"
          color="info"
          to={pathManager.talent.community.generatePath()}
        >
          Visit the community
        </RouterButton>
      </Box>
    </>
  );
};

export default Success;
