import { JobSkillsBooleanFilter, Skill } from '@libs/graphql-types';

export const getRawBooleanSkills = (booleanFilter?: JobSkillsBooleanFilter) => {
  const allSkills =
    booleanFilter?.boolean_skills_filter_items?.reduce<Skill[]>(
      (acc, skill) => {
        const all = (skill?.all_of_skills || []) as Skill[];
        const or = (skill?.one_of_skills || []) as Skill[];

        return acc.concat(...or, ...all);
      },
      [],
    ) || [];

  const skillsMap = allSkills.reduce<Record<string, Skill>>((acc, skill) => {
    acc[skill.id] = skill;
    return acc;
  }, {});

  return Object.values(skillsMap);
};
