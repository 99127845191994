import { Formik } from 'formik';
import React from 'react';

import { Box, CircularProgress } from '@mui/material';

import { FormikSubmit } from '@libs/helpers/form';

import { OnboardingV3State } from '../types';
import { CheckFormErrors } from './CheckFormErrors';
import { useInitialState, useValidator } from './hooks';

interface OnboardingFormProps extends React.PropsWithChildren<unknown> {
  onSubmit: FormikSubmit<OnboardingV3State>;
}

export const OnboardingForm = ({ children, onSubmit }: OnboardingFormProps) => {
  const { isLoading, initialValues } = useInitialState();
  const validator = useValidator();

  if (isLoading) {
    return (
      <Box justifyContent="center" display="flex">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Formik<OnboardingV3State>
      initialValues={initialValues}
      validationSchema={validator}
      onSubmit={onSubmit}
      validateOnMount
    // enableReinitialize
    >
      <>
        <CheckFormErrors />
        {children}
      </>
    </Formik>
  );
};
