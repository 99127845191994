import cn from 'classnames';
import { SnackbarProvider as BaseSnackbarProvider } from 'notistack';
import React, { PropsWithChildren } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { IconButton, useMediaQuery, useTheme } from '@mui/material';

import useStyles from './styles';

type SnackbarProviderProps = PropsWithChildren<Record<string, unknown>>;

export const SnackbarProvider = (props: SnackbarProviderProps) => {
  const { children, ...rest } = props;
  const theme = useTheme();
  const isSM = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles(props);
  const notistackRef = React.createRef<any>();
  const onClickDismiss = (key: string) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <BaseSnackbarProvider
      {...rest}
      maxSnack={4}
      dense={isSM}
      ref={notistackRef}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      action={
        ((key: string) => (
          <IconButton
            className={classes.buttonIcon}
            size="small"
            onClick={onClickDismiss(key)}
          >
            <CloseIcon className={classes.buttonIcon} fontSize="small" />
          </IconButton>
        )) as unknown as any
      }
      classes={{
        variantSuccess: cn(classes.root, classes.variantSuccess),
        variantError: cn(classes.root, classes.variantError),
        variantWarning: cn(classes.root, classes.variantWarning),
        variantInfo: cn(classes.root, classes.variantInfo),
        containerRoot: 'notistack-message',
      }}
    >
      {children}
    </BaseSnackbarProvider>
  );
};
