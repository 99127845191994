import { gql } from '@apollo/client';

import GET_NOTIFICATIONS_FRAG from 'graphql/fragments/notifications/currentNotificationsFrag';

export const GET_NOTIFICATIONS = gql`
  ${GET_NOTIFICATIONS_FRAG}
  query GetNotifications($first: Int = 20, $page: Int = 1) {
    currentUserUnreadNotificationsCount
    currentUserNotifications(first: $first, page: $page) {
      ...CurrentNotifications
    }
  }
`;

export const MARK_ALL_NOTIFICATIONS_AS_READ = gql`
  mutation MarkAsReadAll {
    currentUserNotificationsMarkAllAsRead
  }
`;

export const UPDATE_NOTIFICATION_STATUS = gql`
  query UpdateStatus {
    currentUserNotifications(first: 100, page: 1) {
      data {
        is_read
      }
    }
  }
`;
