import cn from 'classnames';
import React from 'react';

import { Step, StepLabel, Stepper } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface TopStepsProps {
  activeIndex?: number;
  steps?: string[];
  className?: string;
  onlyActiveLabel?: boolean;
}

const STEPS = ['Select Type of Hire', 'Set up job campaign'];

const useStyles = makeStyles((theme) => ({
  root: {
    paddingRight: 0,
    paddingLeft: 0,
    background: 'transparent',
    maxWidth: 782,
    width: '100%',
  },
  activeStep: {},
  activeStepLabel: {
    fontSize: 14,
  },
  completed: {
    '& .MuiStepLabel-iconContainer svg.MuiStepIcon-root': {
      opacity: 1,
    },
  },
  completedLabel: {
    '$root &': {
      color: theme.palette.text.secondary,
    },
  },
  stepLabel: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      lineHeight: '21px',
    },
  },
  iconContainer: {
    fontSize: 20,

    '& text': {
      fill: 'white',
    },

    'span& svg.MuiStepIcon-root': {
      color: theme.palette.secondary.main,
      opacity: 0.5,
    },

    '$activeStep & svg': {
      color: 'white !important',
      opacity: 1,

      '& text': {
        fontSize: 12,
        fill: theme.palette.secondary.main,
      },

      [theme.breakpoints.down('sm')]: {
        fontSize: 24,
      },
    },
  },
}));

const TopSteps = ({
  activeIndex = 0,
  steps = STEPS,
  className,
  onlyActiveLabel,
}: TopStepsProps) => {
  const classes = useStyles();

  return (
    <Stepper
      classes={{ root: cn(classes.root, className) }}
      activeStep={activeIndex}
    >
      {steps.map((label, index) => (
        <Step
          classes={{
            completed: classes.completed,
            root: cn({ [classes.activeStep]: activeIndex === index }),
          }}
          key={label}
        >
          <StepLabel
            classes={{
              label: classes.stepLabel,
              active: classes.activeStepLabel,
              iconContainer: classes.iconContainer,
              completed: classes.completedLabel,
            }}
          >
            {onlyActiveLabel && activeIndex !== index ? '' : label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default TopSteps;
