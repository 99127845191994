import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  variantSuccess: {
    // По другому не получится поменять цвет. Нужно перебить !important из либы
    background: `${theme.palette.success.main} !important`,
    color: `${theme.palette.success.contrastText} !important`,
  },
  variantError: {
    background: `${theme.palette.error.main} !important`,
    color: `${theme.palette.error.contrastText} !important`,
  },
  variantWarning: {
    background: `${theme.palette.warning.main} !important`,
    color: `${theme.palette.warning.contrastText} !important`,
  },
  variantInfo: {
    background: `${theme.palette.info.main} !important`,
    color: `${theme.palette.info.contrastText} !important`,
  },
  buttonIcon: {
    color: 'inherit',
  },
  root: {
    borderRadius: 0,
    flexWrap: 'nowrap !important',
  } as any,
}));

export default useStyles;
