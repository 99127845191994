import { gql } from '@apollo/client';
import FULL_USER_FRAGMENT from 'graphql/fragments/companyUser/fullCompanyUserFrag';
import FULL_TALENT_FRAGMENT from 'graphql/fragments/talent/fullTalentFragment';

export default gql`
  ${FULL_USER_FRAGMENT}
  ${FULL_TALENT_FRAGMENT}
  query CurrentCompanyUser {
    currentUserAccounts {
      company_user_accounts {
        ...FullUser
      }
      talent_accounts {
        ...FullTalent
      }
    }
    currentCompanyUser {
      ...FullUser
    }
  }
`;
