import { useSnackbar } from 'notistack';
import React from 'react';

import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';

import {
  Talent,
  useInviteTalentToCompanyPoolMutation,
} from '@libs/graphql-types';
import { noop } from '@libs/helpers/common';
import Button from '@libs/ui/components/button';

interface ConnectTalentToCompanyPoolActionProps {
  talent: Talent;
  refetch?: VoidFunction;
  disabled?: boolean;
}

export const ConnectTalentToCompanyPoolAction = ({
  talent,
  refetch = noop,
  disabled = false,
}: ConnectTalentToCompanyPoolActionProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [connect, { loading }] = useInviteTalentToCompanyPoolMutation({
    variables: { talent_id: talent.id },
    onCompleted: () => {
      enqueueSnackbar('You have been successfully connected with talent', {
        variant: 'success',
      });
      refetch();
    },
  });

  return (
    <Button
      onClick={connect as VoidFunction}
      variant="outlined"
      size="medium"
      color="info"
      startIcon={<PersonAddAlt1OutlinedIcon />}
      fullWidth
      disabled={loading || disabled}
    >
      Connect
    </Button>
  );
};
