import { gql } from '@apollo/client';
import FULL_JOB_FRAGMENT from 'graphql/fragments/companyUser/jobFragment';

export const GET_JOB_INFO = gql`
  ${FULL_JOB_FRAGMENT}
  query GetJob($id: ID!) {
    job(id: $id) {
      ...FullJob
    }
  }
`;
