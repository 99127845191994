import { UserToTalentChatMeta } from './client-to-talent';
import { JobChatMeta } from './job-chat';
import { TalentToTalentChatMeta } from './talent-to-talent';

export enum ChatTypes {
  UserToTalent = 'userToTalent',
  JobChannel = 'jobChannel',
  TalentToTalent = 'talentToTalent',
}

export type ChatMetaInfo =
  | UserToTalentChatMeta
  | JobChatMeta
  | TalentToTalentChatMeta;
