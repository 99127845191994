import { useMutation } from '@apollo/client';
import { REMIND_TALENT_ACTIVATION } from 'graphql/talents';
import { useSnackbar } from 'notistack';

import {
  Mutation,
  MutationVerifyTalentAccountArgs,
  Talent,
} from '@libs/graphql-types';

export const useRemindAccount = (talent: Talent, refetch?: VoidFunction) => {
  const { enqueueSnackbar } = useSnackbar();

  const [remind, { loading }] = useMutation<
    Mutation,
    MutationVerifyTalentAccountArgs
  >(REMIND_TALENT_ACTIVATION, {
    onCompleted: () => {
      enqueueSnackbar('Success', {
        variant: 'success',
      });
      if (refetch) {
        refetch();
      }
    },
  });

  const remindTalent = async () => {
    await remind({ variables: { talent_id: talent.id } });
  };

  return { remindTalent, loading };
};
