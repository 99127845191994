import { CompanyModals } from 'components/custom/company/types';
import React from 'react';
import {
  DefaultModalProps,
  useOpenModal,
  withLocationStateModal,
} from 'utils/modals';

import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { DefaultModal } from '@libs/ui/components/modals';
import Typography from '@libs/ui/components/typography';

import CompanyStripePaymentButton from '../../company-stripe-payment-button';

interface JobPushDirectModalProps extends DefaultModalProps<true> { }

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#191919',
    color: 'white',
    borderRadius: 8,
    width: 400,
    padding: theme.spacing(6, 6, 4),

    [theme.breakpoints.down('md')]: {
      width: 'auto',
    },
  },
}));

const JobPushDirectModalComponent = ({
  isOpen,
  close,
}: JobPushDirectModalProps) => {
  const classes = useStyles();

  return (
    <DefaultModal
      className={classes.root}
      handleClose={close}
      open={isOpen}
      title="Upgrade to launch campaign."
      actions={
        <Box margin="0 auto" width={260}>
          <CompanyStripePaymentButton variant="contained" color="primary">
            UPGRADE to DIRECT
          </CompanyStripePaymentButton>
        </Box>
      }
    >
      <Box mt={4}>
        <Typography variant="body2">
          Job is in{' '}
          <Typography variant="body2" color="primary.main" component="span">
            draft mode
          </Typography>
          . Upgrade to push live, see candidate details and get more leads.
        </Typography>
      </Box>
    </DefaultModal>
  );
};

export const JobPushDirectModal = withLocationStateModal({
  id: CompanyModals.JobPushDirectModal,
})(JobPushDirectModalComponent);

export const useOpenJobPushDirectModal = () =>
  useOpenModal(CompanyModals.JobPushDirectModal);
