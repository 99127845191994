import React, { useMemo } from 'react';

import { Box, Paper, Grid } from '@mui/material';

import { Job, JobSkill, SkillTypeEnum } from '@libs/graphql-types';
import SkillChip from '@libs/ui/components/chip/SkillChip';
import { sortSkills } from '@libs/ui/components/job/utils';
import Typography from '@libs/ui/components/typography';

interface RequiredSkillsProps {
  job?: Job;
}

const RequiredSkills = ({ job }: RequiredSkillsProps) => {
  const skills = useMemo(
    () =>
      sortSkills((job?.skills || []) as any) as unknown as Record<
        SkillTypeEnum,
        JobSkill[]
      >,
    [job],
  );
  const allSolutions = skills?.[SkillTypeEnum.Solutions].reduce(
    (acc, item) => {
      acc[item?.job_skill_pivot?.is_required ? 'required' : 'rest'].push(item);
      return acc;
    },
    { required: [] as JobSkill[], rest: [] as JobSkill[] },
  );

  const allHardSkills = skills?.[SkillTypeEnum.HardSkills].reduce(
    (acc, item) => {
      acc[item?.job_skill_pivot?.is_required ? 'required' : 'rest'].push(item);
      return acc;
    },
    { required: [] as JobSkill[], rest: [] as JobSkill[] },
  );

  return (
    <Paper elevation={0}>
      <Box p={4}>
        <Typography paragraph variant="h6">
          Key skills
        </Typography>
        <Box pb={3}>
          <Typography variant="subtitle2">Required Technologies</Typography>
          <Grid component={Box} pt={2} spacing={2} container>
            {allSolutions.required.map((skill) => (
              <Grid key={skill.id} item>
                <SkillChip size="small" skill={skill} />
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box pb={3}>
          <Typography variant="subtitle2">Required Hard Skills</Typography>
          <Grid component={Box} pt={2} spacing={2} container>
            {allHardSkills.required.map((skill) => (
              <Grid key={skill.id} item>
                <SkillChip size="small" skill={skill} />
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box pb={3}>
          <Typography variant="subtitle2">Optional Technologies</Typography>
          <Grid component={Box} pt={2} spacing={2} container>
            {allSolutions.rest.map((skill) => (
              <Grid key={skill.id} item>
                <SkillChip size="small" skill={skill} />
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box pb={3}>
          <Typography variant="subtitle2">Optional Hard Skills</Typography>
          <Grid component={Box} pt={2} spacing={2} container>
            {allHardSkills?.rest.map((skill) => (
              <Grid key={skill.id} item>
                <SkillChip size="small" skill={skill} />
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box pb={3}>
          <Typography variant="subtitle2">Optional Soft Skills</Typography>
          <Grid component={Box} pt={2} spacing={2} container>
            {skills[SkillTypeEnum.SoftSkills].map((skill) => (
              <Grid key={skill.id} item>
                <SkillChip size="small" skill={skill} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Paper>
  );
};

export default RequiredSkills;
