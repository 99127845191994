import { mergeDeep } from '@apollo/client/utilities';
import FixedFooter from 'components/custom/onboarding/fixed-footer';
import { LOCAL_STORAGE_KEYS } from 'consts/ls-keys';
import React from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { EXTERNAL_LINKS } from '@libs/helpers/consts';
import { modelPath } from '@libs/helpers/form';
import { RouterButton } from '@libs/ui/components/button';
import { ConnectedCheckbox } from '@libs/ui/components/form/checkbox';
import { ConnectedTextField } from '@libs/ui/components/form/text-field';
import { OuterLink } from '@libs/ui/components/typography';

import { useDefaultOnSubmitHandler } from '../form-context/hooks';
import { BuilderFormState } from '../form-context/types';
import NextButtonWithValidation from '../shared/NextButtonWithValidation';
import StepTemplate from '../shared/StepTemplate';
import { useCreateJob } from '../shared/hooks';
import { DefaultStepProps } from '../shared/types';
import { useCreateCompanyHandler } from './hooks';

interface AccountCreationProps extends DefaultStepProps { }

const useStyles = makeStyles((theme) => ({
  form: {
    maxWidth: 400,
    margin: '0 auto',

    '& > div:not(:last-child)': {
      marginBottom: theme.spacing(6),
    },
  },
  agreement: {
    marginTop: theme.spacing(8),
    display: 'flex',
    justifyContent: 'center',

    '& .Mui-checked svg': {
      color: theme.palette.primary.main,
    },
    '& svg': {
      color: 'white',
    },
  },

  hint: {
    paddingTop: theme.spacing(5),
    maxWidth: 500,
    margin: '0 auto',
    textAlign: 'center',
  },
}));

const AccountCreation = ({
  index,
  nextStepRoute,
  prevStepRoute,
}: AccountCreationProps) => {
  const classes = useStyles();
  const { createJobHandler, isJobCreating } = useCreateJob();
  const { createCompanyAndUser, loading: isCreating } =
    useCreateCompanyHandler();
  const { onSubmit, loading } = useDefaultOnSubmitHandler({
    redirectRoute: nextStepRoute,
    onSuccess: async (values) => {
      const userId = await createCompanyAndUser(values);
      const finalValues = mergeDeep(values, {
        companyAccount: { id: userId } as any,
      });
      await createJobHandler(finalValues);
      localStorage.removeItem(LOCAL_STORAGE_KEYS.companyOnboardingDeviceId);
    },
  });

  const isLoadingFinal = isJobCreating || loading || isCreating;

  return (
    <StepTemplate
      title="Setup your account 🎉"
      subtitle="You’re almost done!"
      currentStep={index}
      currentStepLabel="Setup"
    >
      <Box className={classes.form}>
        <ConnectedTextField
          name={modelPath<BuilderFormState>((m) => m.companyAccount.firstName)}
          data-test-id="firstName"
          fullWidth
          variant="filled"
          label="First name"
        />

        <ConnectedTextField
          name={modelPath<BuilderFormState>((m) => m.companyAccount.lastName)}
          data-test-id="lastName"
          fullWidth
          variant="filled"
          label="Last name"
        />

        <ConnectedTextField
          name={modelPath<BuilderFormState>(
            (m) => m.companyAccount.companyName,
          )}
          data-test-id="companyName"
          fullWidth
          variant="filled"
          label="Company name"
        />

        <ConnectedTextField
          name={modelPath<BuilderFormState>((m) => m.companyAccount.email)}
          data-test-id="email"
          fullWidth
          variant="filled"
          label="Your Business Email"
        />
      </Box>

      <div className={classes.agreement}>
        <ConnectedCheckbox
          name={modelPath<BuilderFormState>((m) => m.companyAccount.agreement)}
          label={
            <>
              I’ve read and agree to the{' '}
              <OuterLink target="_blank" href={EXTERNAL_LINKS.privacyPolicy}>
                Privacy
              </OuterLink>{' '}
              and{' '}
              <OuterLink
                target="_blank"
                href={EXTERNAL_LINKS.termsAndConditions}
              >
                Terms&nbsp;&&nbsp;Conditions
              </OuterLink>
              .
            </>
          }
        />
      </div>
      {/*
      <Collapse in={isProfileCreated}>
        <Box className={classes.hint} maxWidth={350}>
          <Typography variant="caption">
            <Typography variant="caption" component="span" color="primary.main">
              To note:
            </Typography>{' '}
            your personal details has been saved. Login to OpenTalent to make
            changes.
          </Typography>
        </Box>
      </Collapse>

      <Collapse in={!isProfileCreated}>
        <Box className={classes.hint}>
          <Typography variant="caption">
            <Typography variant="caption" component="span" color="primary.main">
              To note:
            </Typography>{' '}
            Please check your personal info before you proceed to the next step.
            After clicking the ‘Next’ button, your profile will be created and
            you will only be able to make changes by logging in to your account
            on{' '}
            <OuterLink target="_blank" variant="caption" href="opentalent.co">
              opentalent.co
            </OuterLink>
            .
          </Typography>
        </Box>
      </Collapse> */}

      <FixedFooter>
        <Grid spacing={4} justifyContent="center" container>
          <Grid xs={6} item>
            <RouterButton
              startIcon={<ArrowBackIcon />}
              to={prevStepRoute}
              color="primary"
              variant="outlined"
              fullWidth
            >
              Back
            </RouterButton>
          </Grid>
          <Grid xs={6} item>
            <NextButtonWithValidation
              path={modelPath<BuilderFormState>((m) => m.companyAccount)}
              disabled={isLoadingFinal}
              onClick={onSubmit}
            >
              Finish
            </NextButtonWithValidation>
          </Grid>
        </Grid>
      </FixedFooter>
    </StepTemplate>
  );
};

export default AccountCreation;
