// https://openid.net/specs/openid-connect-core-1_0.html#StandardClaims
export enum UserRoles {
  Talent = 'talent',
  Company = 'company_user',
}

export interface UserInterface {
  name: string;
  given_name: string;
  family_name: string;
  middle_name: string;
  nickname: string;
  email: string;
  gender: string;
  locale: string;
  phone_number: string;
  phone_number_verified: boolean;
  address: string;
  picture: string;
  sub: string;
  'https://opentalent.co': {
    user_roles: UserRoles[];
  };
}
