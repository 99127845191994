import React from 'react';

import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { MatchQualityEnum } from '@libs/graphql-types';

interface MatchLabelProps {
  matchQuality: MatchQualityEnum;
}

const LABELS_MAP = {
  [MatchQualityEnum.Fair]: {
    title: 'Fair match',
    color: '#26CF85',
  },
  [MatchQualityEnum.Good]: {
    title: 'Good match',
    color: '#FFB400',
  },
  [MatchQualityEnum.Poor]: {
    title: 'Poor match',
    color: '#EF5350',
  },
};

const useStyles = makeStyles((theme) => ({
  root: {},
  indicatorWrapper: {
    alignItems: 'center',
    display: 'flex',
  },

  text: {
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    marginTop: 1,
  },

  indicator: {
    width: 10,
    height: 26,
    borderRadius: theme.spacing(2),
    background: ({ matchQuality }: MatchLabelProps) =>
      `${LABELS_MAP[matchQuality].color}`,
  },
}));

export const MatchLabel = (props: MatchLabelProps) => {
  const { matchQuality } = props;
  const classes = useStyles(props);
  const data = LABELS_MAP[matchQuality];

  return (
    <Grid className={classes.root} container spacing={2} wrap="nowrap">
      <Grid className={classes.indicatorWrapper} item>
        <div className={classes.indicator} />
      </Grid>
      <Grid className={classes.text} item>
        {data.title}
      </Grid>
    </Grid>
  );
};
