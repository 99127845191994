import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { Skeleton } from '@mui/lab';
import { Badge, IconButton, Popover, Fade } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useRef, useState } from 'react';

import NotificationsList from './NotificationsList';
import { useNotifications } from './hooks';

interface NotificationsProps {
  isLoading: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.grey[500],
  },
  badge: {
    minWidth: 12,
    height: 14,
    width: 14,
    fontSize: 9,
    padding: '0 4px',
  },
  skeleton: {
    backgroundColor: 'white',
    opacity: 0.1,
  },
  popover: {
    top: '65px !important',
    right: 0,
    left: 'auto !important',
  },
}));

const Notifications = (props: NotificationsProps) => {
  const ref = useRef<any>();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isLoading } = props;
  const classes = useStyles(props);

  const data = useNotifications() as any;
  const count = data?.currentUserUnreadNotificationsCount;
  const notifications = data?.currentUserNotifications?.data || [];

  if (isLoading) {
    return (
      <Skeleton
        className={classes.skeleton}
        variant="circular"
        width={30}
        height={30}
      />
    );
  }

  return (
    <div>
      <IconButton
        className={classes.root}
        onClick={() => setIsMenuOpen((s) => !s)}
        ref={ref as any}
        size="medium"
        data-test-id="notifications-btn"
      >
        <Badge
          classes={{ badge: classes.badge }}
          showZero
          color="primary"
          invisible={count < 1}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={count}
        >
          <NotificationsNoneIcon />
        </Badge>
      </IconButton>
      {ref.current && (
        <Popover
          PaperProps={{
            square: true,
          }}
          TransitionComponent={Fade}
          classes={{ paper: classes.popover }}
          open={isMenuOpen}
          anchorEl={ref.current}
          onClose={() => setIsMenuOpen(false)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <NotificationsList notifications={notifications} />
        </Popover>
      )}
    </div>
  );
};

export default Notifications;
