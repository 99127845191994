import React, { useContext, useEffect, useRef, useState } from 'react';

import { streamChatClient } from './consts';

interface DynamicChatDataProviderProps
  extends React.PropsWithChildren<unknown> { }

interface DynamicChatDataProviderValue {
  unreadMessageCount: number;
}

export const DynamicChatDataContext =
  React.createContext<DynamicChatDataProviderValue | null>(null);

export const useUnreadMessageCount = (): number => {
  const unsubscribe = useRef<VoidFunction | null>(null);
  const initialUnreadCount =
    (streamChatClient.user?.total_unread_count as number) || 0;
  const [unreadCount, setUnreadCount] = useState<number>(initialUnreadCount);

  useEffect(() => {
    unsubscribe.current = streamChatClient.on((event) => {
      const unreadCount =
        event.total_unread_count ?? event?.me?.total_unread_count;

      if (unreadCount !== undefined) {
        setUnreadCount(unreadCount);
      }
    }).unsubscribe;

    return () => {
      unsubscribe?.current?.();
    };
  }, []);

  return unreadCount;
};

export const DynamicChatDataProvider = ({
  children,
}: DynamicChatDataProviderProps) => {
  const unreadMessageCount = useUnreadMessageCount();
  const value: DynamicChatDataProviderValue = {
    unreadMessageCount,
  };

  return (
    <DynamicChatDataContext.Provider value={value}>
      {children}
    </DynamicChatDataContext.Provider>
  );
};

export const useDynamicChatDataContext = () => {
  const value = useContext(DynamicChatDataContext);

  if (!value) {
    throw new Error(`DynamicChatDataContext not initialized`);
  }

  return value;
};
