import { makeStyles } from '@mui/styles';

import { Theme } from '@libs/ui/themes/default';

import { CONTENT_MAX_WIDTH, DRAWER_WIDTH } from '../consts';
import { PageLayoutProps } from './PageLayout';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    background: '#EDEDED',
    maxWidth: '100%',
  },
  contentWrapper: {
    padding: theme.spacing(8, 6),
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: CONTENT_MAX_WIDTH,

    margin: ({ centered }: PageLayoutProps) =>
      centered ? '0 auto' : 'inherit',

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(6, 4),
    },
  },

  contentSkeleton: {
    height: '100%',
    width: '100%',
    flexGrow: 1,
  },
}));

export default useStyles;
