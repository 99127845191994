import React from 'react';

import Button, { ButtonProps } from '@libs/ui/components/button';

import { useSelectPremiumAction } from './hooks';

interface CompanyStripePaymentButtonProps
  extends React.PropsWithChildren<ButtonProps> {
  children: React.ReactNode;
}

const CompanyStripePaymentButton = ({
  children = 'Select',
  ...rest
}: CompanyStripePaymentButtonProps) => {
  const { makeRequest, loading } = useSelectPremiumAction();

  return (
    <Button
      size="large"
      rounded
      fullWidth
      variant="contained"
      color="info"
      disabled={loading}
      onClick={() => makeRequest()}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default CompanyStripePaymentButton;
