import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { ReactComponent as LogoIcon } from 'assets/icons/opentalent_dark.svg';
import PageLayout from 'components/layout/page-layout';
import React from 'react';

import { Box, Grow } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Typography from '@libs/ui/components/typography';

interface StepTemplateProps extends React.PropsWithChildren<unknown> { }

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',

    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2),
    },
  },

  contentWrapper: {
    overflow: 'hidden',
    margin: '0',
    width: '100%',
    maxWidth: '100%',
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
    justifyContent: 'center',
    padding: 0,
    paddingBottom: theme.spacing(18),

    '& .MuiFilledInput-root': {
      background: 'white',
    },
  },

  '@global': {
    '.MuiFilledInput-root': {
      border: 'none',
    },

    header: {
      backgroundColor: `${theme.palette.other.black} !important`,

      '&::before': {
        background: `${theme.palette.other.black} !important`,
      },
    },
  },
  fotmContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  wrapper: {
    maxWidth: 700,
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
  tickIcon: {
    marginBottom: -3,
  },
  mailEmoji: {
    fontSize: 60,
    lineHeight: '120%',
    textAlign: 'center',
  },
}));

const StepTemplate = (props: StepTemplateProps) => {
  const classes = useStyles();

  return (
    <PageLayout
      classes={{ contentWrapper: classes.contentWrapper }}
      documentTitle="Onboarding"
      headerProps={{
        accountProps: null,
        Logo: LogoIcon,
      }}
    >
      <div className={classes.root}>
        <Grow timeout={500} in>
          <Box
            className={classes.fotmContent}
            flexGrow={1}
            data-test-id="creatingScreen"
          >
            <Box mb={6}>
              <Typography variant="h3" fontWeight={600}>
                <Typography
                  component="span"
                  variant="h4"
                  fontStyle="italic"
                  fontWeight={700}
                >
                  Verification required
                </Typography>{' '}
                <CheckIcon
                  height={30}
                  width={30}
                  className={classes.tickIcon}
                />
              </Typography>
            </Box>
            <Box mb={6}>
              <Typography
                whiteSpace="break-spaces"
                textAlign="center"
              >{`We sent you an email.
Visit your inbox to verify your account
and access OpenTalent.`}</Typography>
            </Box>

            <Box className={classes.mailEmoji}>📨</Box>
          </Box>
        </Grow>
      </div>
    </PageLayout>
  );
};

export default StepTemplate;
