import FixedFooter from 'components/custom/onboarding/fixed-footer';
import React from 'react';
import { pathManager } from 'routes';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Grid } from '@mui/material';

import Button, { RouterButton } from '@libs/ui/components/button';
import Typography from '@libs/ui/components/typography';

import Container from '../shared/Container';

interface BookCallProps {}

const BookCall = (props: BookCallProps) => {
  return (
    <Container>
      <div style={{ maxWidth: 500 }}>
        <Typography textAlign="center" variant="h3" paragraph>
          📆 Book a call
        </Typography>

        <Box pb={20}>
          <Typography textAlign="center" variant="subtitle1">
            Let our Talent Matching Specialists help you to attract, source and
            hire the talent you need to drive your business forward.
          </Typography>
        </Box>

        <Grid container justifyContent="center">
          <Grid item>
            <Button style={{ width: 300 }} variant="contained" color="primary">
              BOOK A CALL NOW
            </Button>
          </Grid>
        </Grid>

        <FixedFooter>
          <Grid justifyContent="center" container>
            <Grid item>
              <RouterButton
                startIcon={<ArrowBackIcon />}
                to={pathManager.company.newOnboarding.greeting.generatePath()}
                color="primary"
                variant="outlined"
                style={{ width: 300 }}
              >
                next
              </RouterButton>
            </Grid>
          </Grid>
        </FixedFooter>
      </div>
    </Container>
  );
};

export default BookCall;
