import { useSearchParams } from 'hooks/routing';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import TopSteps from 'screens/company-user/choose-new-job-type/shared/TopSteps';

import { TabContext, TabPanel } from '@mui/lab';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useMediaQueries } from '@libs/helpers/hooks/media-queries';

import CompanyForm from './company';
import { Steps } from './types';
import UserForm from './user';

interface FormProps extends RouteComponentProps { }

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: '100%',
    minWidth: 700,
    margin: '0 auto',

    [theme.breakpoints.down('md')]: {
      minWidth: 'auto',
      width: '100%',
    },
  },
  content: {
    maxWidth: 500,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    [theme.breakpoints.down('md')]: {
      maxWidth: 'auto',
    },
  },

  tabPanel: {
    width: '100%',
  },

  steps: {
    marginBottom: theme.spacing(6),

    '& .MuiStepLabel-label': {
      color: 'white',
    },
    '& svg': {
      color: `${theme.palette.grey[700]} !important`,

      '&.Mui-active': {
        color: `${theme.palette.primary.main} !important`,
      },

      '& .MuiStepIcon-text': {
        fill: theme.palette.text.primary,
      },
    },
  },
}));

const STEPS = ['About You', 'About Your Company'];

const Form = ({ location, history }: FormProps) => {
  const classes = useStyles();
  const { step = 0 } = useSearchParams();
  const { isSM } = useMediaQueries();
  const currentStep = Number(step) as unknown as number;

  return (
    <Box className={classes.wrapper}>
      <TopSteps
        className={classes.steps}
        activeIndex={currentStep}
        steps={STEPS}
        onlyActiveLabel={isSM}
      />
      <Box className={classes.content}>
        <TabContext value={`${currentStep}`}>
          <TabPanel className={classes.tabPanel} value={Steps.Company}>
            <CompanyForm />
          </TabPanel>
          <TabPanel className={classes.tabPanel} value={Steps.User}>
            <UserForm />
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
};

export default Form;
