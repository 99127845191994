import { makeStyles } from '@mui/styles';

import { Theme } from '@libs/ui/themes/default';

import { SelectImageComponentProps } from './types';

interface StylesProps extends SelectImageComponentProps { }

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: 124,
    height: 124,
    display: 'flex',
    borderRadius: '100%',
    border: ({ error, dark }) => {
      if (error) {
        return `1px dashed ${theme.palette.error.main}`;
      }
      if (dark) {
        return `1px dashed white`;
      }

      return `1px dashed ${theme.palette.other.light}`;
    },
    alignItems: 'center',
    background: ({ dark }) => (dark ? 'transparent' : 'white'),
    cursor: 'pointer',
    position: 'relative',
    overflow: 'hidden',
    boxShadow: 'none',
    outline: 'none',
    color: ({ uploaded }: StylesProps) =>
      uploaded
        ? theme.palette.secondary.contrastText
        : theme.palette.other.light,
    transition: `all ${theme.transitions.duration.short}s ${theme.transitions.easing.easeInOut}`,

    '&:active, &:active $icon': {
      color: theme.palette.tertiary.main,
      borderColor: theme.palette.tertiary.main,
    },

    '&:hover $label': {
      opacity: 1,
    },
  },
  icon: {
    color: ({ dark }) => (dark ? 'white' : 'inherit'),
    transition: 'inherit',
  },
  label: {
    whiteSpace: 'break-spaces',
    position: 'relative',
    zIndex: 10,
    textAlign: 'center',
    transition: `all ${theme.transitions.duration.standard}s ${theme.transitions.easing.easeInOut}`,
    opacity: ({ uploaded }: StylesProps) => (uploaded ? 0 : 1),
    display: ({ uploaded }: StylesProps) => (uploaded ? 'none' : 'inherit'),
    color: ({ uploaded, dark }: StylesProps) => {
      if (uploaded) {
        return theme.palette.secondary.contrastText;
      }

      if (dark) {
        return 'white';
      }

      return theme.palette.other.light;
    },
  },
  input: {
    position: 'absolute',
    top: -5000000,
    left: -5000000,
    visibility: 'hidden',
  },
  preview: {
    objectFit: 'contain',
    imageOrientation: 'from-image',
    width: '100%',
    height: '100%',
  },
}));

export default useStyles;
