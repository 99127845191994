import { intervalToDuration, isPast, parseISO } from 'date-fns';

export const useIntervalTimer = ({
  start,
  end,
  currentTime,
}: {
  start: string;
  end: string;
  currentTime: Date;
}) => {
  if (!start) {
    return null;
  }

  const startFrom = isPast(parseISO(start)) ? currentTime.toISOString() : start;
  const endFrom = isPast(parseISO(end)) ? currentTime.toISOString() : end;

  return intervalToDuration({
    start: parseISO(startFrom),
    end: parseISO(endFrom),
  });
};
