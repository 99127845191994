import { SubCategorySelector } from 'components/custom/subcategory-selector';
import { TALENT_CATEGORIES } from 'graphql/talents';
import React, { useState } from 'react';

import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Query, TalentCategory } from '@libs/graphql-types';
import { modelPath } from '@libs/helpers/form';
import { ConnectedGraphSelect } from '@libs/ui/components/form/select';

import { BuilderFormState } from '../form-context/types';

interface CategoryFieldsProps {}

const useStyles = makeStyles((theme) => ({
  subcategory: {
    '&:empty': {
      display: 'none',
    },
  },
}));

const CategoryFields = (props: CategoryFieldsProps) => {
  const classes = useStyles();
  const [categories, setCategories] = useState<TalentCategory[]>([]);

  return (
    <>
      <Grid item>
        <ConnectedGraphSelect
          name={modelPath<BuilderFormState>((m) => m.jobCategory.category)}
          queryOptions={{
            fetchPolicy: 'network-only',
            onCompleted: (query: Query) =>
              setCategories((query?.talentCategories as any[]) || []),
          }}
          hideNoneValue
          query={TALENT_CATEGORIES}
          dataPath="talentCategories"
          dataMap={{ text: 'name', value: 'id' }}
          variant="filled"
          label="Category"
          fullWidth
        />
      </Grid>
      <Grid item className={classes.subcategory}>
        <SubCategorySelector
          categoryPath={modelPath<BuilderFormState>(
            (m) => m.jobCategory.category,
          )}
          name={modelPath<BuilderFormState>((m) => m.jobCategory.subcategory)}
          categories={categories}
          chipProps={{
            color: 'tertiary',
          }}
          autoCompleteProps={{
            filterSelectedOptions: true,
            popupIcon: null,
            multiple: false,
          }}
          inputProps={{
            variant: 'filled',
            label: 'Select sub-category',
            name: modelPath<BuilderFormState>((m) => m.jobCategory.subcategory),
          }}
        />
      </Grid>
    </>
  );
};

export default CategoryFields;
