import React from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Grid, Button, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { modelPath } from '@libs/helpers/form';
import { ConnectedTextField } from '@libs/ui/components/form/text-field';
import { DefaultModal } from '@libs/ui/components/modals';
import Typography from '@libs/ui/components/typography';

import { FormikState, StepProps } from '../types';

interface ProposalStep2Props extends StepProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 540,

    [theme.breakpoints.down('sm')]: {
      minWidth: 'auto',
    },
  },
}));

export const ProposalStep2 = ({
  onSubmit,
  onBack,
  isValid,
  close,
}: ProposalStep2Props) => {
  const classes = useStyles();

  return (
    <DefaultModal
      open
      handleClose={close}
      className={classes.root}
      title="Who are you presenting?"
      actions={
        <Grid spacing={4} container>
          <Grid xs={6} item>
            <Button
              fullWidth
              size="large"
              color="info"
              variant="contained"
              onClick={onBack}
              startIcon={<ArrowBackIcon />}
            >
              Back
            </Button>
          </Grid>
          <Grid xs={6} item>
            <Button
              endIcon={<ArrowForwardIcon />}
              fullWidth
              size="large"
              variant="outlined"
              color="info"
              onClick={onSubmit}
              disabled={!isValid}
            >
              Next step
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Box mt={6} mb={8}>
        <Typography textAlign="center" variant="body1" color="textSecondary">
          Please share candidate details below
        </Typography>
      </Box>

      <Grid spacing={4} container flexDirection="column">
        <Grid item>
          <ConnectedTextField
            name={modelPath<FormikState>((m) => m.step2.first_name)}
            fullWidth
            variant="filled"
            label="First name*"
          />
        </Grid>
        <Grid item>
          <ConnectedTextField
            name={modelPath<FormikState>((m) => m.step2.last_name)}
            fullWidth
            variant="filled"
            label="Last name*"
          />
        </Grid>
        <Grid item>
          <ConnectedTextField
            name={modelPath<FormikState>((m) => m.step2.email)}
            fullWidth
            variant="filled"
            type="email"
            label="Email*"
          />
        </Grid>
      </Grid>
    </DefaultModal>
  );
};
