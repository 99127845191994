import { useLazyQuery } from '@apollo/client';
import { useFormikContext } from 'formik';
import { SKILLS_BY_SLUG } from 'graphql/skills';
import React, { useMemo, useState, useCallback } from 'react';

import { debounce } from '@mui/material/utils';

import {
  Query,
  QuerySkillSearchArgs,
  SkillTypeEnum,
} from '@libs/graphql-types';
import ConnectedMultipleSelect from '@libs/ui/components/form/multiple-select/ConnectedMultipleSelect';
import { OptionType } from '@libs/ui/components/form/select';

interface DefaultSkillsControlProps {
  name?: string;
}

const COLORS_MAP = {
  [SkillTypeEnum.HardSkills]: 'success',
  [SkillTypeEnum.SoftSkills]: 'lightBlue',
  [SkillTypeEnum.Solutions]: 'tertiary',
};

export const DefaultSkillsControl = ({ name }: DefaultSkillsControlProps) => {
  const [chosen, setChosen] = useState<boolean>(false);
  const { handleSubmit } = useFormikContext();
  const [request, { data, loading }] = useLazyQuery<
    Query,
    QuerySkillSearchArgs
  >(SKILLS_BY_SLUG, { variables: { search: '' } });
  const onChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value?.length > 0) {
        await request({ variables: { search: e.target.value || '' } });
      }
      setChosen(false);
    },
    [request],
  );
  const debouncedOnChange = useMemo(() => debounce(onChange, 1000), [onChange]);
  const options: OptionType[] = (data?.skillSearch ?? []).map((skill) => ({
    ...skill,
    text: skill?.name,
    value: skill?.id,
  }));

  return (
    <ConnectedMultipleSelect
      inputProps={{
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          e.persist();
          debouncedOnChange(e);
        },
        variant: 'filled',
        label: 'E.g. Salesforce, Azure, ASP.NET...',
        placeholder: '',
        margin: 'dense',
      }}
      name={name || 'skills_ids'}
      options={chosen ? [] : options}
      autoCompleteProps={{
        onChange: () => {
          setChosen(true);
          handleSubmit();
        },
        loading,
        popupIcon: null,
      }}
      chipProps={(option) => ({
        size: 'small',
        color: COLORS_MAP[option.skill_type as SkillTypeEnum] as any,
      })}
    />
  );
};
