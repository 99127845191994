/* eslint-disable no-console */
import {
  ApolloClient,
  ApolloProvider as BaseApolloProvider,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/react';
import { Severity } from '@sentry/react';
import { createUploadLink } from 'apollo-upload-client';
import { useCurrentUserContext } from 'components/contexts/CurrentUserContext';
import { API_URI, isDev } from 'consts/config';
import { LOCAL_STORAGE_KEYS } from 'consts/ls-keys';
import { useAuth0 } from 'hooks/auth/useAuth0';
import { useSnackbar } from 'notistack';
import React from 'react';
import { ANONYMOUS_LOCATIONS } from 'routes';

import cache from './cache';

const SERVER_ERROR_MESSAGE = 'Internal server error';
// const ABN_ERROR_TEXT =
//   'Please contact ABN AMRO or OpenTalent support to get access to the application.';

const useHttpLink = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const { value: currentUser } = useCurrentUserContext();

  const authLink = setContext(async (_, { headers }) => {
    let token = '';
    const needToken =
      !ANONYMOUS_LOCATIONS.some((location) =>
        window.location.pathname.includes(location),
      ) || isAuthenticated;

    try {
      if (needToken) {
        token = await getAccessTokenSilently();
      }
    } catch (e) {
      console.error(e);
    }

    return {
      headers: {
        ...headers,
        ...(token && { authorization: `Bearer ${token}` }),
        ...(currentUser?.type && { 'Ot-Api-Consumer-Type': currentUser?.type }),
        ...(currentUser?.userId && {
          'Ot-Api-Consumer-Id': currentUser?.userId,
        }),
      },
    };
  });

  return authLink.concat(createUploadLink({ uri: API_URI }));
};

const useErrorLink = () => {
  const { enqueueSnackbar } = useSnackbar();

  return onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        const { message, locations, path } = error;
        enqueueSnackbar(message, { variant: 'error' });
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
            locations,
          )}, Path: ${path}`,
        );

        if (message === SERVER_ERROR_MESSAGE) {
          Sentry.captureException(new Error(`[API error]: ${message}`), {
            level: Severity.Error,
            extra: {
              operation: JSON.stringify(operation, null, 1),
              originalError: error,
              trace: (error as any).trace,
            },
          });
        }
      });
    }

    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
      // const statusCode = (networkError as any).statusCode;
      const message =
        (networkError as any).result.message || networkError.message;

      localStorage.setItem(LOCAL_STORAGE_KEYS.authError, message);
      enqueueSnackbar(message, { variant: 'error' });
    }
  });
};

const useApolloClient = () => {
  const httpLink = useHttpLink();
  const errorLink = useErrorLink();

  return new ApolloClient({
    link: from([errorLink, httpLink]),
    uri: API_URI,
    cache,
    connectToDevTools: isDev,
  });
};

const ApolloProvider = ({
  children,
}: React.PropsWithChildren<Record<string, unknown>>) => {
  const client = useApolloClient();
  return <BaseApolloProvider client={client}>{children}</BaseApolloProvider>;
};

export default ApolloProvider;
