import { DocumentNode, useQuery } from '@apollo/client';

import { useQueryParamsToLoadVariables } from '../hooks';
import { ViewTypes } from '../types';
import { GET_JOBS_COUNT_MATCHER, GET_JOBS_COUNT_TALENT } from './queries';

const QERIES: Record<ViewTypes, DocumentNode> = {
  [ViewTypes.Talent]: GET_JOBS_COUNT_TALENT,
  [ViewTypes.Matcher]: GET_JOBS_COUNT_MATCHER,
};
export const getCountText = (count?: number): string =>
  count !== undefined ? `(${count})` : '';

export const useLoadTabsCount = (currentView: ViewTypes) => {
  const variables = useQueryParamsToLoadVariables(currentView);
  const query = QERIES[currentView];
  const { data, loading, refetch } = useQuery(query, {
    variables,
    fetchPolicy: 'no-cache',
  });
  const openCount = data?.open?.paginatorInfo?.total;
  const closedCount = data?.closed?.paginatorInfo?.total;
  const savedCount = data?.saved?.paginatorInfo?.total;
  const externalCount = data?.external?.paginatorInfo?.total;
  const savedExternalCount = data?.savedExternal?.paginatorInfo?.total;
  const applicationsCount = data?.applications?.paginatorInfo?.total;
  const recommendedCount = data?.recommended?.paginatorInfo?.total;

  return {
    openCount,
    closedCount,
    savedCount,
    savedExternalCount,
    applicationsCount,
    loading,
    externalCount,
    recommendedCount,

    refetchJobCounts: refetch,
  };
};
