import { Formik } from 'formik';
import React from 'react';

import { Box, CircularProgress } from '@mui/material';

import { noop } from '@libs/helpers/common';

import BuilderErrorCheck from '../builder-error-check';
import { useInitialState } from './hooks';
import { BuilderFormState } from './types';
import { getValidator } from './validator';

interface FormContextProps extends React.PropsWithChildren<unknown> {}

const FormContext = ({ children }: FormContextProps) => {
  const { loading, initialValues } = useInitialState();

  if (loading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Formik<BuilderFormState>
      onSubmit={noop}
      initialValues={initialValues}
      validationSchema={getValidator()}
      validateOnMount
      enableReinitialize
    >
      <>
        {children}
        <BuilderErrorCheck />
      </>
    </Formik>
  );
};

export default FormContext;
