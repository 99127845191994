import { ReactComponent as MapPinIcon } from 'assets/icons/map-pin.svg';
import React from 'react';

import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Talent } from '@libs/graphql-types';
import { formatCurrency } from '@libs/helpers/format';

interface MainInfoProps {
  talent: Talent;
}

const useStyles = makeStyles((theme) => ({
  value: {
    fontWeight: 400,
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
}));

const MainInfo = ({ talent }: MainInfoProps) => {
  const classes = useStyles();
  const location = talent.location;
  const rate = talent.rate;

  if (!rate && !location) {
    return <></>;
  }

  return (
    <Grid container spacing={4}>
      {!!talent.rate && (
        <Grid item>
          <Typography color="text.secondary" variant="subtitle2">
            Hourly Rate:{' '}
            <span className={classes.value}>{formatCurrency(talent.rate)}</span>
          </Typography>
        </Grid>
      )}
      {talent.location && (
        <Grid className={classes.noWrap} item>
          <Grid spacing={1} container>
            <Grid item>
              <MapPinIcon width={15} />
            </Grid>
            <Grid item>
              <Typography
                component="span"
                fontWeight={500}
                color="text.secondary"
                variant="subtitle2"
              >
                {talent.location}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default MainInfo;
