import { TalentModals } from 'components/custom/talent/types';
import React from 'react';
import { DefaultModalProps, withLocationStateModal } from 'utils/modals';

import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { DefaultModal } from '@libs/ui/components/modals';
import Typography from '@libs/ui/components/typography';

import girlImg from './girl@2x.png';

interface LobbyPaidPopupProps extends DefaultModalProps<true> { }

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(9, 0, 0),
  },
}));

const LobbyPaidPopupComponent = ({ isOpen, close }: LobbyPaidPopupProps) => {
  const classes = useStyles();

  return (
    <DefaultModal
      handleClose={close}
      open={isOpen}
      className={classes.root}
      title={
        <Box mb={8}>
          <Typography variant="h5" paragraph>
            Welcome To The Club.{' '}
          </Typography>
        </Box>
      }
      actions={null}
    >
      <Box display="flex" justifyContent="center">
        <img srcSet={`${girlImg} 2x`} alt="allo" />
      </Box>
    </DefaultModal>
  );
};

export const LobbyPaidPopup = withLocationStateModal({
  id: TalentModals.PaidTalentWelcome,
})(LobbyPaidPopupComponent);
