import { Box } from '@mui/material';
import { ConnectedPageLayout } from 'components/layout/page-layout';
import React from 'react';

import Typography from '@libs/ui/components/typography';

export const NotFound = () => {
  return (
    <ConnectedPageLayout
      documentTitle="Page not found"
      headerProps={{ accountProps: {} }}
      drawerProps={{}}
    >
      <Box pt="60px">
        <Typography variant="h1">404.</Typography>
        <Typography variant="h3">Page not found</Typography>
      </Box>
    </ConnectedPageLayout>
  );
};
