import { ReactComponent as PhotoIcon } from 'assets/icons/photo.svg';
import cn from 'classnames';
import React from 'react';

import { Grid } from '@mui/material';

import Typography from '@libs/ui/components/typography';

import useStyles from './styles';
import { SelectImageComponentProps } from './types';

const SelectImageComponent = (props: SelectImageComponentProps) => {
  const classes = useStyles(props);
  const { label, Icon = PhotoIcon, imgSrc, name, onChange } = props;

  return (
    <label className={cn(classes.container)}>
      {imgSrc && <img className={classes.preview} src={imgSrc} alt="Preview" />}
      <Grid
        className={classes.label}
        justifyContent="center"
        alignItems="center"
        direction="column"
        container
      >
        <Grid item>
          <Icon className={classes.icon} />
        </Grid>
        <Grid item>
          <Typography variant="overline" className={classes.label}>
            {label}
          </Typography>
        </Grid>
      </Grid>
      <input
        onChange={(e) => {
          const file = e.target.files?.[0];
          onChange?.(file || null);
        }}
        className={classes.input}
        type="file"
        name={name}
      />
    </label>
  );
};

export default SelectImageComponent;
