import { MESSAGE_ACTIONS } from 'stream-chat-react';

export const DEFAULT_MASSAGE_ACTIONS_LIST = [
  MESSAGE_ACTIONS.edit,
  MESSAGE_ACTIONS.delete,
  MESSAGE_ACTIONS.mute,
  MESSAGE_ACTIONS.react,
  MESSAGE_ACTIONS.reply,
  MESSAGE_ACTIONS.quote,
];
