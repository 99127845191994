import { makeStyles } from '@mui/styles';

import { Theme } from '@libs/ui/themes/default';

import { DrawerListItemProps } from './DrawerListItem';

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    ...theme.typography.subtitle2,
    fontWeight: theme.typography.fontWeightMedium as any,
    color: 'inherit',
  },
  icon: {
    color: 'inherit',
  },
  caret: {
    color: theme.palette.secondary.contrastText,
  },
  badge: {
    right: 26,
    background: theme.palette.warning.dark,
    color: 'white',
  },
  listItem: {
    // pointerEvents: ({ disabled }: DrawerListItemProps) =>
    //   disabled ? 'none' : undefined,

    color: ({ isActive, disabled }: DrawerListItemProps) => {
      if (disabled) {
        return 'rgba(0, 0, 0, 0.38)';
      }

      return isActive
        ? theme.palette.text.primary
        : theme.palette.text.secondary;
    },
    background: ({ isActive }: DrawerListItemProps) =>
      isActive ? 'rgba(30, 30, 28, 0.08)' : undefined,

    '&::before': {
      content: ({ isActive }: DrawerListItemProps) => (isActive ? "''" : ''),
      width: 3,
      height: theme.spacing(8),
      background: theme.palette.tertiary.main,
      borderTopRightRadius: 2,
      borderBottomRightRadius: 2,
      position: 'absolute',
      left: 0,
    },
  },
}));

export default useStyles;
