import { useFormikContext } from 'formik';
import useMediaQueries from 'hooks/common/useMediaQueries';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { pathManager } from 'routes';
import { getCountText } from 'screens/talent-matcher/job-board/content/hooks';
import { JobMarkType } from 'types';

import TuneIcon from '@mui/icons-material/Tune';
import { TabContext, TabList } from '@mui/lab';
import { Box, Button, Grid, Hidden, Tab } from '@mui/material';

import { modelPath } from '@libs/helpers/form';

import { FilterForm, JobTabType } from '../types';

interface ControlsProps {
  openDrawer: VoidFunction;
  applicationsCount?: number;
  savedCount?: number;
  recommendedCount?: number;
  totalCount?: number;
}

const Controls = ({
  openDrawer,
  applicationsCount,
  totalCount,
  savedCount,
  recommendedCount,
}: ControlsProps) => {
  const location = useLocation();
  const history = useHistory();
  const { isMD, isSM } = useMediaQueries();
  const { values, setFieldValue } = useFormikContext<FilterForm>();
  const isActive = values.is_active ?? true;

  return (
    <Box pb={4}>
      <Grid
        container
        wrap={isMD ? 'wrap' : 'nowrap'}
        alignItems="center"
        spacing={2}
        justifyContent="space-between"
      >
        <Hidden lgUp>
          <Grid style={{ flexGrow: 1, width: isSM ? '100%' : 'inherit' }} item>
            <Button onClick={openDrawer} endIcon={<TuneIcon />}>
              Search Filter
            </Button>
          </Grid>
        </Hidden>
        <Grid style={{ maxWidth: '100%' }} item>
          <TabContext value={String(isActive)}>
            <TabList
              indicatorColor="secondary"
              textColor="secondary"
              color="info"
              variant="scrollable"
              // scrollButtons="auto"
              allowScrollButtonsMobile
            >
              <Tab
                onClick={() =>
                  setFieldValue(
                    modelPath<FilterForm>((m) => m.is_active),
                    true,
                  )
                }
                color="info"
                label={`Total ${getCountText(totalCount)}`}
                value="true"
              />
              <Tab
                onClick={() => {
                  history.push({
                    state: { jobBoardSearch: location.search },
                    pathname: pathManager.talent.markedJobs.generatePath({
                      type: JobMarkType.Recommended,
                    }),
                  });
                }}
                color="secondary"
                label={`Recommended ${getCountText(recommendedCount)}`}
                value={JobTabType.Recommended}
              />
              <Tab
                onClick={() => {
                  history.push({
                    state: { jobBoardSearch: location.search },
                    pathname: pathManager.talent.markedJobs.generatePath({
                      type: JobMarkType.Applied,
                    }),
                  });
                }}
                color="info"
                label={`My applications ${getCountText(applicationsCount)}`}
              />
              <Tab
                onClick={() => {
                  history.push({
                    state: { jobBoardSearch: location.search },
                    pathname: pathManager.talent.markedJobs.generatePath({
                      type: JobMarkType.Saved,
                    }),
                  });
                }}
                color="info"
                label={`Saved jobs ${getCountText(savedCount)}`}
              />
            </TabList>
          </TabContext>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Controls;
