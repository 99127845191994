import React, { useContext, useState } from 'react';

import { CompanyUserOnboardingTypeEnum } from '@libs/graphql-types';

import { useReferralLink } from './hooks';

interface OldCompanyOnboardingProviderProps
  extends React.PropsWithChildren<unknown> {}

export interface OldCompanyOnboardingProviderValue {
  user: any;
  company: any;
}

interface OldCompanyOnboardingProviderState {
  value: OldCompanyOnboardingProviderValue;
  setValue: React.Dispatch<
    React.SetStateAction<OldCompanyOnboardingProviderValue>
  >;
}

export const OldCompanyOnboardingContext =
  React.createContext<OldCompanyOnboardingProviderState | null>(null);

export const OldCompanyOnboardingProvider = ({
  children,
}: OldCompanyOnboardingProviderProps) => {
  const referral_key = useReferralLink();
  const initialValue: OldCompanyOnboardingProviderValue = {
    user: {
      referral_key,
      onboarding_type: CompanyUserOnboardingTypeEnum.Simple,
    },
    company: {},
  };
  const [value, setValue] = useState(initialValue);

  return (
    <OldCompanyOnboardingContext.Provider value={{ value, setValue }}>
      {children}
    </OldCompanyOnboardingContext.Provider>
  );
};

export const useOldCompanyOnboardingContext = () => {
  const value = useContext(OldCompanyOnboardingContext);

  if (!value) {
    throw new Error(`OldCompanyOnboardingContext not initialized`);
  }

  return value;
};
