import React from 'react';

import { Box, Grid, Paper } from '@mui/material';

import { Job } from '@libs/graphql-types';
import { SkillChip } from '@libs/ui/components/chip';
import { getRawBooleanSkills } from '@libs/ui/components/job/utils';
import Typography from '@libs/ui/components/typography';

interface BooleanSkillsProps {
  job?: Job;
}

const BooleanSkills = ({ job }: BooleanSkillsProps) => {
  const skills = getRawBooleanSkills(job?.skills_boolean_filter);

  return (
    <Paper elevation={0}>
      <Box p={4}>
        <Typography paragraph variant="h6">
          Key skills
        </Typography>
        <Box pb={3}>
          <Grid component={Box} pt={2} spacing={2} container>
            {skills.map((skill) => (
              <Grid key={skill.id} item>
                <SkillChip size="small" skill={skill} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Paper>
  );
};

export default BooleanSkills;
