import { gql } from '@apollo/client';

export default gql`
  mutation UpdateCompany(
    $company_id: ID!
    $name: String
    $address: String
    $type_of_activity: String
    $logo: String
    $website: String
    $country: String
    $about: String
  ) {
    updateCompany(
      company_id: $company_id
      country: $country
      about: $about
      name: $name
      address: $address
      type_of_activity: $type_of_activity
      logo: $logo
      website: $website
    ) {
      id
      name
    }
  }
`;
