const ERRORS = {
  required: 'Required',
  invalidEmail: 'Invalid email',
  emailIsTaken: 'This email is already taken',
  invalidUrl: 'Invalid URL',
  invalidVat: 'Invalid VAT number',
  invalidPhone: 'Invalid phone',

  invalidNumber: 'Invalid number',

  onlyDigits: 'Must be only digits',
  positiveNumbers: 'Must be positive number',

  maxCharactersLength: (n: number | string) => `Maximum ${n} characters`,
  maxOptionsLength: (n: number | string) => `Maximum ${n} items`,
  minOptionsLength: (n: number | string) => `Minimum ${n} items`,

  greaterThen: (n: number | string) => `Must be greater than ${n}`,
  lessThen: (n: number | string) => `must be less than or equal ${n}`,

  invalidDate: 'Invalid date',
  invalidIntervalDate: 'Invalid interval date',
  presentDate: 'Must be present date',

  mandatoryJobSkillsNumber: ({ min = 2, max = 6 } = {}) =>
    `Enter up to ${max} entries with a min. of ${min}`,
};

export default ERRORS;
