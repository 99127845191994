import { mergeDeep } from '@apollo/client/utilities';
import React from 'react';

import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
} from '@mui/lab';
import { makeStyles } from '@mui/styles';

import TextField, {
  TextFieldProps as BaseTextFieldProps,
} from '@libs/ui/components/form/text-field';

export interface DatePickerProps
  extends Omit<MuiDatePickerProps, 'renderInput'> {
  TextFieldProps?: BaseTextFieldProps;
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialogActions-root button': {
      color: theme.palette.secondary.main,
    },
  },
}));

const DatePicker = (props: DatePickerProps) => {
  const { TextFieldProps } = props;
  const classes = useStyles();

  return (
    <MuiDatePicker
      {...props}
      OpenPickerButtonProps={{ color: 'secondary' }}
      DialogProps={{ classes: { paper: classes.root } }}
      renderInput={(inputProps) => {
        return (
          <TextField
            fullWidth
            {...mergeDeep(inputProps, TextFieldProps as any)}
          />
        );
      }}
    />
  );
};

export default DatePicker;
