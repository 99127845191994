import { useCurrentUser } from 'hooks/auth';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { pathManager } from 'routes/consts';

export const useCheckUserRedirect = () => {
  const { getData } = useCurrentUser();
  const history = useHistory();

  useEffect(() => {
    const { isAuthorized, isCompany, isTalent } = getData();

    if (!isAuthorized) {
      return;
    }

    if (isCompany) {
      history.push(pathManager.company.jobBoard.generatePath());
    }

    if (isTalent) {
      history.push(pathManager.talent.jobBoard.generatePath());
    }
  }, [getData, history]);
};
