import cn from 'classnames';
import { ChatSidebarItemDumb } from 'components/chat/common/chat-sidebar';
import React from 'react';

import { makeStyles } from '@mui/styles';

import { UserToTalentChatMeta } from '../../types';
import { ChatSidebarItemProps } from './types';

interface ChatTalentWithCompanyProps extends ChatSidebarItemProps {}

const useStyles = makeStyles((theme) => ({
  companyName: {
    color: theme.palette.text.secondary,
    fontSize: 10,
    lineHeight: '10px',
  },

  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  primaryBlock: {
    marginBottom: theme.spacing(1),
  },

  userName: {
    marginRight: theme.spacing(11),
  },
}));

const ChatTalentWithCompany = ({
  channel,
  latestMessage,
  active = false,
  unread,
  onChannelSelect,
}: ChatTalentWithCompanyProps) => {
  const classes = useStyles();
  const details = channel.data?.channelDetails as UserToTalentChatMeta;
  const { image, name, companyName } = details.companyUser || {};
  const lastMessageTime = channel?.state?.last_message_at as Date | undefined;

  return (
    <ChatSidebarItemDumb
      date={lastMessageTime}
      avatar={image}
      onChannelSelect={onChannelSelect}
      primaryContent={
        <div className={cn(classes.primaryBlock, classes.ellipsis)}>
          <div className={classes.userName}>{name}</div>
          <div className={classes.companyName}>{companyName}</div>
        </div>
      }
      secondaryContent={latestMessage}
      unread={unread}
      active={active}
    />
  );
};

export default ChatTalentWithCompany;
