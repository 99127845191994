import { GET_CURRENT_TALENT } from 'graphql/talents';
import { useSnackbar } from 'notistack';
import React from 'react';

import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';

import {
  Company,
  useApplyCurrentTalentToCompanyPoolMutation,
} from '@libs/graphql-types';
import { noop } from '@libs/helpers/common';
import Button from '@libs/ui/components/button';

interface ConnectTalentToCompanyPoolActionProps {
  company: Company;
  refetch?: VoidFunction;
  disabled?: boolean;
}

export const ConnectTalentToCompanyPoolAction = ({
  refetch = noop,
  disabled = false,
  company,
}: ConnectTalentToCompanyPoolActionProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [apply, { loading }] = useApplyCurrentTalentToCompanyPoolMutation({
    variables: { company_id: company!.id },
    refetchQueries: [
      {
        query: GET_CURRENT_TALENT,
      },
    ],
    onCompleted: () => {
      refetch();
      enqueueSnackbar('Your request has been sent to the company.', {
        variant: 'success',
      });
    },
  });

  return (
    <Button
      onClick={apply as VoidFunction}
      variant="outlined"
      size="medium"
      color="info"
      startIcon={<PersonAddAlt1OutlinedIcon />}
      disabled={loading || disabled}
    >
      Apply
    </Button>
  );
};
