import React from 'react';
import { Link } from 'react-router-dom';

import { Box, Grow } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Button from '@libs/ui/components/button';
import Typography from '@libs/ui/components/typography';

interface GreetingProps {
  link: string;
}

const useStyles = makeStyles(() => ({
  wrapper: {
    maxWidth: 500,
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

const Greeting = ({ link }: GreetingProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      <Grow in timeout={500}>
        <div>
          <Typography
            fontWeight={600}
            textAlign="center"
            variant="h4"
            paragraph
            fontStyle="italic"
          >
            Welcome to OpenTalent <span role="img">🎉</span>
          </Typography>
          <Box px={4}>
            <Typography
              whiteSpace="break-spaces"
              textAlign="center"
              variant="body2"
              paragraph
            >
              Europe’s #1 talent-centric platform.
            </Typography>
          </Box>
          <Box textAlign="center" pt={2}>
            <Link to={link}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                style={{ width: 290 }}
                size="large"
              >
                Get access now
              </Button>
            </Link>
          </Box>
        </div>
      </Grow>
    </Box>
  );
};

export default Greeting;
