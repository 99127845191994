import React from 'react';

import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Button from '@libs/ui/components/button';
import Typography, { OuterLink } from '@libs/ui/components/typography';

import Container from '../shared/Container';
import { useMeetingLink } from '../shared/hooks';

interface FinalProps { }

const useStyles = makeStyles((theme) => ({
  root: { maxWidth: 500 },

  title: {
    [theme.breakpoints.down('md')]: {
      fontSize: 32,
      lineHeight: '40px',
    },
  },
}));

const Final = (props: FinalProps) => {
  const classes = useStyles();
  const link = useMeetingLink(
    'https://www.meeting.opentalent.co/meetings/sanjib-deka/onboarding',
  );

  return (
    <Container>
      <div className={classes.root}>
        <Typography
          className={classes.title}
          textAlign="center"
          variant="h3"
          paragraph
        >
          🥳{' '}
          <Typography
            className={classes.title}
            variant="h3"
            component="span"
            fontStyle="italic"
            fontWeight={600}
          >
            Congratulations!
          </Typography>
        </Typography>

        <Box pb={10}>
          <Typography textAlign="center" variant="subtitle1">
            Your job is ready to be published.
          </Typography>
        </Box>

        <Box maxWidth={350} margin="0 auto">
          <Box pb={6}>
            <Typography color="primary.main" textAlign="center" variant="h5">
              So what’s next?
            </Typography>
          </Box>
          <Typography variant="subtitle1" paragraph>
            Now please{' '}
            <OuterLink
              href={link}
              target="_blank"
              variant="subtitle1"
              color="info.main"
              style={{ textDecoration: 'underline' }}
            >
              book a 15-min ‘Intake’ call
            </OuterLink>{' '}
            to;
          </Typography>

          <ol style={{ paddingLeft: 14, marginBottom: 24 }}>
            <Typography component="li" variant="subtitle1">
              Review / optimise your first compaign{' '}
              <Typography
                component="span"
                variant="subtitle1"
                style={{ opacity: 0.7 }}
              >
                (e.g. add missing data, job description...)
              </Typography>
            </Typography>

            <Typography component="li" variant="subtitle1">
              Share instant candidate matches
            </Typography>

            <Typography component="li" variant="subtitle1">
              Explain our ‘No Cure No Pay’ model
            </Typography>

            <Typography component="li" variant="subtitle1">
              Assign you a Recruiter.
            </Typography>
          </ol>

          <Grid container justifyContent="center">
            <Grid item>
              <Button
                style={{ width: 236 }}
                variant="contained"
                color="primary"
                href={link}
                {...{ target: '_blank' }}
              >
                BOOk YOUR CALL
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box pt={8} textAlign="center">
          <Typography variant="caption">
            <Typography variant="caption" component="span" color="primary.main">
              To note:
            </Typography>{' '}
            in order to protect the quality of jobs on OpenTalent, Intake calls
            are mandatory for all new companies.
          </Typography>
        </Box>
        <Typography
          textAlign="center"
          style={{ maxWidth: 470, paddingTop: 24, margin: '0 auto' }}
          variant="body2"
          color="rgba(255, 255, 255, 0.7);"
        >
          ✉️ We also sent you an email to access your OpenTalent account. Check
          it out after booking your Intake call.
        </Typography>
      </div>
    </Container>
  );
};

export default Final;
