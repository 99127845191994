import React from 'react';

import { AcceptTalentModal } from './AcceptTalentModal';
import { RejectTalentModal } from './RejectTalentModal';
import { RemoveTalentModal } from './RemoveTalentModal';

interface AllCompanyToTalentModalsProps {
  onSuccess: VoidFunction;
  modalKey: string;
}

export const AllCompanyToTalentModals = (
  props: AllCompanyToTalentModalsProps,
) => {
  return (
    <div>
      <AcceptTalentModal
        onSuccess={props.onSuccess}
        modalKey={props.modalKey}
      />
      <RemoveTalentModal modalKey={props.modalKey} />
      <RejectTalentModal
        onSuccess={props.onSuccess}
        modalKey={props.modalKey}
      />
    </div>
  );
};
