import React from 'react';
import { Link } from 'react-router-dom';

import { ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { BottomDrawerListItemProps } from './types';

const useStyles = makeStyles((theme) => ({
  text: {
    ...theme.typography.subtitle2,
    fontWeight: theme.typography.fontWeightMedium as any,
  },
  icon: {
    '& svg': {
      width: 24,
      height: 24,
    },
  },
  listItem: {
    display: 'flex',
    color: theme.palette.text.secondary,
    opacity: ({ disabled }: BottomDrawerListItemProps) => (disabled ? 0.6 : 1),
  },
}));

export const BottomDrawerListItem = (props: BottomDrawerListItemProps) => {
  const { Icon, htmlTitle = '', text, to, href, onClick, disabled } = props;
  const classes = useStyles(props);
  const Wrapper = disabled ? 'div' : !!to ? Link : !!href ? 'a' : 'span';

  return (
    <Wrapper
      {...(!!href && { href, target: '_blank' })}
      {...(!!to && ({ to } as any))}
      {...{ onClick }}
    >
      <Tooltip title={htmlTitle} placement="right">
        <ListItem classes={{ root: classes.listItem }} button>
          <ListItemIcon classes={{ root: classes.icon }}>
            <Icon color="inherit" />
          </ListItemIcon>
          <ListItemText
            disableTypography
            classes={{ root: classes.text }}
            primary={text}
          />
        </ListItem>
      </Tooltip>
    </Wrapper>
  );
};
