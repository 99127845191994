import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import React from 'react';
import {
  DefaultModalProps,
  useOpenModal,
  withLocationStateModal,
} from 'utils/modals';

import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  GetCurrentUserCompanyTagsDocument,
  Talent,
  useApproveTalentCompanyPoolApplicationMutation,
  useSetTagsToTalentMutation,
} from '@libs/graphql-types';
import { modelPath } from '@libs/helpers/form';
import { FormikSubmit } from '@libs/helpers/form';
import Button from '@libs/ui/components/button';
import { GraphConnectedMultipleSelect } from '@libs/ui/components/form/multiple-select';
import { OptionType } from '@libs/ui/components/form/select';
import { DefaultModal } from '@libs/ui/components/modals';

interface ModalStateData {
  talent: Talent;
}

interface LimitedProfileAccessModalProps
  extends DefaultModalProps<ModalStateData> {
  onSuccess: VoidFunction;
}

interface FormikState {
  tags: OptionType[];
}

const useStyles = makeStyles((theme) => ({
  modal: {
    width: '540px',

    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
}));

const useSubmitAction = ({
  talent,
  onSuccess,
}: {
  talent: Talent | undefined;
  onSuccess: () => void;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const talentId = talent?.id;
  const [approve, { loading: isApproveing }] =
    useApproveTalentCompanyPoolApplicationMutation({
      variables: { talent_id: talent?.id || '' },
      onCompleted: () => {
        enqueueSnackbar('Talent has been added to your community.', {
          variant: 'success',
        });
      },
    });
  const [setTalentTags, { loading }] = useSetTagsToTalentMutation();

  const onSubmit: FormikSubmit<FormikState> = async ({ tags }) => {
    if (!talentId) {
      enqueueSnackbar('No talent id provided', { variant: 'error' });
      return;
    }
    const tagIds = tags.map((i) => String(i.value));
    const tagsResult = await setTalentTags({
      variables: { talent_id: talentId, tags_ids: tagIds },
    });
    const tagsUpdateSuccess = tagsResult.data?.setTagsToTalent;
    if (!tagsUpdateSuccess) {
      enqueueSnackbar('Could not change talent tags', { variant: 'error' });
    }

    const result = await approve();
    const success = result.data?.approveTalentCompanyPoolApplication;
    if (!success) {
      enqueueSnackbar('Could not approve talent', { variant: 'error' });
    }

    if (true && tagsUpdateSuccess) {
      onSuccess();
    }
  };

  return { isLoading: isApproveing || loading, onSubmit };
};

const AcceptTalentModalComponent = ({
  modalData,
  isOpen,
  close,
  onSuccess,
}: LimitedProfileAccessModalProps) => {
  const classes = useStyles();
  const talent = modalData?.talent;
  const { isLoading, onSubmit } = useSubmitAction({
    talent,
    onSuccess: () => {
      onSuccess();
      close();
    },
  });

  return (
    <Formik<FormikState> onSubmit={onSubmit} initialValues={{ tags: [] }}>
      {({ submitForm }) => (
        <DefaultModal
          handleClose={close}
          open={isOpen}
          className={classes.modal}
          title="Verify talent for your community"
          actions={
            <Grid spacing={4} container>
              <Grid xs={6} item>
                <Button
                  fullWidth
                  size="large"
                  color="info"
                  variant="contained"
                  disabled={isLoading}
                  onClick={submitForm}
                >
                  Verify talent
                </Button>
              </Grid>
              <Grid xs={6} item>
                <Button
                  fullWidth
                  size="large"
                  variant="outlined"
                  color="info"
                  onClick={close}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          }
        >
          <Box mt={6}>
            <GraphConnectedMultipleSelect
              name={modelPath<FormikState>((m) => m.tags)}
              query={GetCurrentUserCompanyTagsDocument}
              dataPath="currentUserCompanyTags"
              dataMap={{ text: 'name', value: 'id' }}
              chipProps={{
                color: 'tertiary',
                size: 'small',
              }}
              autoCompleteProps={{
                isOptionEqualToValue: (
                  option: OptionType,
                  value: OptionType,
                ) => {
                  return value.value === option.value;
                },
                filterSelectedOptions: true,
              }}
              inputProps={{
                variant: 'filled',
                label: 'Add tags for internal use',
                placeholder: '',
                margin: 'dense',
                fullWidth: true,
              }}
            />
          </Box>
        </DefaultModal>
      )}
    </Formik>
  );
};
AcceptTalentModalComponent.id = 'AcceptTalentModal';

export const AcceptTalentModal = withLocationStateModal<ModalStateData>({
  id: AcceptTalentModalComponent.id,
  getModalKey: (data) => data?.talent?.id,
})(AcceptTalentModalComponent);

export const useOpenAcceptTalentModal = () =>
  useOpenModal(AcceptTalentModalComponent.id);
