import { useFormikContext, getIn } from 'formik';
import React from 'react';

import { Box, Card, CardContent } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { modelPath } from '@libs/helpers/form';
import Button from '@libs/ui/components/button';
import Select from '@libs/ui/components/form/select';
import { ConnectedTextField } from '@libs/ui/components/form/text-field';
import Typography, { OuterLink } from '@libs/ui/components/typography';

import { OnboardingWrapper } from '../../shared';
import { OnboardingV4State } from '../../types';
import { ReactComponent as OpentalentLogo } from '../asstes/opentalent-logo.svg';

interface GeneralProps {
  loading: boolean;
}

export const useStyles = makeStyles((theme) => ({
  card: {
    background: '#1E1E1A',
    textAlign: 'center',
    borderRadius: theme.spacing(4),
    maxWidth: 610,

    '& a': {
      textDecoration: 'underline',
    },
  },
  cardContent: {
    padding: `${theme.spacing(14)} !important`,

    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacing(6)} !important`,
    },
  },

  fieldsWrapper: {
    width: 500,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  footer: {
    position: 'absolute',
  },
  btn: {
    borderRadius: 34,

    'a&': {
      color: `${theme.palette.text.primary} !important`,
    },

    '&.Mui-disabled': {
      background: '#BDBDBD',
    },
  },
}));

export const General = ({ loading }: GeneralProps) => {
  const classes = useStyles();
  const { errors, handleSubmit, values } = useFormikContext();
  const currentErrors = getIn(
    errors,
    modelPath<OnboardingV4State>((m) => m.general),
    {},
  );
  const hasErrors = !!Object.values(currentErrors)?.length;
  const company =
    getIn(
      values,
      modelPath<OnboardingV4State>((m) => m.company.newCompanyName),
    ) ||
    getIn(
      values,
      modelPath<OnboardingV4State>((m) => m.company.existingCompany?.text),
    );

  return (
    <OnboardingWrapper>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Box mb={6}>
            <OpentalentLogo />
          </Box>

          <Box mb={8}>
            <Typography variant="h5" style={{ fontSize: 28 }} fontWeight={700}>
              Create your account
            </Typography>
          </Box>

          <Box className={classes.fieldsWrapper}>
            <Box mb={4}>
              <Select
                style={{ textAlign: 'left' }}
                fullWidth
                variant="filled"
                disabled
                label="Company"
                value={company}
                options={[{ text: company, value: company }]}
              />
            </Box>

            <Box mb={4}>
              <ConnectedTextField
                name={modelPath<OnboardingV4State>((m) => m.general.firstName)}
                fullWidth
                variant="filled"
                label="First name"
              />
            </Box>
            <Box mb={4}>
              <ConnectedTextField
                name={modelPath<OnboardingV4State>((m) => m.general.lastName)}
                fullWidth
                variant="filled"
                label="Last name"
              />
            </Box>
            <Box mb={4}>
              <ConnectedTextField
                name={modelPath<OnboardingV4State>((m) => m.general.email)}
                fullWidth
                type="email"
                variant="filled"
                label="Email"
              />
            </Box>
          </Box>

          <Box mb={4}>
            <Button
              fullWidth
              size="large"
              color="primary"
              variant="contained"
              className={classes.btn}
              disabled={hasErrors || loading}
              onClick={handleSubmit}
            >
              SIGN UP
            </Button>
          </Box>

          <Box>
            <Typography variant="subtitle2" color="text.secondary">
              By signing up, you accept our{' '}
              <OuterLink
                variant="subtitle2"
                href="https://opentalent.notion.site/Privacy-Policy-OpenTalent-229e57a0fb584fd4834f07ee9f594132"
                target="_blank"
                color="text.secondary"
              >
                privacy policy and terms
              </OuterLink>
              .
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </OnboardingWrapper>
  );
};
