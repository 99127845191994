import React from 'react';
import { Route } from 'react-router-dom';

import { pathManager } from 'routes/consts';
import Logout from 'screens/auth/Logout';
import QuickAuth from 'screens/auth/QuickAuth';

export const AUTH_ROUTES = [
  <Route
    exact
    component={Logout}
    path={pathManager.logout.getRoute()}
    key={pathManager.logout.getRoute()}
  />,
  <Route
    exact
    component={QuickAuth}
    path={pathManager.quickAuth.getRoute()}
    key={pathManager.quickAuth.getRoute()}
  />,
  <Route
    exact
    component={QuickAuth}
    path={pathManager.auth.getRoute()}
    key={pathManager.auth.getRoute()}
  />,
];
