import React from 'react';
import { Route } from 'react-router-dom';
import { pathManager } from 'routes/consts';
import { NewOnboarding } from 'screens/company-user/new-onboarding';
import CompanyOnboardingOld from 'screens/company-user/onboarding';
import CompanyOnboardingV3 from 'screens/company-user/onboarding-v3';
import CompanyOnboardingV4 from 'screens/company-user/onboarding-v4/CompanyOnboardingV4';
import OnboardingRedirect from 'screens/company-user/onboarding/OnboardingRedirect';
import { Leave } from 'screens/leave';
import { JobDetails } from 'screens/public/job-details';
import Invitation from 'screens/talent/invitation';
import TalentOnboarding from 'screens/talent/onboarding';

export const PUBLIC_ROUTES = [
  <Route
    component={CompanyOnboardingOld}
    path={pathManager.company.onboarding.main.getRoute()}
    key={pathManager.company.onboarding.main.getRoute()}
  />,
  <Route
    component={OnboardingRedirect}
    path={pathManager.company.onboarding.redirect.getRoute()}
    key={pathManager.company.onboarding.redirect.getRoute()}
  />,
  <Route
    component={NewOnboarding}
    path={pathManager.company.newOnboarding.main.getRoute()}
    key={pathManager.company.newOnboarding.main.getRoute()}
  />,
  <Route
    component={CompanyOnboardingV3}
    path={pathManager.company.onboardingV3.main.getRoute()}
    key={pathManager.company.onboardingV3.main.getRoute()}
  />,
  <Route
    component={CompanyOnboardingV4}
    path={pathManager.company.onboardingV4.main.getRoute()}
    key={pathManager.company.onboardingV4.main.getRoute()}
  />,

  <Route
    path={pathManager.invitation.getRoute()}
    key={pathManager.invitation.getRoute()}
    component={Invitation}
  />,
  <Route
    component={TalentOnboarding}
    path={pathManager.talent.onboarding.main.getRoute()}
    key={pathManager.talent.onboarding.main.getRoute()}
  />,
  <Route
    component={Leave}
    path={pathManager.leave.getRoute()}
    key={pathManager.leave.getRoute()}
  />,
  <Route
    component={JobDetails}
    path={pathManager.public.job.getRoute()}
    key={pathManager.public.job.getRoute()}
  />,
];
