import { JobMatchTypeEnum } from '@libs/graphql-types';
import {
  JobOrderByColumn,
  JobLocationTypeEnum,
  JobTypeEnum,
  JobMatchDeclinationReasonsEnum,
} from '@libs/graphql-types';
import { OptionType } from '@libs/ui/components/form/select';

export const JOB_MATCH_TYPES_LABELS: Record<JobMatchTypeEnum, string> = {
  [JobMatchTypeEnum.TalentApplication]: 'Applied',
  [JobMatchTypeEnum.InstantMatch]: 'Instant match',
  [JobMatchTypeEnum.OpentalentSuggestion]: 'Opentalent suggestion',
  [JobMatchTypeEnum.Invited]: 'Invited',
  [JobMatchTypeEnum.Intake]: 'Intake',
  [JobMatchTypeEnum.Hired]: 'Hired',
  [JobMatchTypeEnum.Rejected]: 'Rejected',
  [JobMatchTypeEnum.Withdrawn]: 'Withdrawn',
};

export const JOB_EXPIRE_HOURS_THRESHOLD = 16;

export const JOB_TYPE_MAP = {
  [JobLocationTypeEnum.OnSite]: 'On-site',
  [JobLocationTypeEnum.Remote]: 'Remote',
  [JobLocationTypeEnum.Hybrid]: 'Hybrid',
};

export const JOB_ORDER_BY_COLUMN_MAP = {
  [JobOrderByColumn.CampaignStart]: 'Latest campaign',
  [JobOrderByColumn.RateMax]: 'Highest rate',
};
export const JOB_ORDER_BY_COLUMN_OPTIONS: OptionType[] = Object.entries(
  JOB_ORDER_BY_COLUMN_MAP,
).map(([value, text]) => ({ value, text }));

export const JOB_TYPES_LABELS_MAP = {
  [JobTypeEnum.Freelance]: 'Freelance job',
  [JobTypeEnum.Permanent]: 'Permanent role',
  [JobTypeEnum.Project]: 'Project',
};

export const JOB_MATCH_DECLINE_REASONS = {
  [JobMatchDeclinationReasonsEnum.None]: 'None',
  [JobMatchDeclinationReasonsEnum.NotEnoughSkills]: 'Not enough skills',
};

export const MAX_JOB_RATE = 300;
export const MIN_JOB_RATE = 25;

export const JOB_CAPACITY_OPTIONS: OptionType[] = [10, 20, 30, 40].map(
  (value) => ({
    text: `${value}  hrs/week`,
    value,
  }),
);
export const JOB_FINDERS_FEE = {
  min: 2500,
  max: 15000,
};
export const FREELANCE_JOB_FINDERS_FEE = {
  min: 1,
  max: 10,
};

export const JOB_MATCHERS_MAX_COUNT = 10;

export const LOCATION_TYPE_OPTIONS: OptionType[] = [
  { value: JobLocationTypeEnum.Remote, text: 'Remote (in Europe)' },
  { value: JobLocationTypeEnum.Hybrid, text: 'Hybrid' },
  { value: JobLocationTypeEnum.OnSite, text: 'On-site' },
];
