import React from 'react';

import { Grid } from '@mui/material';

import { ReactComponent as CheckedThickIcon } from './checked-thick.svg';
import { ReactComponent as ThickIcon } from './thick.svg';

interface CheckThickListItemProps extends React.PropsWithChildren<unknown> {
  isChecked: boolean;
}

export const CheckThickListItem = ({
  isChecked,
  children,
}: CheckThickListItemProps) => {
  return (
    <Grid flexWrap="nowrap" spacing={2} container>
      <Grid item>{isChecked ? <CheckedThickIcon /> : <ThickIcon />}</Grid>
      <Grid style={{ textAlign: 'left' }} item>
        {children}
      </Grid>
    </Grid>
  );
};
