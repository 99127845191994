import defaultAvatarPath from 'assets/images/default-avatar.svg';

import { SkillTypeEnum } from '@libs/graphql-types';

export const DEFAULT_AVATAR = defaultAvatarPath;

export const SKILL_TYPES_MAP = {
  [SkillTypeEnum.HardSkills]: 'Hard Skills',
  [SkillTypeEnum.Solutions]: 'Solutions',
  [SkillTypeEnum.SoftSkills]: 'Soft Skills',
};
export const INFINITY_SIGN = '∞';

export const OPENTALENT_COMPANY_ID = '1';
