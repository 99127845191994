import React from 'react';

import { makeStyles } from '@mui/styles';

import { TalentSearchHighlight } from '@libs/graphql-types';
import Typography from '@libs/ui/components/typography';

interface HighlightsProps {
  items: TalentSearchHighlight[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& em': {
      fontWeight: 'bold',
      color: theme.palette.text.primary,
      fontStyle: 'normal',
    },
  },
}));

const Highlights = ({ items }: HighlightsProps) => {
  const classes = useStyles();

  return (
    <>
      {items.map((item) => (
        <div key={item.text} className={classes.root}>
          <Typography variant="subtitle1">
            Text found in {item.source}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            dangerouslySetInnerHTML={{ __html: item.text || '' }}
          />
        </div>
      ))}
    </>
  );
};

export default Highlights;
