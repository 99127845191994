import {
  ChatSidebarItemDumb,
  ChatSidebarItemProps,
} from 'components/chat/common/chat-sidebar';
import { DEFAULT_AVATAR } from 'consts/common';
import { useCurrentUser } from 'hooks/auth';
import React from 'react';

import { TalentToTalentChatMeta } from '../../types';

interface TalentToTalentChatSidebarItemProps extends ChatSidebarItemProps {}

export const TalentToTalentChatSidebarItem = ({
  channel,
  latestMessage,
  active = false,
  unread,
  onChannelSelect,
}: TalentToTalentChatSidebarItemProps) => {
  const { user } = useCurrentUser();
  const { talent1, talent2 } = channel.data
    ?.channelDetails as TalentToTalentChatMeta;
  const lastMessageTime = channel?.state?.last_message_at as Date | undefined;
  const otherTalent = talent1?.id === user?.id ? talent2 : talent1;

  return (
    <ChatSidebarItemDumb
      date={lastMessageTime}
      avatar={otherTalent.image || DEFAULT_AVATAR}
      onChannelSelect={onChannelSelect}
      primaryContent={otherTalent.name}
      secondaryContent={latestMessage}
      unread={unread}
      active={active}
    />
  );
};
