import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { isProdInstance } from 'consts/config';

const DSN = process.env.REACT_APP_SENTRY_DSN;

const sentryEnv = isProdInstance ? 'production' : 'development';

if (sentryEnv === 'production') {
  Sentry.init({
    dsn: DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: sentryEnv,
    enabled: !!DSN,
  });
}
