import React from 'react';

import { CardContent, Card } from '@mui/material';

import Typography from '@libs/ui/components/typography';

interface AboutCardProps {
  about?: string;
}

export const AboutCard = ({ about }: AboutCardProps) => {
  return (
    <Card style={{ height: '100%' }} elevation={0}>
      <CardContent style={{ paddingBottom: 16 }}>
        <Typography variant="h6" paragraph fontWeight={500}>
          About
        </Typography>

        <Typography variant="body2">{about}</Typography>
      </CardContent>
    </Card>
  );
};
