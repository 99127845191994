import cn from 'classnames';
import React from 'react';

import { makeStyles } from '@mui/styles';

import { BGVariant } from './types';

interface ArrowTabItemProps extends React.PropsWithChildren<unknown> {
  onClick: React.MouseEventHandler;
  isActive?: boolean;
  bg?: BGVariant;
  className?: string;
}

const HEIGHT = 49;

const COMMON_PSEUDO_STYLES = {
  content: '""',
  position: 'absolute',
  height: '53%',
  width: '100%',
};

const useStyles = makeStyles((theme) => {
  const bgColors: Record<BGVariant, string> = {
    primary: theme.palette.primary.main,
  };
  const getBorder = ({ isActive }: ArrowTabItemProps) =>
    `1px solid ${
      isActive ? theme.palette.secondary.main : theme.palette.grey[400]
    }`;
  const bgGetter = ({ isActive, bg }: ArrowTabItemProps) =>
    isActive ? theme.palette.secondary.main : (bg && bgColors[bg]) || 'white';

  return {
    root: {
      boxSizing: 'border-box',
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(3),
      cursor: 'pointer',
      height: HEIGHT,
      border: 'none',
      position: 'relative',
      paddingRight: 15,
      paddingLeft: 15,
      background: bgGetter,
      color: ({ isActive }: ArrowTabItemProps) =>
        isActive ? 'white' : undefined,

      '& *': {
        boxSizing: 'border-box',
      },

      '&:not(:last-child)': {
        marginRight: theme.spacing(4),
      },

      '&:first-child $leftArrow': {
        '&::after': {
          transform: 'skew(0deg)',
        },
        '&::before': {
          transform: 'skew(0deg)',
        },
      },
      '&:last-child $rightArrow': {
        '&::after': {
          transform: 'skew(0deg)',
          borderBottomRightRadius: 0,
        },
        '&::before': {
          transform: 'skew(0deg)',
          borderTopRightRadius: 0,
        },
      },
    },
    content: {
      position: 'relative',
      whiteSpace: 'pre',
    },
    arrowPseudo: {
      position: 'absolute',
      top: 0,
      height: '100%',
      width: '100%',

      '&::after': COMMON_PSEUDO_STYLES,
      '&::before': COMMON_PSEUDO_STYLES,
    },
    leftArrow: {
      left: -8,

      '&::after': {
        top: 0,
        right: 0,
        transform: 'skew(20deg)',
        borderLeft: getBorder,
        borderTop: getBorder,
        background: bgGetter,
        borderRightBottomRadius: 4,
        borderTopLeftRadius: 4,
      },

      '&::before': {
        bottom: 0,
        right: 0,
        transform: 'skew(-20deg)',
        borderBottomLeftRadius: 4,
        borderLeft: getBorder,
        borderBottom: getBorder,
        background: bgGetter,
      },
    },
    rightArrow: {
      right: -8,

      '&::after': {
        top: 0,
        right: 0,
        transform: 'skew(20deg)',
        borderRight: getBorder,
        borderTop: getBorder,
        background: bgGetter,
        borderBottomRightRadius: 4,
        borderTopRightRadius: 4,
      },

      '&::before': {
        bottom: 0,
        right: 0,
        transform: 'skew(-20deg)',
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
        borderRight: getBorder,
        borderBottom: getBorder,
        background: bgGetter,
      },
    },
  };
});

const ArrowTabItem = (props: ArrowTabItemProps) => {
  const { children, onClick, className } = props;
  const classes = useStyles(props);

  return (
    <button
      className={cn(classes.root, className)}
      type="button"
      onClick={onClick}
    >
      <i className={cn(classes.leftArrow, classes.arrowPseudo)} />
      <i className={cn(classes.rightArrow, classes.arrowPseudo)} />
      <div className={classes.content}>{children}</div>
    </button>
  );
};

export default ArrowTabItem;
