import { useAuth0 } from 'hooks/auth/useAuth0';
import React, { PropsWithChildren } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import RouteRestriction from 'screens/auth/RouteRestriction';

import { ProtectedRoutePredicate } from './types';

type PrivateRouteProps = PropsWithChildren<{
  predicate: ProtectedRoutePredicate;
  component?:
  | React.ReactNode
  | React.LazyExoticComponent<(props: any) => JSX.Element>
  | React.LazyExoticComponent<React.FC<any>>
  | React.FC<any>;
}> &
  RouteProps;

const PrivateRoute = ({
  predicate,
  component,
  render,
  ...rest
}: PrivateRouteProps) => {
  const { user } = useAuth0();
  const rejectReason = predicate(user);

  if (rejectReason) {
    return <RouteRestriction rejectReason={rejectReason} />;
  }

  const RouteComponent = component as React.ComponentType<any>;

  return (
    <Route
      {...rest}
      render={(props) => {
        return render?.(props) || <RouteComponent {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
