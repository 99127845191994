import { LOCAL_STORAGE_KEYS } from 'consts/ls-keys';
import React from 'react';
import { useInterval } from 'react-use';
import { pathManager } from 'routes';

import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { RouterButton } from '@libs/ui/components/button';
import Typography from '@libs/ui/components/typography';

interface CreateTalentCommnunityMiddleContentProps { }

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 3),
    background: 'rgba(242, 98, 236, 1)',
    color: 'white',
    display: 'inline-flex',
    borderRadius: 4,
    cursor: 'pointer',
  },
  blackText: {
    color: 'black',
  },
}));

export const CreateTalentCommnunityMiddleContent = (
  props: CreateTalentCommnunityMiddleContentProps,
) => {
  const [wasShown, setWasShown] = React.useState(
    !!localStorage.getItem(
      LOCAL_STORAGE_KEYS.createCommunityByTalentHeaderBadge,
    ),
  );
  const classes = useStyles();

  useInterval(
    () => {
      const lsWasShown = localStorage.getItem(
        LOCAL_STORAGE_KEYS.createCommunityByTalentHeaderBadge,
      );

      if (lsWasShown) {
        setWasShown(true);
      }
    },
    !wasShown ? 1000 : null,
  );

  if (wasShown) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Grid container wrap="nowrap" spacing={4}>
        <Grid item flexGrow={1} display="flex" alignItems="center">
          <Typography variant="body1">
            <span className={classes.blackText}>NEW:</span> Launch your own
            Talent Pool on OpenTalent
          </Typography>
        </Grid>

        <Grid item>
          <RouterButton
            to={pathManager.talent.createCommunity.generatePath()}
            variant="contained"
            color="primary"
            size="small"
          >
            Learn more
          </RouterButton>
        </Grid>
      </Grid>
    </div>
  );
};
