import '@stream-io/stream-chat-css/dist/css/index.css';
import Auth0Provider from 'auth0/Auth0Provider';
import { CurrentUserContextProvider } from 'components/contexts/CurrentUserContext';
import ApolloProvider from 'graphql/client/ApolloProvider';
import { ConfirmProvider } from 'material-ui-confirm';
// @ts-ignore
import mixpanel from 'mixpanel-browser';
import React from 'react';
import { createRoot } from 'react-dom/client';
// @ts-ignore
import { MixpanelProvider } from 'react-mixpanel';
import 'react-quill/dist/quill.snow.css';
import Router from 'routes';
import 'utils/sentry';
import CustomErrorBoundary from 'utils/sentry/CustomErrorBoundary';

import { LocalizationProvider } from '@mui/lab';
import DateFnsUtils from '@mui/lab/AdapterDateFns';
import { StyledEngineProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import { configureYup } from '@libs/helpers/yup';
import { SnackbarProvider } from '@libs/ui/components/snackbar/SnackbarProvider';
import defaultTheme from '@libs/ui/themes/v5-theme';

configureYup();
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <MixpanelProvider mixpanel={mixpanel}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={defaultTheme}>
        <Auth0Provider>
          <LocalizationProvider dateAdapter={DateFnsUtils}>
            <SnackbarProvider>
              <CurrentUserContextProvider>
                <ApolloProvider>
                  <ConfirmProvider
                    defaultOptions={{
                      confirmationButtonProps: {
                        variant: 'contained',
                        color: 'primary',
                      },
                    }}
                  >
                    <CustomErrorBoundary>
                      <CssBaseline />
                      <Router />
                    </CustomErrorBoundary>
                  </ConfirmProvider>
                </ApolloProvider>
              </CurrentUserContextProvider>
            </SnackbarProvider>
          </LocalizationProvider>
        </Auth0Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  </MixpanelProvider>,
);
