import { getIn, useFormikContext } from 'formik';
import { SUGGEST_COMPANY } from 'graphql/common';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { pathManager } from 'routes';
import { OnboardingV4State } from 'screens/company-user/onboarding-v4/types';

import { Box, Card, CardContent } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { modelPath } from '@libs/helpers/form';
import { RouterButton } from '@libs/ui/components/button';
import { GraphConnectedMultipleSelect } from '@libs/ui/components/form/multiple-select';
import Typography, { RouterLink } from '@libs/ui/components/typography';

import IconsRow from '../icons-row';
import { TalentCompanyCount } from './TalentCompanyCount';

interface AddCompanyFormProps { }

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  card: {
    marginLeft: 'auto',
    background: '#1E1E1A',
    textAlign: 'center',
    borderRadius: theme.spacing(4),

    '& a': {
      color: `${theme.palette.info.main} !important`,
    },
  },
  createText: {
    cursor: 'pointer',
    color: theme.palette.info.main,
  },
  cardContent: {
    padding: `${theme.spacing(14)} !important`,

    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacing(6)} !important`,
    },
  },
  yellowText: {
    color: 'rgba(255, 255, 255, 0.54) !important',
  },
  btn: {
    borderRadius: 34,

    'a&': {
      color: `${theme.palette.text.primary} !important`,
    },

    '&.Mui-disabled': {
      background: '#BDBDBD',
    },
  },
}));

export const AddCompanyForm = (props: AddCompanyFormProps) => {
  const classes = useStyles();
  const history = useHistory();
  const [newCompany, setNewCompany] = useState('');
  const { errors, values, setFieldValue } = useFormikContext();
  const existingCompany = getIn(
    values,
    modelPath<OnboardingV4State>((m) => m.company.existingCompany),
    {},
  );
  const currentErrors = getIn(
    errors,
    modelPath<OnboardingV4State>((m) => m.company),
    {},
  );
  const hasErrors = !!Object.values(currentErrors)?.length;

  const needToSuggest = newCompany.length > 2;

  const createCompanySuggest = (
    <Typography variant="body2">
      Company not found.{' '}
      <i
        className={classes.createText}
        onClick={() => {
          history.push(pathManager.company.onboardingV4.general.generatePath());
          setFieldValue(
            modelPath<OnboardingV4State>((m) => m.company.newCompanyName),
            newCompany,
          );
        }}
      >
        Create "{newCompany}"?
      </i>
    </Typography>
  );

  return (
    <div className={classes.root}>
      <Card elevation={0} className={classes.card}>
        <CardContent classes={{ root: classes.cardContent }}>
          <Box mb={3}>
            <Typography style={{ fontSize: 24 }} variant="h5" fontWeight={600}>
              Create your account.
            </Typography>
          </Box>

          <Box mb={6}>
            <Typography variant="body1">Select your Company</Typography>
          </Box>

          <Box mb={6}>
            <GraphConnectedMultipleSelect
              name={modelPath<OnboardingV4State>(
                (m) => m.company.existingCompany,
              )}
              query={SUGGEST_COMPANY}
              dataPath="companies.data"
              mapInputTextToQuery={(value: string) => ({ search: value })}
              dataMap={{ text: 'name', value: 'id' }}
              autoCompleteProps={{
                multiple: false,
              }}
              noOptionsText={needToSuggest ? createCompanySuggest : undefined}
              inputProps={{
                variant: 'filled',
                label: 'Company',
                placeholder: '',
                margin: 'dense',
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  setNewCompany(e.target.value);
                },
              }}
            />
          </Box>

          <Box mb={6}>
            <TalentCompanyCount
              companyId={existingCompany?.value}
              companyName={existingCompany?.text}
            />
          </Box>
          <Box mb={6}>
            <RouterButton
              fullWidth
              size="large"
              color="primary"
              variant="contained"
              className={classes.btn}
              disabled={hasErrors}
              to={pathManager.company.onboardingV4.general.generatePath()}
            >
              CONTINUE
            </RouterButton>
          </Box>

          <Box>
            <Typography variant="subtitle2" style={{ fontSize: 14 }}>
              Already have an account?{' '}
              <RouterLink
                to={pathManager.quickAuth.generatePath()}
                color="info.main"
                style={{ fontSize: 14 }}
              >
                Sign in
              </RouterLink>
            </Typography>
          </Box>
        </CardContent>
      </Card>

      <Box mt={10} mb={4}>
        <Typography
          textAlign="center"
          variant="subtitle2"
          color="primary"
          className={classes.yellowText}
        >
          +35.000 pre-populated communities live
        </Typography>
      </Box>

      <Box maxWidth={440} mx="auto">
        <IconsRow />
      </Box>
    </div>
  );
};
