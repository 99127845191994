import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMessageInputContext } from 'stream-chat-react';

import { noop } from '@libs/helpers/common';

export const useApplyTextFromLocationState = () => {
  const { setText, textareaRef } = useMessageInputContext();
  const history = useHistory<{ inputText?: string }>();
  const text = history.location?.state?.inputText;

  useEffect(() => {
    if (text) {
      setText(text);

      if (textareaRef.current) {
        textareaRef.current.selectionStart = text.length;
        textareaRef.current.selectionEnd = text.length;
      }
    }
    return noop;
  }, [setText, history, text, textareaRef]);
};
