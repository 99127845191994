import { ConnectedPageLayout } from 'components/layout/page-layout';
import React from 'react';

import Success from './Success';

const Profile = () => {
  return (
    <ConnectedPageLayout
      headerProps={{ accountProps: {} }}
      documentTitle="Subscription"
      drawerProps={{}}
    >
      <Success />
    </ConnectedPageLayout>
  );
};

export default Profile;
