import { AuthUtils } from 'auth0/utils';
import {
  DynamicChatDataProvider,
  StreamChatProvider,
} from 'components/chat/common/provider';
import { ConnectedPageLayout } from 'components/layout/page-layout';
import { useAuth0 } from 'hooks/auth/useAuth0';
import useCurrentUserRequest from 'hooks/auth/useCurrentUserRequest';
import React, { Suspense, useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { pathManager } from 'routes';
import { COMMON_ROUTES, COMPANY_ROUTES, TALENT_ROUTES } from 'routes/grouped';
import { NotFound } from 'screens/not-found';
import { useSetSentryUser } from 'utils/sentry/hooks';

import { AppLoadingScreen } from './AppLoadingScreen';
import { AppWrongEmailScreen } from './AppWrongEmailScreen';

interface AuthenticatedRouteProps { }

const useAuthenticate = () => {
  const history = useHistory();
  const { user: auth0User, isAuthenticated, isLoading } = useAuth0();
  const isCompany = isAuthenticated && AuthUtils.isCompany(auth0User);
  const { user, isLoading: isCurrentUserLoading } = useCurrentUserRequest({
    respectAnonymusRoutes: true,
  });

  useEffect(() => {
    if (!isCurrentUserLoading && !isLoading && !auth0User) {
      history.push({
        pathname: pathManager.quickAuth.generatePath(),
        state: {
          from: history.location.pathname,
        },
      });
    }
  }, [auth0User, history, isCurrentUserLoading, isLoading]);

  return {
    isLoading: isLoading || isCurrentUserLoading,
    user,
    isCompany,
    auth0User,
  };
};

export const AuthenticatedRoute = (props: AuthenticatedRouteProps) => {
  const { isLoading, user, auth0User } = useAuthenticate();

  useSetSentryUser(user);

  if (isLoading || (!user && !auth0User?.email)) {
    return <AppLoadingScreen />;
  }

  if (!user && auth0User?.email) {
    return <AppWrongEmailScreen />;
  }

  return (
    <StreamChatProvider>
      <DynamicChatDataProvider>
        <Suspense
          fallback={<ConnectedPageLayout drawerProps={{}} headerProps={{}} />}
        >
          <Switch>
            {COMMON_ROUTES}
            {COMPANY_ROUTES}
            {TALENT_ROUTES}
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </DynamicChatDataProvider>
    </StreamChatProvider>
  );
};
