import { debounce } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';

export const useCurrentTime = () => {
  const intervalRef = useRef<number | null | undefined>();
  const [currentTime, setCurrentTime] = useState(new Date());
  const debouncedSetTime = useMemo(() => debounce(setCurrentTime, 2000), []);

  useEffect(() => {
    if (intervalRef.current === undefined) {
      const id = setInterval(() => setCurrentTime(new Date()), 3000) as any;
      intervalRef.current = id;
    }
    return () => {
      intervalRef.current = null;
    };
  }, [debouncedSetTime, intervalRef]);

  return currentTime;
};
