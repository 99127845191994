import { LOCAL_STORAGE_KEYS } from 'consts/ls-keys';
import { useAuth0 } from 'hooks/auth/useAuth0';
import React, { useEffect } from 'react';

import { AppLoadingScreen } from './helpers';

const Logout = () => {
  const { logout } = useAuth0();

  useEffect(() => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.currentAccount);
    logout({ federated: true, localOnly: false });
  }, [logout]);

  return <AppLoadingScreen />;
};

export default Logout;
