import { JobOrderByColumn } from '@libs/graphql-types';
import { OptionType } from '@libs/ui/components/form/select';

export enum TalentJobSourse {
  Opentalent = 'opentalent',
  External = 'external',
}

export interface FilterForm {
  externalJobEmployment: OptionType[];
  externalJobSeniority: OptionType[];
  country: string;
  externalCompanyName: OptionType;

  categories: OptionType[];
  search: string;
  skills: OptionType[];
  rate_min: string;
  rate_max: string;
  order_by_column?: JobOrderByColumn;
  is_saved: boolean;
  page: string;
  is_active: boolean;

  tab: JobTabType;

  companies: Record<string, boolean>;

  jobSourceInternal: boolean;
  jobSourceExternal: boolean;
}

export enum JobTabType {
  Open = 'open',
  MyApplications = 'myApplications',
  Closed = 'closed',
  Saved = 'saved',
  Recommended = 'recommended',
}
