import { useCurrentTime } from 'hooks/common/useCurrentTime';
import React from 'react';

import { Pagination } from '@mui/lab';
import { Box } from '@mui/material';

import { Job } from '@libs/graphql-types';
import Typography from '@libs/ui/components/typography';

import ApplyModal from '../apply-modal';
import {
  useCheckPagination,
  useJobList,
  useJobModals,
  usePaginationChange,
} from '../hooks';
import InviteModal from '../invite-modal';
import Controls from './Controls';
import ExternalJobCardV2 from './external-job-card-v2';
import JobCard from './job-card';

interface ContentProps {
  openDrawer: VoidFunction;
  applicationsCount?: number;
  savedCount?: number;
  recommendedCount?: number;
  refetchJobCounts: VoidFunction;
  totalCount?: number;
}

export const isItExternalJob = (job: Job) =>
  job.job_source_type === 'CORESIGNAL';

const Content = ({
  openDrawer,
  totalCount,
  applicationsCount,
  savedCount,
  recommendedCount,
  refetchJobCounts,
}: ContentProps) => {
  const currentTime = useCurrentTime();
  const onPaginationChange = usePaginationChange();
  const { jobs, loadingJobList, currentPage, lastPage, loadJobs } =
    useJobList();
  const { inviteJob, applyJob, handleClose, onJobApply, onInvite } =
    useJobModals();

  useCheckPagination({ lastPage, currentPage });

  return (
    <div>
      <Controls
        openDrawer={openDrawer}
        applicationsCount={applicationsCount}
        savedCount={savedCount}
        recommendedCount={recommendedCount}
        totalCount={totalCount}
      />
      <div>
        {loadingJobList && (
          <Typography variant="body1">
            Loading results, please wait...
          </Typography>
        )}
        {!jobs.length && !loadingJobList && (
          <Typography variant="body1">
            No jobs matching your criteria. Try to change search options.
          </Typography>
        )}
        {jobs.map((job, index) => {
          if (isItExternalJob(job)) {
            return (
              <ExternalJobCardV2
                key={`${job?.posted_at}-${index}`}
                job={job}
                currentTime={currentTime}
                onJobSave={refetchJobCounts}
              />
            );
          }
          return (
            <JobCard
              // eslint-disable-next-line react/no-array-index-key
              key={`${job?.posted_at}-${job?.name}-${index}`}
              onInvite={onInvite}
              onJobApply={onJobApply}
              job={job as Job}
              currentTime={currentTime}
              loadJobs={loadJobs}
              onJobSave={refetchJobCounts}
            />
          );
        })}
      </div>
      {!loadingJobList && (
        <Box display="flex" justifyContent="center" mt={4}>
          <Pagination
            page={currentPage}
            showFirstButton
            showLastButton
            count={lastPage}
            variant="outlined"
            shape="rounded"
            onChange={onPaginationChange}
          />
        </Box>
      )}
      {inviteJob && <InviteModal handleClose={handleClose} job={inviteJob} />}
      {applyJob && <ApplyModal handleClose={handleClose} job={applyJob} />}
    </div>
  );
};

export default React.memo(Content);
