import { usePushWithQuery } from 'hooks/routing';

import {
  CompanyUserOnboardingTypeEnum,
  CreateCompanyUserMutationVariables,
} from '@libs/graphql-types';
import { noop } from '@libs/helpers/common';

import { Steps } from '../types';
import { CreateUserState } from './types';

export const useCreateCompanyUser = ({
  onSuccess = noop,
}: {
  onSuccess?: (values: any) => void;
}) => {
  const push = usePushWithQuery();

  const updateRequest = async (variables: CreateUserState) => {
    onSuccess?.(variables);
    push({ query: { step: Steps.Company } });
  };

  return { updateRequest };
};

export const useInitialValues = () => {
  const initialValues: CreateCompanyUserMutationVariables = {
    avatar: '',
    first_name: '',
    last_name: '',
    email: '',
    is_primary: true,
    onboarding_type: CompanyUserOnboardingTypeEnum.Simple,
  };

  return {
    initialValues,
  };
};
