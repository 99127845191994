import React from 'react';

import { Grid } from '@mui/material';

import { Subcategory, Talent } from '@libs/graphql-types';
import Chip from '@libs/ui/components/chip';

interface CategoriesListProps {
  talent: Talent;
}

const CategoriesList = ({ talent }: CategoriesListProps) => {
  const subcategories = (talent?.subcategories as Subcategory[]) || [];

  if (!subcategories.length) {
    return <></>;
  }

  return (
    <Grid spacing={2} container>
      {subcategories.map((i) => (
        <Grid key={i.id} item>
          <Chip color="grey" size="small" label={i.name} />
        </Grid>
      ))}
    </Grid>
  );
};

export default CategoriesList;
