import FixedFooter from 'components/custom/onboarding/fixed-footer';
import React from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { modelPath } from '@libs/helpers/form';
import { RouterButton } from '@libs/ui/components/button';
import { ConnectedTextField } from '@libs/ui/components/form/text-field';

import { useDefaultOnSubmitHandler } from '../form-context/hooks';
import { BuilderFormState } from '../form-context/types';
import NextButtonWithValidation from '../shared/NextButtonWithValidation';
import StepTemplate from '../shared/StepTemplate';
import { DefaultStepProps } from '../shared/types';
import CandidatesAmount from './CandidatesAmount';
import CategoryFields from './CategoryFields';

interface JobCategoryProps extends DefaultStepProps {}

const useStyles = makeStyles((theme) => ({
  form: {
    maxWidth: 430,
    margin: '0 auto',

    '& > div:not(:last-child)': {
      marginBottom: theme.spacing(6),
    },
  },
  amount: {
    paddingTop: theme.spacing(20),

    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(10),
    },
  },
}));

const JobCategory = ({
  nextStepRoute,
  index,
  prevStepRoute,
}: JobCategoryProps) => {
  const classes = useStyles();
  const { onSubmit, loading } = useDefaultOnSubmitHandler({
    redirectRoute: nextStepRoute,
  });

  return (
    <StepTemplate
      title="Select Category"
      subtitle="What are you looking for?"
      currentStep={index}
      currentStepLabel="Category"
    >
      <Box className={classes.form}>
        <CategoryFields />

        <ConnectedTextField
          name={modelPath<BuilderFormState>((m) => m.jobCategory.title)}
          data-test-id="title"
          fullWidth
          variant="filled"
          label="Job title (e.g. UX designer, PHP developer)"
        />
      </Box>
      <Box className={classes.amount} textAlign="center">
        <CandidatesAmount />
      </Box>

      <FixedFooter>
        <Grid spacing={4} justifyContent="center" container>
          <Grid xs={6} item>
            <RouterButton
              startIcon={<ArrowBackIcon />}
              to={prevStepRoute}
              color="primary"
              variant="outlined"
              fullWidth
            >
              Back
            </RouterButton>
          </Grid>
          <Grid xs={6} item>
            <NextButtonWithValidation
              path={modelPath<BuilderFormState>((m) => m.jobCategory)}
              onClick={onSubmit}
              disabled={loading}
            >
              next
            </NextButtonWithValidation>
          </Grid>
        </Grid>
      </FixedFooter>
    </StepTemplate>
  );
};

export default JobCategory;
