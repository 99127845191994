import React from 'react';

import { Box, FormControlLabel, Radio } from '@mui/material';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import { TypeCardProps } from './types';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'left',
    borderRadius: 3,
    padding: 24,
    background:
      'linear-gradient(180deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 255, 255, 0.07) 100%), #121212',
    cursor: 'pointer',
    outline: ({ selected }: { selected: boolean }) =>
      selected
        ? `2px solid ${theme.palette.success.main}`
        : '2px solid transparent',
    transition: 'outline 0.3s ease',

    '& .MuiSvgIcon-root': {
      color: theme.palette.success.main,
    },
  },
}));

export const TypeCard = ({
  onChange,
  title,
  text,
  selected = false,
}: TypeCardProps) => {
  const classes = useStyles({ selected });

  return (
    <Box onClick={onChange} className={classes.root}>
      <FormControlLabel
        value="female"
        control={<Radio checked={selected} color="info" />}
        label={<Typography variant="h6">{title}</Typography>}
      />

      <Typography component={Box} variant="body2">
        {text}
      </Typography>
    </Box>
  );
};
