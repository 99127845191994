import {
  ChatSidebarItemDumb,
  ChatSidebarItemProps,
} from 'components/chat/common/chat-sidebar';
import { DEFAULT_AVATAR } from 'consts/common';
import React from 'react';

import { JobChatMeta } from '../../types';

interface JobChatSidebarItemProps extends ChatSidebarItemProps {}

export const JobChatSidebarItem = ({
  channel,
  latestMessage,
  active = false,
  unread,
  onChannelSelect,
}: JobChatSidebarItemProps) => {
  const details = channel.data?.channelDetails as JobChatMeta;
  const lastMessageTime = channel?.state?.last_message_at as Date | undefined;
  const title =
    details.jobName ||
    channel.data?.name ||
    `Chat for job with id ${details.jobId}`;

  return (
    <ChatSidebarItemDumb
      date={lastMessageTime}
      avatar={DEFAULT_AVATAR}
      onChannelSelect={onChannelSelect}
      primaryContent={title}
      secondaryContent={latestMessage}
      unread={unread}
      active={active}
    />
  );
};
