import { ChatSidebarItem } from 'components/chat/common/chat-sidebar';
import React from 'react';
import { SearchResultItemProps } from 'stream-chat-react';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';

interface SearchChannelResultItemProps
  extends SearchResultItemProps<DefaultStreamChatGenerics> {}

export const SearchChannelResultItem = ({
  result,
  selectResult,
}: SearchChannelResultItemProps) => {
  return (
    <ChatSidebarItem
      setActiveChannel={selectResult as any}
      channel={result as any}
    />
  );
};
