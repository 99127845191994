import { Grid } from '@mui/material';
import React from 'react';

import Button from '@libs/ui/components/button';
import { DefaultModal } from '@libs/ui/components/modals';

interface InitialApplyModalProps {
  isApplying: boolean;
  open: boolean;
  handleClose: VoidFunction;
  onSubmit: VoidFunction;
}

const InitialApplyModal = ({
  open,
  handleClose,
  onSubmit,
  isApplying,
}: InitialApplyModalProps) => {
  return (
    <DefaultModal
      title="Interested in this job?"
      open={open}
      handleClose={handleClose}
      actions={
        <>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            autoFocus
            size="large"
            onClick={onSubmit}
            disabled={isApplying}
          >
            Apply
          </Button>
          <Button
            onClick={handleClose}
            fullWidth
            size="large"
            variant="outlined"
            color="secondary"
          >
            Cancel
          </Button>
        </>
      }
    >
      <Grid spacing={4} direction="column" container>
        <Grid item>Press Apply button and we’ll contact you soon</Grid>
      </Grid>
    </DefaultModal>
  );
};

export default InitialApplyModal;
