import {
  DateRangePicker as MuiDateRangePicker,
  StaticDateRangePickerProps,
} from '@mui/lab';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

import TextField, {
  TextFieldProps as BaseTextFieldProps,
} from '@libs/ui/components/form/text-field';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialogActions-root button': {
      color: theme.palette.secondary.main,
    },
  },
}));

export interface DateRangePickerProps
  extends Omit<StaticDateRangePickerProps, 'renderInput'> {
  TextFieldProps?: BaseTextFieldProps;
  FirstTextFieldProps?: Omit<BaseTextFieldProps, 'variant'>;
  SecondTextFieldProps?: Omit<BaseTextFieldProps, 'variant'>;
}

const DateRangePicker = (props: DateRangePickerProps) => {
  const { TextFieldProps, FirstTextFieldProps, SecondTextFieldProps } = props;
  const classes = useStyles();

  return (
    <MuiDateRangePicker
      {...props}
      DialogProps={{ classes: { paper: classes.root } }}
      renderInput={(startProps, endProps) => (
        <Grid wrap="nowrap" spacing={4} container>
          <Grid item>
            <TextField
              variant="outlined"
              {...startProps}
              {...(TextFieldProps as any)}
              {...(FirstTextFieldProps as any)}
            />
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              {...endProps}
              {...(TextFieldProps as any)}
              {...(SecondTextFieldProps as any)}
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default DateRangePicker;
