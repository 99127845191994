import { gql } from '@apollo/client';

export const GET_JOBS = gql`
  query GetJobs($talent_id: ID!) {
    availableJobsForTalent(talent_id: $talent_id) {
      job {
        id
        name
      }
      is_applied
      is_invited
    }
  }
`;

export const INVITE_TO_JOB = gql`
  mutation InviteToJob($job_id: ID!, $talent_id: ID!, $message: String) {
    inviteToJob(talent_id: $talent_id, job_id: $job_id, message: $message)
  }
`;

export const GET_JOBS_TOTAL = gql`
  query GetJobsTotal($id: ID!) {
    jobs {
      paginatorInfo {
        total
      }
    }
  }
`;
