import List from '@mui/material/List';
import React from 'react';

import DrawerListItem, { DrawerListItemProps } from '../drawer-list-item';

interface DrawerListProps {
  items?: DrawerListItemProps[];
  disablePadding?: boolean;
}

const DrawerList = ({ items, disablePadding }: DrawerListProps) => {
  return (
    <List disablePadding={disablePadding}>
      {items?.map((item) => (
        <DrawerListItem key={item.text} {...item} />
      ))}
    </List>
  );
};

export default DrawerList;
