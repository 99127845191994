import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import * as yup from 'yup';

import { useCreateJobSuggestionMutation } from '@libs/graphql-types';
import { useCheckTalentExistsByEmailLazyQuery } from '@libs/graphql-types';
import { FormikSubmit } from '@libs/helpers/form';

import { FormState } from './types';

export const useApplyForAction = (job_id: string) => {
  const { enqueueSnackbar } = useSnackbar();
  const [request, { loading }] = useCreateJobSuggestionMutation({
    onCompleted() {
      enqueueSnackbar('Sended!', { variant: 'success' });
    },
  });

  const onSubmit: FormikSubmit<FormState> = useCallback(
    async (values, helpers) => {
      const res = await request({
        variables: {
          job_id,
          email: values.email,
          first_name: values.firstName,
          last_name: values.lastName,
        },
      });

      if (res.data?.createJobSuggestion) {
        helpers.resetForm();
      }
    },
    [request, job_id],
  );

  return { onSubmit, isLoading: loading };
};

const emailSchema = yup.string().email().nullable().trim().required();

export const useModalValidationSchema = () => {
  const [check] = useCheckTalentExistsByEmailLazyQuery();
  const schema = yup.object().shape({
    firstName: yup.string().trim().nullable().required(),
    lastName: yup.string().trim().nullable(),
    email: emailSchema.test({
      name: 'checkDuplicateEmail1',
      message: 'This talent already exists on the platform',
      test: async (email) => {
        const isValidEmail = emailSchema.isValidSync(email);

        if (!isValidEmail) {
          return true;
        }
        const res = await check({ variables: { talent_email: email } });

        return !res.data?.checkTalentExistsByEmail;
      },
    }),
  });

  return { schema };
};
