import { SimplePaletteColorOptions, ThemeOptions } from '@mui/material';

import { PALETTE } from './palette';
import { TYPOGRAPHY_THEME_OPTIONS } from './typography';

export const INPUT_THEME_OPTIONS: ThemeOptions['components'] = {
  MuiInput: {
    styleOverrides: {
      underline: {
        '&:before': {
          borderBottomColor: PALETTE.other.dark ?? 'rgba(25, 25, 25, 0.24)',
        },
        '&:after': {
          borderBottomColor: PALETTE.other.black ?? '#282824',
        },
        '&.Mui-disabled': {
          '&:before': {
            borderBottomStyle: 'solid',
          },
        },
      },
    },
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        minWidth: 198,
      },
      marginDense: {
        marginTop: 0,
        marginBottom: 0,
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        '&.Mui-focused': {
          borderColor: PALETTE.other.black ?? '#282824',
        },
      },
      input: {
        '&[type=number]': {
          '-moz-appearance': 'textfield',

          '&::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
          },
          '&::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
          },
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        '&.Mui-focused $notchedOutline': {
          borderColor: PALETTE.other.black ?? '#282824',
        },
      },
      notchedOutline: {
        borderColor: `${PALETTE.other.main ?? 'rgba(25, 25, 25, 0.08)'
          } !important`,
      },
    },
  },
  MuiFilledInput: {
    styleOverrides: {
      root: {
        border: `1px solid ${PALETTE.other.main ?? 'rgba(25, 25, 25, 0.08)'}`,
        borderBottom: 'none',
        backgroundColor: 'white !important',
      },
      underline: {
        '&:before': {
          borderBottomColor: PALETTE.other.dark ?? 'rgba(25, 25, 25, 0.24)',
        },
        '&:after': {
          borderBottomColor: PALETTE.other.black ?? '#282824',
        },
        '&.Mui-disabled': {
          '&:before': {
            borderBottomStyle: 'solid',
          },
        },
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      contained: {
        marginLeft: 0,
      },
    },
  },

  MuiList: {
    styleOverrides: {
      root: {
        maxHeight: 400,
      },
    },
  },

  MuiInputLabel: {
    styleOverrides: {
      root: {
        ...TYPOGRAPHY_THEME_OPTIONS.body1,
        '&.Mui-focused': {
          color: PALETTE?.text?.primary || 'rgba(0, 0, 0, 0.54)',
          '&.Mui-error': {
            color:
              (PALETTE.error as SimplePaletteColorOptions).main ?? '#EC634E',
          },
        },
      },
      outlined: {
        '&.Mui-focused': {
          color: PALETTE.other.black ?? '#282824',
        },
      },
      filled: {
        '&.Mui-focused': {
          color: PALETTE.other.black ?? '#282824',
        },
      },
    },
  },
};
