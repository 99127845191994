import { useLazyQuery } from '@apollo/client';
import { AuthUtils } from 'auth0/utils';
import { useCurrentUserContext } from 'components/contexts/CurrentUserContext';
import {
  GetCompanyUserLayoutData,
  GetTalentLayoutData,
} from 'graphql/common/queries';
import { useEffect } from 'react';
import { ANONYMOUS_LOCATIONS } from 'routes';

import { Query } from '@libs/graphql-types';

import { useAuth0 } from './useAuth0';

const useCurrentUserRequest = ({
  respectAnonymusRoutes,
}: {
  respectAnonymusRoutes: boolean;
}) => {
  const { value: currentUserContextValue } = useCurrentUserContext();
  const { user: auth0User, isLoading } = useAuth0();
  const isOathTalent = AuthUtils.isTalent(auth0User);
  const isTalent = !!currentUserContextValue?.type
    ? currentUserContextValue?.type === 'talent'
    : isOathTalent;
  const isNotAnonymousRoute =
    !respectAnonymusRoutes ||
    !ANONYMOUS_LOCATIONS.some((location) =>
      window.location.pathname.includes(location),
    );
  const [getUser, { data, loading }] = useLazyQuery<Query>(
    isTalent ? GetTalentLayoutData : GetCompanyUserLayoutData,
  );

  useEffect(() => {
    if (auth0User && !data && isNotAnonymousRoute) {
      getUser();
    }
  }, [auth0User, data, getUser, isNotAnonymousRoute]);

  const user = data?.[isTalent ? 'currentTalent' : 'currentCompanyUser'];

  return { getUser, data, user, isLoading: isLoading || loading, isTalent };
};

export default useCurrentUserRequest;
