import React from 'react';
import { SearchResultsListProps } from 'stream-chat-react';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';

import { Box, Divider } from '@mui/material';

import Typography from '@libs/ui/components/typography';

import { DefaultSearchResultItem } from './search-result-item';
import { isItChannel, isItMessage } from './utils';

interface CustomSearchResultsListProps
  extends SearchResultsListProps<DefaultStreamChatGenerics> {}

const getResultText = (num: number) => `${num} result${num === 1 ? '' : 's'}`;

export const CustomSearchResultsList = ({
  results,
  selectResult,
  SearchResultItem = DefaultSearchResultItem,
}: CustomSearchResultsListProps) => {
  const channels = results.filter(isItChannel);
  const messages = results.filter(isItMessage);
  const hasChannels = !!channels.length;
  const hasMessages = !!messages.length;

  return (
    <div>
      {hasChannels && (
        <>
          <Typography paragraph fontWeight={600}>
            Channels{' '}
            <Typography
              color="text.secondary"
              component="span"
              fontWeight={400}
              variant="subtitle2"
            >
              {getResultText(channels.length)}
            </Typography>
          </Typography>
          {channels.map((ch, index) => (
            <Box key={index} mb={2}>
              <SearchResultItem
                index={index}
                result={ch}
                selectResult={selectResult}
              />
            </Box>
          ))}
        </>
      )}

      {hasMessages && hasChannels && (
        <Box py={4}>
          <Divider />
        </Box>
      )}

      {hasMessages && (
        <>
          <Typography paragraph fontWeight={600}>
            Messages{' '}
            <Typography
              color="text.secondary"
              component="span"
              fontWeight={400}
              variant="subtitle2"
            >
              {getResultText(messages.length)}
            </Typography>
          </Typography>
          {messages.map((ch, index) => (
            <Box key={index} mb={2}>
              <DefaultSearchResultItem
                index={channels.length + index}
                result={ch}
                selectResult={selectResult}
              />
            </Box>
          ))}
        </>
      )}
    </div>
  );
};
