import { gql } from '@apollo/client';

export default gql`
  mutation CreateCompanyUser(
    $first_name: String!
    $last_name: String!
    $email: Email!
    $avatar: String
    $position: String
    $is_onboarding: Boolean
    $is_primary: Boolean
    $referral_key: String
  ) {
    createCompanyUserProfile(
      first_name: $first_name
      last_name: $last_name
      email: $email
      position: $position
      avatar: $avatar
      is_onboarding: $is_onboarding
      is_primary: $is_primary
      referral_key: $referral_key
    ) {
      id
    }
  }
`;
