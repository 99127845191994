import predicates from 'auth0/predicates';
import React from 'react';
import PrivateRoute from 'routes/PrivateRoute';
import { pathManager } from 'routes/consts';
import ChooseNewJobType from 'screens/company-user/choose-new-job-type';
import CreateProfile from 'screens/company-user/create-profile';
import Dashboard from 'screens/company-user/dashboard';
import CompanyJob from 'screens/company-user/job';
import CompanyJobBoard from 'screens/company-user/job-board';
import JobForm from 'screens/company-user/job-form';
import PendingTalents from 'screens/company-user/pending-talents';
import CompanySettings from 'screens/company-user/settings';
import Users from 'screens/company-user/users';
import Workforce from 'screens/company-user/workforce';
import LazyProfile from 'screens/profile';

export const COMPANY_ROUTES = [
  <PrivateRoute
    exact
    predicate={predicates.isCompany}
    path={pathManager.company.createProfile.getRoute()}
    key={pathManager.company.createProfile.getRoute()}
    component={CreateProfile}
  />,
  <PrivateRoute
    exact
    predicate={predicates.isCompany}
    component={LazyProfile}
    path={pathManager.company.talentProfile.getRoute()}
    key={pathManager.company.talentProfile.getRoute()}
  />,
  <PrivateRoute
    component={CompanySettings}
    exact
    predicate={predicates.isCompany}
    path={pathManager.company.settings.main.getRoute()}
    key={pathManager.company.settings.main.getRoute()}
  />,
  <PrivateRoute
    component={Users}
    exact
    predicate={predicates.isCompany}
    key={pathManager.company.users.edit.getRoute()}
    path={[
      pathManager.company.users.edit.getRoute(),
      pathManager.company.users.create.getRoute(),
    ]}
  />,
  <PrivateRoute
    component={CompanyJobBoard}
    exact
    predicate={predicates.isCompany}
    path={pathManager.company.jobBoard.getRoute()}
    key={pathManager.company.jobBoard.getRoute()}
  />,
  <PrivateRoute
    component={JobForm}
    exact
    predicate={predicates.isCompany}
    key={pathManager.company.editJob.getRoute()}
    path={[
      pathManager.company.editJob.getRoute(),
      pathManager.company.newJob.form.getRoute(),
      pathManager.company.duplicateJob.getRoute(),
    ]}
  />,
  <PrivateRoute
    component={ChooseNewJobType}
    predicate={predicates.isCompany}
    path={pathManager.company.newJob.choose.getRoute()}
    key={pathManager.company.newJob.choose.getRoute()}
  />,
  <PrivateRoute
    exact
    component={CompanyJob}
    predicate={predicates.isCompany}
    path={pathManager.company.job.getRoute()}
    key={pathManager.company.job.getRoute()}
  />,
  <PrivateRoute
    exact
    predicate={predicates.isCompany}
    component={Workforce}
    path={pathManager.company.workforce.getRoute()}
    key={pathManager.company.workforce.getRoute()}
  />,
  <PrivateRoute
    component={Dashboard}
    exact
    predicate={predicates.isCompany}
    path={pathManager.company.dashboard.getRoute()}
    key={pathManager.company.dashboard.getRoute()}
  />,
  <PrivateRoute
    component={PendingTalents}
    exact
    predicate={predicates.isCompany}
    path={pathManager.company.pendingTalents.getRoute()}
    key={pathManager.company.pendingTalents.getRoute()}
  />,
];
