import { ConnectedPageLayout } from 'components/layout/page-layout';
import useMediaQueries from 'hooks/common/useMediaQueries';
import React from 'react';
import { usePDF } from 'react-to-pdf';

import { Box, Grid } from '@mui/material';

import { Job, Talent } from '@libs/graphql-types';
import { noop } from '@libs/helpers/common';
import {
  calcMatchersSeats,
  isUnprocessableJob,
} from '@libs/ui/components/job/utils';
import Typography from '@libs/ui/components/typography';

import TitleLine from './TitleLine';
import { ApplyButton } from './apply-button';
import ApplyForm from './apply-form';
import { ApplyToSourceModal } from './apply-to-source-modal';
import {
  useIsAppliedToSource,
  useMatcherApplication,
  useScreenData,
} from './hooks';
import HowItWorks from './how-it-works';
import JobComments from './job-comments';
import JobInfo from './job-info';
import { JobTalentSugestions } from './job-talent-suggestions';
import { CandidatrProposalModal } from './present-candidate/candidate-proposal-modal';
import { ScreenProps } from './types';

const SourceJob = ({ match }: ScreenProps) => {
  const { isMD } = useMediaQueries();
  const jobId = match.params.id;
  const { job, isLoading, application } = useScreenData({ id: jobId });
  const seatsLeft = calcMatchersSeats(job?.matchers?.length || 0);
  const matcherApplication = useMatcherApplication(
    job?.job_matcher_applications || [],
  );
  const { toPDF, targetRef } = usePDF({ filename: `${job?.name}.pdf` });
  const isAppliedToSource = useIsAppliedToSource(
    (job?.matchers as Talent[]) || [],
  );
  const applicationStatus = isAppliedToSource
    ? 'APPROVED'
    : matcherApplication?.status || 'UNKNOWN';
  const isApproved = applicationStatus === 'APPROVED';

  const unprocessable = isUnprocessableJob(job);

  return (
    <ConnectedPageLayout
      documentTitle="Job board"
      drawerProps={{}}
      headerProps={{ accountProps: {} }}
      isLoading={isLoading}
    >
      {job ? (
        <Box>
          <Grid container>
            <Grid item md={isMD ? 12 : 8}>
              <Box mb={4}>
                <TitleLine
                  matcherApplicationStatus={applicationStatus}
                  seatsLeft={seatsLeft}
                  job={job}
                  onPDFCreate={() => toPDF()}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={4}
            flexWrap={isMD ? 'wrap' : 'nowrap'}
            flexDirection={isMD ? 'column' : 'row'}
          >
            <Grid item md={isMD ? 12 : 8}>
              <Box pb={isMD ? 4 : 8}>
                <JobInfo
                  ref={targetRef}
                  job={job as Required<Job>}
                  applyButton={
                    <ApplyButton
                      job={job}
                      disabled={unprocessable}
                      matcherApplicationStatus={applicationStatus}
                      seatsLeft={seatsLeft}
                    />
                  }
                />
              </Box>

              <JobTalentSugestions job={job} />

              {unprocessable ? (
                <HowItWorks />
              ) : applicationStatus === 'APPROVED' ? null : !seatsLeft ||
                applicationStatus === 'REJECTED' ? (
                <ApplyForm
                  applicationStatus={applicationStatus}
                  application={application}
                  job={job as Job}
                />
              ) : (
                <HowItWorks />
              )}
            </Grid>
            <Grid item md={isMD ? 12 : 4}>
              {isApproved && <JobComments job={job} />}
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box maxWidth="768px" pt={4}>
          <Typography variant="h5">{`Job ${jobId} not found`}</Typography>
        </Box>
      )}
      {job && (
        <>
          <ApplyToSourceModal job={job} />
          <CandidatrProposalModal onSuccess={noop} jobId={job.id} />
        </>
      )}
    </ConnectedPageLayout>
  );
};

export default SourceJob;
