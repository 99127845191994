import React from 'react';

import { Card, CardContent } from '@mui/material';

import Typography from '@libs/ui/components/typography';

interface JobsCardProps {
  jobsCount?: number;
}

export const JobsCard = ({ jobsCount }: JobsCardProps) => {
  return (
    <Card style={{ height: '100%' }} elevation={0}>
      <CardContent style={{ paddingBottom: 16 }}>
        <Typography variant="h6" paragraph fontWeight={500}>
          Jobs
        </Typography>

        <Typography variant="body1">
          <Typography color="info.main" component="span">
            {jobsCount || 0}
          </Typography>{' '}
          jobs currently live
        </Typography>
      </CardContent>
    </Card>
  );
};
