import MuiTypography, {
  TypographyProps as MuiTypographyProps,
} from '@mui/material/Typography';
import cn from 'classnames';
import React from 'react';

import { CUSTOM_COLORS, CUSTOM_VARIANTS } from './consts';
import useStyles from './styles';
import { CustomColors, CustomVariants } from './types';

export interface TypographyProps
  extends Omit<MuiTypographyProps, 'variant' | 'color'> {
  variant?: MuiTypographyProps['variant'] | CustomVariants;
  transform?: React.CSSProperties['textTransform'];
  fontWeight?: React.CSSProperties['fontWeight'];
  color?: MuiTypographyProps['color'] | CustomColors;
  component?: string;
  pointer?: boolean;
}

const Typography = (props: TypographyProps) => {
  const { variant, className, color, pointer, ...rest } = props;
  const styles = useStyles(props);
  const isCustomVariant = !!variant && CUSTOM_VARIANTS.includes(variant as any);
  const isCustomColor = !!color && CUSTOM_COLORS.includes(color as any);
  const currentVariant = (
    isCustomVariant ? undefined : variant
  ) as MuiTypographyProps['variant'];
  const currentColor = (
    isCustomColor ? undefined : color
  ) as MuiTypographyProps['color'];

  return (
    <MuiTypography
      {...(isCustomVariant && { component: 'span' })}
      {...rest}
      className={cn(className, styles.text, {
        [styles[variant as CustomVariants]]: isCustomVariant,
        [styles[color as CustomVariants]]: isCustomColor,
      })}
      variant={currentVariant}
      color={currentColor}
    />
  );
};

Typography.displayName = 'CustomTypography';

export default Typography;
