import { TalentMatcherContextProvider } from 'components/contexts';
import React, { Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { AppLoadingScreen, AuthenticatedRoute } from 'screens/auth/helpers';
import { NotFound } from 'screens/not-found';

import { PUBLIC_ROUTES, AUTH_ROUTES } from './grouped';

const Router = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<AppLoadingScreen />}>
        <TalentMatcherContextProvider>
          <Switch>
            {AUTH_ROUTES}
            {PUBLIC_ROUTES}

            <Route path="*" component={AuthenticatedRoute} />

            {/* Else */}
            <Route component={NotFound} />
          </Switch>
        </TalentMatcherContextProvider>
      </Suspense>
    </BrowserRouter>
  );
};

export default Router;
