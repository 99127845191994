import { DEFAULT_AVATAR } from 'consts/common';
import React from 'react';

import { Avatar, AvatarGroup, Box, Card, CardContent } from '@mui/material';

import { GetCompanyViewScreenDataQuery } from '@libs/graphql-types';
import Typography from '@libs/ui/components/typography';

interface CommunityCardProps {
  talents?: Required<GetCompanyViewScreenDataQuery>['talentsSearch']['data'];
  total?: number;
}

export const CommunityCard = ({ total = 0, talents }: CommunityCardProps) => {
  return (
    <Card style={{ height: '100%' }} elevation={0}>
      <CardContent style={{ paddingBottom: 16 }}>
        <Typography variant="h6" paragraph fontWeight={500}>
          Community
        </Typography>

        {!!talents?.length && (
          <Box mb={4}>
            <AvatarGroup max={4} style={{ justifyContent: 'flex-end' }}>
              {talents?.map((talent) => (
                <Avatar
                  alt="Talent"
                  src={talent.talent?.avatar?.avatar || DEFAULT_AVATAR}
                />
              ))}
            </AvatarGroup>
          </Box>
        )}

        <Typography variant="body1">
          <Typography color="info.main" component="span">
            {total || 0}
          </Typography>{' '}
          vetted members
        </Typography>
      </CardContent>
    </Card>
  );
};
