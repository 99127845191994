export const LOCAL_STORAGE_KEYS = {
  talentOnboardingEmail: 'talentOnboardingEmail',
  talentOnboardingTag: 'talentOnboardingTag',
  talentOnboardingAppliedJobId: 'talentOnboardingAppliedJobId',
  talentInvitationUid: 'talentInvitationUid',

  talentOnboardingDeviceId: 'talentOnboardingDeviceId',
  talentOnboardingCompanyId: 'talentOnboardingCompanyId',
  talentOnboardingOrigin: 'talentOnboardingOrigin',

  companyOnboardingDeviceId: 'companyOnboardingDeviceId',

  perksLivePopup: (id: string | number) => `perksLivePopup_${id}`,
  lobbyPopup: (id: string | number) => `lobbyPopup_${id}`,
  verifiedLobbyPopup: (id: string | number) => `lobbyPopup_verified_${id}`,
  paidTalentWelcome: (id: string | number) => `paidTalentWelcome_${id}`,

  boosterBanner: (id: string | number) => `booster_{id}`,

  companyDirectJobPagePopup: 'companyDirectJobPagePopup',

  currentAccount: 'currentAccount',

  createCommunityByTalentHeaderBadge: 'createCommunityByTalentHeaderBadge',

  authError: 'authError',
};
