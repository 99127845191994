import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import { DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone';
import { getDropzoneRejectionMessage } from 'utils/dropzone';

import CloseIcon from '@mui/icons-material/Close';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { Grid } from '@mui/material';

import Typography from '@libs/ui/components/typography';

import useStyles from './styles';

export interface DropzoneProps {
  dropzoneOptions?: DropzoneOptions;
  Icon?: React.ElementType;
  label?: string;
  name?: string;
  error?: boolean;
  helperText?: string;
  onChange?: (e: File[]) => void;
  value?: File[];
  withoutList?: boolean;
}

const DEFAULT_LABEL = "Drag 'n drop files here, or click to select.";

const Dropzone = (props: DropzoneProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles(props);
  const {
    onChange,
    dropzoneOptions,
    Icon = PostAddIcon,
    label = DEFAULT_LABEL,
    name,
    value = [],
    withoutList = false,
  } = props;
  const handleChange = useCallback(
    (files: File[]) => {
      if (onChange) onChange(files);
    },
    [onChange],
  );
  const handleReject = useCallback(
    (e: FileRejection[]) => {
      const errMsg = getDropzoneRejectionMessage(e);
      if (enqueueSnackbar) {
        enqueueSnackbar(errMsg, { variant: 'error' });
      }
      // eslint-disable-next-line no-console
      console.error(errMsg);
    },
    [enqueueSnackbar],
  );
  const onDocumentRemove = (index: number) => {
    const documents = value.filter((_, i) => i !== index);
    if (onChange) onChange(documents);
  };
  const { getRootProps, getInputProps } = useDropzone({
    ...dropzoneOptions,
    onDropAccepted: handleChange,
    onDropRejected: dropzoneOptions?.onDropRejected || handleReject,
  });

  return (
    <Grid className={classes.container} container>
      <Grid
        justifyContent="center"
        alignItems="center"
        direction="column"
        className={classes.dropzone}
        {...getRootProps()}
        container
        item
      >
        <Grid item>
          <Icon />
        </Grid>
        <Grid item>
          <Typography variant="overline" color="textSecondary">
            {label}
          </Typography>
        </Grid>
        <input name={name} {...getInputProps()} />
      </Grid>
      {!withoutList && (
        <Grid component="ul" className={classes.fileList} item>
          {value.map((file, index) => {
            const title = file?.name || (file as any)?.title;
            return (
              <Grid
                wrap="nowrap"
                className={classes.fileItem}
                container
                key={title}
              >
                <Grid
                  className={classes.fileItemText}
                  component={Typography as any}
                  item
                  variant="body2"
                >
                  {title}
                </Grid>
                {!!value?.length && !!onChange && (
                  <Grid item>
                    <CloseIcon
                      className={classes.removeIcon}
                      onClick={() => onDocumentRemove(index)}
                    />
                  </Grid>
                )}
              </Grid>
            );
          })}
        </Grid>
      )}
    </Grid>
  );
};

export default Dropzone;
