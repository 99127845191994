import React from 'react';

import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import Typography from '@libs/ui/components/typography';

import leftImage from './coupon-left-border.svg';
import rightImage from './coupon-right-border.svg';

export interface PerkCardProps {
  serviceName: string;
  title: string;
  text: React.ReactNode;
  logo: string;
  logo2x?: string;
  actions: React.ReactNode;
}
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 608,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  headerRoot: {
    paddingBottom: 0,
    padding: theme.spacing(6, 6, 0, 6),
  },
  image: {
    display: 'block',
    maxWidth: 56,
    maxHeight: 56,
  },
  contentRoot: {
    flexGrow: 1,
    padding: theme.spacing(4, 6),
  },
  headerContent: {
    minHeight: 56,
    display: 'flex',
    alignItems: 'center',
  },
  actionsRoot: {
    padding: theme.spacing(0, 6, 6, 6),
  },
  coupon: {
    padding: theme.spacing(4),
    border: '1px solid rgba(0, 0, 0, 0.38)',
    background: '#FFFEDB',
    borderLeft: 'none',
    borderRight: 'none',
    position: 'relative',
    margin: theme.spacing(0, 3, 4),

    '& p': {
      whiteSpace: 'nowrap',
      overflowX: 'hidden',
      width: '100%',
      textOverflow: 'ellipsis',
    },

    '&:before': {
      content: '""',
      width: 10,
      height: '100%',
      position: 'absolute',
      background: `url(${leftImage}) no-repeat`,
      backgroundSize: 'cover',
      left: -9,
      top: 0,
    },
    '&:after': {
      content: '""',
      width: 10,
      height: '100%',
      position: 'absolute',
      background: `url(${rightImage}) no-repeat`,
      backgroundSize: 'cover',
      right: -8,
      top: 0,
    },
  },
}));

export const PerkCard = ({
  actions,
  serviceName,
  logo2x,
  title,
  logo,
  text,
}: PerkCardProps) => {
  const classes = useStyles();

  return (
    <Card className={classes.root} elevation={0}>
      <CardHeader
        disableTypography
        classes={{ content: classes.headerContent, root: classes.headerRoot }}
        title={
          <Grid display="flex" alignItems="center" container>
            <Grid flexGrow={1} item>
              <Typography variant="h6">{serviceName}</Typography>
            </Grid>
            {!!logo && (
              <Grid item>
                <img
                  {...(logo2x ? { srcSet: `${logo2x} 2x` } : {})}
                  src={logo}
                  alt="logo"
                  className={classes.image}
                />
              </Grid>
            )}
          </Grid>
        }
      />
      <CardContent className={classes.contentRoot}>
        <Box className={classes.coupon}>
          <Typography variant="body2" title={title} fontWeight={600}>
            {title}
          </Typography>
        </Box>
        <Typography variant="body2" color="textSecondary">
          {text}
        </Typography>
      </CardContent>
      <CardActions classes={{ root: classes.actionsRoot }}>
        {actions}
      </CardActions>
    </Card>
  );
};
