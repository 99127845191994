import { ErrorBoundary } from '@sentry/react';
import React from 'react';

interface CustomErrorBoundaryProps extends React.PropsWithChildren<unknown> {}

const CustomErrorBoundary = ({ children }: CustomErrorBoundaryProps) => {
  return <ErrorBoundary>{children}</ErrorBoundary>;
};

export default CustomErrorBoundary;
