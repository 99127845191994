import { ReactComponent as Comas } from 'assets/icons/commas.svg';
import quoteAvatar from 'assets/images/abn-staff-avatar.png';
import cn from 'classnames';
import React from 'react';

import { Avatar, Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Typography from '@libs/ui/components/typography';

interface QuoteSidebarProps { }

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    background: 'transparent',
    alignItems: 'center',
    maxWidth: 360,
  },
  card: {
    width: '100%',
  },
  cardContent: {
    padding: theme.spacing(6),
  },
  userBlock: {
    minWidth: 240,
  },
  text: {
    maxWidth: 480,
    position: 'relative',
  },
  coma: {
    position: 'absolute',
    content: "''",
    opacity: '0.8',
  },
  comaUp: {
    top: '-9px',
    left: '-32px',

    [theme.breakpoints.down('md')]: {
      left: '-12px',
    },
  },
  comaDown: {
    bottom: '-10px',
    right: '-20px',
  },

  cardGrid: {
    wrap: 'nowrap',

    [theme.breakpoints.down('md')]: {
      wrap: 'wrap',
    },
  },

  greyText: {
    color: 'rgba(255, 255, 255, 0.70)',
  },
}));

const QuoteSidebar = (props: QuoteSidebarProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.card}>
        <Box className={classes.cardContent}>
          <Grid
            spacing={8}
            className={classes.cardGrid}
            alignItems="center"
            container
          >
            <Grid item>
              <Box className={classes.text}>
                <Typography>
                  OpenTalent is perfect for connecting freelancers with
                  assignments. Its simplicity and user-friendliness makes it a
                  great tool for ABN Amro, but for freelancers too.
                </Typography>
                <Comas className={cn(classes.coma, classes.comaUp)} />
                <Comas className={cn(classes.coma, classes.comaDown)} />
              </Box>
            </Grid>

            <Grid item>
              <Grid
                className={classes.userBlock}
                wrap="nowrap"
                spacing={4}
                container
              >
                <Grid item>
                  <Avatar
                    sx={{ width: 60, height: 60 }}
                    alt="Avatar"
                    src={quoteAvatar}
                  />
                </Grid>
                <Grid item>
                  <Typography lineHeight="20px">Linda Beugeling</Typography>
                  <Typography
                    lineHeight="18px"
                    variant="body2"
                    className={classes.greyText}
                  >
                    Domain Expert Flex-Staffing
                  </Typography>
                  <Typography lineHeight="24px" className={classes.greyText}>
                    ABN AMRO
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <span />
      </Box>
    </Box>
  );
};

export default QuoteSidebar;
