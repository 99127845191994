import { mergeDeep } from '@apollo/client/utilities';
import { useCallback } from 'react';

import {
  useCreateCompanyForUserMutation,
  useCreateCompanyUserMutation,
} from '@libs/graphql-types';

import { OldCompanyOnboardingProviderValue } from './context/OnboardingContext';
import { mapCompanyValuesToServer, mapUserValuesToServer } from './utils';

export const useFinalSubmitAction = () => {
  const [createUser, { loading }] = useCreateCompanyUserMutation();
  const [createCompany, { loading: isCompanyCreating }] =
    useCreateCompanyForUserMutation();

  const onSubmit = useCallback(
    async ({ user, company }: OldCompanyOnboardingProviderValue) => {
      const res = await createUser({ variables: mapUserValuesToServer(user) });
      const id = res?.data?.createCompanyUserProfile?.id;

      if (!id) {
        throw new Error('No user id provided');
      }

      await createCompany({
        variables: mergeDeep(mapCompanyValuesToServer(company), {
          primary_company_user_id: id,
        }),
      });
    },
    [createCompany, createUser],
  );

  return { onSubmit, loading: loading || isCompanyCreating };
};
