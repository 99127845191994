import { useIsPaidMatcherAccount } from 'hooks/talents/useTalentAccountType';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import TalentMatcherJobBoard from 'screens/talent-matcher/job-board/TalentMatcherJobBoard';

import JobBoardV2 from './JobBoardV2';

interface TalentJobBoardSwitcherProps extends RouteComponentProps { }

const TalentJobBoardSwitcher = (props: TalentJobBoardSwitcherProps) => {
  const isMatcher = useIsPaidMatcherAccount();

  return isMatcher ? (
    <TalentMatcherJobBoard {...props} />
  ) : (
    <JobBoardV2 {...props} />
  );
};

export default TalentJobBoardSwitcher;
