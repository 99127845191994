import { useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';

import { Mutation, MutationCreateJobSuggestionArgs } from '@libs/graphql-types';

import { CREATE_JOB_SUGGESTION } from './queries';

export const useSuggestionAction = (job_id: string) => {
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [request, { loading }] = useMutation<
    Mutation,
    MutationCreateJobSuggestionArgs
  >(CREATE_JOB_SUGGESTION, {
    onCompleted: (res) => {
      if (res.createJobSuggestion) {
        setIsSubmitted(true);
      }
    },
  });

  const onRequest = useCallback(
    (vars: Omit<MutationCreateJobSuggestionArgs, 'job_id'>) => {
      request({ variables: { ...vars, job_id } });
    },
    [job_id, request],
  );

  return { onRequest, loading, isSubmitted };
};
