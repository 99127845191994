import React from 'react';

import { Job } from '@libs/graphql-types';
import {
  CampaignStatus,
  getCampaignStatus,
} from '@libs/ui/components/job/utils';
import Typography from '@libs/ui/components/typography';

import { useIntervalTimer } from './hooks';

interface JobTimerProps {
  job: Job;
  currentTime: Date;
}

const formatDateValue = (num: number) => num.toString().padStart(2, '0');

const JobTimer = ({ job, currentTime }: JobTimerProps) => {
  const campaignStatus = getCampaignStatus(job);
  const duration = useIntervalTimer({
    start: job.campaign_start_date,
    end: job.campaign_end_date,
    currentTime,
  });

  if (!duration) {
    return null;
  }

  if (campaignStatus === CampaignStatus.Finished) {
    return (
      <Typography variant="h6" color="textSecondary">
        Finished
      </Typography>
    );
  }

  if (campaignStatus === CampaignStatus.Archived) {
    return (
      <Typography variant="h6" color="textSecondary">
        Closed
      </Typography>
    );
  }

  const { days = 0, hours = 0, minutes = 0 } = duration;

  return (
    <Typography
      lineHeight="26px"
      variant="h6"
      color="textSecondary"
    >{`${formatDateValue(days)}d:${formatDateValue(hours)}h:${formatDateValue(
      minutes,
    )}m`}</Typography>
  );
};

export default JobTimer;
