import React from 'react';

import { useChatCreationStrategyFromLocation } from './hooks';

interface FromLocationChatCreatorProps {}

const FromLocationChatCreator = (props: FromLocationChatCreatorProps) => {
  useChatCreationStrategyFromLocation();

  return <div />;
};

export default FromLocationChatCreator;
