import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { parse, stringify } from 'utils/querystring';

interface Args {
  replace?: boolean;
  query: Record<string, any>;
  pathname?: string;
}

export const usePushWithQuery = () => {
  const history = useHistory();
  const parsedQuery = useMemo(
    () => parse(history.location.search.slice(1)),
    [history.location.search],
  );

  return useCallback(
    ({ replace, pathname, query }: Args) => {
      const newQuery = replace ? query : { ...parsedQuery, ...query };
      const action = replace ? history.replace : history.push;
      action({ pathname, search: stringify(newQuery) });
    },
    [history, parsedQuery],
  );
};
