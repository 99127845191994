import { UserInterface, UserRoles } from 'auth0/types';
import {
  PredicateRejectionReasons,
  ProtectedRoutePredicate,
} from 'routes/types';

import { AuthUtils } from './utils';

export const isAuthenticated: ProtectedRoutePredicate = (user: UserInterface) =>
  user ? null : PredicateRejectionReasons.NotAuthenticated;

const getUserRoles = (user: UserInterface) => {
  return user?.['https://opentalent.co']?.user_roles || [];
};

// const hasRightRole =
//   (role: UserRoles): ProtectedRoutePredicate =>
//     (user: UserInterface) => {
//       const authError = isAuthenticated(user);
//       if (authError) {
//         return authError;
//       }
//       const hasRole = getUserRoles(user).includes(role);
//
//       return hasRole ? null : PredicateRejectionReasons.WrongRoleType;
//     };

const isPassValidator =
  (validator: (user: UserInterface) => boolean): ProtectedRoutePredicate =>
    (user: UserInterface) => {
      const authError = isAuthenticated(user);
      if (authError) {
        return authError;
      }
      const hasRole = validator(user);

      return hasRole ? null : PredicateRejectionReasons.WrongRoleType;
    };

const hasNoRole =
  (role: UserRoles): ProtectedRoutePredicate =>
    (user: UserInterface) => {
      const authError = isAuthenticated(user);
      if (authError) {
        return authError;
      }
      const hasNoRole = getUserRoles(user).includes(role);

      return hasNoRole ? null : PredicateRejectionReasons.WrongRoleType;
    };

const isTalent: ProtectedRoutePredicate = (user: UserInterface) =>
  isPassValidator(AuthUtils.isTalent)(user);

const isCompany: ProtectedRoutePredicate = (user: UserInterface) =>
  isPassValidator(AuthUtils.isCompany)(user);

const isNotCompany: ProtectedRoutePredicate = (user: UserInterface) =>
  hasNoRole(UserRoles.Company)(user);

const predicates = {
  isTalent,
  isCompany,
  isNotCompany,
};

export default predicates;
