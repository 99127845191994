import errors from 'consts/validationErrors';
import * as yup from 'yup';

import { maxStringValidator } from '@libs/helpers/yup';

export const getValidator = () =>
  yup.object().shape({
    name: maxStringValidator.required(errors.required),
    website: maxStringValidator.customUrl(),
  });
