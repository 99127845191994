import { gql } from '@apollo/client';

export default gql`
  fragment FullUser on User {
    stream_chat_id
    stream_chat_token
    is_primary
    id
    email
    first_name
    last_name
    position
    account_settings {
      receive_chat_messages
      receive_company_and_product_updates
      receive_job_invitation_responses
      receive_talent_join_notifications
      receive_talent_proposal_notifications
    }
    company {
      id
      account_type
      name
      logo
      website
      type_of_activity
      sign_up_landing_link
      country
      about
    }
    created_at
    updated_at
    avatar {
      avatar
      hash
    }
  }
`;
