import FixedFooter from 'components/custom/onboarding/fixed-footer';
import { useFormikContext } from 'formik';
import React from 'react';
import { pathManager } from 'routes';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { modelPath } from '@libs/helpers/form';
import { RouterButton } from '@libs/ui/components/button';
import { ConnectedTextField } from '@libs/ui/components/form/text-field';

import { TOTAL_STEPS } from '../../consts';
import NextButtonWithValidation from '../../shared/NextButtonWithValidation';
import StepTemplate from '../../shared/StepTemplate';
import QuoteSidebar from '../../sidebars/QuoteSidebar';
import { OnboardingV3State } from '../../types';

interface AddCompanyProps {
  loading: boolean;
}

export const useStyles = makeStyles((theme) => ({
  fieldsWrapper: {
    width: 500,
    margin: theme.spacing(0, 2),

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },

    '& .MuiFormHelperText-root': {
      color: 'white',
      opacity: 0.7,
    },
  },
  footer: {
    position: 'absolute',
  },
}));

export const AddCompany = ({ loading }: AddCompanyProps) => {
  const classes = useStyles();
  const { submitForm } = useFormikContext<OnboardingV3State>();

  return (
    <StepTemplate
      title="Where do you work?"
      subtitle="Select a company, or add one"
      totalSteps={TOTAL_STEPS}
      currentStep={2}
      SidebarContentContent={QuoteSidebar}
    >
      <Box className={classes.fieldsWrapper}>
        <Box mb={4}>
          <ConnectedTextField
            name={modelPath<OnboardingV3State>((m) => m.company.name)}
            fullWidth
            variant="filled"
            label="Company Name *"
          />
        </Box>
      </Box>

      <FixedFooter className={classes.footer}>
        <Grid spacing={4} justifyContent="center" container>
          <Grid xs={6} item>
            <RouterButton
              startIcon={<ArrowBackIcon />}
              to={pathManager.company.onboardingV3.general.generatePath()}
              color="primary"
              variant="outlined"
              fullWidth
            >
              Back
            </RouterButton>
          </Grid>
          <Grid xs={6} item>
            <NextButtonWithValidation
              path={modelPath<OnboardingV3State>((m) => m.company.name)}
              onClick={submitForm}
              disabled={loading}
            >
              next
            </NextButtonWithValidation>
          </Grid>
        </Grid>
      </FixedFooter>
    </StepTemplate>
  );
};
