import { ChatTypes } from 'components/chat';
import {
  isCompanyChatUser,
  mapChatUserData,
} from 'components/chat/client-to-talent';
import {
  useStreamChatContext,
  CompanyUserChatData,
  CustomChatUserData,
} from 'components/chat/common/provider';
import { createTalentToTalentChannel } from 'components/chat/talent-to-talent';
import { useEffect, useMemo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { createUserToTalent } from 'screens/profile/modals/chat-with-talent/hooks';
import { DefaultGenerics, StreamChat } from 'stream-chat';
import { useChatContext } from 'stream-chat-react';

export interface ChatCreationStrategy<CreationData extends unknown = {}> {
  type: ChatTypes;
  data: CreationData;
}

export const createUserToTalentChat = async (
  client: StreamChat<DefaultGenerics>,
  { user1, user2 }: { user1: CustomChatUserData; user2: CustomChatUserData },
) => {
  if (!user1 || !user2) {
    console.error('No data to create chat!');
    return null;
  }
  const chatData = isCompanyChatUser(user1)
    ? { talentData: user2, userData: user1 }
    : { talentData: user1, userData: user2 as CompanyUserChatData };
  const createdChannel = await createUserToTalent(client, chatData);

  await createdChannel?.create();

  return createdChannel;
};

const STRATEGY_MAP: Record<ChatTypes, (...args: any[]) => any> = {
  [ChatTypes.UserToTalent]: createUserToTalentChat,
  [ChatTypes.TalentToTalent]: createTalentToTalentChannel,
  // NOTE: FIX it later
  [ChatTypes.JobChannel]: createUserToTalentChat,
};

export const useChatCreationStrategy = async (
  creatingStrategy: ChatCreationStrategy,
) => {
  const { client, userData } = useStreamChatContext();
  const chatContext = useChatContext();
  const { type, data } = creatingStrategy;
  const applyStrategy = STRATEGY_MAP[type];
  const createChannel = useCallback(async () => {
    const channel = await applyStrategy?.(client, {
      user1: data,
      user2: userData,
    });
    if (channel) {
      chatContext.setActiveChannel(channel);
    }
    return;
  }, [applyStrategy, chatContext, client, data, userData]);

  useEffect(() => {
    createChannel();
  }, [createChannel]);
};

export const useChatCreationStrategyFromLocation = () => {
  const location = useLocation<any>();

  const hookData = useMemo(() => {
    const { data: user, type } = location.state?.strategy || {};
    const userChatData = user ? mapChatUserData(user) : {};

    return { type, data: userChatData };
  }, [location.state?.strategy]);

  useChatCreationStrategy(hookData);
};
