/* eslint-disable func-names */
import React from 'react';
import { useHistory } from 'react-router-dom';

import { DefaultModalProps } from './types';

interface WithLocationStateModalArgs<S extends unknown> {
  id: string;
  getModalKey?: (data: S) => string;
  renderAlways?: boolean;
}

export function withLocationStateModal<S extends unknown>({
  id,
  renderAlways = false,
  getModalKey,
}: WithLocationStateModalArgs<S>) {
  return function <T extends DefaultModalProps<S>>(
    Component: React.ComponentType<T>,
  ) {
    return (props: Omit<T, 'isOpen' | 'close' | 'modalData'>) => {
      const history = useHistory<Record<string, S>>();
      const modalData = history.location.state?.[id];
      const hasModalKey =
        getModalKey && modalData
          ? getModalKey(modalData) === props.modalKey
          : true;

      const isOpen = !!modalData && hasModalKey;
      const close = () =>
        history.replace({
          ...history.location,
          state: { [id]: null } as Record<string, S>,
        });

      const ModalComponent = Component as React.ComponentType<
        DefaultModalProps<S>
      >;

      if (!renderAlways && !isOpen) {
        return null;
      }

      return (
        <ModalComponent
          {...props}
          isOpen={isOpen}
          modalData={modalData}
          close={close}
        />
      );
    };
  };
}
