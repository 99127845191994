import React from 'react';

import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Button from '@libs/ui/components/button';
import { ConnectedSelect } from '@libs/ui/components/form/select';
import Typography from '@libs/ui/components/typography';

import { CATEGORIES_OPTIONS } from '../content/consts';

interface FilterProps { }

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-root': {
      background: 'transparent',
    },
  },
  alignCenter: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export const Filter = (props: FilterProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid flexGrow={1} item>
          <Grid style={{ marginBottom: 8 }} spacing={4} container>
            <Grid item>
              <ConnectedSelect
                name="category"
                options={CATEGORIES_OPTIONS}
                fullWidth
                variant="standard"
                label="Category"
                displayEmpty
                labelShrink
                noneItemText="All"
              />
            </Grid>
            <Grid item>
              <ConnectedSelect
                name="country"
                options={[]}
                fullWidth
                variant="standard"
                labelShrink
                displayEmpty
                label="Country"
                noneItemText="Europe"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.alignCenter} item>
          <Grid container spacing={4}>
            <Grid className={classes.alignCenter} item>
              <Typography variant="body2">
                Is there a perk you would like us to have?
              </Typography>
            </Grid>
            <Grid item>
              <Button
                component="a"
                {...{ target: '_blank' }}
                href="https://form.typeform.com/to/OuY1IylV"
                variant="outlined"
                color="info"
              >
                Fill in the form
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
