import FixedFooter from 'components/custom/onboarding/fixed-footer';
import React from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Collapse, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { JobTypeEnum } from '@libs/graphql-types';
import { modelPath } from '@libs/helpers/form';
import { RouterButton } from '@libs/ui/components/button';
import { ConnectedTextField } from '@libs/ui/components/form/text-field';
import { formatRate } from '@libs/ui/components/job/utils';

import { RATES } from '../consts';
import { useDefaultOnSubmitHandler } from '../form-context/hooks';
import { BuilderFormState } from '../form-context/types';
import NextButtonWithValidation from '../shared/NextButtonWithValidation';
import StepTemplate from '../shared/StepTemplate';
import { useJobTypeState } from '../shared/hooks';
import { DefaultStepProps } from '../shared/types';

interface JobRateProps extends DefaultStepProps { }

const useStyles = makeStyles((theme) => ({
  form: {
    maxWidth: 430,
    margin: '0 auto',

    '& h6': {
      textAlign: 'right',
    },

    '& .MuiFormHelperText-root': {
      color: 'white',
      textAlign: 'right',
      marginRight: 0,

      '&.Mui-error': {
        color: theme.palette.error.main,
      },
    },
  },
  fields: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}));

const TEXTS = {
  [JobTypeEnum.Freelance]: {
    title: 'Select Hourly Rate',
    subtitle: 'Set minimum and maximum',
    currentStepLabel: 'Rate',
  },
  [JobTypeEnum.Permanent]: {
    title: 'Select Monthly Salary',
    subtitle: 'Set minimum and maximum',
    currentStepLabel: 'Salary',
  },
  [JobTypeEnum.Project]: {
    title: 'Select Monthly Salary',
    subtitle: 'Set minimum and maximum',
    currentStepLabel: 'Salary',
  },
};

const JobRate = ({ index, nextStepRoute, prevStepRoute }: JobRateProps) => {
  const classes = useStyles();
  const jobType = useJobTypeState();
  const currentRates = RATES[jobType];
  const { onSubmit, loading } = useDefaultOnSubmitHandler({
    redirectRoute: nextStepRoute,
  });
  const currentTexts = TEXTS[jobType];

  return (
    <StepTemplate {...currentTexts} currentStep={index}>
      <Box className={classes.form}>
        <Collapse in={jobType === JobTypeEnum.Freelance}>
          <Grid className={classes.fields} spacing={4} container>
            <Grid xs={6} item>
              <ConnectedTextField
                name={modelPath<BuilderFormState>((m) => m.rate.minRate)}
                data-test-id="firstName"
                fullWidth
                variant="filled"
                label="Min rate"
              />
            </Grid>
            <Grid xs={6} item>
              <ConnectedTextField
                name={modelPath<BuilderFormState>((m) => m.rate.maxRate)}
                data-test-id="firstName"
                fullWidth
                variant="filled"
                label="Max rate"
                helperText={`Max. ${formatRate({
                  min: currentRates.max,
                  period: 'hour',
                })}`}
              />
            </Grid>
          </Grid>
        </Collapse>

        <Collapse in={jobType === JobTypeEnum.Permanent}>
          <Grid className={classes.fields} spacing={4} container>
            <Grid xs={12} sm={6} item>
              <ConnectedTextField
                name={modelPath<BuilderFormState>((m) => m.rate.minSalary)}
                data-test-id="firstName"
                fullWidth
                variant="filled"
                label="Min salary"
              />
            </Grid>
            <Grid xs={12} sm={6} item>
              <ConnectedTextField
                name={modelPath<BuilderFormState>((m) => m.rate.maxSalary)}
                data-test-id="firstName"
                fullWidth
                variant="filled"
                label="Max salary"
                helperText={`Max. ${formatRate({
                  min: currentRates.max,
                  period: 'month',
                })}`}
              />
            </Grid>
          </Grid>
        </Collapse>
      </Box>

      <FixedFooter>
        <Grid spacing={4} justifyContent="center" container>
          <Grid xs={6} item>
            <RouterButton
              startIcon={<ArrowBackIcon />}
              to={prevStepRoute}
              color="primary"
              variant="outlined"
              fullWidth
            >
              Back
            </RouterButton>
          </Grid>
          <Grid xs={6} item>
            <NextButtonWithValidation
              path={modelPath<BuilderFormState>((m) => m.workspace)}
              disabled={loading}
              onClick={onSubmit}
              endIcon={null}
            >
              Next
            </NextButtonWithValidation>
          </Grid>
        </Grid>
      </FixedFooter>
    </StepTemplate>
  );
};

export default JobRate;
