import { gql } from '@apollo/client';
import JOB_FRAGMENT from 'graphql/fragments/talent/jobBoardJobFragment';

export const GET_JOBS = gql`
  ${JOB_FRAGMENT}
  query GetJobs(
    $skills: [ID]
    $categories: [ID]
    $rate_min: Float
    $rate_max: Float
    $search: String
    $order_by: [QueryCurrentTalentJobBoardSearchOrderByOrderByClause!]
    $is_saved: Boolean
    $job_source_type: [JobSourceType]
    $first: Int = 20
    $page: Int
    $company_id: ID
    $is_assigned_as_matcher: Boolean
    $is_applied_as_matcher: Boolean
    $is_active: Boolean
    $is_applied: Boolean
    $job_matcher_jobs_filter: JobMatcherJobsFilterEnum
    $communities_filter: JobCommunitiesFilterInput
    $country: String
  ) {
    currentTalentJobBoardSearch(
      is_applied: $is_applied
      country: $country
      communities_filter: $communities_filter
      job_matcher_jobs_filter: $job_matcher_jobs_filter
      skills: $skills
      is_active: $is_active
      job_source_type: $job_source_type
      categories: $categories
      rate_min: $rate_min
      rate_max: $rate_max
      search: $search
      order_by: $order_by
      is_saved: $is_saved
      first: $first
      page: $page
      company_id: $company_id
      is_assigned_as_matcher: $is_assigned_as_matcher
      is_applied_as_matcher: $is_applied_as_matcher
    ) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        ...JobBoardJobFragment
      }
    }
  }
`;

export const SAVE_JOB = gql`
  mutation SaveJobToFavorites($job_id: ID!) {
    saveJobToFavorites(job_id: $job_id)
  }
`;

export const DELETE_JOB = gql`
  mutation DeleteJobFromFavorites($job_id: ID!) {
    deleteJobFromFavorites(job_id: $job_id)
  }
`;

export const WITHDRAW_JOB = gql`
  mutation CancelApplicationForJob($job_id: ID!, $reason: String) {
    cancelApplicationForJob(job_id: $job_id, reason: $reason)
  }
`;
