import { useGetCompanyPoolConnectionToTalent } from 'hooks/company';
import { useIsFreeCompanyAccount } from 'hooks/company/useCompanyAccountType';
import { formatName } from 'utils/talent';

import { Talent } from '@libs/graphql-types';

export const useHaveAccessToTalent = (talent?: Talent | null): boolean => {
  const isFreeAccount = useIsFreeCompanyAccount();
  const connection = useGetCompanyPoolConnectionToTalent({ talent });
  const hasAccess = !!connection && !isFreeAccount;

  return hasAccess;
};

export const useTalentName = (talent?: Talent) => {
  const name = formatName({
    firstName: talent?.first_name_abac?.value,
    lastName: talent?.last_name_abac?.value,
  });

  return name;
};
