import { DocumentNode, QueryHookOptions, useLazyQuery } from '@apollo/client';
import { getIn } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import useDebounce from 'react-use/lib/useDebounce';

import { OptionType } from '@libs/ui/components/form/select';

type MapFunction = (item: any) => OptionType;
type DataMapType =
  | {
    text: string;
    value: string;
  }
  | MapFunction;

interface HocProps {
  dataMap?: DataMapType;
  query: DocumentNode;
  queryOptions?: QueryHookOptions;
  dataPath: string;
  mapInputTextToQuery?: (value: string) => any;
  value?: any;
}

type DefaultProps = {
  options: OptionType[];
};

const mapOptions = (data: any[], dataMap: DataMapType) => {
  if (dataMap instanceof Function) {
    return data?.map(dataMap);
  }
  return data?.map((item: any) => ({
    value: item?.[dataMap.value],
    text: item?.[dataMap.text],
  }));
};

function wrapControlWithGraph<ComponentProps = DefaultProps>(
  Component: React.FC<any>,
) {
  return ({
    query,
    queryOptions,
    dataPath,
    dataMap = { text: 'name', value: 'id' },
    value,
    mapInputTextToQuery,
    ...props
  }: ComponentProps & HocProps) => {
    const [inputText, setInputText] = useState('');
    const [request, { data, loading }] = useLazyQuery(query, {
      ...queryOptions,
      onCompleted: (...res) => {
        setIsFreshData(true);
        queryOptions?.onCompleted?.(...res);
      },
    });
    const [isFreshData, setIsFreshData] = useState(true);
    const options = useMemo(
      () => mapOptions(getIn(data, dataPath, []), dataMap),
      [data, dataPath, dataMap],
    );

    useDebounce(
      () => {
        if (mapInputTextToQuery && inputText.length > 1) {
          request({
            variables: mapInputTextToQuery(inputText),
          });
        }
      },
      1000,
      [mapInputTextToQuery, request, inputText],
    );

    useEffect(() => {
      if (!mapInputTextToQuery) {
        request();
      }
    }, [mapInputTextToQuery, request]);

    return (
      <Component
        options={options}
        disabled={loading}
        value={value}
        {...props}
        noOptionsText={
          loading || (!isFreshData && !!mapInputTextToQuery)
            ? 'Loading...'
            : (props as any).noOptionsText
        }
        inputProps={{
          ...(props as any).inputProps,
          onChange: (e: any) => {
            setInputText(e?.target?.value);
            setIsFreshData(false);
            (props as any).inputProps?.onChange?.(e);
          },
        }}
      />
    );
  };
}
export default wrapControlWithGraph;
