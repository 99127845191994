import React from 'react';

import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import Typography from '@libs/ui/components/typography';

import { Coupon } from './Coupon';

export interface PerkCardV2Props {
  serviceName: string;
  title: string;
  text: React.ReactNode;
  logo: string;
  logo2x?: string;
  subtitle?: string;
  actions: React.ReactNode;
  couponText: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    overflow: 'visible',
    maxWidth: 608,
    height: `calc(100% - ${theme.spacing(6)})`,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(6),
  },
  headerRoot: {
    paddingBottom: 0,
    padding: theme.spacing(4, 6, 0, 6),
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  logoBlock: {
    position: 'absolute',
    left: 'calc(50% - 28px)',
    top: -28,
    width: 72,
    height: 72,
    borderRadius: '50%',
    background: 'white',
    border: `4px solid #EDEDED`,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& > div': {
      width: 60,
      height: 60,
      borderRadius: '50%',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  contentRoot: {
    flexGrow: 1,
    padding: theme.spacing(4, 6),
  },
  headerContent: {
    minHeight: 56,
    display: 'flex',
    alignItems: 'center',
  },
  actionsRoot: {
    padding: theme.spacing(0, 6, 6, 6),
  },
}));

export const PerkCardV2 = ({
  actions,
  subtitle,
  serviceName,
  logo2x,
  title,
  logo,
  text,
  couponText,
}: PerkCardV2Props) => {
  const classes = useStyles();

  return (
    <Card className={classes.root} elevation={0}>
      <CardHeader
        disableTypography
        classes={{ content: classes.headerContent, root: classes.headerRoot }}
        title={
          <Grid display="flex" alignItems="center" container>
            <Grid flexGrow={1} item>
              <Typography variant="h6">{serviceName}</Typography>
              <Typography variant="caption">{subtitle}</Typography>
            </Grid>
            {!!logo && (
              <Grid className={classes.logoBlock}>
                <div>
                  <img
                    {...(logo2x ? { srcSet: `${logo2x} 2x` } : {})}
                    src={logo}
                    alt="logo"
                    className={classes.image}
                  />
                </div>
              </Grid>
            )}
            <Grid item>
              <Coupon text={couponText} />
            </Grid>
          </Grid>
        }
      />
      <CardContent className={classes.contentRoot}>
        <Box mb={2}>
          <Typography variant="body2" fontWeight="bold">
            {title}
          </Typography>
        </Box>
        <Typography variant="body2" color="textSecondary">
          {text}
        </Typography>
      </CardContent>
      <CardActions classes={{ root: classes.actionsRoot }}>
        {actions}
      </CardActions>
    </Card>
  );
};
