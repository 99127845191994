import cn from 'classnames';
import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CSSProperties } from '@mui/styles/withStyles';

import { isNil } from '@libs/helpers/common';
import Button from '@libs/ui/components/button';

export type DefaultModalProps = React.PropsWithChildren<{
  open: boolean;
  handleClose: VoidFunction;
  title?: React.ReactNode;
  actions?: React.ReactNode;
  textAlign?: CSSProperties['textAlign'];
  className?: string;
}>;

const useStyles = makeStyles((theme) => ({
  modal: {
    padding: theme.spacing(6, 6),
    textAlign: ({ textAlign }: DefaultModalProps) => textAlign || 'center',
    margin: theme.spacing(2),
  },
  root: {},
  title: {
    padding: 0,
  },
  actions: {
    paddingTop: theme.spacing(6),
    paddingBottom: 0,
  },
  noHorizontalPadding: {
    paddingLeft: 0,
    paddingRight: 0,
    overflowY: 'visible',
  },
  content: {
    paddingBottom: ({ actions }: DefaultModalProps) =>
      isNil(actions) ? 0 : theme.spacing(6),
  },
}));

const DefaultModal = (props: DefaultModalProps) => {
  const { open, handleClose, children, actions, title, className } = props;
  const classes = useStyles(props);

  return (
    <Dialog
      classes={{ root: classes.root, paper: cn(classes.modal, className) }}
      open={open}
      onClose={handleClose}
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle className={classes.title}>{title}</DialogTitle>
      <DialogContent
        className={cn(classes.noHorizontalPadding, classes.content)}
      >
        {children}
      </DialogContent>
      {isNil(actions) ? null : (
        <DialogActions
          className={cn(classes.actions, classes.noHorizontalPadding)}
        >
          {actions || (
            <Button
              fullWidth
              variant="contained"
              color="primary"
              autoFocus
              size="large"
              onClick={handleClose}
            >
              ok
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default DefaultModal;
