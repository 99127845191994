import { ConnectedDropzone } from 'components/form/dropzone';
import { useFormikContext } from 'formik';
import { useCurrentUser } from 'hooks/auth';
import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import ServerFiles from 'screens/talent/edit-profile/verification-section/ServerFiles';
import { CreatingFormState } from 'screens/talent/shared/profile/types';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Grid, Button, Box, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  FileTypeEnum,
  TalentDocument,
  useUploadFileMutation,
} from '@libs/graphql-types';
import { modelPath } from '@libs/helpers/form';
import { DefaultModal } from '@libs/ui/components/modals';
import Typography from '@libs/ui/components/typography';

import { FILE_MIME_TYPES } from '../consts';
import { FormikState, StepProps } from '../types';

interface ProposalStep3Props extends StepProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 540,

    [theme.breakpoints.down('sm')]: {
      minWidth: 'auto',
    },
  },
}));

export const useDocumentsChange = ({
  name,
  talentId = '1',
}: {
  name: string;
  talentId?: string;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [upload, { loading }] = useUploadFileMutation();
  const { setFieldValue } = useFormikContext<CreatingFormState>();

  const onDocumentsChange = useCallback(
    async (files: File[]) => {
      const filesToUpload = files.filter((i) => !(i as any).hash);
      try {
        if (!filesToUpload.length) {
          return;
        }

        const res = await upload({
          variables: {
            file: filesToUpload[0],
            owner_id: talentId,
            file_type: FileTypeEnum.SuggestionCv,
          },
        });
        const file = res.data?.uploadFile;
        const uploadedFiles = (file ? [file] : []) as TalentDocument[];

        setFieldValue(name, uploadedFiles);
      } catch (e) {
        enqueueSnackbar((e as any).toString());
      }
    },
    [name, upload, talentId, setFieldValue, enqueueSnackbar],
  );
  return { onDocumentsChange, loading };
};

export const ProposalStep3 = (props: ProposalStep3Props) => {
  const classes = useStyles();
  const talent = useCurrentUser();
  const talentId = talent.user?.id;
  const { onDocumentsChange, loading } = useDocumentsChange({
    name: modelPath<FormikState>((m) => m.step3.cv),
    talentId,
  });

  return (
    <DefaultModal
      open
      className={classes.root}
      handleClose={props.close}
      title="Candidate's CV*"
      actions={
        <Grid spacing={4} container>
          <Grid xs={6} item>
            <Button
              fullWidth
              size="large"
              color="info"
              variant="contained"
              onClick={props.onBack}
              startIcon={<ArrowBackIcon />}
              disabled={loading}
            >
              Back
            </Button>
          </Grid>
          <Grid xs={6} item>
            <Button
              endIcon={<ArrowForwardIcon />}
              fullWidth
              size="large"
              variant="outlined"
              color="info"
              disabled={!props.isValid || loading}
              onClick={props.onSubmit}
            >
              Next step
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Box mt={6} mb={8}>
        <Typography textAlign="center" variant="body1" color="textSecondary">
          Please upload the candidate's CV
        </Typography>
      </Box>

      <Box>
        <ConnectedDropzone
          name={modelPath<FormikState>((m) => m.step3.cv)}
          withoutList
          onChange={onDocumentsChange}
          dropzoneOptions={{
            accept: FILE_MIME_TYPES,
          }}
        />
        {loading && <CircularProgress color="info" />}
        {!loading && (
          <ServerFiles name={modelPath<FormikState>((m) => m.step3.cv)} />
        )}
      </Box>
    </DefaultModal>
  );
};
