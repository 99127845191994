import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { pathManager } from 'routes';

import TypeOfHiring from './type-of-hiring';

// import TypeOfService from './type-of-service';

interface ChooseNewJobTypeProps { }

const ChooseNewJobType = (props: ChooseNewJobTypeProps) => {
  return (
    <Switch>
      <Route
        path={pathManager.company.newJob.type.getRoute()}
        component={TypeOfHiring}
      />
      {/* <Route */}
      {/*   path={pathManager.company.newJob.service.getRoute()} */}
      {/*   component={TypeOfService} */}
      {/* /> */}
      <Redirect to={pathManager.company.newJob.type.getRoute()} />
    </Switch>
  );
};

export default ChooseNewJobType;
