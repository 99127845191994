import { mergeDeep } from '@apollo/client/utilities';
import { getIn, useFormikContext } from 'formik';
import { useCallback, useMemo } from 'react';

import { FileTypeEnum, useUploadFileMutation } from '@libs/graphql-types';

import { ImageFormikState } from './types';

export const useChangeImageHandler = ({
  fileType,
  name,
}: {
  fileType: FileTypeEnum;
  name: string;
}) => {
  const { values, setFieldValue } = useFormikContext();
  const currentValue = getIn(values, name);
  const [upload, { loading }] = useUploadFileMutation();
  const onFileChange = useCallback(
    async (file: File | null) => {
      if (!file) {
        return;
      }
      const newState = mergeDeep(currentValue, { isLoading: true, file });
      setFieldValue(name, newState);
      const res = await upload({
        variables: { file, file_type: fileType },
      });
      const hash = res.data?.uploadFile?.hash;

      setFieldValue(name, mergeDeep(newState, { isLoading: false, hash }));
    },
    [currentValue, fileType, name, setFieldValue, upload],
  );

  return { onFileChange, loading };
};

export const useCurrentImageSrc = (name: string) => {
  const { values } = useFormikContext();
  const imgSrc = useMemo(() => {
    const imageState: ImageFormikState = getIn(values, name);
    const file = imageState?.file;
    console.log('🚀 ~ file: hooks.ts ~ line 46 ~ imgSrc ~ file', file);

    return file ? URL.createObjectURL(file) : undefined;
  }, [name, values]);

  return imgSrc;
};
