export const DIRECT_FEATURES = [
  'Post up to 3 jobs ',
  'View full candidate profiles',
  'See Instant Matches',
  'Get Inbound Leads',
  'Invite candidates to apply',
  'Send message to candidates',
  'Download CVs',
  'Add Finder Fees',
];

export const DIRECT_PRICE = 150;
