import { useCurrentUser } from 'hooks/auth';

import { Talent } from '@libs/graphql-types';

export const useIsItOpentalentCompanyUser = () => {
  const companyId = useCurrentUserCompanyId();

  return companyId === '1';
};

export const useCurrentUserCompanyId = () => {
  const { getData } = useCurrentUser();
  const companyId = getData()?.data?.currentCompanyUser?.company?.id;

  return companyId;
};

export const useCurrentUserCompanyName = () => {
  const { getData } = useCurrentUser();
  const name = getData()?.data?.currentCompanyUser?.company?.name;

  return name;
};

export const useGetCompanyPoolConnectionToTalent = ({
  talent,
}: {
  talent?: Partial<Talent> | null;
}) => {
  const companyId = useCurrentUserCompanyId();
  const connection = talent?.company_pool_connections?.find(
    (i) => i?.company?.id === companyId,
  );

  return connection;
};
