import { JobTypeEnum } from '@libs/graphql-types';
import { modelPath } from '@libs/helpers/form';
import {
  MAX_JOB_RATE,
  MIN_JOB_RATE,
} from '@libs/ui/components/job/utils/consts';

import { pathManager } from '../../../../routes/consts';
import AccountCreation from './account-creation';
import { BuilderFormState } from './form-context/types';
import JobCategory from './job-category';
import JobDuration from './job-duration';
import JobRate from './job-rate';
import JobSkills from './job-skills';
import JobTypeChoosing from './job-type-choosing';
import Workspace from './workspace';

export const STEPS = [
  {
    step: 1,
    statePath: modelPath<BuilderFormState>((m) => m.jobType),
    StepComponent: JobTypeChoosing,
    nextStepRoute: pathManager.company.newOnboarding.builder.step.generatePath({
      step: 2,
    }),
    prevStepRoute: pathManager.company.newOnboarding.main.generatePath(),
  },
  {
    step: 2,
    StepComponent: JobCategory,
    statePath: modelPath<BuilderFormState>((m) => m.jobCategory),
    nextStepRoute: pathManager.company.newOnboarding.builder.step.generatePath({
      step: 3,
    }),
    prevStepRoute: pathManager.company.newOnboarding.builder.step.generatePath({
      step: 1,
    }),
  },
  {
    step: 3,
    StepComponent: JobSkills,
    statePath: modelPath<BuilderFormState>((m) => m.jobSkills),

    nextStepRoute: pathManager.company.newOnboarding.builder.step.generatePath({
      step: 4,
    }),
    prevStepRoute: pathManager.company.newOnboarding.builder.step.generatePath({
      step: 2,
    }),
  },
  {
    step: 4,
    StepComponent: JobDuration,
    statePath: modelPath<BuilderFormState>((m) => m.jobDuration),
    nextStepRoute: pathManager.company.newOnboarding.builder.step.generatePath({
      step: 5,
    }),
    prevStepRoute: pathManager.company.newOnboarding.builder.step.generatePath({
      step: 3,
    }),
  },
  {
    step: 5,
    StepComponent: Workspace,
    statePath: modelPath<BuilderFormState>((m) => m.workspace),
    nextStepRoute: pathManager.company.newOnboarding.builder.step.generatePath({
      step: 6,
    }),
    prevStepRoute: pathManager.company.newOnboarding.builder.step.generatePath({
      step: 4,
    }),
  },
  {
    step: 6,
    StepComponent: JobRate,
    statePath: modelPath<BuilderFormState>((m) => m.rate),
    nextStepRoute: pathManager.company.newOnboarding.builder.step.generatePath({
      step: 7,
    }),
    prevStepRoute: pathManager.company.newOnboarding.builder.step.generatePath({
      step: 5,
    }),
  },
  {
    step: 7,
    StepComponent: AccountCreation,
    statePath: modelPath<BuilderFormState>((m) => m.companyAccount),
    nextStepRoute: pathManager.company.newOnboarding.final.generatePath(),
    prevStepRoute: pathManager.company.newOnboarding.builder.step.generatePath({
      step: 6,
    }),
  },
];

export const RATES = {
  [JobTypeEnum.Freelance]: {
    min: MIN_JOB_RATE,
    max: MAX_JOB_RATE,
  },
  [JobTypeEnum.Permanent]: {
    min: 500,
    max: 20000,
  },
  [JobTypeEnum.Project]: {
    min: 500,
    max: 20000,
  },
} as const;
