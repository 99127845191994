import { useQuery } from '@apollo/client';
import React from 'react';

import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Typography from '@libs/ui/components/typography';

import { GET_COMPANY_TALENTS } from './queries';

interface TalentCompanyCountProps {
  companyName: string;
  companyId: string;
}

export const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.paper,
    borderRadius: 8,
    padding: theme.spacing(4, 4),

    '& .MuiTypography-root': {
      color: `${theme.palette.text.primary} !important`,
    },
  },
  number: {
    marginRight: theme.spacing(4),
  },
  text: {
    textAlign: 'left',

    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
}));

export const TalentCompanyCount = ({
  companyName,
  companyId,
}: TalentCompanyCountProps) => {
  const classes = useStyles();
  const noData = !companyName && !companyId;
  const { data } = useQuery(GET_COMPANY_TALENTS, {
    variables: {
      worked_in_company_str: companyName,
      worked_in_company_id: companyId,
    },
    skip: noData,
  });
  const total = data?.talentsSearch?.custom_paginator_info?.total;

  if (noData) {
    return <></>;
  }

  if (!total) {
    return <></>;
  }

  return (
    <div className={classes.root}>
      <Grid container justifyContent="center">
        <Grid item className={classes.number}>
          <Typography fontWeight={700} variant="h3">
            {total}
          </Typography>
        </Grid>
        <Grid item md={6} className={classes.text}>
          <Typography>
            professionals already applied to this community.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};
