import React, { Dispatch, SetStateAction, useState } from 'react';
import { Link } from 'react-router-dom';
import { pathManager } from 'routes';

import { Box, Paper } from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';

import { Job } from '@libs/graphql-types';

import { ViewTypes } from '../../types';
import JobCardFooter from './JobCardFooter';
import JobCardTitle from './JobCardTitle';
import JobDescription from './JobDescription';
import JobDetails from './JobDetails';
import JobKeySkills from './JobKeySkills';
import { JobCandidatesPipeline } from './job-candidates-pipeline';

interface MatcherJobCardProps {
  onInvite: Dispatch<SetStateAction<Required<Job> | undefined>>;
  onJobApply: Dispatch<SetStateAction<Required<Job> | undefined>>;
  onJobSave?: VoidFunction;
  loadJobs?: VoidFunction;
  job: Job;
  currentTime: Date;
  currentView: ViewTypes;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    marginBottom: theme.spacing(4),
    cursor: ({ currentView }: { currentView: ViewTypes; isOpen: boolean }) =>
      currentView === ViewTypes.Matcher ? 'pointer' : 'default',
    transition: 'background-color 0.5s ease',

    '&:hover': {
      backgroundColor: grey[100],
    },
  },
  toggler: {
    color: theme.palette.tertiary.main,
  },
  togglerIcon: {
    transform: ({ isOpen }: { isOpen: boolean; currentView: ViewTypes }) =>
      `rotate(${isOpen ? 0 : 180}deg)`,
  },
}));

const MatcherJobCard = ({
  job,
  currentTime,
  currentView,
  onInvite,
  onJobApply,
  loadJobs,
}: MatcherJobCardProps) => {
  const [isOpen] = useState(false);
  const classes = useStyles({ isOpen, currentView });

  return (
    <Paper
      component={Link}
      {...{
        to: pathManager.recruiter.sourceJob.generatePath({
          id: job.id,
        }),
      }}
      data-test-id="MatcherJobCard"
      classes={{ root: classes.root }}
      elevation={0}
    >
      <Box p={6}>
        <JobCardTitle job={job} currentTime={currentTime} />
        <JobKeySkills job={job} isOpen={isOpen} />
        <JobDetails job={job} isOpen={isOpen} />
        <JobCandidatesPipeline job={job} />
        <JobDescription job={job} isOpen={isOpen} />
        <JobCardFooter
          job={job}
          onJobApply={onJobApply}
          onInvite={onInvite}
          loadJobs={loadJobs}
          isOpen={isOpen}
          currentView={currentView}
        />
      </Box>
    </Paper>
  );
};

export default MatcherJobCard;
