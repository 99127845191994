import { JobMatcherApplication } from '@libs/graphql-types';
import { JOB_MATCHERS_MAX_COUNT } from '@libs/ui/components/job/utils/consts';

export const calcMatchersSeats = (matchersCount = 0): number => {
  const result = JOB_MATCHERS_MAX_COUNT - (matchersCount || 0);

  return result >= 0 ? result : 0;
};

export const getMatcherApplication = (
  talentId: string,
  applications: JobMatcherApplication[],
) => applications.find((i) => i.matcher.id === talentId);
