import cn from 'classnames';
import React from 'react';

import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

export interface ButtonProps extends Omit<MuiButtonProps, 'color'> {
  onClick?: VoidFunction;
  color?: MuiButtonProps['color'] | 'tertiary' | 'error';
  component?: string | React.ComponentType<any>;
  rounded?: boolean;
}

const useStyles = makeStyles((theme) => ({
  color_tertiary: {
    background: ({ variant }: ButtonProps) => {
      switch (variant) {
        case 'text':
          return 'transparent';
        default:
          return theme.palette.tertiary.dark;
      }
    },
    color: ({ variant }: ButtonProps) => {
      switch (variant) {
        case 'text':
          return theme.palette.tertiary.dark;
        default:
          return 'inherit';
      }
    },
    transition: 'all .3s',

    '&:hover': {
      background: ({ variant }: ButtonProps) => {
        switch (variant) {
          case 'text':
            return 'transparent';
          default:
            return theme.palette.tertiary.light;
        }
      },
    },
  },
  color_error: {
    color: `${theme.palette.error.main} !important`,
    background: theme.palette.warning.light,
  },
  rounded: {
    borderRadius: 10,
  },
}));

const getColor = (color: ButtonProps['color']): any =>
  color && ['tertiary', 'error'].includes(color) ? undefined : color;

const Button = (props: ButtonProps) => {
  const { color, rounded, ...rest } = props;
  const classes = useStyles(props);

  return (
    <MuiButton
      {...rest}
      color={getColor(color)}
      className={cn(props.className, {
        [classes.color_tertiary]: color === 'tertiary',
        [classes.color_error]: color === 'error',
        [classes.rounded]: rounded,
      })}
    />
  );
};

export default Button;
