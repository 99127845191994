import MuiChip, { ChipProps as MuiChipProps } from '@mui/material/Chip';
import React from 'react';

import { CUSTOM_COLORS } from './consts';
import useStyles, { isCustomColor } from './styles';

export interface ChipProps extends Omit<MuiChipProps, 'color'> {
  color?: MuiChipProps['color'] | typeof CUSTOM_COLORS[number];
}

const Chip = (props: ChipProps) => {
  const classes = useStyles(props);
  const { color, ...rest } = props;
  const currentColor = isCustomColor(props.color) ? undefined : color;

  return (
    <MuiChip
      classes={{ root: classes.root }}
      {...rest}
      color={currentColor as MuiChipProps['color']}
    />
  );
};

export default Chip;
