import React from 'react';
import { Link } from 'react-router-dom';
import { pathManager } from 'routes';

import { Box, Chip, Tooltip } from '@mui/material';

import { Talent } from '@libs/graphql-types';
import { stopEvent } from '@libs/helpers/common';

interface JobApplicationChipProps {
  talent: Talent;
}

export const JobApplicationChip = ({ talent }: JobApplicationChipProps) => {
  const appliedJob = talent?.job_applications?.[0]?.job;
  const appliedJobId = appliedJob?.id;

  if (!appliedJobId) {
    return <></>;
  }

  const jobName = appliedJob?.name;

  const jobLink = pathManager.company.job.generatePath({ id: appliedJobId });

  return (
    <Box onClick={stopEvent}>
      <Link to={jobLink} style={{ display: 'flex' }}>
        <Tooltip title={`Applied for "${jobName}"`}>
          <Chip
            style={{ color: 'white', cursor: 'pointer' }}
            size="small"
            label="applied for a job"
            color="success"
          />
        </Tooltip>
      </Link>
    </Box>
  );
};
