import { gql } from '@apollo/client';

export default gql`
  mutation SuggestSkill(
    $skill_type: SkillTypeEnum!
    $name: String!
    $reason: String
  ) {
    suggestSkill(skill_type: $skill_type, name: $name, reason: $reason) {
      id
      skill_type
      name
      slug
      created_at
      updated_at
    }
  }
`;
