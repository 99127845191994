import { AppLoadingScreen } from './helpers';
import { useAuthRedirect } from './hooks';
import React from 'react';

interface Auth0Props { }

const Auth0 = (props: Auth0Props) => {
  useAuthRedirect();

  return <AppLoadingScreen />;
};

export default Auth0;
