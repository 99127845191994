import { useCurrentUser } from 'hooks/auth';
import React, { useCallback } from 'react';
import { useChatContext } from 'stream-chat-react';

import { ChatMetaInfo, ChatTypes } from '../../../types';
import ChatCompanyWithTalent from './ChatCompanyWithTalent';
import ChatTalentWithCompany from './ChatTalentWithCompany';
import { ChatSidebarItemProps } from './types';

export const ClientToTalentChatSidebarItem = (props: ChatSidebarItemProps) => {
  const { channel } = props;
  const chatContext = useChatContext();
  const { isTalent } = useCurrentUser();
  const details = channel.data?.channelDetails as ChatMetaInfo;
  const onChannelSelect = useCallback(() => {
    props.setActiveChannel?.(channel);
    chatContext.closeMobileNav();
  }, [channel, chatContext, props]);

  if (!details) {
    return null;
  }

  if (details.type === ChatTypes.UserToTalent) {
    if (isTalent) {
      return (
        <ChatTalentWithCompany onChannelSelect={onChannelSelect} {...props} />
      );
    }
    return (
      <ChatCompanyWithTalent onChannelSelect={onChannelSelect} {...props} />
    );
  }

  return <></>;
};
