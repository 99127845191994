import { ActionItem } from 'components/custom/talent/types';
import { useSnackbar } from 'notistack';

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';

import {
  JobMatch,
  SuggestingStageEnum,
  useChangeSuggestingStatusForJobMatchMutation,
} from '@libs/graphql-types';

import { useOpenRejectSubmittedTalentModal } from './RejectSubmittedTalentModal';

const STATUSES_LIST = [
  {
    id: SuggestingStageEnum.Forwarded,
    text: 'Proposed to client',
    Icon: PersonAddAlt1OutlinedIcon,
  },
  {
    id: SuggestingStageEnum.Rejected,
    text: 'Rejected',
    Icon: CancelOutlinedIcon,
    color: 'error' as const,
  },
  {
    id: SuggestingStageEnum.InInterview,
    text: 'In interview',
    Icon: ContentPasteOutlinedIcon,
  },
  {
    id: SuggestingStageEnum.RejectedAfterInterview,
    text: 'Rejected after interview',
    Icon: CancelOutlinedIcon,
    color: 'error' as const,
  },
  {
    id: SuggestingStageEnum.Hired,
    text: 'Hired',
    Icon: WorkOutlineOutlinedIcon,
  },
];

const filterActionsByCurrentState = (
  actions: ActionItem[],
  match: JobMatch,
) => {
  switch (match.suggesting_stage) {
    // case SuggestingStageEnum.Submitted:
    //   return actions.slice(1);
    case SuggestingStageEnum.Rejected:
      return [];
    case SuggestingStageEnum.InInterview:
      return actions.slice(3);
    case SuggestingStageEnum.RejectedAfterInterview:
      return [];
    case SuggestingStageEnum.Hired:
      return [];
    default:
      return actions;
  }
};

export const useSubmittedTalentActions = ({
  match,
  onSuccess,
}: {
  match: JobMatch;
  onSuccess: VoidFunction;
}): { actions: ActionItem[]; loading: boolean } => {
  const { enqueueSnackbar } = useSnackbar();
  const openRejectModal = useOpenRejectSubmittedTalentModal();
  const [changeStatus, { loading }] =
    useChangeSuggestingStatusForJobMatchMutation({
      onCompleted: () => {
        enqueueSnackbar("Talent's status has been changed", {
          variant: 'success',
        });
        onSuccess?.();
      },
    });

  const actions: ActionItem[] = STATUSES_LIST.map(({ id, ...rest }) => {
    return {
      ...rest,
      onClick: () => {
        if (
          [
            SuggestingStageEnum.Rejected,
            SuggestingStageEnum.RejectedAfterInterview,
          ].includes(id as any)
        ) {
          console.log('reject');
          openRejectModal({ rejectStatus: id, match });
          return;
        }

        changeStatus({
          variables: {
            job_match_id: match.id,
            new_suggesting_stage: id,
          },
        });
      },
    };
  });
  const filteredActions = filterActionsByCurrentState(actions, match);

  return { actions: filteredActions, loading };
};
