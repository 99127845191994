import React from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { RouterButton } from '@libs/ui/components/button';

interface BackLinkButtonProps {
  backLink: string;
}

const BackLinkButton = ({ backLink }: BackLinkButtonProps) => {
  return (
    <RouterButton
      startIcon={<ArrowBackIcon />}
      size="large"
      variant="contained"
      color="primary"
      to={backLink}
    >
      Back
    </RouterButton>
  );
};

export default BackLinkButton;
