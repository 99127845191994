import { gql } from '@apollo/client';

export default gql`
  mutation CreateCompany(
    $name: String!
    $address: String
    $type_of_activity: String
    $logo: String
    $website: String
  ) {
    createCompany(
      name: $name
      address: $address
      type_of_activity: $type_of_activity
      logo: $logo
      website: $website
    ) {
      id
      name
    }
  }
`;
