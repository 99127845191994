import { MESSAGE_TO_JUMP_HISTORY_KEY } from 'components/chat/common/channel';
import { ChatSidebarItemDumb } from 'components/chat/common/chat-sidebar';
import { useStreamChatContext } from 'components/chat/common/provider';
import { DEFAULT_AVATAR } from 'consts/common';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { DefaultGenerics, MessageResponse } from 'stream-chat';
import { SearchResultItemProps } from 'stream-chat-react';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';

interface SearchMessageResultItemProps
  extends SearchResultItemProps<DefaultStreamChatGenerics> {}

const useSelectChannel = ({ selectResult, channelId, messageId }: any) => {
  const { client } = useStreamChatContext();
  const history = useHistory();

  return async () => {
    const channels = await client.queryChannels({ cid: channelId });

    if (channels[0]) {
      await selectResult(channels[0]);

      if (messageId) {
        history.push({ state: { [MESSAGE_TO_JUMP_HISTORY_KEY]: messageId } });
      }
    }
  };
};

export const SearchMessageResultItem = ({
  selectResult,
  result,
}: SearchMessageResultItemProps) => {
  const typedResult = result as MessageResponse<DefaultGenerics>;
  const name = typedResult.user?.name;
  const message = typedResult.text;
  const avatar = (typedResult.user?.image as string) || DEFAULT_AVATAR;
  const date = typedResult.updated_at
    ? new Date(typedResult.updated_at)
    : undefined;

  const patchedSelectResult = useSelectChannel({
    selectResult,
    channelId: typedResult.channel?.cid,
    messageId: typedResult.id,
  });

  return (
    <ChatSidebarItemDumb
      primaryContent={name}
      secondaryContent={message}
      avatar={avatar}
      date={date}
      onChannelSelect={patchedSelectResult}
    />
  );
};
