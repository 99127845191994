import { useAuth0 } from 'hooks/auth/useAuth0';
import { useCurrentUser } from 'hooks/auth/useCurrentUser';
import { useIsCurrentTalentVerified } from 'hooks/talents/useTalentAccountType';
import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { pathManager } from 'routes';
import { getIsLobbyTalent } from 'utils/talent';

export const useAuthRedirect = () => {
  const { user: auth0User, isLoading } = useAuth0();
  const { data, isCompany, isTalent } = useCurrentUser();
  const isTalentVerified = useIsCurrentTalentVerified();
  const history = useHistory();
  const talent = data?.currentTalent;
  const from = window.localStorage.getItem('authFrom');

  const checkRedirect = useCallback(async () => {
    if (isLoading) {
      return;
    }
    if (!auth0User) {
      // eslint-disable-next-line no-console
      console.error('Not authenticated');
      history.push(pathManager.auth.generatePath());
      return;
    }
    if (from) {
      history.push(from);
      window.localStorage.setItem('authFrom', '');
      return;
    }

    if (isCompany) {
      history.push(pathManager.company.jobBoard.generatePath());
    } else if (isTalent) {
      const isAccepted = talent?.is_invitation_accepted;
      const isLobbyTalent = getIsLobbyTalent(talent);

      if (!isTalentVerified) {
        history.push(pathManager.talent.pendingVerificaion.generatePath());
        return;
      }

      if (isLobbyTalent) {
        history.push(pathManager.talent.lobby.main.generatePath());
        return;
      }
      if (isAccepted) {
        history.push(pathManager.talent.jobBoard.generatePath());
        return;
      } else {
        history.push(pathManager.talent.onboarding.greeting.generatePath());
        return;
      }
    }
  }, [
    isLoading,
    auth0User,
    from,
    isCompany,
    isTalent,
    history,
    talent,
    isTalentVerified,
  ]);

  useEffect(() => {
    checkRedirect();
  }, [checkRedirect, data]);

  return { isLoading };
};
