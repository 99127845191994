import { UserToTalentHeader } from 'components/chat/client-to-talent/channel-header';
import { JobChannelHeader } from 'components/chat/job-chat';
import { TalentToTalentChannelHeader } from 'components/chat/talent-to-talent';
import React from 'react';
import { useChannelStateContext } from 'stream-chat-react';

import { ChatMetaInfo, ChatTypes } from '../../types';
import { ChannelHeaderDump } from './ChannelHeaderDump';
import { DefaultHeaderProps } from './types';

export const ChannelHeader = ({ toggleMobileView }: DefaultHeaderProps) => {
  const { channel } = useChannelStateContext();
  const details = channel.data?.channelDetails as ChatMetaInfo;

  switch (details?.type) {
    case ChatTypes.UserToTalent:
      return <UserToTalentHeader toggleMobileView={toggleMobileView} />;
    case ChatTypes.JobChannel:
      return <JobChannelHeader toggleMobileView={toggleMobileView} />;
    case ChatTypes.TalentToTalent:
      return (
        <TalentToTalentChannelHeader toggleMobileView={toggleMobileView} />
      );
    default:
      return <ChannelHeaderDump title="Unknown chat" />;
  }
};
