import {
  createTheme,
  PaletteOptions,
  ThemeOptions,
} from '@mui/material/styles';
import { deepmerge } from '@mui/utils';

import { themeOptions as defaultThemeOptions } from '@libs/ui/themes/v5-theme';

const overrideOptions: Partial<ThemeOptions> = {
  palette: {
    primary: {
      main: '#F2FF88',
      light: '#FFFF8A',
      dark: '#D1D100',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    secondary: {
      main: '#FFF',
      dark: '#FFF',
      light: '#FFF',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
  } as PaletteOptions,

  components: {
    MuiDrawer: {
      styleOverrides: {
        root: {
          borderRight: 'none',
        },
      },
    },
  },
};

const theme = createTheme(deepmerge(defaultThemeOptions, overrideOptions));

export default theme;
export type Theme = typeof theme;
