import { JobTypeEnum } from '@libs/graphql-types';
import { LOCATION_TYPE_OPTIONS } from '@libs/ui/components/job/utils/consts';

import { RATES } from '../consts';
import { BuilderFormState } from './types';

export const getDefaultValues = (): BuilderFormState => ({
  _internal: {
    deviceId: '',
  },
  companyAccount: {
    firstName: '',
    lastName: '',
    email: '',
    companyName: '',
    agreement: false,
  },
  jobType: {
    type: JobTypeEnum.Permanent,
  },
  jobCategory: {
    title: '',
    category: '',
    subcategory: undefined as any,
  },
  jobSkills: {
    mandatory: [],
    common: [],
  },
  jobDuration: {
    startDate: '',
    endDate: '',
    capacity: undefined,
    permanentCapacityType: '' as any,
  },
  rate: {
    minRate: RATES.FREELANCE.min,
    maxRate: RATES.FREELANCE.max,
    minSalary: RATES.PERMANENT.min,
    maxSalary: RATES.PERMANENT.max,
  },
  workspace: {
    location: LOCATION_TYPE_OPTIONS[0].value as string,
    country: '',
    city: '',
  },
  findersFee: {
    findersFee: '',
  },
});
