import React, { useMemo } from 'react';

import { SuggestingStageEnum } from '@libs/graphql-types';
import { noop } from '@libs/helpers/common';
import ArrowTabs from '@libs/ui/components/arrow-tabs';
import { TabItemsType } from '@libs/ui/components/arrow-tabs/types';

import { MATCHES_TABS } from '../consts';

interface ArrowMatchesTabsProps {
  value?: SuggestingStageEnum;
  count: Partial<Record<SuggestingStageEnum | string, number>>;
  className?: string;
  onChange?: (value: SuggestingStageEnum) => void;
}

const ArrowMatchesTabs = ({
  value,
  count,
  className,
  onChange = noop,
}: ArrowMatchesTabsProps) => {
  const items = useMemo<TabItemsType[]>(
    () => [
      {
        children: `Submitted (${count[MATCHES_TABS.submitted] || 0})`,
        id: MATCHES_TABS.submitted,
      },
      {
        children: `Forwarded (${count[MATCHES_TABS.forwarded] || 0})`,
        id: MATCHES_TABS.forwarded,
      },
      {
        children: `Rejected (${count[MATCHES_TABS.rejected] || 0})`,
        id: MATCHES_TABS.rejected,
      },
      {
        children: `In interview (${count[MATCHES_TABS.inInterview] || 0})`,
        id: MATCHES_TABS.inInterview,
      },
      {
        children: `Rejected after iv (${count[MATCHES_TABS.rejectedAfterInterview] || 0
          })`,
        id: MATCHES_TABS.rejectedAfterInterview,
      },
      {
        children: `Hired (${count[MATCHES_TABS.hired] || 0})`,
        id: MATCHES_TABS.hired,
      },
    ],
    [count],
  );

  return (
    <ArrowTabs
      className={className}
      currentActive={value || ''}
      items={items}
      onChange={onChange as any}
    />
  );
};

export default ArrowMatchesTabs;
