import React from 'react';

import { Job } from '@libs/graphql-types';
import Button from '@libs/ui/components/button';

import { useOpenCandidateProposalModalComponent } from './candidate-proposal-modal';

interface PresentCandidateProps {
  job: Job;
}

const PresentCandidateButton = ({ job }: PresentCandidateProps) => {
  const openCandidateProposalModal = useOpenCandidateProposalModalComponent();

  return (
    <Button
      variant="contained"
      color="info"
      onClick={() => openCandidateProposalModal()}
    >
      Present a candidate
    </Button>
  );
};

export default PresentCandidateButton;
