import { makeStyles } from '@mui/styles';

import { Theme } from '@libs/ui/themes/default';

import { DRAWER_WIDTH } from '../consts';

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    position: 'relative',
    zIndex: 1,
  },
  paper: {
    paddingTop: theme.spacing(6),
    background: theme.palette.secondary.main,
    width: DRAWER_WIDTH,
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    flexDirection: 'column',
  },
  skeleton: {
    backgroundColor: 'white',
    opacity: 0.1,
    margin: `0 ${theme.spacing(3)}`,
  },
  contactBtn: {
    letterSpacing: 0,
    color: theme.palette.text.primary,
    justifyContent: 'flex-start',
  },
}));

export default useStyles;
