import { gql } from '@apollo/client';

export const SUGGEST_COMPANY = gql`
  query SuggestCompany($search: String, $first: Int = 10) {
    companies(search: $search, first: $first) {
      data {
        id
        name
      }
    }
  }
`;
