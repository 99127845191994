import { TalentToTalentChatMeta } from 'components/chat/talent-to-talent';
import { DEFAULT_AVATAR } from 'consts/common';
import { DefaultGenerics, StreamChat } from 'stream-chat';

import { CustomChatUserData } from '../common/provider';
import { ChatTypes } from '../types';
import { getChannelIdDefault } from '../utils';

export const createTalentToTalentChannel = async (
  client: StreamChat<DefaultGenerics>,
  {
    user1: talent1,
    user2: talent2,
  }: {
    user1: CustomChatUserData;
    user2: CustomChatUserData;
  },
) => {
  if (!client || !talent1 || !talent2) {
    console.error('No data to create chat!');
    return;
  }

  const chatId = getChannelIdDefault([talent1.chatId, talent2.chatId]);

  const details: TalentToTalentChatMeta = {
    type: ChatTypes.TalentToTalent,
    talent1: talent1,
    talent2: talent2,
  };

  const channel = await client.channel('messaging', chatId, {
    members: [talent1.chatId, talent2.chatId],
    name: `${talent1.name} - ${talent2.name}`,
    image: DEFAULT_AVATAR,
    channelDetails: details,
  });

  await channel?.create();

  return channel;
};
