import React from 'react';

import { Job } from '@libs/graphql-types';

import InitialApplyModal from './InitialApplyModal';
import SuccessApplyModal from './SuccessApplyModal';
import { useApplyForAction } from './hooks';

interface ApplyModalProps {
  job: Required<Job>;
  handleClose: VoidFunction;
}

const ApplyModal = ({ job, handleClose }: ApplyModalProps) => {
  const { applyForJob, isSubmitted, isApplying } = useApplyForAction(job.id);

  return (
    <>
      <InitialApplyModal
        open={!isSubmitted}
        onSubmit={applyForJob}
        handleClose={handleClose}
        isApplying={isApplying}
      />
      <SuccessApplyModal open={isSubmitted} handleClose={handleClose} />
    </>
  );
};

export default ApplyModal;
