import { useCallback, useState } from 'react';

import { useApplyForJobMutation } from '@libs/graphql-types';

export const useApplyForAction = (job_id: string) => {
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [applyForJobRequest, { loading }] = useApplyForJobMutation({
    variables: { job_application_data: { job_id } },
    onCompleted: (res) => {
      if (res.applyForJob) {
        setIsSubmitted(true);
      }
    },
  });

  const applyForJob = useCallback(() => {
    applyForJobRequest();
  }, [applyForJobRequest]);

  return { applyForJob, isApplying: loading, isSubmitted };
};
