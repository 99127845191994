import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  chatPage: {
    '--border-radius': '4px',
    minHeight: 'auto !important',

    flexGrow: 1,
    height: 'calc(100% - 72px)',

    '& .str-chat': {
      // height: 'calc(100% - 70px)',
    },

    '& .str-chat__main-panel': {
      borderRadius: 4,
      overflow: 'hidden',
    },

    '& .str-chat__channel-search-container.inline': {
      height: 'auto',
      top: 48,
    },

    '& .str-chat-channel-list': {
      marginRight: theme.spacing(4),
      background: 'white',
      borderRadius: 4,
      overflow: 'hidden',
      minWidth: 300,

      [theme.breakpoints.down('md')]: {
        width: '100%',
        display: 'none',
        zIndex: 9,

        '&.str-chat-channel-list--open': {
          display: 'block',
        },
      },
    },

    '& .str-chat__channel-search': {
      padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
    },

    '& .str-chat__channel-list-messenger__main': {
      padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
    },
  },
}));
