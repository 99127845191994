import { useQuery } from '@apollo/client';
import { GET_ALL_ACCOUNTS } from 'graphql/common/allAccounts';

import { Query } from '@libs/graphql-types';

export const useCurrentUserAccounts = () => {
  const { data, loading } = useQuery<Query>(GET_ALL_ACCOUNTS);

  return { accounts: data?.currentUserAccounts, isLoading: loading };
};
