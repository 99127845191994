import { mergeDeep } from '@apollo/client/utilities';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { pathManager } from 'routes';

import { useOldCompanyOnboardingContext } from '../context/OnboardingContext';
import { useFinalSubmitAction } from '../hooks';
import { CreateCompanyState } from './types';

export const useCreateCompany = () => {
  const history = useHistory();
  const { onSubmit, loading } = useFinalSubmitAction();
  const { value, setValue } = useOldCompanyOnboardingContext();

  const onCreate = useCallback(
    async (variables: CreateCompanyState) => {
      let newState = mergeDeep(value, { company: variables });

      setValue(newState);

      await onSubmit(newState);

      history.push(pathManager.company.onboarding.final.generatePath());
    },
    [history, onSubmit, setValue, value],
  );

  return { isCompanyCreating: loading, onCreate };
};
