import { ReactComponent as LogoIcon } from 'assets/icons/opentalent_dark.svg';
import PageLayout from 'components/layout/page-layout';
import { useSearchParams } from 'hooks/routing';
import React, { useEffect } from 'react';
import { useEffectOnce } from 'react-use';

import { makeStyles } from '@mui/styles';

import {
  useDeleteAccountMutation,
  useGetLeavePageInfoLazyQuery,
} from '@libs/graphql-types';

import LeavingContent from './LeavingContent';

interface LeaveProps {}

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    overflow: 'hidden',
    margin: '0',
    width: '100%',
    maxWidth: '100%',
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
    paddingBottom: theme.spacing(25),

    '& .MuiFilledInput-root': {
      background: 'white',
    },
  },
  '@global': {
    header: {
      backgroundColor: `${theme.palette.other.black} !important`,

      '&::before': {
        background: `${theme.palette.other.black} !important`,
      },
    },
  },
}));

export const Leave = (props: LeaveProps) => {
  const { deleteKey } = useSearchParams();
  const classes = useStyles();
  const currentDeleteKey = (deleteKey as string) || '';
  const [getData, { data }] = useGetLeavePageInfoLazyQuery({
    variables: {
      delete_key: currentDeleteKey,
    },
  });
  const invitedBy =
    data?.selfDeletePageInfo?.invited_by_full_name || 'Our user';

  const [deleteAcc] = useDeleteAccountMutation();

  useEffectOnce(() => {
    if (currentDeleteKey) {
      deleteAcc({ variables: { delete_key: currentDeleteKey } });
    }
  });

  useEffect(() => {
    if (currentDeleteKey) {
      getData({ variables: { delete_key: currentDeleteKey } });
    }
  }, [currentDeleteKey, getData]);

  return (
    <PageLayout
      classes={{ contentWrapper: classes.contentWrapper }}
      documentTitle="Onboarding"
      headerProps={{
        accountProps: null,
        Logo: LogoIcon,
      }}
    >
      <LeavingContent deleteKey={currentDeleteKey} name={invitedBy} />
    </PageLayout>
  );
};

export default Leave;
