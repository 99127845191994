import { ReactComponent as LogoIcon } from 'assets/icons/opentalent_dark.svg';
import PageLayout from 'components/layout/page-layout';
import React from 'react';

import { Box, Grow } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Typography from '@libs/ui/components/typography';

interface StepTemplateProps extends React.PropsWithChildren<unknown> {
  title: string;
  subtitle: string;
  currentStep: number;
  totalSteps: number;
  SidebarContentContent: React.ComponentType;
}

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },

    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2),
    },
  },

  contentWrapper: {
    overflow: 'hidden',
    margin: '0',
    width: '100%',
    maxWidth: '100%',
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
    justifyContent: 'center',
    padding: 0,

    '& .MuiFilledInput-root': {
      background: 'white',
    },
  },

  '@global': {
    '.MuiFilledInput-root': {
      border: 'none',
    },

    header: {
      backgroundColor: `${theme.palette.other.black} !important`,

      '&::before': {
        background: `${theme.palette.other.black} !important`,
      },
    },
  },
  formContent: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(18),
  },
  title: {
    textAlign: 'center',
    marginBottom: theme.spacing(4),
    fontWeight: 700,
    fontStyle: 'italic',
  },
  subTitle: {
    textAlign: 'center',
    marginBottom: theme.spacing(8),

    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(6),
    },
  },
  stepsCount: {
    textAlign: 'center',
    display: 'block',
    marginBottom: theme.spacing(2),
  },
  sidebar: {
    width: 500,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    background:
      'linear-gradient(180deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 255, 255, 0.07) 100%), #121212',

    paddingBottom: theme.spacing(18),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

const StepTemplate = ({
  children,
  currentStep,
  totalSteps,
  subtitle,
  title,
  SidebarContentContent,
}: StepTemplateProps) => {
  const classes = useStyles();

  return (
    <PageLayout
      classes={{ contentWrapper: classes.contentWrapper }}
      documentTitle="Onboarding"
      headerProps={{
        accountProps: null,
        Logo: LogoIcon,
      }}
    >
      <div className={classes.root}>
        <Box
          className={classes.formContent}
          flexGrow={1}
          data-test-id="creatingScreen"
        >
          <Grow timeout={500} in>
            <div>
              <Typography variant="overline" className={classes.stepsCount}>
                {`STEP: ${currentStep} OF ${totalSteps}`}
              </Typography>

              <Typography variant="h4" className={classes.title}>
                {title}
              </Typography>
              <Typography variant="h6" className={classes.subTitle}>
                {subtitle}
              </Typography>
            </div>
          </Grow>

          {children}
        </Box>

        <Box className={classes.sidebar}>
          <SidebarContentContent />
        </Box>
      </div>
    </PageLayout>
  );
};

export default StepTemplate;
