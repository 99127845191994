import {
  MatcherLayoutViewTypes,
  useTalentMatcherContext,
} from 'components/contexts';
import { useFormikContext } from 'formik';
import React from 'react';

import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { modelPath } from '@libs/helpers/form';
import Select from '@libs/ui/components/form/select';
import Typography from '@libs/ui/components/typography';

import { FilterForm, JobTabType } from './types';

interface TitleLineProps { }

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 500,
    fontSize: '24px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
}));

const TitleLine = (props: TitleLineProps) => {
  const classes = useStyles();
  const { value, setValue } = useTalentMatcherContext();
  const { setFieldValue } = useFormikContext<FilterForm>();
  const filterPath = modelPath<FilterForm>((m) => m.tab);

  return (
    <Grid container>
      <Grid flexGrow={1} item>
        <Typography className={classes.title}>
          <span>Job Board</span>
        </Typography>
      </Grid>

      <Grid>
        <Select
          margin="dense"
          variant="filled"
          label="Account type"
          hideNoneValue
          value={value.currentView}
          onChange={(e) => {
            const newAccoutnTypeValue = e.target
              .value as MatcherLayoutViewTypes;

            setFieldValue(filterPath, JobTabType.Open);

            setValue({
              currentView: newAccoutnTypeValue,
            });
          }}
          options={[
            {
              text: 'Recruiter',
              value: MatcherLayoutViewTypes.Matcher,
            },
            {
              text: 'Candidate',
              value: MatcherLayoutViewTypes.Talent,
            },
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default TitleLine;
