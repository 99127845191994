import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

export function useOpenModal<M>(id: string) {
  const history = useHistory();
  return useCallback(
    (modalData?: M) => {
      const prevState = history.location.state as object;
      history.replace({
        ...history.location,
        state: { ...prevState, [id]: modalData || true },
      });
    },
    [history, id],
  );
}
