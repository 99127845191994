import { useFormikContext } from 'formik';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import {
  CompanyUserOnboardingTypeEnum,
  useCreateCompanyForUserMutation,
  useCreateCompanyUserMutation,
} from '@libs/graphql-types';
import { modelPath } from '@libs/helpers/form';

import { useSaveTmpData } from '../form-context/hooks';
import { BuilderFormState } from '../form-context/types';

export const useCreateCompanyHandler = () => {
  const [createCompany, { loading: isCompanyCreating }] =
    useCreateCompanyForUserMutation();
  const [createUser, { loading }] = useCreateCompanyUserMutation();
  const createCompanyAndUser = useCallback(
    async (form: BuilderFormState) => {
      const values = form.companyAccount;

      const res = await createUser({
        variables: {
          first_name: values.firstName,
          last_name: values.lastName,
          email: values.email,
          is_primary: true,
          onboarding_type: CompanyUserOnboardingTypeEnum.Main,
        },
      });
      await createCompany({
        variables: {
          name: values.companyName,
          primary_company_user_id: res.data?.createCompanyUserProfile?.id,
        },
      });

      return res.data?.createCompanyUserProfile?.id!;
    },
    [createCompany, createUser],
  );

  return { loading: loading || isCompanyCreating, createCompanyAndUser };
};

export const useSubmitHandler = ({
  nextStepRoute,
}: {
  nextStepRoute: string;
}) => {
  const { setFieldValue, values } = useFormikContext<BuilderFormState>();
  const history = useHistory();
  const { onSave, loading: isSaving } = useSaveTmpData();
  const { createCompanyAndUser, loading: isCreating } =
    useCreateCompanyHandler();

  const onSubmit = useCallback(async () => {
    let userId = values?.companyAccount?.id;

    if (!userId) {
      userId = await createCompanyAndUser(values);

      setFieldValue(
        modelPath<BuilderFormState>((m) => m.companyAccount.id),
        userId,
      );
    }

    await onSave({
      overrides: { companyAccount: { id: userId } as any },
      onSuccess: async () => {
        history.push(nextStepRoute);
      },
    });
  }, [
    values,
    createCompanyAndUser,
    setFieldValue,
    onSave,
    history,
    nextStepRoute,
  ]);

  return {
    onSubmit,
    loading: isSaving || isCreating,
    isProfileCreated: !!values.companyAccount.id,
  };
};
