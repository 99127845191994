import { getIn, useFormikContext } from 'formik';
import React from 'react';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import Button, { ButtonProps } from '@libs/ui/components/button';

interface NextButtonWithValidationProps extends ButtonProps {
  path: string;
}

const NextButtonWithValidation = (props: NextButtonWithValidationProps) => {
  const { errors } = useFormikContext();
  const currentErrors = getIn(errors, props.path, {});
  const hasErrors = !!Object.values(currentErrors)?.length;
  const endIcon =
    props.endIcon !== undefined ? props.endIcon : <ArrowForwardIcon />;

  return (
    <Button
      endIcon={endIcon}
      color="primary"
      variant="contained"
      fullWidth
      {...props}
      disabled={hasErrors || props.disabled}
    >
      {props.children || 'next'}
    </Button>
  );
};

export default NextButtonWithValidation;
