import { debounce } from '@mui/material';
import { useFormikContext } from 'formik';
import { useRef, useEffect, useMemo } from 'react';

const FormikAutoSaving = ({
  debounceMs,
  onValuesChange,
}: {
  debounceMs?: number;
  onValuesChange?: (values: any) => void;
}) => {
  const { values, submitForm } = useFormikContext();
  const isInitialMount = useRef(true);

  const debouncedSubmit = useMemo(
    () => debounce(submitForm, debounceMs),
    [debounceMs, submitForm],
  );

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      debouncedSubmit();
      if (onValuesChange) onValuesChange(values);
    }
  }, [debouncedSubmit, onValuesChange, values]);

  return null;
};

export default FormikAutoSaving;
