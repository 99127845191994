import { useQuery } from '@apollo/client';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as CrownIcon } from 'assets/icons/crown.svg';
import { ReactComponent as IdeaIcon } from 'assets/icons/idea.svg';
import { ReactComponent as SlackIcon } from 'assets/icons/slack.svg';
import { ChatTypes } from 'components/chat';
import { COMPANY_FORM_ID, TALENT_FORM_ID } from 'components/typeform/hooks';
import { useCurrentUser } from 'hooks/auth';
import { useIsFreeCompanyAccount } from 'hooks/company/useCompanyAccountType';
import { pathManager } from 'routes';

import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import { AccountTypeEnum, Query } from '@libs/graphql-types';
import { EXTERNAL_LINKS } from '@libs/helpers/consts';

import { LOAD_TALENT_QUERY } from '../chat-with-us-button/queries';
import { BottomDrawerListItemProps } from './types';

export const useBottomDrawerItems = (): BottomDrawerListItemProps[] => {
  const { isTalent } = useCurrentUser();
  const useItems = isTalent
    ? useTalentBottomDrawerItems
    : useCompanyBottomDrawerItems;
  const items = useItems();

  return items;
};

const useGetChatToUsLocationState = () => {
  const { data } = useQuery<Query>(LOAD_TALENT_QUERY, {
    variables: { talent_id: 1 },
  });

  return {
    pathname: pathManager.chat.generatePath(),
    state: {
      strategy: {
        type: ChatTypes.TalentToTalent,
        data: data?.talent,
      },
    },
  } as any;
};

const useTalentBottomDrawerItems = (): BottomDrawerListItemProps[] => {
  const { data } = useCurrentUser();
  const chatToUsLocation = useGetChatToUsLocationState();
  const isFreeTalentAccount =
    data?.currentTalent?.account_type === AccountTypeEnum.Free;
  const items: BottomDrawerListItemProps[] = [];

  if (isFreeTalentAccount) {
    items.push({
      text: 'Membership',
      to: {
        pathname: pathManager.talent.lobby.main.generatePath(),
      },
      Icon: CrownIcon,
    });
  } else {
    items.push({
      text: 'Chat With Us',
      to: chatToUsLocation,
      Icon: QuestionAnswerIcon,
      // disabled: isNotVerifiedTalent,
      // htmlTitle: isNotVerifiedTalent ? DRAWER_ITEM_LOBBY_TEXT : '',
    });
  }

  // items.push({
  //   text: 'Refer a Client 💰',
  //   href: 'https://form.typeform.com/to/IsXp4ljT',
  //   disabled: isNotVerifiedTalent,
  //   htmlTitle: isNotVerifiedTalent ? DRAWER_ITEM_LOBBY_TEXT : '',
  //   Icon: CampaignIcon,
  // });

  items.push({
    text: "What's Next?",
    href: EXTERNAL_LINKS.whatsNext,
    Icon: IdeaIcon,
    // disabled: isNotVerifiedTalent,
    // htmlTitle: isNotVerifiedTalent ? DRAWER_ITEM_LOBBY_TEXT : '',
  });
  items.push({
    text: "We're on Slack",
    href: 'https://join.slack.com/t/opentalentcommunity/shared_invite/zt-wgcocyxg-63qpP96wR5biBAF5GGmlOw',
    // disabled: isNotVerifiedTalent,
    // htmlTitle: isNotVerifiedTalent ? DRAWER_ITEM_LOBBY_TEXT : '',
    Icon: SlackIcon,
  });
  items.push({
    text: 'Give Feedback',
    // disabled: isNotVerifiedTalent,
    // htmlTitle: isNotVerifiedTalent ? DRAWER_ITEM_LOBBY_TEXT : '',
    href: `https://form.typeform.com/to/${TALENT_FORM_ID}`,
    Icon: LiveHelpOutlinedIcon,
  });

  return items;
};
const useCompanyBottomDrawerItems = (): BottomDrawerListItemProps[] => {
  const isFreeCompanyAccount = useIsFreeCompanyAccount();
  const items: BottomDrawerListItemProps[] = [];

  if (isFreeCompanyAccount) {
    items.push({
      text: 'Book a Demo',
      href: 'https://calendly.com/pieter-meeting/book-a-demo',
      Icon: CalendarIcon,
    });
  } else {
    items.push({
      text: 'Contact Us',
      href: 'https://wa.me/message/VHHZBB7TCN52C1',
      Icon: WhatsAppIcon,
    });
  }
  items.push({
    text: 'Give Feedback',
    href: `https://form.typeform.com/to/${COMPANY_FORM_ID}`,
    Icon: LiveHelpOutlinedIcon,
  });
  return items;
};
