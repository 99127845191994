import { FileRejection } from 'react-dropzone';

export const getDropzoneRejectionMessage = (e: FileRejection[]) =>
  e
    .map(({ errors }) =>
      errors
        .reduce((acc, ei) => acc.concat(ei.message), [] as string[])
        .join(', '),
    )
    .join(', ');
