import { useSnackbar } from 'notistack';

import { useCreateJobSuggestionMutation } from '@libs/graphql-types';
import { FormikSubmit } from '@libs/helpers/form';

import { FormikState } from './types';

export const useSubmitAction = ({
  jobId,
  onSuccess,
}: {
  jobId: string;
  onSuccess: VoidFunction;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [createSuggestion, { loading }] = useCreateJobSuggestionMutation();
  const onSubmit: FormikSubmit<FormikState> = async (values, actions) => {
    const { data } = await createSuggestion({
      variables: {
        job_id: jobId,
        first_name: values.step2.first_name,
        last_name: values.step2.last_name,
        email: values.step2.email,
        cv_attachment: values.step3.cv[0].hash,
        motivation_letter_attachment: values.step4.motivation_letter?.[0]?.hash,
        screening_questionnaire_attachment:
          values.step5.screening_questionnaire?.[0]?.hash,
      },
      onCompleted: () => {
        actions.resetForm();
        enqueueSnackbar('Your proposal has been sent', { variant: 'success' });
        onSuccess();
      },
      onError: () => {
        enqueueSnackbar('Something went wrong', { variant: 'error' });
      },
    });
    if (!data?.createJobSuggestion) {
      return;
    }

    return true;
  };

  return {
    onSubmit,
    loading,
  };
};
