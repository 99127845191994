import { useSearchParams } from 'hooks/routing';

import { CompanyPoolingTypeEnum } from '@libs/graphql-types';

import { FilterState } from './list/types';

export const useCommunityFilter = () => {
  const { type, country, lookingFor } = useSearchParams();

  return {
    type: (type as FilterState['type']) || CompanyPoolingTypeEnum.Company,
    country: (country as string) || '',
    lookingFor: (lookingFor as string) || '',
  };
};
