import { CheckCircle } from '@mui/icons-material';
import { MenuItemProps } from '@mui/material';

export enum TalentModals {
  InviteByCompany = 'invite',
  PaidTalentWelcome = 'PaidTalentWelcome',
  PendingLobby = 'lobby-pending',
  VerifiedLobby = 'lobby-verified',
  CreateCompany = 'createCompany',
  LimitedAccess = 'access',
  RecruiterSubscription = 'recruiterSubscription',
  PerksLive = 'PerksLive',

  Chat = 'chat',
  StartChatting = 'startChat',
}

export interface ActionItem {
  text: string;
  Icon: typeof CheckCircle;
  onClick: VoidFunction;
  disabled?: boolean;
  color?: MenuItemProps['color'];
}

export interface ActionsMenuProps {
  open: boolean;
  anchorEl: any;
  handleClose: VoidFunction;
  actions: ActionItem[];
  hideDisabled?: boolean;
}
