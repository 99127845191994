import { useQuery } from '@apollo/client';
import { useCurrentUser } from 'hooks/auth';

import {
  JobMatcherApplication,
  Query,
  QueryJobArgs,
  Talent,
} from '@libs/graphql-types';
import { getMatcherApplication } from '@libs/ui/components/job/utils';

import { GET_JOB_INFO } from './queries';

export const useScreenData = ({ id }: { id: string }) => {
  const { data, loading } = useQuery<Query, QueryJobArgs>(GET_JOB_INFO, {
    variables: { id },
    fetchPolicy: 'network-only',
  });

  return {
    job: data?.job,
    application: data?.currentTalentJobApplicationByJobId,
    isLoading: loading,
  };
};

export const useIsAppliedToSource = (matchers: Talent[]) => {
  const { getData } = useCurrentUser();
  const userID = getData()?.user?.id;

  if (!userID) {
    return false;
  }

  return matchers.some((i) => i.id === userID);
};

export const useMatcherApplication = (
  applications: JobMatcherApplication[],
) => {
  const { getData } = useCurrentUser();
  const userID = getData()?.user?.id;

  if (!userID) {
    return undefined;
  }

  return getMatcherApplication(userID, applications);
};
