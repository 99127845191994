import { useField } from 'formik';
import React, { useCallback } from 'react';

import Dropzone, { DropzoneProps } from './Dropzone';

interface ConnectedDropzoneProps extends DropzoneProps {
  name: string;
}

const ConnectedDropzone = (props: ConnectedDropzoneProps) => {
  const { name, helperText, dropzoneOptions, onChange, ...rest } = props;
  const [field, meta, helperProps] = useField<HTMLInputElement>(name);
  const onDropAccepted = useCallback(
    (files: File[]) => {
      helperProps.setValue(files as any);
      if (onChange) onChange(files);
    },
    [helperProps, onChange],
  );
  const error = meta.touched && !!meta.error;
  const finalHelperText = meta.touched && meta.error ? meta.error : helperText;

  return (
    <Dropzone
      dropzoneOptions={{ ...dropzoneOptions }}
      {...rest}
      value={field.value as any}
      name={name}
      onChange={onDropAccepted}
      error={error}
      helperText={finalHelperText}
    />
  );
};

export default ConnectedDropzone;
