import { UserInterface, UserRoles } from 'auth0/types';
import { getCurrentUserFromLS } from 'components/contexts/CurrentUserContext';

const isTalent = (user: UserInterface) => {
  const currentUserFromLS = getCurrentUserFromLS();

  if (currentUserFromLS) {
    return getCurrentUserFromLS()?.type === 'talent';
  }

  return user?.['https://opentalent.co']?.user_roles?.includes(
    UserRoles.Talent,
  );
};
const isCompany = (user: UserInterface) => {
  const currentUserFromLS = getCurrentUserFromLS();

  if (currentUserFromLS) {
    return getCurrentUserFromLS()?.type === 'company_user';
  }

  return user?.['https://opentalent.co']?.user_roles?.includes(
    UserRoles.Company,
  );
};

export const AuthUtils = { isTalent, isCompany };
