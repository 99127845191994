import { useMatcherAssignmentsExceeded } from 'hooks/talents';
import React from 'react';

import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import { Alert, Box, Grid, IconButton } from '@mui/material';

import { Job, JobMatcherApplicationStatusEnum } from '@libs/graphql-types';
import { isUnprocessableJob } from '@libs/ui/components/job/utils';
import Typography from '@libs/ui/components/typography';

import { ApplyButton } from './apply-button';
import PresentCandidateButton from './present-candidate/PresentCandidateButton';

interface TitleLineProps {
  job: Job;
  seatsLeft: number;
  matcherApplicationStatus: JobMatcherApplicationStatusEnum;
  onPDFCreate: VoidFunction;
}

const TitleLine = ({
  job,
  seatsLeft,
  matcherApplicationStatus,
  onPDFCreate,
}: TitleLineProps) => {
  const isAssignmentsExceeded = useMatcherAssignmentsExceeded();
  const isApproved = matcherApplicationStatus === 'APPROVED';
  const unprocessable = isUnprocessableJob(job);
  const needShowApplyBtn = !isApproved && !unprocessable;
  const needShowPresentCandidateBtn = isApproved;
  const noMoreSource =
    isAssignmentsExceeded && matcherApplicationStatus === 'UNKNOWN';

  return (
    <>
      <div>
        <Typography
          variant="overline"
          transform="uppercase"
          color="success.main"
        >
          Recruiter program
        </Typography>

        <Grid container spacing={4} wrap="nowrap">
          <Grid flexGrow={1} item>
            <Typography variant="h5">{job.name}</Typography>
          </Grid>

          <Grid item>
            <IconButton onClick={onPDFCreate}>
              <PictureAsPdfOutlinedIcon />
            </IconButton>
          </Grid>
          {needShowApplyBtn && (
            <Grid item>
              <ApplyButton
                job={job}
                disabled={unprocessable}
                seatsLeft={seatsLeft}
                matcherApplicationStatus={matcherApplicationStatus}
              />
            </Grid>
          )}
          {needShowPresentCandidateBtn && (
            <Grid item>
              <PresentCandidateButton job={job} />
            </Grid>
          )}
        </Grid>
      </div>

      {noMoreSource && (
        <Box mt={4}>
          <Alert color="error">
            The number of positions to which the user is assigned as a matcher
            has been exceeded
          </Alert>
        </Box>
      )}
    </>
  );
};

export default TitleLine;
