import {
  Grid, // IconButton
} from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

import { Notification } from '@libs/graphql-types';
// import CloseIcon from '@mui/icons-material/Close';
import Button from '@libs/ui/components/button';

import { getActionLink } from './utils';

interface NotificationActionsProps {
  notification: Notification;
  handleChangeStatus: VoidFunction;
  isLoading: boolean;
  deleteNotification: VoidFunction;
}

const NotificationActions = ({
  notification,
  handleChangeStatus,
  isLoading,
  deleteNotification,
}: NotificationActionsProps) => {
  return (
    <Grid wrap="nowrap" alignItems="center" spacing={2} container>
      {notification?.buttons?.map((btn) => (
        <Grid key={btn?.text} item>
          <Link to={btn?.action ? getActionLink(btn.action) : ''}>
            <Button onClick={handleChangeStatus} variant="outlined">
              {btn?.text}
            </Button>
          </Link>
        </Grid>
      ))}
      {/* <Grid item>
        <IconButton
          onClick={deleteNotification}
          disabled={isLoading}
          size="small"
        >
          <CloseIcon />
        </IconButton>
      </Grid> */}
    </Grid>
  );
};

export default NotificationActions;
