import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { formatName } from 'utils/talent';

import { Talent, User } from '@libs/graphql-types';

export const useSetSentryUser = (user: Talent | User | undefined) => {
  useEffect(() => {
    const userFormSentry = Sentry.getCurrentHub().getScope()?.getUser() || {};

    if (user && !Object.keys(userFormSentry).length) {
      const name = formatName({
        firstName: user?.first_name,
        lastName: user?.last_name,
      });
      const email = user?.email;
      const userId = user?.id;
      Sentry.setUser({ name, email, id: userId });
    }

    return () => {
      Sentry.setUser({});
    };
  }, [user]);
};
