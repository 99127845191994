import cn from 'classnames';
import React from 'react';

import { Avatar, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';

import DateTimeLabel, { useStyles as useDateTimeStyles } from './DateTimeLabel';

interface ChatSidebarItemDumbProps {
  onChannelSelect?: VoidFunction;
  avatar: string;
  primaryContent: React.ReactNode;
  secondaryContent: React.ReactNode;
  date?: Date;
  active?: boolean;
  unread?: number;
}

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.grey[50],
    cursor: 'pointer',
    position: 'relative',
  },
  rootActive: {
    background: theme.palette.grey[200],
  },
  listItemText: {
    maxWidth: 196,
  },
  listItemTextSecondary: {
    '& *': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
      margin: 0,
    },
  },
  listItemTextSecondaryUnread: {
    '& *': {
      fontWeight: 'bold',
      color: theme.palette.text.primary,
    },
  },

  userName: {
    marginRight: theme.spacing(11),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export const ChatSidebarItemDumb = ({
  onChannelSelect,
  avatar,
  primaryContent,
  secondaryContent,
  date,
  active = false,
  unread = 0,
}: ChatSidebarItemDumbProps) => {
  const classes = useStyles();
  const dateTimeClasses = useDateTimeStyles();

  return (
    <ListItem
      onClick={() => onChannelSelect?.()}
      classes={{ root: cn(classes.root, { [classes.rootActive]: active }) }}
    >
      <ListItemAvatar>
        <Avatar alt="Avatar" src={avatar} />
      </ListItemAvatar>
      <ListItemText
        classes={{
          root: classes.listItemText,
          primary: cn(classes.userName, classes.ellipsis),
          secondary: cn(classes.listItemTextSecondary, classes.ellipsis, {
            [classes.listItemTextSecondaryUnread]: !!unread,
          }),
        }}
        primary={primaryContent}
        secondary={secondaryContent}
        secondaryTypographyProps={{ component: 'div' }}
      />
      <DateTimeLabel className={dateTimeClasses.dateTime} date={date} />
    </ListItem>
  );
};
