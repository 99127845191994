import { useFormikContext } from 'formik';
import { useCallback } from 'react';

import { useCreateNewJobMutation } from '@libs/graphql-types';

import { BuilderFormState } from '../form-context/types';
import { mapFormDataToJobCreation } from './../form-context/mappers';

export const useJobTypeState = () => {
  const { values } = useFormikContext<BuilderFormState>();
  const jobType = values.jobType.type;

  return jobType;
};

export const useCreateJob = () => {
  const [createJob, { loading }] = useCreateNewJobMutation();

  const createJobHandler = useCallback(
    async (values: BuilderFormState) => {
      await createJob({ variables: mapFormDataToJobCreation(values) });
    },
    [createJob],
  );

  return { isJobCreating: loading, createJobHandler };
};
