import { gql } from '@apollo/client';

export const GET_COMMON_APP_INFO = gql`
  query GetCommonAppInfo {
    commonAppInfo {
      total_ot_freelancers_count
      total_ot_freelancers_countries_count
      total_unique_skills_count
      total_unique_companies_count
    }
  }
`;
