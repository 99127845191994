import React from 'react';

import { streamChatClient, StreamChatContext } from './consts';
import { useConnectChatUser, useCurrentUserData } from './hooks';
import { ChatProviderValue } from './types';

interface StreamChatProviderProps extends React.PropsWithChildren<unknown> {}

export const StreamChatProvider = ({ children }: StreamChatProviderProps) => {
  const userData = useCurrentUserData();

  useConnectChatUser(userData);

  const value: ChatProviderValue = {
    client: streamChatClient,
    userData,
  };

  return (
    <StreamChatContext.Provider value={value}>
      {children}
    </StreamChatContext.Provider>
  );
};
