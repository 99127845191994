import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';

import {
  MutationCreateJobSuggestionArgs,
  useCreateJobSuggestionMutation,
} from '@libs/graphql-types';

export const useSuggestionAction = (job_id: string) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [request, { loading }] = useCreateJobSuggestionMutation({
    onCompleted(res) {
      enqueueSnackbar('Sended!', { variant: 'success' });
      if (res.createJobSuggestion) {
        setIsSubmitted(true);
      }
    },
  });

  const onRequest = useCallback(
    (vars: Omit<MutationCreateJobSuggestionArgs, 'job_id'>) => {
      request({
        variables: {
          ...vars,
          job_id,
        },
      });
    },
    [job_id, request],
  );

  return { onRequest, loading, isSubmitted };
};
