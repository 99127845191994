import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import cn from 'classnames';
import { TalentPoolActions } from 'components/custom/company/talent-pool-actions';
import { TalentActions } from 'components/custom/talent/actions-menu';
import { ActionItem } from 'components/custom/talent/types';
import { NEW_TALENTS_FLAG_DAYS_COUNT } from 'consts/talents';
import { useCompanyAccountTypeCheck } from 'hooks/company/useCompanyAccountType';
import React from 'react';
import { isNotOlderThan } from 'utils/common';
import { getIsVerifiedTalent, needToShowTalentBlueTick } from 'utils/talent';

import { Grid, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { CompanyAccountTypeEnum, Talent } from '@libs/graphql-types';
import { stopEvent } from '@libs/helpers/common';
import Chip from '@libs/ui/components/chip';
import Typography from '@libs/ui/components/typography';

import RemindText from '../talent-card/RemindText';

interface TitleSectionProps {
  name: string;
  talent: Talent;
  refetch: VoidFunction;
  isInPool?: boolean;
  applicationBage?: React.ReactNode;
  filterActions?: (actions: ActionItem[]) => ActionItem[];
}

const useStyles = makeStyles((theme) => ({
  checkIcon: {
    transform: 'translateY(5px)',
    display: 'inline-block',
  },
  titleChip: {
    transform: 'translateY(-2px)',
  },
  greenChip: {
    backgroundColor: theme.palette.success.dark,
    color: 'white',
  },
  inviteButton: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  pendingVerification: {
    backgroundColor: theme.palette.warning.dark,
    color: theme.palette.secondary.contrastText,
    borderRadius: '16px',
    padding: '0 6px',
    lineHeight: '20px',
    height: '20px',
    fontSize: '12px',
    marginLeft: theme.spacing(4),
  },

  about: {
    wordBreak: 'break-word',
  },
}));

const TitleSection = ({
  applicationBage,
  name,
  talent,
  isInPool,
  refetch,
  filterActions,
}: TitleSectionProps) => {
  const classes = useStyles();
  const isEnterprise = useCompanyAccountTypeCheck([
    CompanyAccountTypeEnum.Enterprise,
  ]);
  const isTalentVerified = getIsVerifiedTalent(talent);
  const showBlueTick = needToShowTalentBlueTick(talent);
  const isNewUser = talent?.created_at
    ? isNotOlderThan(talent?.created_at, NEW_TALENTS_FLAG_DAYS_COUNT)
    : false;

  return (
    <Grid container wrap="nowrap">
      <Grid item flexGrow={1}>
        <Box mb={1}>
          <Typography component="span" variant="h6">
            {name}
          </Typography>

          {!!showBlueTick && (
            <Box display="inline-block" ml={2}>
              <CheckIcon className={classes.checkIcon} />
            </Box>
          )}

          {!talent?.is_invitation_accepted && (
            <Box display="inline-block" ml={2}>
              <Chip
                className={classes.titleChip}
                label="pending activation"
                color="grey"
                size="small"
              />
            </Box>
          )}

          {!!isEnterprise && talent?.is_matcher && (
            <Box display="inline-block" ml={2}>
              <Chip
                size="small"
                label="Recruiter"
                className={classes.titleChip}
              />
            </Box>
          )}

          {!isTalentVerified && (
            <Box display="inline-block" ml={2}>
              <Chip
                size="small"
                label="pending verification"
                color="tertiary"
                className={classes.titleChip}
              />
            </Box>
          )}

          {isNewUser && (
            <Box display="inline-block" ml={2}>
              <Chip
                size="small"
                label="New member"
                color="success"
                className={cn(classes.titleChip, classes.greenChip)}
              />
            </Box>
          )}

          {!!applicationBage && (
            <Box
              style={{
                position: 'relative',
                display: 'inline-block',
                top: '-2px',
              }}
              ml={2}
            >
              {applicationBage}
            </Box>
          )}
        </Box>
        <Box mb={1}>
          <Typography className={classes.about} variant="body2">
            {talent.recent_position_title}
          </Typography>
        </Box>
      </Grid>

      <Grid item onClick={stopEvent}>
        <Box onClick={stopEvent} className={classes.inviteButton}>
          {!!isInPool ? (
            <TalentActions
              talent={talent}
              refetch={refetch}
              filterActions={filterActions}
            />
          ) : (
            <TalentPoolActions talent={talent} refetch={refetch} />
          )}
        </Box>
        {!talent?.is_invitation_accepted && (
          <Box pt={2}>
            <RemindText talent={talent} />
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default TitleSection;
