import React from 'react';
import { pathManager } from 'routes';

import InfoIcon from '@mui/icons-material/Info';
import { Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useMediaQueries } from '@libs/helpers/hooks/media-queries';
import Typography, { RouterLink } from '@libs/ui/components/typography';

interface PendingVerificationTalentContentProps { }

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: theme.palette.warning.main,
  },
}));

export const PendingVerificationTalentContent = (
  props: PendingVerificationTalentContentProps,
) => {
  const classes = useStyles();
  const { isSM, isXS } = useMediaQueries();

  return (
    <Grid container spacing={isXS ? 2 : 4}>
      <Grid item>
        <InfoIcon className={classes.icon} />
      </Grid>
      <Grid item>
        <Typography variant="overline">
          {isSM ? '' : `LIMITED ACCESS - `}
          <RouterLink
            to={pathManager.talent.lobby.main.generatePath()}
            variant="overline"
            color="info.main"
            style={{ textDecoration: 'underline' }}
          >
            {isXS ? '' : `ACTIVATE YOUR `}MEMBERSHIP
          </RouterLink>
        </Typography>
      </Grid>
    </Grid>
  );
};
