import React from 'react';

import InfoIcon from '@mui/icons-material/Info';
import { Grid, Tooltip, Zoom } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Typography from '@libs/ui/components/typography';

import { ICONS_MAP } from './consts';
import { DataGridItem } from './types';

interface DataGridProps {
  items: DataGridItem[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      paddingBottom: theme.spacing(4),
      paddingLeft: 0,
    },
  },
  leftCol: {
    color: theme.palette.grey[500],
    width: 200,

    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
}));

const DataGrid = ({ items }: DataGridProps) => {
  const classes = useStyles();
  return (
    <>
      {items.map(({ title, icon, value, hint }) => {
        const Icon = ICONS_MAP[icon];
        return (
          <Grid
            wrap="nowrap"
            className={classes.root}
            spacing={2}
            container
            key={title}
          >
            <Grid item xs={12} sm={4}>
              <Grid
                className={classes.leftCol}
                spacing={2}
                container
                flexWrap="nowrap"
              >
                <Grid item>
                  <Icon />
                </Grid>
                <Grid item>{title}</Grid>
              </Grid>
            </Grid>
            <Grid item>{value}</Grid>
            {hint && (
              <Grid item>
                <Typography
                  component="span"
                  style={{ verticalAlign: 'middle' }}
                >
                  <Tooltip
                    TransitionComponent={Zoom}
                    title={hint}
                    placement="right"
                  >
                    <InfoIcon
                      style={{ margin: '0 auto -4px' }}
                      fontSize="small"
                      color="disabled"
                    />
                  </Tooltip>
                </Typography>
              </Grid>
            )}
          </Grid>
        );
      })}
    </>
  );
};

export default DataGrid;
