export enum JoinTypeEnum {
  Independent = 'independent',
  Agency = 'agency',
  Company = 'company',
}

export interface TypeCardProps {
  onChange: VoidFunction;
  title: string;
  text: React.ReactNode;
  value: JoinTypeEnum;
  selected?: boolean;
}
