import { mergeDeep } from '@apollo/client/utilities';
import UploadImageControl from 'components/form/image-upload-v2/upload-image-control';
import { Formik } from 'formik';
import React from 'react';

// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Grid } from '@mui/material';

import { FileTypeEnum } from '@libs/graphql-types';
import { modelPath } from '@libs/helpers/form';
import Button from '@libs/ui/components/button';
import { ConnectedTextField } from '@libs/ui/components/form/text-field';
import Typography from '@libs/ui/components/typography';

import { useOldCompanyOnboardingContext } from '../context/OnboardingContext';
import { useCreateCompany } from './hooks';
import { CreateCompanyState } from './types';
import { getValidator } from './validator';

interface CompanyProps {}

const Company = (props: CompanyProps) => {
  const {
    value: { company },
  } = useOldCompanyOnboardingContext();
  const { onCreate, isCompanyCreating } = useCreateCompany();

  return (
    <div>
      <Box pb={6}>
        <Typography variant="body1">Fill in company details</Typography>
      </Box>
      <Box maxWidth="500px">
        <Formik
          onSubmit={onCreate}
          validationSchema={getValidator()}
          initialValues={mergeDeep(
            { website: '', name: '', _logo: { isLoading: false } },
            company,
          )}
          validateOnBlur={false}
          validateOnChange
          enableReinitialize
        >
          {({ handleSubmit, values }) => (
            <Grid spacing={4} direction="column" container wrap="nowrap">
              <Grid item>
                <UploadImageControl
                  fileType={FileTypeEnum.CompanyLogo}
                  name={modelPath<CreateCompanyState>((m) => m._logo)}
                  label={`Upload logo`}
                  dark
                />
              </Grid>
              <br />
              <Grid item>
                <ConnectedTextField
                  size="small"
                  name={modelPath<CreateCompanyState>((m) => m.name)}
                  fullWidth
                  variant="filled"
                  label="Company name*"
                />
              </Grid>
              <Grid item>
                <ConnectedTextField
                  name={modelPath<CreateCompanyState>((m) => m.website)}
                  size="small"
                  fullWidth
                  variant="filled"
                  label="Website"
                />
              </Grid>
              <Grid item>
                <Box pt={2}>
                  <Grid spacing={4} container>
                    {/* <Grid xs={6} item>
                      <Button
                        startIcon={<ArrowBackIcon />}
                        fullWidth
                        variant="outlined"
                        color="primary"
                      >
                        Back
                      </Button>
                    </Grid> */}
                    <Grid xs={6} item>
                      <Button
                        fullWidth
                        onClick={() => handleSubmit()}
                        variant="contained"
                        color="primary"
                        disabled={isCompanyCreating || values._logo.isLoading}
                      >
                        FINISH COMPANY SETUP
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          )}
        </Formik>
      </Box>
    </div>
  );
};

export default Company;
