import { generatePath } from 'react-router-dom';

export class RoutePath<T extends Record<string, any> | never> {
  protected _path = '';

  constructor(path: string) {
    this._path = path;
  }

  getRoute = () => this._path;

  generatePath = (params?: T) => generatePath(this._path, params);
}
