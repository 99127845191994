import React from 'react';

import { stopEvent } from '@libs/helpers/common';

import ArrowTabItem from './ArrowTabItem';
import { TabItemsType } from './types';

interface ArrowTabsProps {
  onChange: (id: string) => void;
  currentActive?: string;
  items: TabItemsType[];
  className?: string;
}

const ArrowTabs = ({
  items,
  onChange,
  currentActive = '',
  className,
}: ArrowTabsProps) => {
  return (
    <div onClick={stopEvent} className={className} key={currentActive}>
      {items.map(({ id, bg, children }) => (
        <ArrowTabItem
          isActive={currentActive === id}
          onClick={() => onChange(id)}
          key={id}
          bg={bg}
        >
          {children}
        </ArrowTabItem>
      ))}
    </div>
  );
};

export default ArrowTabs;
