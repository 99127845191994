import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { pathManager } from 'routes';

import { STEPS } from './consts';
import FormContext from './form-context';

interface BuilderProps {}

const Builder = (props: BuilderProps) => {
  return (
    <FormContext>
      <Switch>
        {STEPS.reverse().map((data) => (
          <Route
            exact
            key={data.step}
            render={() => (
              <data.StepComponent
                prevStepRoute={data.prevStepRoute}
                nextStepRoute={data.nextStepRoute}
                index={data.step}
              />
            )}
            path={pathManager.company.newOnboarding.builder.step.generatePath({
              step: data.step,
            })}
          />
        ))}
        <Redirect
          to={pathManager.company.newOnboarding.builder.step.generatePath({
            step: 1,
          })}
        />
      </Switch>
    </FormContext>
  );
};

export default Builder;
