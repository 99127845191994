import { SuggestingStageEnum } from '@libs/graphql-types';

export const MATCHES_TABS = {
  submitted: SuggestingStageEnum.Submitted,
  rejected: SuggestingStageEnum.Rejected,
  hired: SuggestingStageEnum.Hired,
  inInterview: SuggestingStageEnum.InInterview,
  rejectedAfterInterview: SuggestingStageEnum.RejectedAfterInterview,
  forwarded: SuggestingStageEnum.Forwarded,
} as const;
