import { useMutation } from '@apollo/client';
import cn from 'classnames';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';

import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Mutation, MutationSaveJobToFavoritesArgs } from '@libs/graphql-types';

import { SAVE_JOB, DELETE_JOB } from '../../queries';

interface SaveToFavoritesButtonProps {
  isSaved?: boolean;
  jobId: string;
  onJobSave?: VoidFunction;
}

const useStyles = makeStyles((theme) => ({
  iconButton: {
    minWidth: 'auto',
    padding: 5,
  },
  iconButtonActive: {
    background: 'rgba(30, 30, 28, 0.08)',
  },
}));

const useSaveAction = ({
  jobId,
  setIsSaved,
  isSaved,
  onJobSave,
}: SaveToFavoritesButtonProps & {
  setIsSaved: (val: boolean) => void;
  onJobSave?: VoidFunction;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [save, { loading }] = useMutation<
    Mutation,
    MutationSaveJobToFavoritesArgs
  >(isSaved ? DELETE_JOB : SAVE_JOB, {
    variables: { job_id: jobId },

    onCompleted: () => {
      setIsSaved(!isSaved);

      if (onJobSave) {
        onJobSave();
      }

      enqueueSnackbar(
        isSaved
          ? 'The job has been removed from SAVED JOBS.'
          : 'Job is saved successfully',
        {
          variant: 'success',
        },
      );
    },
  });

  return { onSave: () => save(), isLoading: loading };
};

const SaveToFavoritesButton = (props: SaveToFavoritesButtonProps) => {
  const classes = useStyles();
  const { isSaved = false, onJobSave } = props;
  const [isJobSaved, setIsSaved] = useState<boolean>(isSaved);
  const { isLoading, onSave } = useSaveAction({
    ...props,
    setIsSaved,
    isSaved: isJobSaved,
    onJobSave,
  });

  return (
    <Button
      disabled={isLoading}
      onClick={onSave}
      className={cn(classes.iconButton, {
        [classes.iconButtonActive]: isJobSaved,
      })}
      variant="outlined"
    >
      {isJobSaved ? <StarIcon /> : <StarBorderIcon />}
    </Button>
  );
};

export default SaveToFavoritesButton;
