import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';

import { Grow } from '@mui/material';

import { useTalentsCountBySubcategoriesLazyQuery } from '@libs/graphql-types';
import { isNil } from '@libs/helpers/common';
import { formatNumber } from '@libs/helpers/format';
import Typography from '@libs/ui/components/typography';

import { BuilderFormState } from '../form-context/types';

interface CandidatesAmountProps {}

const useCandidateAmount = () => {
  const { values } = useFormikContext<BuilderFormState>();
  const [getCount, { data, loading }] =
    useTalentsCountBySubcategoriesLazyQuery();
  const category = values.jobCategory.category;
  const subcategory = values.jobCategory.subcategory;

  useEffect(() => {
    if (category && subcategory) {
      getCount({ variables: { subcategory_ids: [category] } });
    }
  }, [category, getCount, subcategory]);

  if (!subcategory || !category || loading) {
    return;
  }

  return data?.talentsCountBySubcategories?.[0]?.talents_count;
};

const CandidatesAmount = (props: CandidatesAmountProps) => {
  const count = useCandidateAmount();
  const needShow = isNil(count);

  if (needShow) {
    return null;
  }

  return (
    <Grow in>
      <div>
        <Typography>
          Awesome! 🤟🏽 We already found{' '}
          <Typography color="primary.main" component="span">
            {formatNumber(count)}
          </Typography>{' '}
          potential candidates.
        </Typography>
      </div>
    </Grow>
  );
};

export default CandidatesAmount;
