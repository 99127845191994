import { gql } from '@apollo/client';

export default gql`
  mutation UploadTalentDocument($talent_id: ID!, $files: [Upload!]!) {
    uploadTalentDocument(talent_id: $talent_id, files: $files) {
      title
      url
      size
      content_type
      hash
      created_at
      updated_at
    }
  }
`;
