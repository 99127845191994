import { useQuery } from '@apollo/client';
import { mergeDeep } from '@apollo/client/utilities';
import { LOCAL_STORAGE_KEYS } from 'consts/ls-keys';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { mayBeAddDeviceId } from 'utils/common/mayBeAddDeviceId';

import { useSetTmpDataMutation } from '@libs/graphql-types';
import { Query, QueryGetTempDataItemArgs } from '@libs/graphql-types';

import { UserInfo } from '../../final/types';
import { mapTmpDataToForm } from './mappers';
import { GET_COMPANY_ONBOARDING_DATA } from './queries';
import { BuilderFormState } from './types';
import { getDefaultValues } from './utils';

export const useInitialState = () => {
  const deviceId = mayBeAddDeviceId(
    LOCAL_STORAGE_KEYS.companyOnboardingDeviceId,
  );
  const { loading, data } = useQuery<Query, QueryGetTempDataItemArgs>(
    GET_COMPANY_ONBOARDING_DATA,
    {
      variables: { device_id: deviceId },
    },
  );

  const initialValues: BuilderFormState = useMemo(() => {
    const tmpData = mapTmpDataToForm({
      tmpData: data?.getTempDataItem?.result?.data || '',
    });
    const _internal = { deviceId };

    return mergeDeep(getDefaultValues(), tmpData, {
      _internal,
    });
  }, [data, deviceId]);

  return { loading, initialValues };
};

export const useSaveTmpData = () => {
  const { values } = useFormikContext<BuilderFormState>();
  const [setTmpData, { loading }] = useSetTmpDataMutation();

  const onSave = useCallback(
    async ({
      onSuccess,
      overrides,
    }: {
      onSuccess?: (values: BuilderFormState) => void;
      overrides?: Partial<BuilderFormState>;
    }) => {
      const finalValues = mergeDeep(overrides, values);

      await setTmpData({
        variables: {
          device_id: values._internal.deviceId,
          data: JSON.stringify(finalValues),
        },
      });

      await onSuccess?.(values);

      return values;
    },
    [setTmpData, values],
  );

  return { loading, onSave };
};

export const useDefaultOnSubmitHandler = ({
  redirectRoute,
  onSuccess,
}: {
  redirectRoute: string;
  onSuccess?: (values: BuilderFormState) => void;
}) => {
  const history = useHistory();
  const { onSave, loading } = useSaveTmpData();
  const onSubmit = useCallback(async () => {
    await onSave({
      onSuccess: async (values) => {
        await onSuccess?.(values);
        const state: UserInfo = {
          firstName: values.companyAccount.firstName,
          lastName: values.companyAccount.lastName,
          email: values.companyAccount.email,
        };
        history.push({
          pathname: redirectRoute,
          state,
        });
      },
    });
  }, [history, onSave, onSuccess, redirectRoute]);

  return { onSubmit, loading };
};
