import React from 'react';

import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

// import Typography from '@libs/ui/components/typography';
import { OnboardingWrapper } from '../../shared';
// import { ReactComponent as OpentalentLogo } from '../asstes/opentalent-logo.svg';
import { AddCompanyForm } from './add-company-form';

interface AddCompanyProps { }

export const useStyles = makeStyles((theme) => ({
  root: {},
  leftContent: {
    maxWidth: 630,

    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      textAlign: 'center',
    },

    '& > div': {
      maxWidth: 300,

      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
      },
    },
  },

  wrapper: {
    justifyContent: 'center',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },

  formWrap: {
    maxWidth: 610,

    [theme.breakpoints.down('md')]: {
      margin: '0 auto',
      maxWidth: '100%',
    },
  },
}));

// const BULLET_POINTS = [
//   {
//     text: 'Simple setup',
//     icon: '❤️',
//   },
//   {
//     text: '100% vetted talent',
//     icon: '✅',
//   },
//   {
//     text: 'Highly engaged',
//     icon: '🙋',
//   },
//   {
//     text: 'No more middlemen',
//     icon: '🚫',
//   },
//   {
//     text: 'Fast AI matching',
//     icon: '🤖',
//   },
//   {
//     text: 'Easy integrations',
//     icon: '🔗',
//   },
// ];

export const AddCompany = (props: AddCompanyProps) => {
  const classes = useStyles();

  return (
    <OnboardingWrapper>
      <Grid container className={classes.wrapper}>
        {/* <Grid item flexGrow={1} className={classes.leftContent}> */}
        {/*   <Box> */}
        {/*     <Box mb={10}> */}
        {/*       <OpentalentLogo /> */}
        {/*     </Box> */}
        {/*     <Hidden mdDown> */}
        {/*       <Box mb={10}> */}
        {/*         <Typography variant="h4" fontWeight={600}> */}
        {/*           Build your talent community 🎉 */}
        {/*         </Typography> */}
        {/*       </Box> */}
        {/*       <Box mb={10}> */}
        {/*         <Grid container direction="column" spacing={5}> */}
        {/*           {BULLET_POINTS.map(({ text, icon }) => ( */}
        {/*             <Grid item key={text}> */}
        {/*               <Typography variant="body1" component="span"> */}
        {/*                 {icon} */}
        {/*               </Typography>{' '} */}
        {/*               <Typography variant="body1" component="span"> */}
        {/*                 {text} */}
        {/*               </Typography> */}
        {/*             </Grid> */}
        {/*           ))} */}
        {/*         </Grid> */}
        {/*       </Box> */}
        {/*     </Hidden> */}
        {/*   </Box> */}
        {/* </Grid> */}
        <Grid item md={7} className={classes.formWrap}>
          <Box
            mb={10}
            height="100%"
            display="flex"
            justifyContent="center"
            flexDirection="row"
            alignItems="center"
          >
            <AddCompanyForm />
          </Box>
        </Grid>
      </Grid>
    </OnboardingWrapper>
  );
};
