import { ConnectedPageLayout } from 'components/layout/page-layout';
import { Formik } from 'formik';

import { Box } from '@mui/material';

import Typography from '@libs/ui/components/typography';

import { PerksList } from './content';
import { Filter } from './filter';

interface PerksProps { }

const Perks = (props: PerksProps) => {
  return (
    <Formik
      onSubmit={console.log}
      initialValues={{ country: '', category: '' }}
    >
      <ConnectedPageLayout
        documentTitle="Job board"
        drawerProps={{}}
        headerProps={{ accountProps: {} }}
      >
        <Box mb={6}>
          <Typography variant="h5">Perks</Typography>
        </Box>
        <Box mb={8}>
          <Filter />
        </Box>

        <Box>
          <PerksList />
        </Box>
      </ConnectedPageLayout>
    </Formik>
  );
};

export default Perks;
