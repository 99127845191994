import { useFormikContext } from 'formik';
import { useMemo } from 'react';

import { SkillTypeEnum, useSearchSkillsLazyQuery } from '@libs/graphql-types';
import { OptionType } from '@libs/ui/components/form/select';

export const useSkillsSuggest = () => {
  const [suggest, { loading, data }] = useSearchSkillsLazyQuery();

  const createOnChangeHandler =
    (types: SkillTypeEnum[] = []): React.ChangeEventHandler<HTMLInputElement> =>
    async (e) => {
      const search = e.target.value;

      if (search.length < 2) {
        return;
      }

      await suggest({
        variables: {
          search,
          skill_type: types,
        },
      });
    };

  const skillsOptions: OptionType[] = useMemo(
    () =>
      data?.skillSearch?.map((i) => ({ ...i, text: i.name, value: i.id })) ||
      [],
    [data?.skillSearch],
  );

  return { skillsOptions, loading, createOnChangeHandler };
};

export const useSelectSkill = () => {
  const { setFieldValue } = useFormikContext();

  const onAutocompleteChange = (name: string) => (options: OptionType[]) => {
    setFieldValue(name, options);
  };

  return { onAutocompleteChange };
};
