/* eslint-disable no-nested-ternary */
import { colors, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ChipProps } from './Chip';
import { CUSTOM_COLORS } from './consts';

export const isCustomColor = (color: ChipProps['color']) => {
  return color && CUSTOM_COLORS.includes(color as any);
};

export const getCustomColor = (color: ChipProps['color'], theme: Theme) => {
  switch (color) {
    case 'tertiary':
      return theme.palette.tertiary.main;
    case 'success':
      return theme.palette.success.main;
    case 'lightBlue':
      return theme.palette.other.lightBlue;
    case 'green':
      return theme.palette.other.green;
    case 'grey':
      return colors.grey[400];
    case 'white':
      return 'white';
    case 'red':
      return theme.palette.error.main;
    case 'successDark':
      return theme.palette.success.dark;
    default:
      return theme.palette.tertiary.main;
  }
};

const useStyles = makeStyles((theme) => ({
  root: ({ color, variant }: ChipProps) => ({
    ...(isCustomColor(color)
      ? {
          background:
            variant === 'outlined' ? 'inherit' : getCustomColor(color, theme),
          border:
            color === 'white'
              ? `1px solid ${colors.grey[400]}`
              : variant === 'outlined'
              ? `1px solid ${getCustomColor(color, theme)}`
              : 'none',
        }
      : {}),

    color: theme.palette.text.primary,
  }),
}));

export default useStyles;
