import { useAuth0 } from 'hooks/auth';
import { usePushWithQuery } from 'hooks/routing';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { pathManager } from 'routes';
import { JobMarkType } from 'types';

import {
  TalentOriginEnum,
  useApplyForJobMutation,
  useCheckCompanyUserExistsByEmailLazyQuery,
  useCheckTalentExistsByEmailLazyQuery,
} from '@libs/graphql-types';
import { FormikSubmit } from '@libs/helpers/form';

import { FormData } from './types';

export const useSubmitAction = ({ jobId }: { jobId: string }) => {
  const push = usePushWithQuery();
  const { enqueueSnackbar } = useSnackbar();
  const auth0 = useAuth0();
  const [checkTalentEmail] = useCheckTalentExistsByEmailLazyQuery();
  const [checkCompanyEmail] = useCheckCompanyUserExistsByEmailLazyQuery();
  const [applyForJob, { loading: isApplying }] = useApplyForJobMutation();

  const onSubmit: FormikSubmit<FormData> = useCallback(
    async ({ email }) => {
      const talentCheckResponse = await checkTalentEmail({
        variables: { talent_email: email },
      });

      if (talentCheckResponse.data?.checkTalentExistsByEmail) {
        if (!auth0.isAuthenticated) {
          await auth0.loginWithPopup();
          const token = await auth0.getIdTokenClaims();

          if (!token) {
            enqueueSnackbar('Please log in', { variant: 'warning' });
            return;
          }
        }
        const res = await applyForJob({
          variables: { job_application_data: { job_id: jobId } },
        });

        if (!res.data?.applyForJob) {
          enqueueSnackbar('Something went wrong', { variant: 'error' });
          return;
        }

        push({
          pathname: pathManager.talent.markedJobs.generatePath({
            type: JobMarkType.Applied,
          }),
          query: {},
        });
        return;
      }

      const companyCheckResponse = await checkCompanyEmail({
        variables: { email },
      });

      if (companyCheckResponse.data?.checkCompanyUserExistsByEmail) {
        enqueueSnackbar('Only talents can apply to vacancies', {
          variant: 'info',
        });
        return;
      }

      push({
        pathname: pathManager.talent.onboarding.main.generatePath(),
        query: {
          email,
          appliedJobId: jobId,
          origin: TalentOriginEnum.ExternalJobPageApplication,
        },
      });
    },
    [
      checkCompanyEmail,
      checkTalentEmail,
      push,
      jobId,
      auth0,
      applyForJob,
      enqueueSnackbar,
    ],
  );

  return { onSubmit, isLoading: isApplying || auth0.isLoading };
};
