import { useMatcherAssignmentsExceeded } from 'hooks/talents';
import React from 'react';
import { useOpenModal } from 'utils/modals';

import {
  Job,
  JobMatcherApplicationStatusEnum,
  Talent,
} from '@libs/graphql-types';
import Button from '@libs/ui/components/button';

import { useIsAppliedToSource } from '../hooks';
import { Modals } from '../types';

interface ApplyButtonProps {
  job: Job;
  seatsLeft: number;
  matcherApplicationStatus: JobMatcherApplicationStatusEnum;
  disabled: boolean;
}

export const ApplyButton = ({
  seatsLeft,
  matcherApplicationStatus,
  job,
  disabled,
}: ApplyButtonProps) => {
  const openSourceModal = useOpenModal(Modals.Source);
  const isAssignmentsExceeded = useMatcherAssignmentsExceeded();
  const isApplied = useIsAppliedToSource((job?.matchers as Talent[]) || []);
  const noMoreSource =
    isAssignmentsExceeded && matcherApplicationStatus === 'UNKNOWN';
  const BTN_TEXTS_MAP: Record<JobMatcherApplicationStatusEnum, string> = {
    APPROVED: '',
    REJECTED: 'Applied - rejected',
    REQUESTED: 'Applied - pending',
    UNKNOWN: `Apply to source`,
    CANCELED: 'Applied - canceled',
  };

  const isSourceBtnDisabled =
    isApplied ||
    !seatsLeft ||
    ['REJECTED', 'REQUESTED'].includes(matcherApplicationStatus) ||
    noMoreSource ||
    disabled;

  const buttonText = BTN_TEXTS_MAP[matcherApplicationStatus];

  if (!buttonText) {
    return null;
  }

  return (
    <Button
      onClick={() => openSourceModal()}
      variant="contained"
      disabled={isSourceBtnDisabled}
      color={noMoreSource ? 'error' : 'info'}
    >
      {buttonText}
    </Button>
  );
};
