import { isNumber } from '../common';

export const formatNumber = (s: string | number) =>
  new Intl.NumberFormat('de-DE').format(+s);

export const formatNumberSafe = (
  s: any,
  opts: { fallback: any } = { fallback: 0 },
) => {
  if (isNumber(s)) {
    return formatNumber(s);
  }

  return opts?.fallback;
};
