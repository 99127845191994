import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { TALENT_CATEGORIES } from 'graphql/talents';
import React from 'react';
import RateSelector from 'screens/company-user/workforce/search-filter/rate-selector';
import { DefaultSkillsControl } from 'screens/company-user/workforce/search-filter/skills-selector/default-skills-control';

import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  Paper,
} from '@mui/material';

import Accordion from '@libs/ui/components/accordion/Accordion';
import { GraphConnectedMultipleSelect } from '@libs/ui/components/form/multiple-select';
import { ConnectedTextField } from '@libs/ui/components/form/text-field';
import Typography from '@libs/ui/components/typography';

interface FilterProps {
  toggleDrawer: VoidFunction;
}

const Filter = ({ toggleDrawer }: FilterProps) => {
  return (
    <Paper elevation={0}>
      <Box px={4} py={4}>
        <Hidden lgUp>
          <Box pb={4}>
            <Grid alignItems="center" justifyContent="space-between" container>
              <Grid item>
                <Typography>Search Filter</Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={toggleDrawer} size="small">
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        </Hidden>
        <Accordion
          defaultExpanded
          summary={
            <Typography variant="overline">FILTER BY CATEGORY</Typography>
          }
          details={
            <Box flexGrow={1} mb={2}>
              <GraphConnectedMultipleSelect
                name="categories"
                query={TALENT_CATEGORIES}
                dataPath="talentCategories"
                dataMap={{ text: 'name', value: 'id' }}
                chipProps={{
                  color: 'tertiary',
                  size: 'small',
                }}
                inputProps={{
                  variant: 'filled',
                  label: 'Pick from list',
                  placeholder: '',
                  margin: 'dense',
                  fullWidth: true,
                }}
              />
            </Box>
          }
        />
        <Accordion
          defaultExpanded
          summary={<Typography variant="overline">SEARCH</Typography>}
          details={
            <Box flexGrow={1} mb={2}>
              <ConnectedTextField
                label="Search by Job title, Keywords..."
                name="search"
                variant="filled"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon width="14" />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          }
        />
        <Accordion
          defaultExpanded
          summary={<Typography variant="overline">FILTER BY SKILLS</Typography>}
          details={
            <Box flexGrow={1} mb={2}>
              <DefaultSkillsControl name="skills" />
            </Box>
          }
        />
        <Accordion
          defaultExpanded
          summary={<Typography variant="overline">HOURLY RATES</Typography>}
          details={
            <Box flexGrow={1} mb={2} px={2}>
              <RateSelector
                minValue={{ name: 'rate_min' }}
                maxValue={{ name: 'rate_max' }}
              />
            </Box>
          }
        />
      </Box>
    </Paper>
  );
};

export default Filter;
