import { ReactComponent as WorkforceIcon } from 'assets/icons/workforce.svg';
import { ConnectedPageLayout } from 'components/layout/page-layout';
import { useCurrentUser } from 'hooks/auth';
import { useIsEnterpriseCompanyAccount } from 'hooks/company/useCompanyAccountType';
import React from 'react';
import {
  MessageToWorkforceModal,
  ProfileModals,
} from 'screens/company-user/workforce/modals';

import { Alert, Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { RouterButton } from '@libs/ui/components/button';
import Typography, { OuterLink } from '@libs/ui/components/typography';

import ChatApp from './chat-app';

interface ChatProps { }

const PAGE_TITLE = 'Messenger';

const useStyles = makeStyles((theme) => ({
  container: {
    height: 'calc(100vh - 120px)',
    overflow: 'hidden',
  },
  '@global .typeFormBtn': {
    display: 'none',
  },
  alertMessage: {
    padding: 0,
  },
  alertIcon: {
    margin: 0,
  },
  alert: {
    background: '#FFCCBC',
  },
}));

const Chat = (props: ChatProps) => {
  const classes = useStyles();
  const { isCompany } = useCurrentUser();
  const isEnterprise = useIsEnterpriseCompanyAccount();

  return (
    <ConnectedPageLayout
      documentTitle={PAGE_TITLE}
      headerProps={{ accountProps: {} }}
      drawerProps={{}}
      classes={{ contentWrapper: classes.container }}
    >
      <Box py={2} pb={6}>
        <Grid justifyContent="space-between" container>
          <Grid item>
            <Grid spacing={4} flexWrap="nowrap" container>
              <Grid item>
                <Typography variant="h5">{PAGE_TITLE}</Typography>
              </Grid>
              <Grid item>
                <Alert
                  classes={{
                    root: classes.alert,
                    message: classes.alertMessage,
                    icon: classes.alertIcon,
                  }}
                  icon={<></>}
                  color="error"
                >
                  <Typography
                    component="span"
                    variant="body2"
                    color="secondary.main"
                  >
                    To protect the quality of our community we have strict
                    messaging guidelines.
                  </Typography>{' '}
                  <OuterLink
                    variant="body2"
                    color="info.main"
                    target="_blank"
                    href="https://www.notion.so/opentalent/Messaging-Guideline-21e9d00d7393469fb478c02bb816a7a0"
                  >
                    Learn more.
                  </OuterLink>
                </Alert>
              </Grid>
            </Grid>
          </Grid>
          {isEnterprise && isCompany && (
            <Grid item>
              <RouterButton
                to={{ state: { [ProfileModals.MessageToWorkforce]: true } }}
                startIcon={<WorkforceIcon />}
              >
                message MY workforce
              </RouterButton>
            </Grid>
          )}
        </Grid>
      </Box>
      <ChatApp />
      <MessageToWorkforceModal />
    </ConnectedPageLayout>
  );
};

export default Chat;
