import {
  MatcherLayoutViewTypes,
  useTalentMatcherContext,
} from 'components/contexts';
import FormikAutoSaving from 'components/form/formik/FormikAutoSave';
import { ConnectedPageLayout } from 'components/layout/page-layout';
import { Formik } from 'formik';
import useMediaQueries from 'hooks/common/useMediaQueries';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useToggle } from 'react-use';
import Filter from 'screens/talent/job-board-v2/filter';
import { noop } from 'utils/common';

import { Box, Grid, SwipeableDrawer } from '@mui/material';
import { makeStyles } from '@mui/styles';

import TitleLine from './TitleLine';
import Content from './content';
import { useLoadTabsCount } from './content/hooks';
import MatcherFilter from './filter';
import { useAutoSaveHandler, useInitialValues } from './hooks';

interface TalentMatcherJobBoardProps extends RouteComponentProps { }

const useStyles = makeStyles((theme) => ({
  settingsDrawer: {
    position: 'static',
    borderLeft: 'none',

    [theme.breakpoints.down('lg')]: {
      position: 'fixed',
      width: '40%',
    },
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      width: '100%',
    },
  },
  title: {
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '32px',
  },
}));

const TalentMatcherJobBoard = (props: TalentMatcherJobBoardProps) => {
  const { value } = useTalentMatcherContext();
  const currentView = value.currentView;
  const classes = useStyles();
  const saveToQuery = useAutoSaveHandler();
  const initialValues = useInitialValues();
  const { isMD } = useMediaQueries();

  const [isDrawerOpen, toggleIsDrawerOpen] = useToggle(false);
  const openDrawer = () => toggleIsDrawerOpen(true);

  const showMatcherFilter =
    value.currentView === MatcherLayoutViewTypes.Matcher;
  const FinalFilter = showMatcherFilter ? MatcherFilter : Filter;

  const {
    applicationsCount,
    savedCount,
    recommendedCount,
    openCount,
    refetchJobCounts,
    externalCount,
  } = useLoadTabsCount(value.currentView);

  return (
    <Formik initialValues={initialValues} onSubmit={noop}>
      {({ values }) => (
        <ConnectedPageLayout
          documentTitle="Job board"
          drawerProps={{}}
          headerProps={{ accountProps: {} }}
        >
          <Box pb={isMD ? 2 : 6}>
            <TitleLine />
          </Box>

          <Grid
            container
            wrap="nowrap"
            direction={isMD ? 'column' : 'row'}
            spacing={4}
          >
            <FormikAutoSaving onValuesChange={saveToQuery} />
            <Grid style={{ minWidth: 320 }} item>
              <SwipeableDrawer
                classes={{
                  paper: classes.settingsDrawer,
                }}
                anchor="right"
                variant={isMD ? 'temporary' : 'permanent'}
                open={isDrawerOpen}
                onClose={() => toggleIsDrawerOpen(false)}
                onOpen={openDrawer}
              >
                <FinalFilter
                  toggleDrawer={toggleIsDrawerOpen}
                  openCount={openCount}
                  externalCount={externalCount}
                />
              </SwipeableDrawer>
            </Grid>
            <Grid item style={{ flexGrow: 1 }}>
              <Content
                currentView={currentView}
                openDrawer={openDrawer}
                refetchJobCounts={refetchJobCounts}
                applicationsCount={applicationsCount}
                savedCount={savedCount}
                recommendedCount={recommendedCount}
                openCount={openCount}
                externalCount={externalCount}
              />
            </Grid>
          </Grid>
        </ConnectedPageLayout>
      )}
    </Formik>
  );
};

export default TalentMatcherJobBoard;
