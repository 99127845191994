import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { pathManager } from 'routes';

import { useCheckAuth0, useSubmitAction } from './hooks';
import { OnboardingForm } from './onboarding-form/OnboardingForm';
import { AddCompany } from './steps/add-company';
import Final from './steps/final';
import { General } from './steps/general';

interface OnboardingV3Props { }

export const OnboardingV3 = (props: OnboardingV3Props) => {
  const { onSubmit, loading } = useSubmitAction();

  useCheckAuth0();

  return (
    <OnboardingForm onSubmit={onSubmit}>
      <Switch>
        <Route
          path={pathManager.company.onboardingV3.company.getRoute()}
          render={() => <AddCompany loading={loading} />}
        />
        <Route
          path={pathManager.company.onboardingV3.final.getRoute()}
          component={Final}
        />

        <Route
          path={[
            pathManager.company.onboardingV3.general.getRoute(),
            pathManager.company.onboardingV3.main.getRoute(),
          ]}
          component={General}
        />
      </Switch>
    </OnboardingForm>
  );
};

export default OnboardingV3;
