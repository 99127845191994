import { ChannelHeader } from 'components/chat/common/channel-header';
import React from 'react';
import { useChatContext } from 'stream-chat-react';

interface CustomChatHeaderProps {}

const CustomChatHeader = (props: CustomChatHeaderProps) => {
  const { openMobileNav } = useChatContext();

  return <ChannelHeader toggleMobileView={openMobileNav} />;
};

export default CustomChatHeader;
