import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordion, {
  AccordionProps as MuiAccordionProps,
} from '@mui/material/Accordion';
import MuiAccordionDetails, {
  AccordionDetailsProps as MuiAccordionDetailsProps,
} from '@mui/material/AccordionDetails';
import MuiAccordionSummary, {
  AccordionSummaryProps as MuiAccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import { withStyles } from '@mui/styles';
import React from 'react';

export interface AccordionProps extends Omit<MuiAccordionProps, 'children'> {
  summaryProps?: MuiAccordionSummaryProps;
  detailsProps?: MuiAccordionDetailsProps;
  summary?: React.ReactElement;
  details?: React.ReactElement;
}

const StyledAccordion = withStyles({
  root: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const StyledAccordionSummary = withStyles({
  root: {
    marginBottom: -1,
    padding: 0,
    minHeight: 48,
    '&$expanded': {
      minHeight: 48,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const StyledAccordionDetails = withStyles(() => ({
  root: {
    padding: 0,
    width: '100%',
  },
}))(MuiAccordionDetails);

const Accordion = (props: AccordionProps) => {
  const { summary, details, detailsProps, summaryProps, ...rest } = props;

  return (
    <StyledAccordion {...rest}>
      <StyledAccordionSummary {...summaryProps} expandIcon={<ExpandMoreIcon />}>
        {summary}
      </StyledAccordionSummary>
      <StyledAccordionDetails {...detailsProps}>
        {details}
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default Accordion;
