import { mapChatUserData } from 'components/chat/client-to-talent';
import { useStreamChatContext } from 'components/chat/common/provider';
import { Formik } from 'formik';
import React from 'react';
import { DefaultModalProps, withLocationStateModal } from 'utils/modals';
import * as yup from 'yup';

import { Alert } from '@mui/lab';
import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Job } from '@libs/graphql-types';
import { modelPath } from '@libs/helpers/form';
import Button from '@libs/ui/components/button';
import { ConnectedTextField } from '@libs/ui/components/form/text-field';
import { DefaultModal } from '@libs/ui/components/modals';
import Typography from '@libs/ui/components/typography';

import { Modals } from '../types';
import { useSubmitAction } from './hooks';
import { FormModel } from './types';

const useStyles = makeStyles(() => ({
  paper: {
    width: 620,
  },
  content: {
    textAlign: 'left',
  },
}));

const validation = yup.object().shape({
  message: yup.string().nullable().trim().required(),
});

interface ApplyToSourceModalComponentProps extends DefaultModalProps<{}> {
  job: Job;
}

const ApplyToSourceModalComponent = ({
  modalData,
  isOpen,
  close,
  job,
}: ApplyToSourceModalComponentProps) => {
  const { userData } = useStreamChatContext();
  const campaignOwnerData = mapChatUserData(job.campaign_owner!);
  const classes = useStyles();
  const jobTitle = job.name;
  const { onSubmit, loading } = useSubmitAction({ onSuccess: close, job });
  const initialMessage = `Hello ${campaignOwnerData.name},

I'm interested in applying to source for the job ${jobTitle}

Best regards,
${userData?.name || ''}`;

  return (
    <Formik<FormModel>
      initialValues={{
        message: initialMessage,
      }}
      onSubmit={onSubmit}
      validationSchema={validation}
    >
      {({ handleSubmit }) => (
        <DefaultModal
          handleClose={close}
          open={isOpen}
          title={
            <>
              <div>Apply to source for</div>
              <div>{jobTitle}</div>
            </>
          }
          className={classes.paper}
          actions={
            <Grid spacing={4} justifyContent="space-between" container>
              <Grid xs={6} item>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={() => handleSubmit()}
                  disabled={loading}
                >
                  Send Message
                </Button>
              </Grid>
              <Grid xs={6} item>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={close}
                  disabled={loading}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          }
        >
          <Box mt={4} mb={6}>
            <Typography>Recruiter program</Typography>
          </Box>

          <Grid
            className={classes.content}
            container
            spacing={4}
            direction="column"
          >
            <Grid item>
              <ConnectedTextField
                fullWidth
                size="small"
                variant="filled"
                multiline
                name={modelPath<FormModel>((m) => m.message)}
                rows={6}
              />
            </Grid>
          </Grid>
          <br />
          {loading && <Alert color="info">Operation in progress</Alert>}
        </DefaultModal>
      )}
    </Formik>
  );
};

export const ApplyToSourceModal = withLocationStateModal({
  id: Modals.Source,
})(ApplyToSourceModalComponent);
