import cn from 'classnames';
import { DefaultHeaderProps } from 'components/chat/common/channel-header';
import { CompanyUserChatData } from 'components/chat/common/provider';
import { DEFAULT_AVATAR } from 'consts/common';
import { useCurrentUser } from 'hooks/auth';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { pathManager } from 'routes';
import { useChannelStateContext } from 'stream-chat-react';
import { stopEvent } from 'utils/common';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { UserToTalentChatMeta } from '../types';

interface CustomChannelHeaderProps extends DefaultHeaderProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'white',
  },
  clickable: {
    cursor: 'pointer',
  },
  btn: {
    marginRight: theme.spacing(2),
    display: 'none',
    marginLeft: -10,

    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
}));

const DEFAULT_CHAT_VALUES = {
  title: 'Chat',
  subtitle: '',
  image: DEFAULT_AVATAR,
  link: '',
};

const useChatInfo = (): typeof DEFAULT_CHAT_VALUES => {
  const { isCompany } = useCurrentUser();
  const { channel } = useChannelStateContext();
  const channelDetails = channel.data?.channelDetails as UserToTalentChatMeta;

  if (!channel) {
    return DEFAULT_CHAT_VALUES;
  }

  const userData = isCompany
    ? channelDetails.talent
    : channelDetails.companyUser;
  const link = isCompany
    ? pathManager.company.talentProfile.generatePath({ id: userData.id })
    : '';

  return {
    image: userData.image,
    title: userData.name,
    subtitle: (userData as CompanyUserChatData)?.companyName || userData.title,
    link,
  };
};

export const UserToTalentHeader = ({
  toggleMobileView,
}: CustomChannelHeaderProps) => {
  const classes = useStyles();
  const history = useHistory();
  const { title, subtitle, image, link } = useChatInfo();

  const onUserClick = () => {
    if (link) {
      history.push(link);
    }
  };

  return (
    <ListItem
      onClick={onUserClick}
      classes={{ root: cn(classes.root, { [classes.clickable]: !!link }) }}
    >
      {toggleMobileView && (
        <IconButton
          className={classes.btn}
          onClick={(e) => {
            stopEvent(e);
            toggleMobileView();
          }}
          size="small"
        >
          <ChevronLeftIcon />
        </IconButton>
      )}
      <ListItemAvatar>
        <Avatar alt="Avatar" src={image} />
      </ListItemAvatar>
      <ListItemText primary={title} secondary={subtitle} />
    </ListItem>
  );
};
