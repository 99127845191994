import { useCurrentUser, useAuth0 } from 'hooks/auth';
import { useCallback, useContext, useEffect, useState } from 'react';
import { formatName } from 'utils/talent';

import { streamChatClient, StreamChatContext } from './consts';
import { CompanyUserChatData, CustomChatUserData } from './types';

export const useStreamChatContext = () => {
  const value = useContext(StreamChatContext);

  if (!value) {
    throw new Error('No stream chat context');
  }

  return value;
};

export const useCurrentUserData = (): CustomChatUserData | null => {
  const [userData, setUserData] = useState<
    CustomChatUserData | CompanyUserChatData | null
  >(null);
  const { user: auth0User } = useAuth0();
  const { user, data } = useCurrentUser();
  const id = user?.id || '';
  const chatId = user?.stream_chat_id || '';
  const token = user?.stream_chat_token || '';

  useEffect(() => {
    if (!user || !id || !chatId || !token) {
      return;
    }
    const image = user?.avatar?.avatar || auth0User.picture;
    const firstName = user?.first_name;
    const lastName = user?.last_name;
    const name = formatName({ firstName, lastName });
    const title =
      data?.currentTalent?.recent_position_title ||
      data?.currentCompanyUser?.position ||
      '';
    const companyName = data?.currentCompanyUser?.company?.name || '';

    setUserData({ name, image, id, token, title, chatId, companyName });
  }, [auth0User, chatId, data, id, token, user]);

  return userData;
};

export const useConnectUserCallback = () => {
  return useCallback(async (userData: CustomChatUserData) => {
    if (userData) {
      await streamChatClient.connectUser(
        {
          id: userData.chatId,
          name: userData.name,
          image: userData.image,
          language: 'en',
        },
        userData.token,
      );
    }
  }, []);
};

export const useConnectChatUser = (userData: CustomChatUserData | null) => {
  const connectUser = useConnectUserCallback();

  useEffect(() => {
    if (userData) {
      connectUser(userData);
    }
  }, [connectUser, userData]);
};
