import React from 'react';

import { Job } from '@libs/graphql-types';

import InitialInviteModal from './InitialInviteModal';
import SuccessInviteModal from './SuccessInviteModal';
import { useSuggestionAction } from './hooks';

interface InviteModalProps {
  job: Required<Job>;
  handleClose: VoidFunction;
}

const InviteModal = ({ job, handleClose }: InviteModalProps) => {
  const { isSubmitted, onRequest, loading } = useSuggestionAction(job.id);
  return (
    <>
      <InitialInviteModal
        onSubmit={onRequest}
        open={!isSubmitted}
        handleClose={handleClose}
        loading={loading}
        job={job}
      />
      <SuccessInviteModal open={isSubmitted} handleClose={handleClose} />
    </>
  );
};

export default InviteModal;
