import FixedFooter from 'components/custom/onboarding/fixed-footer';
import { addDays } from 'date-fns';
import React from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Collapse, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { JobTypeEnum, PermanentJobCapacityTypeEnum } from '@libs/graphql-types';
import { modelPath } from '@libs/helpers/form';
import { RouterButton } from '@libs/ui/components/button';
import { ConnectedDatePicker } from '@libs/ui/components/form/datepicker';
import { ConnectedRadioGroup } from '@libs/ui/components/form/radio/Radio';
import { ConnectedSelect } from '@libs/ui/components/form/select';
import { JOB_CAPACITY_OPTIONS } from '@libs/ui/components/job/utils/consts';
import Typography from '@libs/ui/components/typography';

import { useDefaultOnSubmitHandler } from '../form-context/hooks';
import { BuilderFormState } from '../form-context/types';
import NextButtonWithValidation from '../shared/NextButtonWithValidation';
import StepTemplate from '../shared/StepTemplate';
import { useJobTypeState } from '../shared/hooks';
import { DefaultStepProps } from '../shared/types';

interface JobDurationProps extends DefaultStepProps { }

const useStyles = makeStyles((theme) => ({
  form: {
    maxWidth: 400,
    margin: '0 auto',
  },
  dateControl: {
    minWidth: 0,
  },
  radio: {
    '& > label > span': {
      color: `${theme.palette.secondary.contrastText} !important`,
    },
  },
}));

const PERMANENT_CAPACITY_OPTIONS = [
  { value: PermanentJobCapacityTypeEnum.FullTime, text: 'Full-time' },
  { value: PermanentJobCapacityTypeEnum.PartTime, text: 'Part-time' },
];

const JobDuration = ({
  index,
  nextStepRoute,
  prevStepRoute,
}: JobDurationProps) => {
  const classes = useStyles();
  const jobType = useJobTypeState();
  const { onSubmit, loading } = useDefaultOnSubmitHandler({
    redirectRoute: nextStepRoute,
  });
  const title =
    jobType === JobTypeEnum.Freelance
      ? 'Select Duration'
      : 'Select Starting Date';

  return (
    <StepTemplate
      title={title}
      subtitle="What are you looking for?"
      currentStep={index}
      currentStepLabel="Duration"
    >
      <Box className={classes.form}>
        <Grid wrap="nowrap" container spacing={4}>
          <Grid flexGrow={1} style={{ minWidth: '50%' }} item>
            <ConnectedDatePicker
              inputFormat="dd/MM/yyyy"
              name={modelPath<BuilderFormState>((m) => m.jobDuration.startDate)}
              label="Starting date"
              minDate={addDays(new Date(), 1)}
              TextFieldProps={{
                variant: 'filled',
                fullWidth: true,
                classes: { root: classes.dateControl },
              }}
            />
          </Grid>
          <Grid flexGrow={1} style={{ minWidth: '50%' }} item>
            <ConnectedDatePicker
              inputFormat="dd/MM/yyyy"
              name={modelPath<BuilderFormState>((m) => m.jobDuration.endDate)}
              label="Ending date"
              minDate={addDays(new Date(), 1)}
              TextFieldProps={{
                variant: 'filled',
                fullWidth: true,
                classes: { root: classes.dateControl },
              }}
            />
          </Grid>
        </Grid>

        <Collapse in={jobType === JobTypeEnum.Freelance}>
          <Box pt={4}>
            <ConnectedSelect
              name={modelPath<BuilderFormState>((m) => m.jobDuration.capacity)}
              fullWidth
              hideNoneValue
              variant="filled"
              label="Capacity"
              options={JOB_CAPACITY_OPTIONS}
            />
          </Box>
        </Collapse>

        <Collapse in={jobType === JobTypeEnum.Permanent}>
          <Box p={2} pt={6}>
            <Typography>At what capacity?</Typography>

            <ConnectedRadioGroup
              options={PERMANENT_CAPACITY_OPTIONS}
              classes={{ root: classes.radio }}
              name={modelPath<BuilderFormState>(
                (m) => m.jobDuration.permanentCapacityType,
              )}
            />
          </Box>
        </Collapse>
      </Box>

      <FixedFooter>
        <Grid spacing={4} justifyContent="center" container>
          <Grid xs={6} item>
            <RouterButton
              startIcon={<ArrowBackIcon />}
              to={prevStepRoute}
              color="primary"
              variant="outlined"
              fullWidth
            >
              Back
            </RouterButton>
          </Grid>
          <Grid xs={6} item>
            <NextButtonWithValidation
              path={modelPath<BuilderFormState>((m) => m.jobDuration)}
              disabled={loading}
              onClick={onSubmit}
            >
              next
            </NextButtonWithValidation>
          </Grid>
        </Grid>
      </FixedFooter>
    </StepTemplate>
  );
};

export default JobDuration;
