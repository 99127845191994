import {
  CreateCompanyForUserMutationVariables,
  CreateCompanyUserMutationVariables,
} from '@libs/graphql-types';

import { CreateCompanyState } from './company/types';
import { CreateUserState } from './user/types';

export const mapUserValuesToServer = ({
  _avatar,
  ...rest
}: CreateUserState): CreateCompanyUserMutationVariables => {
  return { ...rest, avatar: _avatar?.hash };
};

export const mapCompanyValuesToServer = ({
  _logo,
  ...rest
}: CreateCompanyState): CreateCompanyForUserMutationVariables => {
  return { ...rest, logo: _logo?.hash };
};
