import { FormikErrors, getIn } from 'formik';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

interface CheckStepErrorsProps {
  errors: FormikErrors<unknown>;
  currentStep: number;
  stepsData: {
    step: number;
    stepRoute: string;
    statePath: string;
  }[];
}

export const CheckStepErrors = ({
  errors,
  currentStep,
  stepsData,
}: CheckStepErrorsProps) => {
  const history = useHistory();

  useEffect(() => {
    const step = stepsData.find((data) => {
      const { step, statePath } = data;
      const hasErrors = !!Object.keys(getIn(errors, statePath) || {}).length;

      if (hasErrors && step < currentStep) {
        return true;
      }
      return false;
    });

    if (step) {
      history.push(step.stepRoute);
    }
  }, [currentStep, errors, history, stepsData]);

  return null;
};

export default CheckStepErrors;
