import { DefaultSkillsRowItem } from 'components/custom/skills-boolean-search';

import { JobLocationTypeEnum, SourceTypeEnum } from '@libs/graphql-types';

import { CreateJobForm } from './types';

export const EDIT_JOB_TEST_ATTRS = {
  submitButton: 'submitButton',
  saveButton: 'saveButton',
};

export const DEFAULT_BOOLEAN_ITEMS_LIST: DefaultSkillsRowItem[] = [
  { or: [], and: [], relationWithNext: 'AND' },
];

export const INITIAL_VALUES: CreateJobForm = {
  name: '',
  update_auto_matching: true,
  category_id: '',
  campaign_end_date: '',
  description: '',
  propose_to_matchers: false,
  pitch: '',
  start_date: '',
  end_date: '',
  rate_min: '' as any,
  rate_max: '' as any,
  salary_min: '' as any,
  salary_max: '' as any,
  campaign_owner_id: '',
  campaign_start_date: '',
  campaign_talent_pool: [SourceTypeEnum.Opentalent],
  skills: [],
  skills_boolean_v2: {
    items: DEFAULT_BOOLEAN_ITEMS_LIST,
    not: [],
  },
  required_skills: [],
  location_type: JobLocationTypeEnum.Remote,
  is_instant_campaign_start: true,
  finders_fee: NaN,
  is_rate_negotiable: false,
  is_remote_an_option: false,
  country: '',
  city: '',
  is_boolean_search: false,
};
