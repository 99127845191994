import { useField } from 'formik';
import React, { useCallback } from 'react';

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup as RadioGroupBase,
  RadioGroupProps,
} from '@mui/material';

import { OptionType } from '../select';

interface RadioProps extends RadioGroupProps {
  options: OptionType[];
  name: string;
  error?: boolean;
  helperText?: string;
}

const RadioGroup = (props: RadioProps) => {
  const { value, onChange, options = [], error, helperText, ...rest } = props;
  return (
    <FormControl component="fieldset" error={error}>
      <RadioGroupBase {...rest} value={value} onChange={onChange}>
        {options.map((option) => (
          <FormControlLabel
            key={option.text}
            value={option.value}
            control={<Radio />}
            label={option.text}
          />
        ))}
      </RadioGroupBase>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export const ConnectedRadioGroup = ({
  name,
  helperText,
  onChange,
  ...rest
}: RadioProps) => {
  const [field, meta] = useField(name);
  const onChangeHandler = useCallback(
    (e: React.ChangeEvent<any>) => {
      e.persist();
      field.onChange(e);
      if (onChange) {
        onChange(e, e.target.value);
      }
    },
    [field, onChange],
  );
  const error = meta.touched && !!meta.error;
  const finalHelperText = meta.touched && meta.error ? meta.error : helperText;

  return (
    <RadioGroup
      name={name}
      error={error}
      helperText={finalHelperText}
      onChange={onChangeHandler}
      value={field.value}
      {...rest}
    />
  );
};

export default RadioGroup;
