import { UserInterface } from 'auth0/types';

export enum PredicateRejectionReasons {
  WrongRoleType = 'wrongRoleType',
  NotAuthenticated = 'notAuthenticated',
}

export type ProtectedRoutePredicate = (
  u: UserInterface,
) => PredicateRejectionReasons | null;
