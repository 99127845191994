import { CheckStepErrors } from 'components/custom/onboarding/check-step-errors';
import { useFormikContext } from 'formik';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { pathManager } from 'routes/consts';

import { STEPS } from '../consts';

interface BuilderErrorCheckProps { }

const stepsData = STEPS.map((data) => ({
  step: data.step,
  statePath: data.statePath,
  stepRoute: pathManager.company.newOnboarding.builder.step.generatePath({
    step: data.step,
  }),
}));

const BuilderErrorCheck = (props: BuilderErrorCheckProps) => {
  const { errors } = useFormikContext();
  const match = useRouteMatch<{ step: string }>(
    pathManager.company.newOnboarding.builder.step.getRoute(),
  );

  return (
    <CheckStepErrors
      errors={errors}
      stepsData={stepsData}
      currentStep={Number(match?.params?.step)}
    />
  );
};

export default BuilderErrorCheck;
