import cn from 'classnames';
import React from 'react';

import { makeStyles } from '@mui/styles';

interface FixedFooterProps extends React.PropsWithChildren<unknown> {
  className?: string;
}

export const useStyles = makeStyles((theme) => ({
  bottom: {
    height: 74,
    backgroundColor: theme.palette.other.black,
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    zIndex: 2,
    padding: `0 ${theme.spacing(4)}`,
  },
  wrapper: {
    width: '100%',
    maxWidth: 600,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& > *': {
      flexGrow: 1,
    },
  },
}));

const FixedFooter = ({ className, children }: FixedFooterProps) => {
  const classes = useStyles();

  return (
    <div className={cn(classes.bottom, className)}>
      <div className={classes.wrapper}>{children}</div>
    </div>
  );
};

export default FixedFooter;
