import { useAuth0 } from 'hooks/auth';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { pathManager } from 'routes';

import {
  CreateCompanyUserMutationVariables,
  useCheckCompanyUserExistsByEmailLazyQuery,
  useCreateCompanyForUserMutation,
  useCreateCompanyUserMutation,
} from '@libs/graphql-types';
import { FormikSubmit } from '@libs/helpers/form';

import { OnboardingV3State } from './types';

export const useCheckAuth0 = () => {
  const { isAuthenticated } = useAuth0();
  const history = useHistory();

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    history.push(pathManager.auth.generatePath());
  }, [history, isAuthenticated]);
};

const mapFormToVariables = (
  form: OnboardingV3State,
): CreateCompanyUserMutationVariables => {
  return {
    first_name: form.general.firstName,
    last_name: form.general.lastName,
    email: form.general.email,
    is_primary: true,
    onboarding_type: 'MAIN',
  };
};

export const useSubmitAction = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [check, { loading: isChecking }] =
    useCheckCompanyUserExistsByEmailLazyQuery();
  const [createUser, { loading }] = useCreateCompanyUserMutation({});
  const [createCompany, { loading: isCompanyCreating }] =
    useCreateCompanyForUserMutation({
      onCompleted: () => {
        history.push(pathManager.company.onboardingV3.final.generatePath());
      },
    });
  const onSubmit: FormikSubmit<OnboardingV3State> = useCallback(
    async (values) => {
      const variables = mapFormToVariables(values);
      const companyName = values.company.name;

      check({
        variables: { email: variables.email },
        onCompleted: (checkRes) => {
          if (checkRes?.checkCompanyUserExistsByEmail) {
            enqueueSnackbar(
              'User with such enail already exist. Please log in',
              {
                variant: 'error',
              },
            );
            return;
          }

          createUser({
            variables,
            onCompleted: (userData) => {
              if (!companyName) {
                enqueueSnackbar("Couldn't get company name", {
                  variant: 'error',
                });
                return;
              }

              createCompany({
                variables: {
                  name: `${companyName}`,
                  primary_company_user_id:
                    userData.createCompanyUserProfile?.id,
                },
              });
            },
          });
        },
      });
    },
    [check, createUser, enqueueSnackbar, createCompany],
  );

  return { onSubmit, loading: loading || isChecking || isCompanyCreating };
};
