import { useCallback, useEffect, useState } from 'react';

import { useStreamChatContext } from './common/provider';

export const useMessengerStatus = (streamChatId: string) => {
  const [isEnabled, setIsEnabled] = useState(false);
  const { client } = useStreamChatContext();

  const queryTalent = useCallback(async () => {
    if (!streamChatId) {
      return;
    }

    const res = await client.queryUsers({
      id: {
        $eq: streamChatId,
      },
    });
    setIsEnabled(!!res.users.length);
  }, [client, streamChatId]);

  useEffect(() => {
    queryTalent();
  }, [queryTalent]);

  return isEnabled;
};
