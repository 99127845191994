import { AllCompanyToTalentModals } from 'components/custom/company/talent-pool-actions/modals/AllCompanyToTalentModals';
import { TalentActionsMenu } from 'components/custom/talent/actions-menu';
import { useMenuAction } from 'hooks/common/useMenuAction';
import React from 'react';
import { noop } from 'utils/common';

import MoreVertIcon from '@mui/icons-material/MoreVert';

import { Talent } from '@libs/graphql-types';
import Button from '@libs/ui/components/button';

import { ActionItem } from '../types';

interface TalentActionsProps {
  talent: Talent;
  refetch?: VoidFunction;
  loading?: boolean;
  filterActions?: (actions: ActionItem[]) => ActionItem[];
}

export const TalentActions = ({
  talent,
  refetch = noop,
  filterActions,
}: TalentActionsProps) => {
  const { open, handleClose, handleClick, anchorEl } = useMenuAction();

  return (
    <>
      <Button
        onClick={handleClick as VoidFunction}
        variant="outlined"
        size="medium"
        data-test-id="talentActions"
        endIcon={<MoreVertIcon />}
      >
        Actions
      </Button>
      <TalentActionsMenu
        open={open}
        filterActions={filterActions}
        anchorEl={anchorEl}
        handleClose={handleClose}
        talent={talent}
        onSuccess={refetch}
      />

      <AllCompanyToTalentModals onSuccess={refetch} modalKey={talent.id} />
    </>
  );
};
