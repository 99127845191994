import { useField } from 'formik';
import React, { useCallback } from 'react';

import { FormControlLabel, FormControlLabelProps } from '@mui/material';
import MuiCheckbox, {
  CheckboxProps as MuiCheckboxProps,
} from '@mui/material/Checkbox';

export interface CheckboxProps extends MuiCheckboxProps {
  label?: React.ReactNode;
  formControlLabelProps?: Partial<FormControlLabelProps>;
  error?: boolean;
}

const Checkbox = (props: CheckboxProps) => {
  const { label, formControlLabelProps, error, checked, onChange, ...rest } =
    props;
  return (
    <FormControlLabel
      control={<MuiCheckbox onChange={onChange} {...rest} />}
      {...formControlLabelProps}
      label={label}
      checked={checked}
      value={checked}
    />
  );
};

export interface ConnectedCheckboxProps extends CheckboxProps {
  name: string;
}

export const ConnectedCheckbox = ({
  name,
  onChange,
  ...rest
}: ConnectedCheckboxProps) => {
  const [field, meta, helpers] = useField(name);
  const onChangeHandler = useCallback(
    (e: React.ChangeEvent<any>) => {
      const val = e.target.checked;
      helpers.setValue(val);
      if (onChange) {
        onChange(e, val);
      }
    },
    [helpers, onChange],
  );
  const error = meta.touched && !!meta.error;

  return (
    <Checkbox
      {...rest}
      error={error}
      onChange={onChangeHandler}
      checked={field.value}
    />
  );
};

export default Checkbox;
