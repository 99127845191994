import { useCurrentUser } from 'hooks/auth';
import { useSnackbar } from 'notistack';

import {
  useGetPaymentLinkForTalentLazyQuery,
  useGetTypeformPaymentLinkCodeForMatcherLazyQuery,
  Query,
} from '@libs/graphql-types';

export const useSelectPremiumAction = ({
  isTalentMatcher,
}: {
  isTalentMatcher?: boolean;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useCurrentUser();

  const query = isTalentMatcher
    ? useGetTypeformPaymentLinkCodeForMatcherLazyQuery
    : useGetPaymentLinkForTalentLazyQuery;
  const [makeRequest, { loading }] = query({
    variables: { talent_id: user?.id },
    onCompleted: (res) => {
      const response = res as Query;

      if (isTalentMatcher) {
        const code: string | undefined =
          response?.getTypeformPaymentLinkCodeForMatcher;
        if (!code) {
          enqueueSnackbar('Failed to get payment link', { variant: 'error' });
          return;
        }
        const link = `https://form.typeform.com/to/KUkGVEmV#payment_link=${code}`;
        window.location.href = link;
        return;
      }
      const link: string | undefined =
        response?.getPaymentLinkForTalent || response?.getPaymentLinkForMatcher;
      if (!link) {
        enqueueSnackbar('Failed to get payment link', { variant: 'error' });
        return;
      }
      window.location.href = link;
    },
  });

  return { makeRequest, loading };
};
