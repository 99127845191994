import { useField } from 'formik';
import React, { useCallback } from 'react';

import SelectImageComponent, {
  SelectImageComponentProps,
} from '../select-image-component';

export interface ConnectedImageControlProps extends SelectImageComponentProps {}

const ConnectedImageControl = (props: ConnectedImageControlProps) => {
  const { name } = props;
  const [, meta, helperProps] = useField<HTMLInputElement>(name);

  const onChange: SelectImageComponentProps['onChange'] = useCallback(
    (file: File | null) => {
      helperProps.setValue(file as any);
      props?.onChange?.(file);
    },
    [helperProps, props],
  );
  const error = meta.touched && !!meta.error;

  return <SelectImageComponent {...props} error={error} onChange={onChange} />;
};

export default ConnectedImageControl;
