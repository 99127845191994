import React from 'react';
import { MessageContextValue, MessageSimple } from 'stream-chat-react';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';

interface CustomMessageProps
  extends Partial<MessageContextValue<DefaultStreamChatGenerics>> {}

export const CustomMessage = (props: CustomMessageProps) => {
  return <MessageSimple {...props} />;
};
