import { UserToTalentChatMeta } from 'components/chat/client-to-talent';
import { TalentToTalentChatMeta } from 'components/chat/talent-to-talent';
import { useCurrentUser } from 'hooks/auth';
import React from 'react';
import { useChannelStateContext } from 'stream-chat-react';

import { Box } from '@mui/material';

import Typography from '@libs/ui/components/typography';

import { ReactComponent as Icon } from '../../../../assets/images/messages.svg';

const useOtherUser = () => {
  const { channel } = useChannelStateContext();
  const { user } = useCurrentUser();
  const channelDetails = channel.data?.channelDetails || {};

  const { talent1, talent2 } = channelDetails as TalentToTalentChatMeta;
  const { companyUser, talent } = channelDetails as UserToTalentChatMeta;

  const otherUser = [talent1, talent2, companyUser, talent].find(
    (i) => i?.id !== user?.id,
  );

  return otherUser;
};

interface NoChannelMessagesCenteredProps {}

export const NoChannelMessagesCentered = (
  props: NoChannelMessagesCenteredProps,
) => {
  const otherUser = useOtherUser();
  const firstName = otherUser?.name?.split(' ')[0];

  return (
    <Box>
      <Box textAlign="center" mb={2}>
        <Icon />
      </Box>

      <Typography textAlign="center" variant="body2" color="textSecondary">
        Send your first message {firstName ? `to ${firstName}` : ''}
      </Typography>
    </Box>
  );
};
