import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useAuth0 } from 'hooks/auth/useAuth0';

import { AppLoadingScreen } from './helpers';

const QuickAuth = () => {
  const location = useLocation<{ from?: string }>();
  const { loginWithRedirect } = useAuth0();
  const from = location?.state?.from;

  useEffect(() => {
    if (from) {
      window.localStorage.setItem('authFrom', from);
    }
    loginWithRedirect({});
  }, [from, loginWithRedirect]);

  return <AppLoadingScreen />;
};

export default QuickAuth;
