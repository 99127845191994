import { INFINITY_SIGN } from 'consts/common';
import React from 'react';

import Typography from '@libs/ui/components/typography';

import { FormModel } from '../types';
import { useTalentCount } from './hooks';

interface TalentsCountProps {
  values: FormModel;
}

export const TalentsCount = ({ values }: TalentsCountProps) => {
  const { count, loading } = useTalentCount(values);

  return (
    <Typography
      variant="body2"
      color={loading ? 'text.secondary' : 'text.main'}
    >
      This message will be sent to{' '}
      <Typography component="span" variant="body2" color="tertiary.main">
        {loading ? INFINITY_SIGN : count}
      </Typography>{' '}
      talents
    </Typography>
  );
};
