import { useField } from 'formik';
import React from 'react';

import wrapControlWithGraph from '../hocs/wrapControlWithGraph';
import MultipleSelect, { MultipleSelectProps } from './MultipleSelect';

export type ConnectedMultipleSelectProps = {
  name: string;
} & MultipleSelectProps;

const ConnectedMultipleSelect = (props: ConnectedMultipleSelectProps) => {
  const { name, helperText, autoCompleteProps, noOptionsText, ...rest } = props;
  const [field, meta, helpers] = useField(name);

  const error = meta.touched && !!meta.error;
  const finalHelperText = meta.touched && meta.error ? meta.error : helperText;

  return (
    <MultipleSelect
      autoCompleteProps={{
        ...autoCompleteProps,
        ...field,
        onChange: (_: any, value: any) => {
          helpers.setValue(value || '');
          if (autoCompleteProps?.onChange) autoCompleteProps.onChange(value);
        },
        onBlur: () => helpers.setTouched(true),
      }}
      helperText={finalHelperText}
      error={error}
      noOptionsText={noOptionsText}
      {...rest}
    />
  );
};

export default ConnectedMultipleSelect;
export const GraphConnectedMultipleSelect = wrapControlWithGraph<
  Omit<ConnectedMultipleSelectProps, 'options'>
>(ConnectedMultipleSelect);
