/* eslint-disable @typescript-eslint/no-explicit-any */
import compose from '../common/compose';

const DEFAULT_PROXY_OPTIONS = {
  has() {
    throw new Error(
      'Path function must not refer to argument through "in" statement',
    );
  },
};

function collect(arr: Array<Array<string>>) {
  return () => {
    return new Proxy(Object.create(null), {
      get(_: any, prop: string) {
        const currentArr: string[] = [];
        arr.push(currentArr);
        currentArr.push(prop);
        const childProxy: ProxyHandler<any> = new Proxy(Object.create(null), {
          get(__: any, childProp: string) {
            currentArr.push(childProp);
            return childProxy;
          },
          ...DEFAULT_PROXY_OPTIONS,
        });
        return childProxy;
      },
      ...DEFAULT_PROXY_OPTIONS,
    });
  };
}

function pathToString(arr: string[] | undefined) {
  return (
    arr?.reduce((result, item) => {
      const prefix = result === '' ? '' : '.';
      return (
        result + (Number.isNaN(Number(item)) ? prefix + item : `[${item}]`)
      );
    }, '') || ''
  );
}

export function modelPath<T>(path: (object: T) => any): string {
  // Результирующий путь является последней цепочкой вызовов свойств объекта и является последним элементом массива arr,
  // По дизайну путь последних вызовов свойст это последняя цепочка вызовов свойств объекта перед return в функции
  // При инициализации arr уже добавлен пустой массив для обработки функций пути вида object => object
  const arr: Array<Array<string>> = [[]];
  const resultProxy = compose(path, collect(arr))();
  if (!resultProxy) {
    throw new Error('No return statement in function');
  }
  if (arr.length > 2) {
    throw new Error(
      `The path function contains unnecessary references to the properties of the argument
      or is the argument itself used in the calculations of the argument property`,
    );
  }
  return pathToString(arr[arr.length - 1]);
}
