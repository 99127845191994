import { Formik } from 'formik';
import React from 'react';

import { Box, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { FormikSubmit } from '@libs/helpers/form';

import { OnboardingV4State } from '../types';
import { CheckFormErrors } from './CheckFormErrors';
import { useInitialState, useValidator } from './hooks';

interface OnboardingFormProps extends React.PropsWithChildren<unknown> {
  onSubmit: FormikSubmit<OnboardingV4State>;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#0F0F0D',
  },
}));

export const OnboardingForm = ({ children, onSubmit }: OnboardingFormProps) => {
  const { isLoading, initialValues } = useInitialState();
  const validator = useValidator();
  const classed = useStyles();

  if (isLoading) {
    return (
      <Box justifyContent="center" display="flex">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Formik<OnboardingV4State>
      initialValues={initialValues}
      validationSchema={validator}
      onSubmit={onSubmit}
      validateOnMount
    // enableReinitialize
    >
      {({ values, errors }) => (
        <div className={classed.root}>
          {/* {console.log(values, errors)} */}
          <CheckFormErrors />
          {children}
        </div>
      )}
    </Formik>
  );
};
