import TopStepper from 'components/custom/onboarding/top-stepper';
import React from 'react';
import { pathManager } from 'routes';

import { Box, Grow } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Typography from '@libs/ui/components/typography';

import { STEPS } from '../consts';

interface StepTemplateProps extends React.PropsWithChildren<unknown> {
  title: string;
  subtitle: string;
  currentStep: number;
  currentStepLabel: string;
}

export const useStyles = makeStyles((theme) => ({
  '@global': {
    '.MuiFilledInput-root': {
      border: 'none',
    },
  },

  root: {
    paddingTop: 63,
    display: 'flex',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2),
    },
  },

  wrapper: {
    maxWidth: 930,
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  subTitle: {
    marginBottom: theme.spacing(8),
    color: theme.palette.grey[500],

    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(6),
    },
  },
}));

const StepTemplate = ({
  children,
  currentStep,
  subtitle,
  title,
  currentStepLabel,
}: StepTemplateProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box
        className={classes.wrapper}
        flexGrow={1}
        data-test-id="creatingScreen"
      >
        <Grow timeout={500} in>
          <div>
            <Typography variant="h5" fontWeight={500} className={classes.title}>
              {title}
            </Typography>
            <Typography variant="subtitle1" className={classes.subTitle}>
              {subtitle}
            </Typography>
            <TopStepper
              getStepLink={(step) =>
                pathManager.company.newOnboarding.builder.step.generatePath({
                  step,
                })
              }
              currentStepLabel={currentStepLabel}
              current={currentStep}
              allStepsCount={STEPS.length}
            />
          </div>
        </Grow>

        {children}
      </Box>
    </div>
  );
};

export default StepTemplate;
