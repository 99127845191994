import { format, isToday } from 'date-fns';
import React from 'react';

import { makeStyles } from '@mui/styles';

interface DateTimeLabelProps {
  date?: Date;
  className?: string;
}

const formatDate = (date: Date): string => {
  if (isToday(date)) {
    return format(date, 'h:mm aa');
  }

  return format(date, 'd/M/yy');
};

export const useStyles = makeStyles(() => ({
  dateTime: {
    position: 'absolute',
    top: 10,
    right: 10,
    fontSize: 11,
  },
}));

const DateTimeLabel = ({ date, className }: DateTimeLabelProps) => {
  if (!date) {
    return null;
  }
  const formatted = formatDate(date);

  return <div className={className}>{formatted}</div>;
};

export default DateTimeLabel;
