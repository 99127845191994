import { JobMarkType } from 'types';
import { RoutePath } from 'utils/routes';

export const pathManager = {
  auth: new RoutePath<never>('/'),
  auth0: new RoutePath<never>('/auth0'),
  logout: new RoutePath<never>('/logout'),
  quickAuth: new RoutePath<never>('/quick-auth'),
  invitation: new RoutePath<never>('/invitation'),
  notifications: new RoutePath<never>('/notifications'),
  chat: new RoutePath<never>('/chat'),
  leave: new RoutePath<never>('/leave'),
  perks: new RoutePath<never>('/perks'),

  public: {
    job: new RoutePath<{ id: string }>('/public/job/:id'),
  },

  recruiter: {
    sourceJob: new RoutePath<{ id: string }>('/recruiter/source-job/:id'),
  },

  company: {
    workforce: new RoutePath<never>('/company'),
    dashboard: new RoutePath<never>('/dashboard'),
    createProfile: new RoutePath<never>('/company/create-profile'),
    talentProfile: new RoutePath<{ id: string }>('/company/talent-profile/:id'),
    settings: {
      main: new RoutePath<never>('/company/settings'),
    },

    pendingTalents: new RoutePath<never>('/company/pending-talents'),

    users: {
      edit: new RoutePath<{ id: string }>('/company/user/:id'),
      create: new RoutePath<never>('/company/user/create'),
    },
    jobBoard: new RoutePath<never>('/company/job-board'),

    onboarding: {
      redirect: new RoutePath<never>('/invite/:userKey'),
      main: new RoutePath<never>('/company/onboarding'),
      greeting: new RoutePath<never>('/company/onboarding/greeting'),
      form: new RoutePath<never>('/company/onboarding/form'),
      final: new RoutePath<never>('/company/onboarding/final'),
      intro: new RoutePath<never>('/company/onboarding/intro'),
    },

    onboardingV3: {
      main: new RoutePath<never>('/company/onboarding-v3'),
      general: new RoutePath<never>('/company/onboarding-v3/general'),
      final: new RoutePath<never>('/company/onboarding-v3/final'),
      company: new RoutePath<never>('/company/onboarding-v3/company'),
    },

    onboardingV4: {
      main: new RoutePath<never>('/company/start'),
      general: new RoutePath<never>('/company/start/general'),
      final: new RoutePath<never>('/company/start/final'),
      company: new RoutePath<never>('/company/start/company'),
      create: new RoutePath<never>('/company/start/create'),
      bookMeeting: new RoutePath<never>('/company/start/book-meeting'),
      bookRecruiter: new RoutePath<never>('/company/start/recruiter'),
      bookAgency: new RoutePath<never>('/company/start/agency'),
    },

    newOnboarding: {
      main: new RoutePath<never>('/company/onboarding-v2'),
      greeting: new RoutePath<never>('/company/onboarding-v2/greeting'),
      bookCall: new RoutePath<never>('/company/onboarding-v2/book-call'),
      final: new RoutePath<never>('/company/onboarding-v2/final'),
      builder: {
        main: new RoutePath<never>('/company/onboarding-v2/builder'),
        step: new RoutePath<{ step: number }>(
          '/company/onboarding-v2/builder/:step',
        ),
      },
    },

    job: new RoutePath<{ id: string }>('/company/job/:id'),
    editJob: new RoutePath<{ id: string }>('/company/edit-job/:id'),
    duplicateJob: new RoutePath<{ id: string }>('/company/duplicate-job/:id'),

    newJob: {
      choose: new RoutePath<never>('/company/new-job/choose'),
      service: new RoutePath<never>('/company/new-job/choose/service'),
      type: new RoutePath<never>('/company/new-job/choose/type'),
      form: new RoutePath<never>('/company/new-job/form'),
    },
  },
  talent: {
    createCommunity: new RoutePath<never>('/talent/create-community'),
    pendingVerificaion: new RoutePath<never>('/talent/pending-verification'),
    companies: {
      main: new RoutePath<never>('/talent/companies'),
      viewCompany: new RoutePath<{ id: string }>('/talent/companies/:id'),
    },
    lobby: {
      main: new RoutePath<never>('/talent/lobby'),
      member: new RoutePath<never>('/talent/lobby/member'),
      matcher: new RoutePath<never>('/talent/lobby/matcher'),
      info: new RoutePath<never>('/talent/lobby/info'),
    },
    subscription: new RoutePath<never>('/talent/subscription'),
    settings: {
      main: new RoutePath<never>('/talent/settings'),
    },
    community: new RoutePath<never>('/talent/community'),
    profile: new RoutePath<never>('/talent'),
    groups: new RoutePath<never>('/talent/groups'),
    editProfile: new RoutePath<never>('/talent/edit-profile'),
    jobBoard: new RoutePath<never>('/talent/job-board'),
    invite: new RoutePath<never>('/invites'),
    invitation: new RoutePath<{ key: string }>('/invitation/:key'),
    invitationSuccess: new RoutePath<never>('/invitation/success'),
    invitationInUse: new RoutePath<never>('/invitation/in-use'),
    jobApply: new RoutePath<{ id: string }>('/talent/job-apply/:id'),
    markedJobs: new RoutePath<{ type: JobMarkType }>(
      '/talent/marked-jobs/:type',
    ),
    viewOtherTalent: new RoutePath<{ id: string }>('/talent/view-talent/:id'),
    jobInvitation: new RoutePath<{ id: string }>('/talent/job-invitation/:id'),
    onboarding: {
      main: new RoutePath<never>('/talent/onboarding'),
      communityCheck: new RoutePath<never>('/talent/onboarding/community'),
      greeting: new RoutePath<never>('/talent/onboarding/greeting'),
      intro: new RoutePath<{ step: number }>('/talent/onboarding/intro/:step'),
      creating: new RoutePath<{ step: number }>(
        '/talent/onboarding/creating/:step',
      ),
      form: new RoutePath<never>('/talent/onboarding/form'),
      finish: new RoutePath<never>('/talent/onboarding/finish'),
    },
  },
};

export const ANONYMOUS_LOCATIONS = [
  pathManager.talent.onboarding.main.generatePath(),
  pathManager.company.newOnboarding.main.generatePath(),
  pathManager.company.onboardingV4.main.generatePath(),
  pathManager.company.onboarding.main.generatePath(),
];

export const EXTERNAL_RESOURCES = {
  jobPage: ({ id }: { id: string | number }) =>
    `https://jobs.opentalent.co/${id}`,
  talentLending: 'https://opentalent.co',
  companiesLanding: 'https://opentalent.co/direct',
};
