import { ConnectedPageLayout } from 'components/layout/page-layout';
import React from 'react';
import { useParams } from 'react-router-dom';

import { Box, Grid } from '@mui/material';

import { useGetCompanyViewScreenDataQuery } from '@libs/graphql-types';

import { AboutCard } from './AboutCard';
import { CommunityCard } from './CommunityCard';
import { JobsCard } from './JobsCard';
import { TitleCard } from './title-card';

export const ViewCompany = () => {
  const { id } = useParams<{ id: string }>();
  const { loading, data } = useGetCompanyViewScreenDataQuery({
    variables: {
      id,
    },
    skip: !id,
  });

  return (
    <ConnectedPageLayout
      documentTitle="Companies"
      headerProps={{ accountProps: {} }}
      drawerProps={{}}
      isLoading={loading}
    >
      <Box mb={4}>
        <TitleCard company={data?.company} />
      </Box>

      <Grid container spacing={4}>
        <Grid item md={4}>
          <AboutCard about={data?.company?.about} />
        </Grid>
        <Grid item md={4}>
          <CommunityCard
            talents={data?.talentsSearch?.data}
            total={data?.talentsSearch?.custom_paginator_info?.total}
          />
        </Grid>
        <Grid item md={4}>
          <JobsCard jobsCount={data?.jobs?.paginatorInfo?.total} />
        </Grid>
      </Grid>
    </ConnectedPageLayout>
  );
};
