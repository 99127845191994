import { ActionItem, ActionsMenuProps } from 'components/custom/talent/types';
import React from 'react';

import { Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { JobMatch } from '@libs/graphql-types';

interface TalentActionsMenuProps extends Omit<ActionsMenuProps, 'actions'> {
  match: JobMatch;
  onSuccess?: VoidFunction;
  actions: ActionItem[];
}

const useStyles = makeStyles((theme) => ({
  menu: {
    minWidth: `150px !important`,
  },
  error: {
    color: theme.palette.error.main,

    '& svg': {
      color: theme.palette.error.main,
    },
  },
  warinig: {
    color: theme.palette.warning.main,

    '& svg': {
      color: theme.palette.warning.main,
    },
  },
  success: {
    color: theme.palette.success.main,

    '& svg': {
      color: theme.palette.success.main,
    },
  },
}));

export const TalentActionsMenu = ({
  anchorEl,
  actions,
  handleClose,
  open,
  hideDisabled = false,
}: TalentActionsMenuProps) => {
  const classes = useStyles();

  return (
    <Menu
      id="long-menu"
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      classes={{ list: classes.menu }}
    >
      {actions.map(({ text, onClick, Icon, disabled = false, color }) => {
        if (disabled && hideDisabled) {
          return null;
        }

        return (
          <MenuItem
            key={text}
            onClick={() => {
              onClick();
              handleClose();
            }}
            style={{ paddingTop: '2px', paddingBottom: '2px' }}
            classes={{
              // @ts-ignore
              root: disabled ? '' : classes[color as any],
            }}
            disabled={disabled}
          >
            {Icon && (
              <ListItemIcon>
                <Icon fontSize="inherit" />
              </ListItemIcon>
            )}
            <ListItemText primary={text} />
          </MenuItem>
        );
      })}
    </Menu>
  );
};
