import FixedFooter from 'components/custom/onboarding/fixed-footer';
import React from 'react';
import { useHistory } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { JobTypeEnum } from '@libs/graphql-types';
import { EXTERNAL_LINKS } from '@libs/helpers/consts';
import { modelPath } from '@libs/helpers/form';
import Button from '@libs/ui/components/button';
import { OuterLink } from '@libs/ui/components/typography';

import ChoiceBox from '../../shared/ChoiceBox';
import { useDefaultOnSubmitHandler } from '../form-context/hooks';
import { BuilderFormState } from '../form-context/types';
import NextButtonWithValidation from '../shared/NextButtonWithValidation';
import StepTemplate from '../shared/StepTemplate';
import { DefaultStepProps } from '../shared/types';
import { useChangeType } from './hooks';

interface JobTypeChoosingProps extends DefaultStepProps {}

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    flexGrow: 1,
    minWidth: '50%',

    '&:last-child': {
      paddingLeft: theme.spacing(4),
    },
    '&:first-child': {
      paddingRight: theme.spacing(4),
    },

    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(2),
      padding: '0 !important',
    },
  },

  wrapper: {
    flexWrap: 'nowrap',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },

  linkContainer: {
    paddingTop: theme.spacing(20),
    textAlign: 'center',

    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(6),
    },
  },
}));

const BOXES = [
  {
    title: 'Freelancer',
    subtitle: 'Contractor paid per hour, or day',
    name: JobTypeEnum.Freelance,
  },
  {
    title: 'Permanent hire',
    subtitle: 'Full-time or part-time employee',
    name: JobTypeEnum.Permanent,
  },
];

const JobTypeChoosing = ({
  index,
  nextStepRoute,
  prevStepRoute,
}: JobTypeChoosingProps) => {
  const history = useHistory();
  const classes = useStyles();
  const { onSubmit, loading } = useDefaultOnSubmitHandler({
    redirectRoute: nextStepRoute,
  });
  const { handleChange, type } = useChangeType({
    path: modelPath<BuilderFormState>((m) => m.jobType.type),
  });

  return (
    <StepTemplate
      title="What are you looking for?"
      subtitle="Select the Type of Hire."
      currentStep={index}
      currentStepLabel="Type"
    >
      <Grid className={classes.wrapper} container>
        {BOXES.map((box) => (
          <Grid key={box.title} className={classes.cardWrapper} item>
            <ChoiceBox
              title={box.title}
              subtitle={box.subtitle}
              name={box.name}
              isActive={box.name === type}
              onClick={() => handleChange(box.name)}
            />
          </Grid>
        ))}
      </Grid>

      <Box className={classes.linkContainer}>
        <OuterLink
          target="_blank"
          color="info.main"
          href={EXTERNAL_LINKS.teamHiring}
        >
          Looking to hire a team / buy a service?
        </OuterLink>
      </Box>

      <FixedFooter>
        <Grid spacing={4} justifyContent="center" container>
          <Grid xs={6} item>
            <Button
              startIcon={<ArrowBackIcon />}
              color="primary"
              variant="outlined"
              fullWidth
              onClick={() => history.goBack()}
            >
              Back
            </Button>
          </Grid>
          <Grid xs={6} item>
            <NextButtonWithValidation
              path={modelPath<BuilderFormState>((m) => m.jobType)}
              onClick={onSubmit}
              disabled={loading}
            >
              next
            </NextButtonWithValidation>
          </Grid>
        </Grid>
      </FixedFooter>
    </StepTemplate>
  );
};

export default JobTypeChoosing;
