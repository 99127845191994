import React from 'react';
import { Link } from 'react-router-dom';
import { pathManager } from 'routes';

import { Grid, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Notification } from '@libs/graphql-types';
import Button from '@libs/ui/components/button';
import Typography from '@libs/ui/components/typography';

import NotificationItem from './NotificationItem';
import {
  useChangeNotificationStatus,
  useMarkAllAsRead,
  useRemoveNotification,
} from './hooks';

interface NotificationsListProps {
  notifications: Notification[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: 654,
    background: theme.palette.grey[200],
    // maxHeight: 622,
    // overflowY: 'auto',
  },
}));

const NotificationsList = ({ notifications }: NotificationsListProps) => {
  const classes = useStyles();
  const { markAllAsRead, isMarkingInProgress } = useMarkAllAsRead();
  const { handleChangeStatus, isStatusLoading } = useChangeNotificationStatus();
  const { handleDelete, isDeleteInProgress } = useRemoveNotification();
  const hasNotifications = !!notifications.length;

  return (
    <Box
      data-test-id="header-notifications-list"
      className={classes.root}
      p={6}
    >
      <Box pb={4}>
        <Typography variant="h5">Notifications</Typography>
      </Box>
      <Grid justifyContent="space-between" alignItems="center" container>
        <Grid item>
          <Typography variant="h6" color="textSecondary">
            Latest
          </Typography>
        </Grid>
        {hasNotifications && (
          <Grid item>
            <Button
              onClick={markAllAsRead}
              disabled={isMarkingInProgress}
              color="tertiary"
            >
              Mark all as read
            </Button>
          </Grid>
        )}
      </Grid>

      <Box pt={4}>
        {notifications.slice(0, 4).map((i) => (
          <NotificationItem
            isLoading={isStatusLoading || isDeleteInProgress}
            deleteNotification={handleDelete}
            changeStatus={handleChangeStatus}
            notification={i}
            key={i.id}
          />
        ))}
      </Box>

      {hasNotifications && (
        <Box pt={4}>
          <Link to={pathManager.notifications.generatePath()}>
            <Button color="tertiary">History of notifications</Button>
          </Link>
        </Box>
      )}
    </Box>
  );
};

export default NotificationsList;
