import cn from 'classnames';
import { CHAT_STYLE_VARS, streamI18nInstance } from 'components/chat';
import {
  NoChannelsContent,
  JumpToMessageAction,
  NoChannelMessages,
} from 'components/chat/common';
import {
  ChatSidebarItem,
  EmptySidebarComponent,
} from 'components/chat/common/chat-sidebar';
import { CustomMessageList } from 'components/chat/common/message';
import {
  useStreamChatContext,
  getOwnChatFilter,
  DEFAULT_CHANNELS_SORT,
} from 'components/chat/common/provider';
import {
  CustomSearchResultsList,
  useSearchMassagesAndUsersHandler,
} from 'components/chat/common/search';
import { DefaultSearchResultItem } from 'components/chat/common/search/search-result-item';
import { useChatStyles } from 'components/chat/styles';
import useMediaQueries from 'hooks/common/useMediaQueries';
import React, { useMemo } from 'react';
import {
  Channel,
  Thread,
  Chat,
  Window,
  MessageInput,
  ChannelList,
} from 'stream-chat-react';

import { Alert } from '@mui/material';

import FromLocationChatCreator from '../chat-creator';
import CustomChatHeader from './CustomHeader';
import { CustomMessageInput } from './custom-message-input';
import { useStyles } from './styles';

interface ChatAppProps {}

const ChatApp = (props: ChatAppProps) => {
  const chatClasses = useChatStyles();
  const { isSM } = useMediaQueries();
  const classes = useStyles();
  const { client, userData } = useStreamChatContext();
  const userId = userData?.chatId;
  const searchFilter = useSearchMassagesAndUsersHandler();
  const filter = useMemo(() => getOwnChatFilter(userId || ''), [userId]);

  if (!userId) {
    return <Alert color="error">Something goes wrong</Alert>;
  }

  return (
    <div className={cn(chatClasses.chat, classes.chatPage)}>
      <Chat
        i18nInstance={streamI18nInstance}
        defaultLanguage="en"
        client={client}
        customStyles={CHAT_STYLE_VARS}
      >
        <ChannelList
          sort={DEFAULT_CHANNELS_SORT}
          filters={filter}
          showChannelSearch
          Preview={ChatSidebarItem}
          EmptyStateIndicator={EmptySidebarComponent}
          additionalChannelSearchProps={{
            searchFunction: searchFilter,
            SearchResultItem: DefaultSearchResultItem,
            SearchResultsList: CustomSearchResultsList,
            SearchResultsHeader: () => <></>,
          }}
        />

        <Channel
          Input={CustomMessageInput}
          EmptyPlaceholder={isSM ? undefined : <NoChannelsContent />}
          EmptyStateIndicator={NoChannelMessages}
        >
          <Window>
            <CustomChatHeader />
            <CustomMessageList />
            <MessageInput focus />
          </Window>
          <Thread />
          <JumpToMessageAction />
        </Channel>
        <FromLocationChatCreator />
      </Chat>
    </div>
  );
};

export default ChatApp;
