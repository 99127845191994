import React from 'react';

import { Grow } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface OnboardingWrapperProps extends React.PropsWithChildren<unknown> { }

const useStyles = makeStyles((theme) => ({
  root: {
    minWidht: '100%',
    minHeight: '100vh',
    backgroundColor: '#0F0F0D',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    '& .MuiTypography-root': {
      color: 'white',
    },

    '& .MuiInputBase-root': {
      borderRadius: theme.spacing(2),
      overflow: 'hidden',
    },
  },

  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(4),
    maxWidth: '1132px',
    width: '100%',
    margin: '0 auto',
  },
}));

export const OnboardingWrapper = ({ children }: OnboardingWrapperProps) => {
  const classes = useStyles();

  return (
    <Grow in timeout={500}>
      <div className={classes.root}>
        <div className={classes.wrapper}>{children}</div>
      </div>
    </Grow>
  );
};
