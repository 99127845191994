import { getIn, useFormikContext } from 'formik';

import { BuilderFormState } from '../form-context/types';

export const useChangeType = ({ path }: { path: string }) => {
  const { setFieldValue, setFieldTouched, validateForm, values } =
    useFormikContext<BuilderFormState>();

  const handleChange = (type: string) => {
    setFieldValue(path, type);
    setFieldTouched(path, true);
    validateForm();
  };

  return { type: getIn(values, path), handleChange };
};
