import { Grid } from '@mui/material';
import React from 'react';

import { DefaultModal } from '@libs/ui/components/modals';

interface SuccessApplyModalProps {
  open: boolean;
  handleClose: VoidFunction;
}

const SuccessApplyModal = ({ open, handleClose }: SuccessApplyModalProps) => (
  <DefaultModal
    open={open}
    handleClose={handleClose}
    title="Thanks for your interest in this job"
  >
    <Grid spacing={4} direction="column" container>
      <Grid item>We’ll contact with you soon</Grid>
    </Grid>
  </DefaultModal>
);

export default SuccessApplyModal;
