import { makeStyles } from '@mui/styles';

import { Theme } from '@libs/ui/themes/default';

import { DropzoneProps } from './Dropzone';

const useStyles = makeStyles((theme: Theme) => ({
  container: {},
  dropzone: {
    height: 120,
    border: (props: DropzoneProps) =>
      `1px dashed ${
        props.error ? theme.palette.error.main : theme.palette.text.secondary
      }`,
    borderRadius: theme.spacing(1),
    outline: 'none',
    boxShadow: 'none',
    cursor: 'copy',
    color: theme.palette.text.secondary,
    transition: `all ${theme.transitions.duration.short}s ${theme.transitions.easing.easeInOut}`,
    marginBottom: theme.spacing(2),

    '&:active': {
      borderColor: theme.palette.tertiary.main,
      color: theme.palette.tertiary.main,
    },
  },
  fileList: {
    width: '100%',
    marginTop: theme.spacing(4),
  },
  fileItem: {
    position: 'relative',

    '&:before': {
      content: "''",
      width: 6,
      height: 6,
      borderRadius: '100%',
      background: theme.palette.secondary.light,
      position: 'absolute',
      left: -20,
      top: 10,
    },
  },
  fileItemText: {
    maxWidth: 'calc(100% - 80px)',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  removeIcon: {
    cursor: 'pointer',
    color: theme.palette.grey[500],
  },
}));

export default useStyles;
