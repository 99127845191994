import errors from 'consts/validationErrors';
import * as yup from 'yup';

import { maxStringValidator } from '@libs/helpers/yup';

export const getValidator = () =>
  yup.object().shape({
    first_name: maxStringValidator.required(errors.required),
    last_name: maxStringValidator,
    email: yup.string().trim().email().required(errors.required),
  });
