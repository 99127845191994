import { gql } from '@apollo/client';
import FULL_TALENT_FRAGMENT from 'graphql/fragments/talent/fullTalentFragment';

export const LOAD_TALENT_QUERY = gql`
  ${FULL_TALENT_FRAGMENT}
  query LoadTalentQuery($talent_id: ID!) {
    talent(id: $talent_id) {
      ...FullTalent
    }
  }
`;
