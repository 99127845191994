import { useFormikContext } from 'formik';
import React, { useMemo } from 'react';

import { Box, Grid } from '@mui/material';

import { CARDS_DATA } from './consts';
import { PerkCardV2 } from './perk-card-2';

interface PerksListProps { }

export const PerksList = (props: PerksListProps) => {
  const { values } = useFormikContext();
  const finalPerks = useMemo(() => {
    const cat = (values as any).category;
    if (!cat) {
      return CARDS_DATA;
    }
    return CARDS_DATA.filter((i) => i.subtitle === cat);
  }, [values]);

  return (
    <Box>
      <Grid container spacing={4}>
        {finalPerks.reverse().map((item) => (
          <Grid key={item.title} item lg={6}>
            <PerkCardV2 {...item} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
