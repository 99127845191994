import React from 'react';

import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Typography from '@libs/ui/components/typography';

import img1 from './images/1.png';
import img2 from './images/2.png';
import img3 from './images/3.png';

interface TopBlockProps { }

const ITEMS = [
  {
    icon: img1,
    title: 'Community',
    text: 'Bring your favorite candidate leads together in one community.',
  },
  {
    icon: img2,
    title: 'Matching',
    text: 'Automatically see the top matching candidates for every open role.',
  },
  {
    icon: img3,
    title: 'Hiring',
    text: 'Connect with anyone directly. Hire anyone free of middlemen fees.',
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(255, 253, 231, 1)',
    padding: theme.spacing(12, 8, 50),
  },
  icon: {
    width: 56,
    height: 56,
  },
  dataItem: {
    width: 300,
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

export const TopBlock = (props: TopBlockProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box mb={10}>
        <Typography variant="h5" textAlign="center" fontWeight={600}>
          Looking for a smarter way to source talent?
        </Typography>
        <Typography variant="h5" textAlign="center">
          Launch your Company on OpenTalent.
        </Typography>
      </Box>
      <Grid container spacing={4} justifyContent="center">
        {ITEMS.map((i) => (
          <Grid item key={i.title}>
            <Grid className={classes.dataItem} container direction="column">
              <Grid mb={4} justifyContent="center" display="flex" item>
                <img className={classes.icon} src={i.icon} alt="Some pic" />
              </Grid>
              <Grid item mb={2}>
                <Typography variant="h6">{i.title}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{i.text}</Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
