import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import MainJobBoard from './main';

interface JobBoardProps extends RouteComponentProps { }

export default function JobBoard(props: JobBoardProps) {
  return (
    <>
      <MainJobBoard {...props} />
    </>
  );
}
