import { Auth0Provider as BaseAuth0Provider } from '@auth0/auth0-react';
import React from 'react';

import {
  AUDIENCE,
  CLIENT_ID,
  DOMAIN,
  SCOPE,
  REDIRECT_URI,
} from 'consts/config';

const Auth0Provider = ({
  children,
}: React.PropsWithChildren<Record<string, unknown>>) => {
  return (
    <BaseAuth0Provider
      domain={DOMAIN}
      clientId={CLIENT_ID}
      redirectUri={REDIRECT_URI}
      scope={SCOPE}
      audience={AUDIENCE}
      cacheLocation="localstorage"
    >
      {children}
    </BaseAuth0Provider>
  );
};

export default Auth0Provider;
