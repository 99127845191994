import React from 'react';

import {
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  ListSubheader,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { FormControlProps } from '@mui/material/FormControl/FormControl';
import MuiSelect, { SelectProps as MuiSelectProps } from '@mui/material/Select';

import { OptionsGroup, OptionType } from './types';
import { isItGroup } from './utils';

export interface SelectProps extends MuiSelectProps {
  helperText?: string;
  options?: OptionType[] | OptionsGroup[];
  formControlProps?: FormControlProps;
  isLoading?: boolean;
  hideNoneValue?: boolean;
  noneItemText?: string;
  labelShrink?: boolean;
}

const SelectProgress = (props: any) => (
  <CircularProgress size={20} {...props} />
);

const Select: React.FC<SelectProps> = ({
  margin,
  label,
  helperText,
  error,
  disabled,
  options,
  variant,
  fullWidth,
  formControlProps,
  isLoading,
  IconComponent,
  hideNoneValue,
  noneItemText = 'None',
  labelShrink,
  ...rest
}) => {
  const outlinedLabel = variant === 'outlined' ? label : undefined;
  const FinalIconComponent = isLoading
    ? SelectProgress
    : IconComponent || undefined;

  return (
    <FormControl
      variant={variant}
      disabled={disabled}
      error={error}
      margin={margin}
      fullWidth={fullWidth}
      // TODO: перенести все пропы для контрола суда
      {...formControlProps}
    >
      {label && <InputLabel shrink={labelShrink}>{label}</InputLabel>}
      <MuiSelect
        label={outlinedLabel}
        IconComponent={FinalIconComponent}
        {...rest}
        MenuProps={{
          ...rest.MenuProps,
          MenuListProps: {
            'data-test-id': 'select-listbox',
          } as any,
        }}
      >
        {!hideNoneValue && <MenuItem value="">{noneItemText}</MenuItem>}
        {options?.map((item) => {
          if (isItGroup(item)) {
            return [
              <ListSubheader key={item.label}>{item.label}</ListSubheader>,
              item.options.map((option) => (
                <MenuItem
                  key={item.label}
                  disabled={option.disabled}
                  value={option.value}
                >
                  {option.text}
                </MenuItem>
              )),
            ];
          }

          return (
            <MenuItem
              disabled={item.disabled}
              key={item.value}
              value={item.value}
            >
              {item.text}
            </MenuItem>
          );
        })}
      </MuiSelect>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

Select.displayName = 'CustomSelect';

export default Select;
