import { gql } from '@apollo/client';

export const GET_COMPANY_TALENTS = gql`
  query GetCompanyTalent(
    $worked_in_company_id: ID
    $worked_in_company_str: String
  ) {
    talentsSearch(
      worked_in_company_str: $worked_in_company_str
      worked_in_company_id: $worked_in_company_id
    ) {
      custom_paginator_info {
        total
      }
    }
  }
`;
