import React, { Dispatch, SetStateAction, useState } from 'react';
import { Link } from 'react-router-dom';
import { pathManager } from 'routes';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Paper } from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';

import { Job, MatchQualityEnum } from '@libs/graphql-types';
import { stopEvent } from '@libs/helpers/common';
import Button from '@libs/ui/components/button';
import Typography from '@libs/ui/components/typography';

import JobCardFooter from './JobCardFooter';
import JobCardTitle from './JobCardTitle';
import JobDescription from './JobDescription';
import JobDetails from './JobDetails';
import JobKeySkills from './JobKeySkills';

interface JobCardProps {
  onInvite: Dispatch<SetStateAction<Required<Job> | undefined>>;
  onJobApply: Dispatch<SetStateAction<Required<Job> | undefined>>;
  onJobSave?: VoidFunction;
  loadJobs?: VoidFunction;
  job: Job;
  currentTime: Date;
  matchQuality?: MatchQualityEnum;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    marginBottom: theme.spacing(4),
    cursor: 'pointer',
    transition: 'background-color 0.5s ease',

    '&:hover': {
      backgroundColor: grey[100],
    },
  },
  toggler: {
    color: theme.palette.tertiary.main,
  },
  togglerIcon: {
    color: theme.palette.info.main,
    transform: ({ isOpen }: { isOpen: boolean }) =>
      `rotate(${isOpen ? 0 : 180}deg)`,
  },
}));

const JobCard = ({
  job,
  currentTime,
  onJobApply,
  onInvite,
  onJobSave,
  loadJobs,
  matchQuality,
}: JobCardProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles({ isOpen });

  return (
    <Paper
      component={Link}
      {...{
        to: pathManager.talent.jobApply.generatePath({
          id: job.id,
        }),
      }}
      data-test-id="jobCard"
      className={classes.root}
      elevation={0}
    >
      <Box p={6}>
        <JobCardTitle
          isSaved={!!job?.is_saved}
          job={job}
          currentTime={currentTime}
          onJobSave={onJobSave}
          matchQuality={matchQuality}
        />
        <JobKeySkills job={job} isOpen={isOpen} />
        <JobDetails job={job} isOpen={isOpen} />
        <Box onClick={stopEvent} display="flex" justifyContent="flex-end">
          <Button
            onClick={() => setIsOpen(!isOpen)}
            className={classes.toggler}
            variant="text"
            color="info"
            endIcon={
              <KeyboardArrowUpIcon
                className={classes.togglerIcon}
                color="inherit"
              />
            }
          >
            <Typography
              style={{ textTransform: 'none' }}
              variant="body2"
              color="info.main"
            >
              Show more
            </Typography>
          </Button>
        </Box>
        <JobDescription job={job} isOpen={isOpen} />
        <JobCardFooter
          job={job}
          onJobApply={onJobApply}
          onInvite={onInvite}
          loadJobs={loadJobs}
          isOpen={isOpen}
        />
      </Box>
    </Paper>
  );
};

export default JobCard;
