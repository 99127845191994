import React from 'react';
import { sortSkills } from '@libs/ui/components/job/utils';

import { Box, Collapse, Grid } from '@mui/material';

import { Job, Skill, SkillTypeEnum } from '@libs/graphql-types';
import SkillChip from '@libs/ui/components/chip/SkillChip';
import Typography from '@libs/ui/components/typography';

interface JobKeySkillsProps {
  job: Job;
  isOpen: boolean;
}

const TITLES = ['Technologies', 'Hard skills', 'Soft skills'];

const JobKeySkills = ({ isOpen, job }: JobKeySkillsProps) => {
  const {
    [SkillTypeEnum.HardSkills]: hard,
    [SkillTypeEnum.SoftSkills]: soft,
    [SkillTypeEnum.Solutions]: solutions,
  } = sortSkills((job.skills || []) as Skill[]);

  return (
    <Box pt={4}>
      <Typography style={{ paddingBottom: 8 }} variant="subtitle2">
        Key skills
      </Typography>

      <Collapse in={!isOpen}>
        <Grid container spacing={2}>
          {[solutions, hard, soft].map((items) =>
            items.map((i) => (
              <Grid key={i.id} item>
                <SkillChip skill={i} size="small" />
              </Grid>
            )),
          )}
        </Grid>
      </Collapse>

      <Collapse in={isOpen}>
        <div>
          {[solutions, hard, soft].map(
            (items, index) =>
              !!items.length && (
                <Box key={TITLES[index]} pb={2}>
                  <Typography variant="subtitle2" color="textSecondary">
                    {TITLES[index]}
                  </Typography>
                  <Grid style={{ minHeight: 32 }} spacing={2} container>
                    {items.map((i) => (
                      <Grid key={i.id} item>
                        <SkillChip skill={i} size="small" />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              ),
          )}
        </div>
      </Collapse>
    </Box>
  );
};

export default JobKeySkills;
