import React from 'react';

import { Box, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Job } from '@libs/graphql-types';

import { Comments } from './comments';

interface TimelineAndCommentsProps {
  job: Job;
}

const useStyles = makeStyles((theme) => ({
  wrap: {
    width: '100%',
    height: '420px',
  },
}));

const JobComments = ({ job }: TimelineAndCommentsProps) => {
  const classes = useStyles();

  return (
    <Paper elevation={0}>
      <Box className={classes.wrap}>
        <Comments job={job} />
      </Box>
    </Paper>
  );
};

export default JobComments;
