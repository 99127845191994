import { Form, Formik } from 'formik';
import Lottie from 'lottie-react';
import React from 'react';

import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import { Grid } from '@mui/material';

import {
  MutationCreateJobSuggestionArgs,
  Job,
  JobTypeEnum,
} from '@libs/graphql-types';
import { isNumber } from '@libs/helpers/common';
import { formatCurrency } from '@libs/helpers/format';
import Button from '@libs/ui/components/button';
import { ConnectedTextField } from '@libs/ui/components/form/text-field';
import { DefaultModal } from '@libs/ui/components/modals';
import Typography, { OuterLink } from '@libs/ui/components/typography';

import getLottieData from './assets/lottie-earn';
import validator from './validator';

interface InitialInviteModalProps {
  onSubmit: (vars: Omit<MutationCreateJobSuggestionArgs, 'job_id'>) => void;
  open: boolean;
  handleClose: VoidFunction;
  loading: boolean;
  job: Job;
}
const getData = (fee?: string | number) => ({
  [JobTypeEnum.Freelance]: {
    title: 'Earn €1.000 by referring your friend for this job',
    subtitle: (
      <>
        For more details please check{' '}
        <OuterLink
          href="https://www.notion.so/opentalent/Get-Paid-830a2d2bd1c34f51a06a0c2637564c18?pvs=4"
          target="_blank"
        >
          here
        </OuterLink>
      </>
    ),
  },
  [JobTypeEnum.Permanent]: {
    title: `Earn ${
      isNumber(fee) ? `${formatCurrency(fee)}` : 'up to €1.000'
    } by referring your friend for this job`,
    subtitle: 'This job has one-time finder’s fee',
  },
  [JobTypeEnum.Project]: {
    title: `Earn ${
      isNumber(fee) ? `${formatCurrency(fee)}` : ''
    } by referring your friend for this job`,
    subtitle: 'This job has one-time finder’s fee',
  },
});

const InitialInviteModal = ({
  onSubmit,
  open,
  handleClose,
  loading,
  job,
}: InitialInviteModalProps) => {
  const fee = job?.finders_fee;
  const data = getData(fee)[job.type ? job.type : JobTypeEnum.Freelance];

  return (
    <>
      <Formik
        validationSchema={validator}
        onSubmit={onSubmit}
        initialValues={{ first_name: '', email: '' }}
      >
        {({ handleSubmit }) => (
          <Form>
            <DefaultModal
              title="Invite befriended top talent"
              handleClose={handleClose}
              open={open}
              actions={
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                  autoFocus
                  size="large"
                  disabled={loading}
                  onClick={handleSubmit}
                >
                  send invite
                </Button>
              }
            >
              <br />
              <div style={{ width: 400, margin: '0 auto' }}>
                <Lottie loop animationData={JSON.parse(getLottieData(`€`))} />
              </div>
              <Typography
                style={{ fontWeight: 500, marginBottom: 8 }}
                variant="body1"
              >
                {data.title}
              </Typography>

              <Typography
                style={{ width: 330, margin: '8px auto' }}
                variant="body2"
                color="textSecondary"
              >
                {data.subtitle}
              </Typography>

              <br />
              <Grid spacing={4} direction="column" container>
                <Grid item>
                  <ConnectedTextField
                    name="name"
                    fullWidth
                    variant="outlined"
                    placeholder="First name"
                    InputProps={{
                      startAdornment: <PermIdentityIcon color="disabled" />,
                    }}
                  />
                </Grid>
                <Grid item>
                  <ConnectedTextField
                    name="email"
                    type="email"
                    fullWidth
                    variant="outlined"
                    placeholder="Email"
                    InputProps={{
                      startAdornment: <MailOutlineIcon color="disabled" />,
                    }}
                  />
                </Grid>
              </Grid>
            </DefaultModal>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default InitialInviteModal;
