import { mapChatUserData } from 'components/chat/client-to-talent';
import { useStreamChatContext } from 'components/chat/common/provider';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { pathManager } from 'routes';
import { createUserToTalentChat } from 'screens/chat/chat-creator/hooks';

import {
  Job,
  useCreateJobMatcherApplicationMutation,
} from '@libs/graphql-types';
import { FormikSubmit } from '@libs/helpers/form';

import { FormModel } from './types';

export const useSubmitAction = ({
  onSuccess,
  job,
}: {
  onSuccess: VoidFunction;
  job: Job;
}) => {
  const { client, userData } = useStreamChatContext();
  const campaignOwnerData = mapChatUserData(job.campaign_owner!);
  const history = useHistory();
  const jobId = job?.id;
  const { enqueueSnackbar } = useSnackbar();
  const [apply, { loading }] = useCreateJobMatcherApplicationMutation({
    onCompleted: () => {
      enqueueSnackbar('Applied!', { variant: 'success' });
    },
  });
  const onSubmit: FormikSubmit<FormModel> = useCallback(
    async ({ message }) => {
      const res = await apply({ variables: { job_id: jobId, message } });

      if (!res.data?.createJobMatcherApplication?.id) {
        return;
      }

      onSuccess();

      if (!userData) {
        enqueueSnackbar('No user data', { variant: 'error' });
        return;
      }

      const channel = await createUserToTalentChat(client, {
        user1: campaignOwnerData,
        user2: userData,
      });

      await channel?.sendMessage({
        text: message,
      });

      history.push(pathManager.chat.generatePath());
    },
    [
      apply,
      campaignOwnerData,
      client,
      enqueueSnackbar,
      history,
      jobId,
      onSuccess,
      userData,
    ],
  );
  return { loading, onSubmit };
};
