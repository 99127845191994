import React from 'react';

import { Grid, Button, Box } from '@mui/material';

import { DefaultModal } from '@libs/ui/components/modals';
import Typography from '@libs/ui/components/typography';

import img from '../assets/img@2x.png';
import { StepProps } from '../types';

interface ProposalStep1Props extends StepProps {}

export const ProposalStep1 = ({
  onSubmit,
  onBack,
  close,
}: ProposalStep1Props) => {
  return (
    <DefaultModal
      open
      handleClose={close}
      title="Candidate proposal form"
      actions={
        <Grid spacing={4} container>
          <Grid xs={6} item>
            <Button
              fullWidth
              size="large"
              color="info"
              variant="contained"
              onClick={onSubmit}
            >
              Start
            </Button>
          </Grid>
          <Grid xs={6} item>
            <Button
              fullWidth
              size="large"
              variant="outlined"
              color="info"
              onClick={onBack}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Box mt={6} mb={6}>
        <Typography variant="body2" color="textSecondary">
          Hey 👋, please propose your candidate for a specific role at
          OpenTalent by answering the following questions. We will present your
          candidate to the final client considering all requirements are met.
          Thanks for your support - by working together we can close this role
          fastest!
        </Typography>
      </Box>

      <Box>
        <img srcSet={`${img} 2x`} alt="img" style={{ width: '100%' }} />
      </Box>
    </DefaultModal>
  );
};
