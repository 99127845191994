import { gql } from '@apollo/client';

export default gql`
  query SearchSkills($search: String!, $skill_type: [SkillTypeEnum]!) {
    skillSearch(search: $search, skill_type: $skill_type) {
      id
      skill_type
      name
      slug
      created_at
      updated_at
    }
  }
`;
